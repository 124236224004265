import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Input, InputGroup, Pagination, Table} from 'rsuite'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {StateParamsModel} from '../../common/Model'
import {Close, Search} from '@rsuite/icons'
import {isEmpty} from 'lodash'
import * as existingCIFRedux from '../redux'
import {useHistory} from 'react-router-dom'

const Cell = Table.Cell

const ExistingCIFListBranchChecker = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [params, setParams] = useState<StateParamsModel>({
    status: 'Submitted to Branch Checker',
    page: 1,
    limit: 10,
    search: '',
    orderBy: 'updated_at',
    order: 'desc',
  })

  const {data: existingCIFListData, loading} = useSelector((state: any) => state.existingCIF)

  const data = existingCIFListData?.new_accounts?.map((newCIF: any, i: number) => ({
    ...newCIF,
    sn: (params?.page - 1) * params?.limit + (i + 1),
  }))

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <button
          className='btn btn-primary btn-sm ms-3'
          onClick={() => {
            history.push({
              pathname: `/existing-cif/existing-cif-listing/${rowData?.id}`,
              state: rowData,
            })
          }}
        >
          Review
        </button>
      </Cell>
    )
  }

  const columns = [
    {
      label: 'Account Name',
      dataKey: 'full_name',
      sortable: false,
      width: 200,
      cell: <Cell dataKey='full_name' />,
    },
    {
      label: 'CIF Number',
      dataKey: 'customer_number',
      sortable: false,
      width: 200,
      cell: <Cell dataKey='customer_number' />,
    },
    {
      label: 'Branch Location',
      dataKey: 'branch',
      cell: <Cell dataKey='branch' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Status',
      dataKey: 'account_status.current_status',
      cell: <Cell dataKey='account_status.current_status' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Updated On',
      dataKey: 'account_status.updated_date',
      cell: <Cell dataKey='account_status.updated_date' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Remarks',
      dataKey: 'account_status.remarks',
      cell: <Cell dataKey='account_status.remarks' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Action',
      width: 160,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  useEffect(() => {
    dispatch(existingCIFRedux.actions.getNewCIFList(params))
  }, [params])
  return (
    <div className='shadow p-3 bg-white rounded'>
      <div className='col-lg-6'>
        <div className='heading__component__title fw-bolder'>Exisiting CIF Lists</div>
      </div>
      <InputGroup inside style={{width: '30%', margin: '1rem 0'}}>
        <Input
          placeholder='Search'
          value={params.search}
          onChange={(value: string) => setParams({...params, search: value})}
          data-cy='search-field'
        />
        <InputGroup.Addon>
          {!isEmpty(params?.search) ? (
            <Close
              style={{cursor: 'pointer'}}
              onClick={() => setParams({...params, search: ''})}
              data-cy='search-close-button'
            />
          ) : (
            <Search />
          )}
        </InputGroup.Addon>
      </InputGroup>
      <div className='datatable'>
        <RSuiteTable columns={columns} data={data ?? []} showLoading={loading} />
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size='sm'
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={existingCIFListData?.meta?.total ?? 0}
          limitOptions={[10, 20, 50, 100]}
          limit={params?.limit}
          activePage={params?.page}
          onChangePage={(value) => setParams({...params, page: value})}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </div>
  )
}

export default ExistingCIFListBranchChecker
