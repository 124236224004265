import DetailCell from '../DetailCell'

interface OtherDetailsInformationSectionProps {
  data?: any
}

const OtherDetailsInformationSection = ({data}: OtherDetailsInformationSectionProps) => {
  return (
    <>
      <h2 className='text-center my-4'>OTHER DETAILS</h2>
      <div className='row'>
        <DetailCell
          label='Authorized Capital'
          value={data?.authorized_capital?.authorized_capital}
        />
        <DetailCell label='Paid Up Capital' value={data?.authorized_capital?.paid_up_capital} />
      </div>
      <div className='row'>
        <DetailCell
          label='Expected Source of Fund'
          value={data?.paid_up_capital?.expected_source_of_fund}
        />
        <DetailCell
          label='Expected Annual Business Turnover'
          value={data?.kyc_information?.expected_annual_business_turnover}
        />
      </div>
    </>
  )
}

export default OtherDetailsInformationSection
