import {toast} from 'react-toastify'
import {call, delay, put, takeLatest} from 'redux-saga/effects'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {actions} from './action'
import {actionTypes} from './constants'
import {services} from './service'

function* getCPUMakerNewCIFAndAccountIndividualJointMinorList(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(services.getCPUMakerNewCIFandAccountList, params)
    const data: any = response?.data?.data

    yield put(actions.getCPUMakerNewCIFandAccountIndividualJointMinorListSuccess(data))
  } catch (error: any) {
    yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
  } finally {
    yield put(actions.getCPUMakerNewCIFandAccountIndividualJointMinorListFinish())
  }
}

function* getCPUMakerNewCIFAndAccountCorporateBankList(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(
      services.getCPUMakerNewCIFandAccountCorporateBankList,
      params
    )
    const data: any = response?.data?.data

    yield put(actions.getCPUMakerNewCIFandAccountCorporateBankListSuccess(data))
  } catch (error: any) {
    yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
  } finally {
    yield put(actions.getCPUMakerNewCIFandAccountCorporateBankListFinish())
  }
}

function* postAccountPreliminaryKYCSaga(action: ActionModel) {
  try {
    const {id, data, additionalData} = action.payload
    const response: ResponseModel = yield call(services.postAccountPreliminaryKYCForm, id, data)
    if (additionalData) {
      yield call(postAccountPreliminaryKYCAdditionalSaga, {
        type: {type: actionTypes.POST_ACCOUNT_PRELIMINARY_KYC_ADDITIONAL_FORM_START},
        payload: {id, data: additionalData},
      })
    }
    yield put(actions.postAccountPreliminaryKYCFormSuccess())
    toast.success(response?.data?.resDesc ?? 'Review Submitted Successfully')
  } catch (error: any) {
    yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
  } finally {
    yield put(actions.postAccountPreliminaryKYCFormFinish())
  }
}

function* postAccountPreliminaryKYCAdditionalSaga(action: ActionModel) {
  try {
    const {id, data} = action.payload
    const response: ResponseModel = yield call(
      services.postAccountPreliminaryKYCAdditionalForm,
      id,
      data
    )
    yield put(actions.postAccountPreliminaryKYCAdditionalFormSuccess())
  } catch (error: any) {
    yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
  } finally {
    yield put(actions.postAccountPreliminaryKYCAdditionalFormFinish())
  }
}

export function* saga() {
  yield takeLatest(
    actionTypes.GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_MINOR_JOINT_MINOR_LIST_START,
    getCPUMakerNewCIFAndAccountIndividualJointMinorList
  )
  yield takeLatest(
    actionTypes.GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_LIST_START,
    getCPUMakerNewCIFAndAccountCorporateBankList
  )
  yield takeLatest(
    actionTypes.POST_ACCOUNT_PRELIMINARY_KYC_FORM_START,
    postAccountPreliminaryKYCSaga
  )
}
