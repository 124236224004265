import CloseIcon from '@rsuite/icons/Close'
import SearchIcon from '@rsuite/icons/Search'
import {ErrorMessage, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import moment from 'moment'
import {ChangeEvent, useEffect, useState} from 'react'
import FormFileInput from 'react-bootstrap-v5/lib/esm/FormFileInput'
import {FaPlus} from 'react-icons/fa'
import {useDispatch, useSelector} from 'react-redux'
import {Input, InputGroup, Modal, Pagination, Table} from 'rsuite'
import Select from 'rsuite/SelectPicker'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import {
  usePostUplaodExistingBulkAccounts,
  usePostUploadBulkAccount,
} from 'src/app/queries/BulkAccountOpening/post'
import DatePickerForGrievance from 'src/cms/helpers/components/forms/DatePickerForGrievance'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {RootState} from 'src/setup'
import * as Yup from 'yup'
import {BulkStateParamsModel} from '../Model'
import * as bulkAccountRedux from '../redux'

const Cell = Table.Cell

export const statusDropdownList = [
  {label: 'Batch Upload Initiated', value: 'batch_initiate'},
  {label: 'API Call Initiated', value: 'api_initiate'},
  {label: 'In Progress', value: 'in_progress'},
  {label: 'Partial Batch Upload Completed', value: 'partial_batch'},
  {label: 'Batch Upload Completed', value: 'completed_batch'},
]

const accountNatureDropdown = [
  {label: 'Real', value: 'Real'},
  {label: 'Remit', value: 'Remit'},
  {label: 'BBLB', value: 'BBLB'},
]

const uploadNewSchema = Yup.object().shape({
  account_nature: Yup.string().required('Required'),
})

const uploadExistingSchema = Yup.object().shape({
  id: Yup.string().required('Required'),
})

const MasterBatchList = () => {
  const dispatch = useDispatch()

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
  const [isUplaodExistingModalOpen, setIsUploadExistingModalOpen] = useState(false)
  const [params, setParams] = useState<BulkStateParamsModel>({
    table_name: 'master',
    page: 1,
    limit: 10,
    orderBy: 'updated_at',
    order: 'desc',
  })

  const {data: bulkAccountListData, loading} = useSelector((state: any) => state.bulkAccountList)

  const data =
    bulkAccountListData?.master_batches?.map((bulkAccount: any, i: number) => ({
      ...bulkAccount,
      sn: (params?.page - 1) * params?.limit + (i + 1),
    })) ?? []

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const handleOpenUploadModal = () => setIsUploadModalOpen(true)
  const handleCloseUploadModal = () => setIsUploadModalOpen(false)
  const handleOpenUploadExistingModal = () => setIsUploadExistingModalOpen(true)
  const handleCloseUploadExistingModal = () => setIsUploadExistingModalOpen(false)

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <button className='btn btn-primary btn-sm ms-3'>Review</button>
      </Cell>
    )
  }

  const columns = [
    {label: 'S.N.', dataKey: 'sn', width: 100, cell: <Cell dataKey='sn' />},
    {
      label: 'Master Batch Number',
      dataKey: 'batch_number',
      sortable: false,
      width: 250,
      cell: <Cell dataKey='batch_number' />,
    },
    {
      label: 'Total No. of Accounts',
      dataKey: 'no_of_accounts',
      width: 200,
      cell: <Cell dataKey='no_of_accounts' />,
    },
    {
      label: 'API Success Count',
      dataKey: 'apiSuccessCount',
      width: 150,
      cell: <Cell>0</Cell>,
    },
    {
      label: 'API Failure Count',
      dataKey: 'apiFailureCount',
      width: 150,
      cell: <Cell>0</Cell>,
    },
    {
      label: 'Child Batches',
      dataKey: 'childBatches',
      width: 150,
      cell: <Cell>0</Cell>,
    },
    {
      label: 'Batch Created On',
      dataKey: 'created_on',
      cell: <Cell dataKey='created_on' />,
      sortable: false,
      width: 150,
    },
    {
      label: 'Last Updated On',
      dataKey: 'lastUpdatedOn',
      cell: <Cell dataKey='lastUpdatedOn' />,
      sortable: false,
      width: 150,
    },
    {
      label: 'Status',
      dataKey: 'status',
      cell: <Cell dataKey='status' />,
      sortable: false,
      width: 230,
    },
  ]

  useEffect(() => {
    dispatch(bulkAccountRedux.actions.getBulkAccountOpeningList(params))
  }, [params])

  return (
    <div className='shadow p-3 bg-white rounded'>
      <div className='row g-0 border-bottom align-items-center mb-3 py-3'>
        <div className='col-lg-6'>
          <div className='heading__component__title fw-bolder'>Master Batch List</div>
        </div>
        <div className='col-lg-6'>
          <div className='d-flex justify-content-end'>
            <div className='sub_button' onClick={handleOpenUploadModal}>
              <CheckPermissions type='Add'>
                <button className='dt-btn dt-btn-primary' data-cy='add-button'>
                  <FaPlus className='me-2' />
                  Upload New Batch
                </button>
              </CheckPermissions>
            </div>
            <div
              className='sub_button'
              data-cy='design-update-status-button'
              onClick={handleOpenUploadExistingModal}
            >
              <CheckPermissions type='Add'>
                <button className='dt-btn dt-btn-primary' data-cy='update-button'>
                  <FaPlus className='me-2' />
                  Upload Existing Batch
                </button>
              </CheckPermissions>
            </div>
          </div>
        </div>
      </div>
      <ParamsComponent params={params} setParams={setParams} loading={loading} />
      <div className='datatable'>
        <RSuiteTable columns={columns} data={data} showLoading={loading} />
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size='sm'
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={bulkAccountListData?.meta?.total ?? 0}
          limitOptions={[10, 20, 50, 100]}
          limit={params?.limit}
          activePage={params?.page}
          onChangePage={(value) => setParams({...params, page: value})}
          onChangeLimit={handleChangeLimit}
        />
      </div>
      <UplaodNewBatchModal
        handleCloseUploadModal={handleCloseUploadModal}
        isUploadModalOpen={isUploadModalOpen}
      />
      <UplaodExistingBatchModal
        handleCloseUploadExistingModal={handleCloseUploadExistingModal}
        isUplaodExistingModalOpen={isUplaodExistingModalOpen}
      />
    </div>
  )
}

const UplaodNewBatchModal = ({
  isUploadModalOpen,
  handleCloseUploadModal,
}: {
  isUploadModalOpen: boolean
  handleCloseUploadModal: () => void
}) => {
  const dispatch = useDispatch()

  const uploadNewInitialVales: {account_nature: string; batch_file: File | null} = {
    account_nature: '',
    batch_file: null,
  }

  const {mutate: uploadBulkAccountOpening, isLoading, isSuccess} = usePostUploadBulkAccount()

  return (
    <Modal open={isUploadModalOpen} onClose={handleCloseUploadModal}>
      <Modal.Header>Upload New Batch</Modal.Header>
      <Formik
        initialValues={uploadNewInitialVales}
        onSubmit={(values, {setFieldError}) => {
          if (!values.batch_file) {
            setFieldError('batch_file', 'File is required')
            return
          }
          const formData = new FormData()
          formData.append('account_nature', values.account_nature)
          formData.append('batch_file', values.batch_file)
          uploadBulkAccountOpening({formData, handleCloseUploadModal})
        }}
        validationSchema={uploadNewSchema}
      >
        {({handleChange, setFieldValue, values, touched, errors}) => (
          <Form>
            <Modal.Body style={{minHeight: '15rem'}}>
              <FormSelect
                name='account_nature'
                setFieldValue={setFieldValue}
                containerClassName='mt-3 mb-10'
                onChange={handleChange}
                options={accountNatureDropdown}
                touched={touched}
                errors={errors}
                values={values}
                required
              />
              <FormFileInput
                type='file'
                name='batch_file'
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('batch_file', e?.target?.files?.[0])
                  console.log(values.batch_file)
                }}
              />
              <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                <ErrorMessage name='batch_file' component='div' className='field-error-message' />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type='submit' className='dt-btn dt-btn-primary' disabled={isLoading}>
                Submit
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

const UplaodExistingBatchModal = ({
  isUplaodExistingModalOpen,
  handleCloseUploadExistingModal,
}: {
  isUplaodExistingModalOpen: boolean
  handleCloseUploadExistingModal: () => void
}) => {
  const dispatch = useDispatch()

  const uploadExistingInitialVales: {id: string; batch_file: File | null} = {
    id: '',
    batch_file: null,
  }

  const {data: failedMasterBatchList}: {data: bulkAccountRedux.IFailedBulkAccountList['data']} =
    useSelector((state: RootState) => state.failedMasterBatchList)

  const {
    mutate: uplaodExistingBulkAccountOpening,
    isLoading,
    isSuccess,
  } = usePostUplaodExistingBulkAccounts()

  useEffect(() => {
    dispatch(bulkAccountRedux.actions.getFailedMasterBatchList())
  }, [])

  return (
    <Modal open={isUplaodExistingModalOpen} onClose={handleCloseUploadExistingModal}>
      <Modal.Header>Upload Existing Batch</Modal.Header>
      <Formik
        initialValues={uploadExistingInitialVales}
        onSubmit={(values, {setFieldError}) => {
          if (!values.batch_file) {
            setFieldError('batch_file', 'File is required')
            return
          }
          const formData = new FormData()
          formData.append('batch_file', values.batch_file)
          uplaodExistingBulkAccountOpening({
            id: values.id,
            formData,
            handleCloseUploadExistingModal,
          })
        }}
        validationSchema={uploadExistingSchema}
      >
        {({handleChange, setFieldValue, values, touched, errors}) => (
          <Form>
            <Modal.Body style={{minHeight: '15rem'}}>
              <FormSelect
                name='id'
                label='SELECT A MASTER BATCH'
                setFieldValue={setFieldValue}
                containerClassName='mt-3 mb-10'
                onChange={handleChange}
                options={failedMasterBatchList?.master_batches?.map((failedMasterBatchData) => ({
                  label: failedMasterBatchData.batch_number,
                  value: failedMasterBatchData.id,
                }))}
                touched={touched}
                errors={errors}
                values={values}
                required
              />
              <FormFileInput
                type='file'
                name='batch_file'
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('batch_file', e?.target?.files?.[0])
                  console.log(values.batch_file)
                }}
              />
              <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                <ErrorMessage name='batch_file' component='div' className='field-error-message' />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type='submit' className='dt-btn dt-btn-primary' disabled={isLoading}>
                Submit
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export const ParamsComponent = ({
  params,
  setParams,
  loading = false,
}: {
  params: BulkStateParamsModel
  setParams: React.Dispatch<React.SetStateAction<BulkStateParamsModel>>
  loading?: boolean
}) => {
  return (
    <div className='d-flex mb-3'>
      <div className='d-flex'>
        <Formik
          initialValues={{
            fromDate: '',
            toDate: '',
            status: '',
          }}
          onSubmit={(values) => {
            setParams({
              ...params,
              from_date: values.fromDate ? moment(values?.fromDate).format('YYYY-MM-DD') : '',
              to_date: values.toDate ? moment(values?.toDate).format('YYYY-MM-DD') : '',
              status: values.status,
            })
          }}
          data-cy='design-form'
        >
          {({touched, handleChange, errors, values, setFieldValue}) => {
            return (
              <Form>
                <div className='d-flex flex-row'>
                  <div className='col-4' style={{zIndex: 11}}>
                    <DatePickerForGrievance
                      containerClassName='col-12'
                      name='fromDate'
                      placeholderText='From date'
                      setFieldValue={setFieldValue}
                      value={values.fromDate || false}
                      touched={touched}
                      required={true}
                      showIcon={true}
                      errors={errors}
                    />
                  </div>
                  <div className='col-4 ms-5' style={{zIndex: 11}}>
                    <DatePickerForGrievance
                      containerClassName='col-12'
                      name='toDate'
                      placeholderText='To date'
                      setFieldValue={setFieldValue}
                      value={values.toDate || false}
                      touched={touched}
                      required={true}
                      showIcon={true}
                      errors={errors}
                    />
                  </div>
                  <div className='col-3 ms-5 d-flex align-items-center' style={{zIndex: 11}}>
                    <div className='w-100'>
                      <Select
                        data={statusDropdownList}
                        size='lg'
                        placeholder='Status'
                        onChange={(value) => setFieldValue('status', value)}
                        style={{width: '100%', height: '100%'}}
                      />
                    </div>
                  </div>
                  <div className='col-1 ms-5'>
                    <button
                      type='submit'
                      disabled={loading}
                      className='btn btn-primary btn'
                      data-cy='design-filter-button'
                    >
                      Filter
                    </button>
                  </div>
                </div>
                <InputGroup inside style={{width: '35%', margin: '1rem 0'}}>
                  <Input
                    placeholder='Search'
                    value={params.search}
                    onChange={(value: string) => setParams({...params, search: value})}
                    data-cy='search-field'
                  />
                  <InputGroup.Addon>
                    {!isEmpty(params?.search) ? (
                      <CloseIcon
                        style={{cursor: 'pointer'}}
                        onClick={() => setParams({...params, search: ''})}
                        data-cy='search-close-button'
                      />
                    ) : (
                      <SearchIcon />
                    )}
                    {/* <SearchIcon /> */}
                  </InputGroup.Addon>
                </InputGroup>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default MasterBatchList
