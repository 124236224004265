import {FC, useEffect, useState} from 'react'
import {Formik, Form, FieldArray, Field} from 'formik'
import * as Yup from 'yup'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from 'src/setup'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import LinkedEntitiesDetails from './LinkedEntitiesDetails'
import usePostLinkedEntities from 'src/app/queries/linkedEntities/post/usePostLinkedEntities'
import usePostCustomerInfoByCIF from 'src/app/queries/CBS/post/usePostCustomerInfoByCIF'
import useCreateLinkedEntities from 'src/app/queries/linkedEntities/post/useCreateLinkedEntities'
import * as masterDataRedux from 'src/app/modules/masterData/redux'
import {useHistory} from 'react-router-dom'

const validationSchema = Yup.object().shape({
  customer_no: Yup.string()
    .required('CIF Number is required')
    .min(6, 'CIF Number must be at least 6 digits'),
})

const sendValidationSchema = Yup.array().of(
  Yup.object().shape({
    customerNumber: Yup.string().required('Customer Number is required for send action'),
    relationship: Yup.string().required('Relation is required for send action'),
  })
)

const CreateLinkedEntities: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const authResp = useSelector((state: any) => state.auth)
  const {user} = authResp
  const [mainSearchResult, setMainSearchResult] = useState<any>(null)
  const [mainSearchLoading, setMainSearchLoading] = useState(false)
  const [itemSearchLoading, setItemSearchLoading] = useState<{[key: number]: boolean}>({})
  const [itemSearchResults, setItemSearchResults] = useState<{[key: number]: any}>({})
  const [firstSearchDone, setFirstSearchDone] = useState(false)
  const [displaySendError, setDisplaySendError] = useState(false)
  const [customerNo, setCustomerNo] = useState('')

  useEffect(() => {
    dispatch(masterDataRedux.actions.getAllMasterData())
  }, [dispatch])

  const {data: linkedEntitiesList = []} = useSelector(
    (state: RootState) => state?.linkedEntitiesList
  )

  const {mutate: fetchCustomerInfo, data} = usePostCustomerInfoByCIF()
  const {mutate: searchLinkedEntities, data: linkedEntitiesData} = usePostLinkedEntities()
  const {mutate: createLinkedEntities, isLoading: createLinkedEntitiesLoading} =
    useCreateLinkedEntities()

  useEffect(() => {
    if (data && !firstSearchDone) {
      setMainSearchResult(data.data)
      setMainSearchLoading(false)
      setFirstSearchDone(true)
    }
  }, [data, firstSearchDone])

  useEffect(() => {
    if (linkedEntitiesData) {
      setMainSearchResult((prev: any) => ({
        ...prev,
        entities: linkedEntitiesData.data.entities,
      }))
    }
  }, [linkedEntitiesData])

  const handleItemSearch = (
    customerNumber: string,
    index: number,
    setFieldError: any,
    setFieldTouched: any
  ) => {
    if (!customerNumber) {
      setTimeout(
        () => setFieldError(`items.${index}.customerNumber`, 'Customer Number is required'),
        100
      )
      return
    }
    setItemSearchLoading((prev) => ({...prev, [index]: true}))
    fetchCustomerInfo(
      {customer_no: customerNumber},
      {
        onSuccess: (data) => {
          setItemSearchResults((prev) => ({...prev, [index]: data.data}))
          setItemSearchLoading((prev) => ({...prev, [index]: false}))
        },
        onError: (error) => {
          setItemSearchLoading((prev) => ({...prev, [index]: false}))
        },
      }
    )
  }

  const handleSubmit = (values: any) => {
    setMainSearchLoading(true)
    fetchCustomerInfo(
      {customer_no: values.customer_no},
      {
        onSuccess: (data) => {
          if (!firstSearchDone) {
            setMainSearchResult(data.data)
          }
          setMainSearchLoading(false)
        },
        onError: (error) => {
          setMainSearchLoading(false)
        },
      }
    )
    searchLinkedEntities({cif_number: values.customer_no})
  }

  const handleSend = async (values: any, setFieldError: any, setFieldTouched: any) => {
    setDisplaySendError(false)
    console.log(values)
    const resultCustomerNumbers = Object.values(itemSearchResults)?.map(
      (item) => item?.customer_no ?? ''
    )
    const formCustomerNumbers: any[] = values?.items?.map((item: any) => item.customerNumber)
    const isEveryCIFSearched = formCustomerNumbers.every((customerNumber) =>
      resultCustomerNumbers.includes(customerNumber)
    )
    if (!isEveryCIFSearched) {
      setDisplaySendError(true)
      return
    }
    try {
      await sendValidationSchema.validate(values?.items, {abortEarly: false})
    } catch (err: any) {
      if (err?.inner) {
        err.inner.forEach((error: any) => {
          const errorPath = `items.${error.path}`
          setFieldTouched(errorPath, true)
          setTimeout(() => setFieldError(errorPath, error.message), 100)
        })
      }
      return
    }
    const payload = {
      action: 'save_send',
      primary_customer_number: mainSearchResult?.customer_no ?? '',
      primary_account_number: mainSearchResult?.account_number ?? '',
      primary_customer_name: mainSearchResult?.full_name ?? '',
      primary_customer_email: mainSearchResult?.email ?? '',
      branch: user?.data.branch.code ?? '',
      customer_number: values.items.map((item: any) => item.customerNumber),
      relationship: values.items.map((item: any) => item.relationship),
    }

    createLinkedEntities(payload, {
      onSuccess: () => {
        history.push(`/branch-maker/linked-entities/linked-entities-listing`)
      },
    })
  }

  return (
    <>
      <div className='heading__component__title fw-bolder mb-5'>Linked Entities</div>
      <Formik
        initialValues={{
          customer_no: customerNo,
          items:
            mainSearchResult?.entities?.length > 0
              ? (mainSearchResult.entities.map(
                  (result: {customer_number: string; relationship: string}) => ({
                    customerNumber: result.customer_number,
                    relationship: result.relationship,
                  })
                ) as {customerNumber: string; relationship: string}[])
              : [{customerNumber: '', relationship: ''}],
        }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({
          handleChange,
          errors,
          touched,
          values,
          setFieldValue,
          setFieldError,
          setFieldTouched,
        }) => (
          <div className='shadow p-8 bg-white rounded'>
            <Form>
              <div className='row align-items-center'>
                <div className='col-md-6'>
                  <FormTextBox
                    containerClassName='col-md-12'
                    type='text'
                    placeholder='Please Insert Your CIF Number'
                    label='Primary CIF Number'
                    name='customer_no'
                    value={values.customer_no}
                    onChange={(e: any) => {
                      handleChange(e)
                      setCustomerNo(e.target.value)
                    }}
                    errors={errors}
                    touched={touched}
                    required={true}
                  />
                </div>
                {!mainSearchResult && (
                  <div className='col-md-6'>
                    <button disabled={mainSearchLoading} type='submit' className='btn btn-primary'>
                      {mainSearchLoading ? 'Searching...' : 'Search'}
                    </button>
                  </div>
                )}
              </div>
            </Form>
            {mainSearchResult && (
              <LinkedEntitiesDetails
                customerNumber={mainSearchResult.customer_no}
                name={mainSearchResult.full_name}
                fatherName={mainSearchResult.father_name}
                grandFatherName={mainSearchResult.grandfather_name}
              />
            )}
            {mainSearchResult && (
              <FieldArray
                name='items'
                render={(arrayHelpers) => (
                  <div>
                    <div className='d-flex justify-content-between'>
                      <div className='heading__component__title fw-bolder mb-2'>
                        Entities to be linked:
                      </div>
                      <button
                        type='button'
                        onClick={() =>
                          arrayHelpers.push({
                            customerNumber: '',
                            relationship: '',
                          })
                        }
                        className='btn btn-secondary'
                      >
                        Add more item
                      </button>
                    </div>
                    {values.items.map((item, index) => (
                      <div className='d-flex row align-items-center mb-2' key={index}>
                        <div className='col-md-4'>
                          <Field name={`items.${index}.customerNumber`}>
                            {({field}: any) => (
                              <FormTextBox
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                type='text'
                                placeholder='Please Insert Your Customer Number'
                                label='Customer Number'
                                {...field}
                                errors={errors}
                                touched={touched}
                                required={true}
                              />
                            )}
                          </Field>
                        </div>
                        <div className='col-md-4'>
                          <Field name={`items.${index}.relationship`}>
                            {({field}: any) => (
                              <FormSelect
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                label='Relationship'
                                {...field}
                                isClearable={true}
                                onChange={(option: any) =>
                                  setFieldValue(`items.${index}.relationship`, option.value)
                                }
                                errors={errors}
                                touched={touched}
                                options={linkedEntitiesList?.map((entity: any) => ({
                                  label: entity?.title,
                                  value: entity?.id,
                                }))}
                                values={values}
                                setFieldValue={setFieldValue}
                                required={true}
                                arrValue={values.items[index].relationship}
                              />
                            )}
                          </Field>
                        </div>
                        <div className='col-md-4 d-flex'>
                          <button
                            type='button'
                            className='btn btn-primary me-2'
                            onClick={() => {
                              setFieldTouched(`items.${index}.customerNumber`, true, true)
                              handleItemSearch(
                                item.customerNumber,
                                index,
                                setFieldError,
                                setFieldTouched
                              )
                            }}
                            disabled={itemSearchLoading[index]}
                          >
                            {itemSearchLoading[index] ? 'Searching...' : 'Search'}
                          </button>
                          {index > 0 && (
                            <button
                              type='button'
                              className='btn btn-danger'
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              Delete
                            </button>
                          )}
                        </div>
                        {itemSearchResults[index] && (
                          <div className='col-12'>
                            <LinkedEntitiesDetails
                              customerNumber={itemSearchResults[index].customer_no}
                              name={itemSearchResults[index].full_name}
                              fatherName={itemSearchResults[index].father_name}
                              grandFatherName={itemSearchResults[index].grandfather_name}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              />
            )}
            <div className='d-flex justify-content-between'>
              {mainSearchResult && (
                <button
                  disabled={createLinkedEntitiesLoading}
                  type='button'
                  className='btn btn-primary'
                  onClick={() => {
                    handleSend(values, setFieldError, setFieldTouched)
                  }}
                >
                  {createLinkedEntitiesLoading ? 'Sending...' : 'Send'}
                </button>
              )}
            </div>
            {displaySendError && (
              <div className='fv-plugins-message-container text-danger fw-bolder small text-start mt-1'>
                You cannot send until you've searched every field
              </div>
            )}
          </div>
        )}
      </Formik>
    </>
  )
}

export default CreateLinkedEntities
