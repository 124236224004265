import {Table} from 'rsuite'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {columnModel} from 'src/cms/helpers/components/rsuiteTable/Models/columnModel'

const Cell = Table.Cell

interface StudentInformationSectionProps {
  data: any
}

const StudentInformationSection = ({data}: StudentInformationSectionProps) => {
  const columns: columnModel[] = [
    {label: 'S.N', cell: <Cell dataKey='sn' />},
    {label: 'Name of Institute', width: 250, cell: <Cell dataKey='name_of_institute' />},
    {label: 'Address', width: 230, cell: <Cell dataKey='student_address' />},
    {label: 'Phone no.', width: 230, cell: <Cell dataKey='phone_no' />},
  ]
  const studentData = data?.students?.students.map((student: any, index: number) => ({
    ...student,
    sn: index + 1,
  }))
  return (
    <>
      <h2 className='text-center my-4'>STUDENT INFORMATION</h2>
      <RSuiteTable columns={columns} data={studentData ?? []} />
    </>
  )
}

export default StudentInformationSection
