import {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Input, InputGroup, Pagination, Table} from 'rsuite'
import {StateParamsModel} from 'src/app/modules/common/Model'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import useGetLinkedEntitiesLists from 'src/app/queries/linkedEntities/get/useGetLinkedEntitiesList'
import {Close, Search} from '@rsuite/icons'
import {isEmpty} from 'lodash'

const Cell = Table.Cell

const LinkedEntitiesBranchCheckerList = () => {
  const history = useHistory()
  const [params, setParams] = useState<StateParamsModel>({
    status: 'Submitted to Branch Checker',
    page: 1,
    limit: 10,
    orderBy: 'updated_at',
    order: 'desc',
  })

  const {data: linkedEntitiesLists, isLoading: loading} = useGetLinkedEntitiesLists(params)

  const data =
    linkedEntitiesLists?.data?.linked_entities?.map((linkedLists: any, i: number) => ({
      ...linkedLists,
      updatedOn: linkedLists?.account_status?.updated_date,
      remarks: linkedLists?.account_status?.remarks ?? '',
    })) ?? []

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <button
          className='btn btn-primary btn-sm ms-3'
          onClick={() =>
            history.push({
              pathname: `/linked-entities/linked-entities-review/${rowData?.id}`,
              state: rowData,
            })
          }
        >
          Review
        </button>
      </Cell>
    )
  }

  const columns = [
    {
      label: 'Primary Customer Number',
      dataKey: 'primary_customer_number',
      width: 180,
      cell: <Cell dataKey='primary_customer_number' />,
    },
    {
      label: 'Primary Account Number',
      dataKey: 'primary_account_number',
      cell: <Cell dataKey='primary_account_number' />,
      sortable: false,
      width: 180,
    },
    {
      label: 'Primary Customer Name',
      dataKey: 'primary_customer_name',
      width: 200,
      cell: <Cell dataKey='primary_customer_name' />,
    },
    {
      label: 'Primary Customer Email',
      dataKey: 'primary_customer_email',
      width: 200,
      cell: <Cell dataKey='primary_customer_email' />,
    },
    {
      label: 'Branch',
      dataKey: 'branch',
      sortable: false,
      width: 140,
      cell: <Cell dataKey='branch' />,
    },
    {
      label: 'Updated On',
      dataKey: 'updatedOn',
      cell: <Cell dataKey='updatedOn' />,
      sortable: false,
      width: 140,
    },
    {
      label: 'Remarks',
      dataKey: 'remarks',
      cell: <Cell dataKey='remarks' />,
      sortable: false,
      width: 230,
    },
    {
      label: 'Action',
      width: 160,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  return (
    <>
      <div className='shadow p-3 bg-white rounded'>
        <div className='row g-0 border-bottom align-items-center mb-3 py-3'>
          <div className='col-lg-6'>
            <div className='heading__component__title fw-bolder'>Linked Entities Lists</div>
          </div>
        </div>
        <InputGroup inside style={{width: '30%', margin: '1rem 0'}}>
          <Input
            placeholder='Search'
            value={params.search}
            onChange={(value: string) => setParams({...params, search: value})}
            data-cy='search-field'
          />
          <InputGroup.Addon>
            {!isEmpty(params?.search) ? (
              <Close
                style={{cursor: 'pointer'}}
                onClick={() => setParams({...params, search: ''})}
                data-cy='search-close-button'
              />
            ) : (
              <Search />
            )}
          </InputGroup.Addon>
        </InputGroup>
        <div className='datatable'>
          <RSuiteTable columns={columns} data={data} showLoading={loading} />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={linkedEntitiesLists?.data?.meta?.total ?? 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </div>
    </>
  )
}

export default LinkedEntitiesBranchCheckerList
