import React from 'react'

interface CustomerInfoProps {
  customerNumber: string
  name: string
  fatherName: string
  grandFatherName: string
}

const LinkedEntitiesDetails: React.FC<CustomerInfoProps> = ({
  customerNumber,
  name,
  fatherName,
  grandFatherName,
}) => {
  return (
    <div className='row my-6'>
      <div className='col-md-4'>
        <span className='fs-5 fw-bolder'>Customer Number: </span>
        <span className='fs-5 opacity-80'>{customerNumber}</span>
      </div>
      <div className='col-md-4'>
        <span className='fs-5 fw-bolder'>Name: </span>
        <span className='fs-5 opacity-80'>{name}</span>
      </div>
      <div className='col-md-4'>
        <span className='fs-5 fw-bolder'>Father Name: </span>
        <span className='fs-5 opacity-80'>{fatherName}</span>
      </div>
      <div className='col-md-4'>
        <span className='fs-5 fw-bolder'>Grand Father Name: </span>
        <span className='fs-5 opacity-80'>{grandFatherName}</span>
      </div>
    </div>
  )
}

export default LinkedEntitiesDetails
