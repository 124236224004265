import axios from 'axios'
import {BULK_ACCOUNT_OPENING} from '../api'
import {toast} from 'react-toastify'
import {useMutation} from 'react-query'

const uploadExistingBulkAccounts = async (
  id: string,
  formData: FormData,
  handleCloseUploadExistingModal: () => void
) => {
  try {
    return await axios.post(`${BULK_ACCOUNT_OPENING}/${id}/existing-upload`, formData, {
      headers: {'Content-Type': 'multipart/form-data'},
    })
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message)
      console.error('Error while uploading bulk account opening file :: ', error)
    } else {
      console.log(error)
    }
  }
}

export const usePostUplaodExistingBulkAccounts = () =>
  useMutation({
    mutationFn: async ({
      id,
      formData,
      handleCloseUploadExistingModal,
    }: {
      id: string
      formData: FormData
      handleCloseUploadExistingModal: () => void
    }) => {
      await uploadExistingBulkAccounts(id, formData, handleCloseUploadExistingModal)
    },
  })
