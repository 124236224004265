import {FC} from 'react'
import {useIntl} from 'react-intl'

import {PageTitle} from 'src/cms/layout/core'

import DuplicateCIFCheck from './component.tsx/DuplicateCIFCheck'
import DashBoardCard from './component.tsx/DashBoardCard'
import {DashboardCardData} from './constant'
import {useSelector} from 'react-redux'

interface DashboardCardDataIndex {
  [key: string]: {label: string; path: string; imageUrl: string}[]
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const authResp = useSelector((state: any) => state.auth)
  const {user} = authResp
  const userRole = user?.data?.role?.name as string
  const cardData =
    (DashboardCardData as DashboardCardDataIndex)[userRole] || DashboardCardData['branchmaker']

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DuplicateCIFCheck />
      <div className='row'>
        {cardData.map((item, index, imageUrl) => (
          <DashBoardCard key={index} label={item.label} imageUrl={item.imageUrl} path={item.path} />
        ))}
      </div>
    </>
  )
}

export {DashboardWrapper}
