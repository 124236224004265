import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL
const customerInfoByCIF = `${API_URL}/linked-entities/create`

interface Payload {
  action: string
  primary_customer_number: string
  primary_account_number: string
  primary_customer_name: string
  primary_customer_email: string
  branch: string
  relationship: string[]
  customer_number: string[]
}

const createLinkedEntities = async (payload: Payload) => {
  try {
    const response = await axios.post(customerInfoByCIF, payload)
    return response.data
  } catch (error: any) {
    throw new Error(`${error.response?.data?.message || error.message}`)
  }
}

const useCreateLinkedEntities = () => {
  const queryClient = useQueryClient()
  return useMutation(createLinkedEntities, {
    onSuccess: (data) => {
      if (data?.resCod == '200') {
        toast.success(data?.resDesc ?? 'Customer Info Fetched successfully!')
      }
      queryClient.invalidateQueries('getlinkedEntitesList')
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error.message || 'An error occurred'}`)
    },
  })
}

export default useCreateLinkedEntities
