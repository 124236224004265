import {FC, useEffect, useState} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import useSubmitDocuments from 'src/app/queries/Account Preliminary/post/usePostDocumentsAccountPreliminary'

interface FingerprintState {
  right: string[]
  left: string[]
}

interface FeatureState {
  right: string[]
  left: string[]
}

interface SelectedFingerprint {
  image: string
  index: number | null
  confirmed: boolean
}

interface EnrollingState {
  right: boolean
  left: boolean
}

interface FingerprintVerifySectionProp {
  RightFingerprintId: string
  LeftFingerprintId: string
  accountId: string
  setResetForm: (resetForm: boolean) => void
  resetForm: boolean
}

const FingerprintVerifySection: FC<FingerprintVerifySectionProp> = ({
  RightFingerprintId,
  LeftFingerprintId,
  accountId,
  setResetForm,
  resetForm,
}) => {
  const [fingerCounts, setFingerCounts] = useState({right: 0, left: 0})
  const [fingerprints, setFingerprints] = useState<FingerprintState>({
    right: ['', '', ''],
    left: ['', '', ''],
  })
  const [features, setFeatures] = useState<FeatureState>({
    right: ['', '', ''],
    left: ['', '', ''],
  })
  const [enrolling, setEnrolling] = useState<EnrollingState>({right: false, left: false})
  const [selectedFingerprint, setSelectedFingerprint] = useState<{
    left: SelectedFingerprint
    right: SelectedFingerprint
  }>({
    left: {image: '', index: null, confirmed: false},
    right: {image: '', index: null, confirmed: false},
  })
  const [showConfirmation, setShowConfirmation] = useState(false)
  const {mutate, isLoading} = useSubmitDocuments()
  const fingerprintUrl = 'http://localhost:55031/'

  const base64ToBlob = (base64: any, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(base64.split(',')[1])
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, {type: contentType})
  }

  const handleEnroll = async (hand: 'right' | 'left') => {
    setEnrolling((prevEnrolling) => ({...prevEnrolling, [hand]: true}))

    try {
      for (let i = 0; i < 3; i++) {
        const url = `${fingerprintUrl}scan?mode=dataandfeature`
        const response = await axios.get(url)
        const data = response.data

        if (data.status) {
          const base64 = `data:image/png;base64,${data.data}`
          const feature = data.feature

          setFingerprints((prevFingerprints) => {
            const updatedFingerprints = [...prevFingerprints[hand]]
            updatedFingerprints[i] = base64
            return {...prevFingerprints, [hand]: updatedFingerprints}
          })

          setFeatures((prevFeatures) => {
            const updatedFeatures = [...prevFeatures[hand]]
            updatedFeatures[i] = feature
            return {...prevFeatures, [hand]: updatedFeatures}
          })

          setFingerCounts((prevCounts) => ({
            ...prevCounts,
            [hand]: prevCounts[hand] + 1,
          }))

          if (i < 2) {
            toast.info(`Attempt ${i + 1} successful. Please scan again.`)
          } else {
            toast.success('All three attempts were successful.')
          }
        } else {
          toast.error(
            `Error: ${
              data?.message ??
              "Fingerprint couldn't be scanned. Please reinsert or reset the device."
            }`
          )
          break
        }
      }
    } catch (error) {
      const errorMessage = (error as any).response?.data?.message || 'Error scanning fingerprint'
      toast.error(errorMessage)
    } finally {
      setEnrolling((prevEnrolling) => ({...prevEnrolling, [hand]: false}))
    }
  }

  const resetFingerprintDevice = async () => {
    try {
      const resp = await axios.get(`${fingerprintUrl}scan?mode=reset`)
      if (resp.data.status) {
        toast.success('Reset successfully')
      }
      setFingerCounts({right: 0, left: 0})
      setFingerprints({right: ['', '', ''], left: ['', '', '']})
      setFeatures({right: ['', '', ''], left: ['', '', '']})
      setSelectedFingerprint({
        left: {image: '', index: null, confirmed: false},
        right: {image: '', index: null, confirmed: false},
      })
    } catch (error) {
      console.error('Error resetting fingerprint device:', error)
    }
  }

  useEffect(() => {
    if (resetForm) {
      resetFingerprintDevice()
      setResetForm(false)
    }
  }, [resetForm])

  const handleCancel = async (hand: 'right' | 'left') => {
    setFingerCounts((prevCounts) => ({
      ...prevCounts,
      [hand]: 0,
    }))
    setFingerprints((prevFingerprints) => ({
      ...prevFingerprints,
      [hand]: ['', '', ''],
    }))
    setFeatures((prevFeatures) => ({
      ...prevFeatures,
      [hand]: ['', '', ''],
    }))
    setSelectedFingerprint((prevState) => ({
      ...prevState,
      [hand]: {
        image: '',
        index: null,
        confirmed: false,
      },
    }))

    try {
      const resp = await axios.get(`${fingerprintUrl}scan?mode=reset`)
    } catch (error) {
      toast.error('Error Canceling Device')
    }
  }

  const handleImageClick = (hand: 'right' | 'left', index: number) => {
    setSelectedFingerprint((prevState) => ({
      ...prevState,
      [hand]: {
        ...prevState[hand],
        index,
      },
    }))
    setShowConfirmation(true)
  }

  const handleConfirmation = (confirm: boolean) => {
    setShowConfirmation(false)
    if (confirm) {
      const {left, right} = selectedFingerprint
      if (left.index !== null && !selectedFingerprint.left.confirmed) {
        setSelectedFingerprint((prevState) => ({
          ...prevState,
          left: {
            image: fingerprints.left[left.index!],
            index: left.index,
            confirmed: false,
          },
        }))
      }
      if (right.index !== null && !selectedFingerprint.right.confirmed) {
        setSelectedFingerprint((prevState) => ({
          ...prevState,
          right: {
            image: fingerprints.right[right.index!],
            index: right.index,
            confirmed: false,
          },
        }))
      }
    }
  }

  const handleConfirmStatus = (hand: 'right' | 'left') => {
    setSelectedFingerprint((prevState) => {
      return {
        ...prevState,
        [hand]: {
          ...prevState[hand],
          confirmed: true,
        },
      }
    })

    if (hand == 'right') {
      const base64Image = selectedFingerprint[hand].image
      const blob = base64ToBlob(base64Image, 'image/png')

      const formData = new FormData()
      formData.append('image', blob, 'fingerprint.png')
      formData.append('documentTypeId', RightFingerprintId)
      mutate({formData, accountId: accountId})
    } else {
      const base64Image = selectedFingerprint[hand].image
      const blob = base64ToBlob(base64Image, 'image/png')

      const formData = new FormData()
      formData.append('image', blob, 'fingerprint.png')
      formData.append('documentTypeId', LeftFingerprintId)
      mutate({formData, accountId: accountId})
    }

    setFingerprints((prevState) => ({
      ...prevState,
      [hand]: ['', '', ''],
    }))

    setFingerCounts((prevCount) => ({
      ...prevCount,
      [hand]: 0,
    }))
  }

  return (
    <div className='border'>
      <div className='bg-warning text-white px-3 py-1 d-flex justify-content-between align-items-center'>
        <span>Fingerprint Verify</span>
        <button
          className='btn-sm text-white'
          style={{backgroundColor: '#006400'}}
          onClick={resetFingerprintDevice}
        >
          Reset
        </button>
      </div>
      <div className='row m-5'>
        <div className='col-md-6'>
          <div className='border'>
            <div className='bg-secondary p-2'>
              <strong>
                Finger Count: Right: {fingerCounts.right}, Left: {fingerCounts.left}
              </strong>
            </div>
            <div className='d-flex justify-content-around p-3'>
              {fingerprints.left.map(
                (img, index) =>
                  img && (
                    <div key={index} className='text-center'>
                      <img
                        src={img}
                        alt={`Left thumb ${index + 1}`}
                        style={{width: '174px', height: '174px', cursor: 'pointer'}}
                        onClick={() => handleImageClick('left', index)}
                      />
                      <div>Finger Count: {index + 1}</div>
                    </div>
                  )
              )}
              {fingerprints.right.map(
                (img, index) =>
                  img && (
                    <div key={index} className='text-center'>
                      <img
                        src={img}
                        alt={`Right thumb ${index + 1}`}
                        style={{width: '174px', height: '174px', cursor: 'pointer'}}
                        onClick={() => handleImageClick('right', index)}
                      />
                      <div>Finger Count: {index + 1}</div>
                    </div>
                  )
              )}
            </div>
            <div className='bg-secondary p-2'>
              <div>
                {(fingerCounts.right > 0 || fingerCounts.left > 0) && (
                  <button
                    onClick={() => {
                      handleCancel(
                        typeof fingerCounts.right === 'string'
                          ? 'left'
                          : fingerCounts.right > 0
                          ? 'right'
                          : 'left'
                      )
                    }}
                    className='btn-sm text-white btn-danger'
                  >
                    Cancel
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='border'>
                <div className='bg-secondary p-2'>
                  <strong>Left Thumb</strong>
                </div>
                <div
                  style={{
                    height: '15rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {selectedFingerprint?.left?.image !== '' && (
                    <img
                      src={selectedFingerprint?.left?.image}
                      alt={`Left thumb image`}
                      style={{width: '174px', height: '174px', cursor: 'pointer'}}
                    />
                  )}
                </div>
                <div className='bg-secondary p-3'>
                  {!selectedFingerprint?.left?.confirmed && fingerCounts?.left < 3 && (
                    <button
                      className='btn-sm btn-primary text-white'
                      onClick={() => handleEnroll('left')}
                      disabled={enrolling.right || fingerCounts?.right > 1}
                    >
                      {enrolling.left ? 'Loading' : 'Enroll'}
                    </button>
                  )}
                  {fingerCounts.left >= 1 &&
                    selectedFingerprint?.left?.image &&
                    !selectedFingerprint?.left?.confirmed && (
                      <button
                        className='btn-sm btn-danger text-white'
                        onClick={() => handleConfirmStatus('left')}
                      >
                        {isLoading ? 'sending...' : 'confirm'}
                      </button>
                    )}
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='border'>
                <div className='bg-secondary p-2'>
                  <strong>Right Thumb</strong>
                </div>
                <div
                  style={{
                    height: '15rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {selectedFingerprint?.right?.image !== '' && (
                    <img
                      src={selectedFingerprint?.right?.image}
                      alt={`Right thumb image`}
                      style={{width: '174px', height: '174px', cursor: 'pointer'}}
                    />
                  )}
                </div>
                <div className='bg-secondary p-3'>
                  {!selectedFingerprint?.right?.confirmed && fingerCounts?.right < 3 && (
                    <button
                      className='btn-sm btn-primary text-white'
                      onClick={() => handleEnroll('right')}
                      disabled={enrolling.left || fingerCounts?.left > 1}
                    >
                      {enrolling.right ? 'Loading' : 'Enroll'}
                    </button>
                  )}

                  {fingerCounts.right >= 1 &&
                    selectedFingerprint?.right?.image &&
                    !selectedFingerprint?.right?.confirmed && (
                      <button
                        className='btn-sm btn-danger text-white'
                        onClick={() => handleConfirmStatus('right')}
                      >
                        confirm
                      </button>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='p-5'>
        <strong>Note</strong>
        <br />
        Step 1: Click Enroll <br />
        Step 2: Place Thumb Finger 3 times
      </div>
      <DeleteModal
        isOpen={showConfirmation}
        handleClick={() => handleConfirmation(true)}
        handleClose={() => handleConfirmation(false)}
        title='select this fingerprint'
      />
    </div>
  )
}

export default FingerprintVerifySection
