import {useDispatch, useSelector} from 'react-redux'
import {
  DigitalSignatureSection,
  FingerprintVerifySection,
  PPSizePhotoSection,
  PersonalDetails,
} from './sections'
import {IoMdRefresh} from 'react-icons/io'
import {useEffect, useState} from 'react'
import * as masterDataRedux from 'src/app/modules/masterData/redux'
import {DocumentTypeModel} from 'src/app/modules/masterData/Model'
import {RootState} from 'src/setup'
import {useHistory, useLocation, useParams} from 'react-router-dom'

interface RouteParams {
  id: string
}
const BiometricVerificationPage = () => {
  const dispatch = useDispatch()
  const {id: accountId} = useParams<RouteParams>()
  const [resetForm, setResetForm] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const rowData = location.state

  useEffect(() => {
    dispatch(masterDataRedux.actions.getDocumentTypes())
  }, [])

  const {data: documentTypeList}: {data: DocumentTypeModel[]} = useSelector(
    (state: RootState) => state?.documentTypeList
  )
  const PPSizePhotoId = documentTypeList.find((item) => item.title === 'PP Size Photo')?.id
  const RightFingerprintId = documentTypeList.find((item) => item.title === 'FingerPrint Right')?.id
  const LeftFingerprintId = documentTypeList.find((item) => item.title === 'FingerPrint Left')?.id
  const SignatureId = documentTypeList.find((item) => item.title === 'Signature')?.id
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-9'>
        <div className='mb-8'>
          <PersonalDetails data={rowData} />
        </div>
        <div className='my-10'>
          <PPSizePhotoSection
            PPSizePhotoId={PPSizePhotoId ?? ''}
            accountId={accountId}
            setResetForm={setResetForm}
            resetForm={resetForm}
          />
        </div>
        <div className='my-10'>
          <FingerprintVerifySection
            RightFingerprintId={RightFingerprintId ?? ''}
            LeftFingerprintId={LeftFingerprintId ?? ''}
            accountId={accountId}
            setResetForm={setResetForm}
            resetForm={resetForm}
          />
        </div>
        <div className='my-10'>
          <DigitalSignatureSection
            SignatureId={SignatureId ?? ''}
            accountId={accountId}
            setResetForm={setResetForm}
            resetForm={resetForm}
          />
        </div>
        <div className='row'>
          <div
            className='col-3 opacity-50'
            style={{cursor: 'pointer'}}
            onClick={() => setResetForm(true)}
          >
            <div className='d-flex flex-column align-items-center justify-content-center text-center'>
              <div className='d-flex align-items-center justify-content-center mb-2'>
                <IoMdRefresh size={23} />
                <span className='h4 ms-3 mb-n1'>Reset Form</span>
              </div>
              <span>Note: All the captured data will be cleared</span>
            </div>
          </div>
          <div className='col-3'></div>
          <div className='user_form_button d-flex mt-5 justify-content-end'>
            <button
              className='heading__component__btn'
              style={{backgroundColor: '00c0ef'}}
              type='submit'
              onClick={() => history.push('/branch-maker/new-cif-and-account-list')}
              disabled={false}
            >
              Submit
            </button>
            <div className='sub_button d-flex justify-content-end'>
              <button
                onClick={() => history.goBack()}
                className='btn'
                style={{backgroundColor: '#dc3545'}}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BiometricVerificationPage
