import CIFReview from 'src/app/modules/common/components/CIFReview'
const NewCIFCheckerReviewPage = () => {
  return (
    <div>
      <h1>New CIF Review</h1>
      <CIFReview />
    </div>
  )
}

export default NewCIFCheckerReviewPage
