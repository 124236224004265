import React, {useState} from 'react'
import {Tabs, Tab} from 'react-bootstrap-v5'
import {
  CorporateBank,
  IndividualMinorJointTable,
  IndividualMinorOnline,
  IndividualMinorOnlineBulkBLB,
  IndividualMinorOnlineBulkReal,
  IndividualMinorOnlineBulkRemit,
} from './tables'

const CPUCheckerNewCIFAndAccountList = () => {
  const [activeKey, setActiveKey] = useState('individualJointMinor')
  return (
    <>
      <h1 className='my-4'>New CIF and Account List</h1>
      <div className='modal-container shadow rounded' data-cy='new-cif-account-list-modal'>
        <div className='bg-white p-5'>
          <Tabs
            activeKey={activeKey}
            onSelect={(k) => setActiveKey(k ?? 'individualJointMinor')}
            id='uncontrolled-tab-example'
          >
            <Tab eventKey='individualJointMinor' title='Individual/Joint/Minor'>
              <div className='card-body bg-white' data-cy='tab-content-individual-joint-minor'>
                <IndividualMinorJointTable />
              </div>
            </Tab>
            <Tab eventKey='corporateBank' title='Corporate/Bank'>
              <div className='card-body bg-white'>
                <CorporateBank />
              </div>
            </Tab>
            {/* <Tab eventKey='individualMinorOnline' title='Individual/Minor (Online)'>
              <div className='card-body bg-white'>
                <IndividualMinorOnline />
              </div>
            </Tab> */}
            <Tab eventKey='individualMinorBulkReal' title='Individual/Minor (Bulk Real)'>
              <div className='card-body bg-white'>
                <IndividualMinorOnlineBulkReal />
              </div>
            </Tab>
            <Tab eventKey='individualMinorBulkRemit' title='Individual/Minor (Bulk Remit)'>
              <div className='card-body bg-white'>
                <IndividualMinorOnlineBulkRemit />
              </div>
            </Tab>
            <Tab eventKey='individualMinorBulkBLB' title='Individual/Minor (Bulk BLB)'>
              <div className='card-body bg-white'>
                <IndividualMinorOnlineBulkBLB />
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  )
}

export default CPUCheckerNewCIFAndAccountList
