import {useSelector} from 'react-redux'
import {MasterDataModel} from 'src/app/modules/masterData/Model'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {RootState} from 'src/setup'

interface AccountInformationSectionProps {
  handleChange: any
  touched: any
  errors: any
  setFieldValue: any
  values: any
}

const AccountInformationSection = ({
  handleChange,
  touched,
  errors,
  setFieldValue,
  values,
}: AccountInformationSectionProps) => {
  const {data: kycLevelCategories}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.kycLevelCategoryList
  )
  const {data: accountPurposes}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.accountPurposeList
  )
  const {data: accountCategories}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.accountCategoriesList
  )
  const {data: currencies}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.currenciesList
  )

  return (
    <>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='account_category'
          label='Account Category'
          containerClassName='col-12'
          labelClassName='col-12'
          options={
            accountCategories.map((accountCategory) => ({
              label: accountCategory.title,
              value: accountCategory.id,
            })) ?? []
          }
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      {values.account_category ===
        accountCategories.find((accountCategory) => accountCategory.title === 'Others')?.id && (
        <div className='col-md-6 col-12'>
          <FormTextBox
            name='account_category_other'
            label='Other Account Category'
            containerClassName='col-12'
            labelClassName='col-12'
            onChange={handleChange}
            errors={errors}
            touched={touched}
          />
        </div>
      )}
      <div className='col-md-6 col-12'>
        <FormSelect
          name='account_currency'
          label='Account Currency'
          containerClassName='col-12'
          labelClassName='col-12'
          options={
            currencies.map((currency) => ({
              label: currency.title,
              value: currency.cbs_code ?? '',
            })) ?? []
          }
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          disabled
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='account_purpose'
          label='Account Purpose'
          containerClassName='col-12'
          labelClassName='col-12'
          options={
            accountPurposes.map((accountPurpose) => ({
              label: accountPurpose.title,
              value: accountPurpose.id,
            })) ?? []
          }
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      {values.account_purpose ===
        accountPurposes.find((accountPurpose) => accountPurpose.title === 'Others')?.id && (
        <div className='col-md-6 col-12'>
          <FormTextBox
            name='account_purpose_other'
            label='Other Account Purpose'
            containerClassName='col-12'
            labelClassName='col-12'
            onChange={handleChange}
            errors={errors}
            touched={touched}
          />
        </div>
      )}
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='salutation'
          label='Salutation'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
          disabled
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='first_name'
          label='First Name'
          labelClassName='col-12'
          containerClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
          disabled
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='middle_name'
          label='Middle Name'
          labelClassName='col-12'
          containerClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
          disabled
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='last_name'
          label='Last Name'
          labelClassName='col-12'
          containerClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
          disabled
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='cheque_book_name'
          label='Cheque Book Name'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
          disabled
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='kyc_level_category'
          label='KYC Level Category'
          containerClassName='col-12'
          labelClassName='col-12'
          options={
            kycLevelCategories.map((kycLevelCategory) => ({
              label: kycLevelCategory.title,
              value: kycLevelCategory.id,
            })) ?? []
          }
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
    </>
  )
}

export default AccountInformationSection
