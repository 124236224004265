import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'

interface OtherDetailsSectionProps {
  handleChange: any
  touched: any
  errors: any
  setFieldValue: any
  values: any
}

const OtherDetailsSection = ({
  handleChange,
  touched,
  errors,
  setFieldValue,
  values,
}: OtherDetailsSectionProps) => {
  return (
    <>
      <div className='col-12'>
        <h2 className='text-center'>OTHER DETAILS</h2>
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='authorized_capital'
          label='Authorized Capital'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='paid_up_capital'
          label='Paid Up Capital'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='expocted_source_of_fund'
          label='Expected Source of Fund'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='expected_annual_business_turnover'
          label='Expected Annual Business Turnover'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
        />
      </div>
    </>
  )
}

export default OtherDetailsSection
