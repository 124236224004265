import {useState, useEffect} from 'react'
import {toast} from 'react-toastify'
import {Checkbox, Modal} from 'rsuite'
import {usePostVerifyOTP} from 'src/app/queries/Email Verification/post/usePostVerifyOTP'

interface TermsAndConditionsModalProps {
  open: boolean
  onClose: () => void
  onSubmit?: () => void
  uniqueId?: string
  code?: string
  onLoadingChange?: (isLoading: boolean) => void
  setIsVerificationModalOpen?: (value: boolean) => void
  setIsEmailVerified?: (value: boolean) => void
}

const TermsAndConditionsModal = ({
  open,
  onClose,
  onSubmit,
  uniqueId,
  code,
  onLoadingChange,
  setIsVerificationModalOpen,
  setIsEmailVerified,
}: TermsAndConditionsModalProps) => {
  const [isChecked, setIsChecked] = useState(false)
  const {mutate, isLoading} = usePostVerifyOTP()

  useEffect(() => {
    if (onLoadingChange) {
      onLoadingChange(isLoading ?? false)
    }
  }, [isLoading, onLoadingChange])

  const verifyOTP = () => {
    onClose()
    mutate(
      {newCIFNewAccount: uniqueId ?? '', code: code ?? ''},
      {
        onSuccess: (resp) => {
          if (resp?.data?.resDesc == 'OTP is verified') {
            setIsEmailVerified && setIsEmailVerified(true)
            setIsVerificationModalOpen && setIsVerificationModalOpen(false)
            toast.success('OTP verified successfully')
          } else {
            toast.error('OTP verification failed')
          }
        },
      }
    )
  }

  return (
    <Modal className='w-25' open={open} onClose={onClose}>
      <Modal.Header>Terms and Conditions</Modal.Header>
      <Modal.Body>Terms and conditions goes here</Modal.Body>
      <Checkbox
        defaultChecked={isChecked}
        onChange={(_, checked) => {
          setIsChecked(checked)
        }}
      >
        I hereby declare that I have read, understood and agreed on all the terms and conditions
      </Checkbox>
      <Modal.Footer>
        <button
          className='btn btn-primary justify-self-end'
          onClick={verifyOTP}
          disabled={!isChecked}
        >
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default TermsAndConditionsModal
