import axios from 'axios'
import {toast} from 'react-toastify'
import {useMutation} from 'react-query'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL
const GENERATE_OTP = `${API_URL}/newcif-newaccount/generate-otp/{newCIFNewAccount}/{type}`

const postToGenerateCode = async (payload: object) => {
  try {
    return await axios.patch(`${GENERATE_OTP}`, payload)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message ?? 'An error occurred')
    }
  }
}

export const usePostToGenerateCode = () =>
  useMutation({
    mutationFn: async ({payload}: {payload: object}) => postToGenerateCode(payload),
    onSuccess: (data) => {
      toast.success('OTP generated successfully')
    },
    onError: (error: any) => {
      toast.error(error?.message ?? 'Failed to generate OTP')
    },
  })
