import KYCForm from './form/KYCForm'

const KYCUpdatePage = () => {
  return (
    <div>
      <KYCForm />
    </div>
  )
}

export default KYCUpdatePage
