export const actionTypes = {
  GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_MINOR_JOINT_MINOR_LIST_START:
    'GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_MINOR_JOINT_MINOR_LIST_START',
  GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_MINOR_JOINT_MINOR_LIST_SUCCESS:
    'GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_MINOR_JOINT_MINOR_LIST_SUCCESS',
  GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_MINOR_JOINT_MINOR_LIST_FINISH:
    'GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_MINOR_JOINT_MINOR_LIST_FINISH',

  GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_LIST_START:
    'GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_START',
  GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_SUCCESS:
    'GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_SUCCESS',
  GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_FINISH:
    'GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_FINISH',

  POST_ACCOUNT_PRELIMINARY_KYC_FORM_START: 'POST_ACCOUNT_PRELIMINARY_KYC_FORM_START',
  POST_ACCOUNT_PRELIMINARY_KYC_FORM_SUCCESS: 'POST_ACCOUNT_PRELIMINARY_KYC_FORM_SUCCESS',
  POST_ACCOUNT_PRELIMINARY_KYC_FORM_FINISH: 'POST_ACCOUNT_PRELIMINARY_KYC_FORM_FINISH',

  POST_ACCOUNT_PRELIMINARY_KYC_ADDITIONAL_FORM_START:
    'POST_ACCOUNT_PRELIMINARY_KYC_ADDITIONAL_FORM_START',
  POST_ACCOUNT_PRELIMINARY_KYC_ADDITIONAL_FORM_SUCCESS:
    'POST_ACCOUNT_PRELIMINARY_KYC_ADDITIONAL_FORM_SUCCESS',
  POST_ACCOUNT_PRELIMINARY_KYC_ADDITIONAL_FORM_FINISH:
    'POST_ACCOUNT_PRELIMINARY_KYC_ADDITIONAL_FORM_FINISH',
}
