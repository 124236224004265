import React, {useEffect, useState} from 'react'
import {Upload, Button} from 'antd'
import {UploadChangeParam, RcFile} from 'antd/lib/upload'
import {PlusCircleOutlined, DeleteOutlined} from '@ant-design/icons'
import {toast} from 'react-toastify'
import {useSelector} from 'react-redux'
import {UploadRequestOption} from 'rc-upload/lib/interface'
import useSubmitDocuments from 'src/app/queries/Account Preliminary/post/usePostDocumentsAccountPreliminary'
import usePostCorporateAccountDocuments from 'src/app/queries/corporateAccountPreliminary/post/usePostCorporateAccountDocuments'
import usePostDocumentsUpload from 'src/app/queries/Account Preliminary/post/usePostDocumentsUpload'
import {RootState} from 'src/setup'

interface FileUploadProps {
  documentType: string
  maxSize: number
  onChange: (file: RcFile, documentType: string) => void
  documentTypeId?: string
  selectedAccountType?: string
  uniqueID?: string
  resetUploads?: boolean
  setResetUploads?: (resetUploads: boolean) => void
  setUploadDocumentsTypeId?: any
  isMissing?: boolean
}

const FileUploadComponent: React.FC<FileUploadProps> = ({
  documentType,
  maxSize,
  onChange,
  documentTypeId = '',
  selectedAccountType,
  uniqueID,
  resetUploads,
  setResetUploads,
  setUploadDocumentsTypeId,
  isMissing,
}) => {
  const {data, loading}: {data: any; loading: boolean} = useSelector(
    (state: RootState) => state.accountPreliminary
  )
  const {data: corporateData, loading: corporateLoading}: {data: any; loading: boolean} =
    useSelector((state: RootState) => state.accountPreliminaryCorporate)
  const {mutate: submitDocuments, isLoading: submitDocumentsLoading} = useSubmitDocuments()
  const {mutate: submitCorporateDocuments, isLoading: submitCorporateDocumentsLoading} =
    usePostCorporateAccountDocuments()
  const {mutate: uploadDocuments, isLoading: documentsLoading} = usePostDocumentsUpload()

  const [fileList, setFileList] = useState<any[]>([])

  const customRequest = (options: UploadRequestOption) => {
    const {file, onSuccess, onError} = options

    if (file instanceof File || file instanceof Blob) {
      const isValidSize = file.size / 1024 / 1024 < maxSize
      if (!isValidSize) {
        toast.error(`${documentType} file size should be less than ${maxSize} MB.`)
        return
      }

      const formData = new FormData()
      formData.append('documentTypeId', documentTypeId)
      formData.append('image', file)
      formData.append('temp_customer_id', uniqueID ?? '')
      setTimeout(() => {
        if (onSuccess) {
          onSuccess('ok')
        }
        const corporateAccountType = corporateData?.data?.accountType.toLowerCase()

        if (
          selectedAccountType == 'corporate' ||
          selectedAccountType == 'bank' ||
          corporateAccountType == 'bank' ||
          corporateAccountType == 'corporate'
        ) {
          formData.append('model_type', 'CorporateNewCIFNewAccount')
          uploadDocuments(
            {
              formData,
            },
            {
              onSuccess: (data) => {
                const uploadedId = formData.get('documentTypeId')
                setUploadDocumentsTypeId((prev: any) => [...prev, uploadedId])
              },
            }
          )
        } else {
          formData.append('model_type', 'NewCIFNewAccount')
          uploadDocuments(
            {
              formData,
            },
            {
              onSuccess: (data) => {
                const uploadedId = formData.get('documentTypeId')
                setUploadDocumentsTypeId((prev: any) => [...prev, uploadedId])
              },
            }
          )
        }
      }, 0)
    } else {
      toast.error('Invalid file type:' + typeof file)
    }
  }

  // Effect to reset fileList when resetUploads is true
  useEffect(() => {
    if (resetUploads) {
      setFileList([])
      setResetUploads?.(false)
    }
  }, [resetUploads])

  return (
    <Upload
      customRequest={customRequest}
      maxCount={1}
      accept='.jpg,.jpeg,.png,.pdf'
      fileList={fileList}
      onChange={(info: UploadChangeParam) => {
        const {file} = info
        setFileList(info.fileList)
        if (file.status === 'done') {
          onChange(file.originFileObj as RcFile, documentType)
        }
      }}
      showUploadList={{
        showRemoveIcon: true,
        removeIcon: <DeleteOutlined style={{color: 'red'}} />,
      }}
    >
      <div
        style={{
          color: 'red',
          marginBottom: '4px',
          fontSize: '12px',
          visibility: isMissing ? 'visible' : 'hidden',
        }}
      >
        {documentType} is required
      </div>
      <Button icon={<PlusCircleOutlined />}>Upload {documentType}</Button>
    </Upload>
  )
}

export default FileUploadComponent
