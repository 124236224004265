import axios from 'axios'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'
import {EXISTING_CIF_API} from '../api'

const existingCIFCreate = async (payload: object) => {
  try {
    return await axios.post(`${EXISTING_CIF_API}/create`, payload)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message)
      console.error('Error while posting corporate KYC Form :: ', error)
    } else {
      console.log(error)
    }
  }
}

export const usePostExistingCIFCreate = () =>
  useMutation({
    mutationFn: async ({payload}: {payload: object}) => existingCIFCreate(payload),
  })
