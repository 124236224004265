import axios from 'axios'
import {toast} from 'react-toastify'
import {CORPORATE_ACCOUNT_PRELIMINARY} from './usePostCorporateAccountPreliminary'
import {useMutation} from 'react-query'

const postCorporateKYCForm = async (id: string, payload: object) => {
  try {
    return await axios.post(`${CORPORATE_ACCOUNT_PRELIMINARY}/${id}/kyc-form`, payload)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message)
      console.error('Error while posting corporate KYC Form :: ', error)
    } else {
      console.log(error)
    }
  }
}

export const usePostCorporateKYCForm = () =>
  useMutation({
    mutationFn: async ({id, payload}: {id: string; payload: object}) =>
      postCorporateKYCForm(id, payload),
  })
