import axios from 'axios'
import {CORPORATE_ACCOUNT_PRELIMINARY} from './usePostCorporateAccountPreliminary'
import {toast} from 'react-toastify'
import {useMutation} from 'react-query'

export interface AdditionalInformationPayload {
  directors: {title_of_director: string; detail_of_director: string}[]
}

const postCorproateKYCAdditionalForm = async (
  id: string,
  payload: AdditionalInformationPayload
) => {
  try {
    return await axios.post(`${CORPORATE_ACCOUNT_PRELIMINARY}/${id}/kyc-additional-form`, payload)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message)
      console.error('Error while posting corporate KYC Form :: ', error)
    } else {
      console.log(error)
    }
  }
}

export const usePostCorporateKYCAdditionalForm = () =>
  useMutation({
    mutationFn: async ({id, payload}: {id: string; payload: AdditionalInformationPayload}) =>
      postCorproateKYCAdditionalForm(id, payload),
  })
