import DetailCell from '../DetailCell'

interface BusinessInformationSectionProps {
  data?: any
}

const BusinessInformationSection = ({data}: BusinessInformationSectionProps) => {
  return (
    <>
      <div className='row mt-5'>
        <DetailCell
          label='Industry Business Type'
          value={data?.kyc_information?.industry_business_type}
        />
        <DetailCell
          label='Soruce of Income'
          value={data?.kyc_information?.source_of_income?.title}
        />
      </div>
      <div className='row'>
        <DetailCell
          label='Other Source of Income'
          value={data?.kyc_information?.source_of_income_other}
        />
        <DetailCell label="Introducer's Name" value={data?.kyc_information?.introducer_name} />
      </div>
      <div className='row'>
        <DetailCell label='KYC Review Date' value={data?.kyc_information?.kyc_review_date} />
        <DetailCell
          label='Proof Address Document'
          value={data?.kyc_information?.proof_address_document}
        />
      </div>
      <div className='row'>
        <DetailCell
          label='Anticipated Annual Transaction'
          value={data?.kyc_information?.anticipated_annual_transaction}
        />
        <DetailCell label='Annual Income' value={data?.annual_income} />
      </div>
    </>
  )
}

export default BusinessInformationSection
