import React from 'react'
import DetailCell from '../DetailCell'

interface CustomerInformationSectionProps {
  data?: any
}

const CustomerInformationSection = ({data}: CustomerInformationSectionProps) => {
  return (
    <>
      <div className='row'>
        <DetailCell label='Customer Number' value={data?.customer_code} />
        <DetailCell label='CIF Number' value={data?.registration_detail?.cif_number} />
      </div>
      <div className='row'>
        <div className='col-6'></div>
        <DetailCell label='Account No.' value={data?.registration_detail?.account_number} />
      </div>
      <div className='row'>
        <DetailCell label='Account Type' value={data?.accountType} />
        <DetailCell label='PEP Confirmation' value={data?.pep_confirmation} />
      </div>
      <div className='row'>
        <DetailCell label='Customer Category' value={data?.customerCategoryId?.title} />
        <DetailCell label='Customer Classification' value={data?.customerClassificationId?.title} />
      </div>
      <div className='row'>
        <DetailCell label='Country' value={data?.country_id?.title} />
        <DetailCell label='Language' value={data?.language} />
      </div>
      <div className='row'>
        <DetailCell label='Location(Branch Location Code)' value={data?.branch_location_code} />
        <DetailCell label='Tax Category' value={data?.taxCategoryId?.title} />
      </div>
      <div className='row'>
        <DetailCell label='Tax Group' value={data?.taxGroupId?.cbs_code} />
        <DetailCell label='AML Risk Rating' value={data?.aml_risk_rating} />
      </div>
      <div className='row'>
        <DetailCell label='Exposure' value={data?.exposureId?.title} />
        <DetailCell label='Media' value={data?.media} />
      </div>
      <div className='row'>
        <DetailCell label='Institutional' value={data?.institutional} />
      </div>
    </>
  )
}

export default CustomerInformationSection
