import {useSelector} from 'react-redux'
import {MasterDataModel} from 'src/app/modules/masterData/Model'
import {RootState} from 'src/setup'

interface DocumentDetailsSectionProps {
  data: any
  cbsData: any
}

const DocumentDetailsUpdateSection = ({data, cbsData}: DocumentDetailsSectionProps) => {
  const {data: identityTypeList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.idTypeList
  )
  const {data: uniqueIdIssuedBy}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.individualUniqueIdList
  )

  const {district, loading} = useSelector((state: RootState) => state.place)

  return (
    <>
      <div className='col-12'>
        <h2 className='text-center'>Document Details Update</h2>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-5'>Prefered Identity</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6' style={{textTransform: 'uppercase'}}>
            {data?.preferred_identity ?? '-'}
          </strong>
        </div>
      </div>
      {data?.preferred_identity === 'citizenship' && (
        <>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-4'>Citizenship Number</strong>
            </div>
            <div className='col-4'>-</div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.citizenship_number ?? '-'}</strong>
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Citizenship Issue District</strong>
            </div>
            <div className='col-4'>-</div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.citizenship_issue_district?.title ?? ''}</strong>
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Citizenship Registration Date (B.S.)</strong>
            </div>
            <div className='col-4'>-</div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.citizenship_issue_date_bs ?? ''}</strong>
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Citizenship Registration Date (A.D.)</strong>
            </div>
            <div className='col-4'>-</div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.citizenship_issue_date_ad ?? ''}</strong>
            </div>
          </div>
        </>
      )}

      {data?.preferred_identity === 'passport' && (
        <>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-4'>Passport Number</strong>
            </div>
            <div className='col-4'>-</div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.passport_number ?? ''}</strong>
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Place of Issuance</strong>
            </div>
            <div className='col-4'>-</div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.passport_issue_district?.title ?? ''}</strong>
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Date of Issuance</strong>
            </div>
            <div className='col-4'>-</div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.passport_issue_date_bs ?? ''}</strong>
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Date of Expiry</strong>
            </div>
            <div className='col-4'>-</div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.passport_issue_date_ad ?? ''}</strong>
            </div>
          </div>
        </>
      )}

      {data?.preferred_identity === 'other' && (
        <>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Type of Identity Card</strong>
            </div>
            <div className='col-4'>-</div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.type_of_identity_card ?? ''}</strong>
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-4'>ID Card Number</strong>
            </div>
            <div className='col-4'>-</div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.id_card_number ?? ''}</strong>
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Date of Issuance (A.D.)</strong>
            </div>
            <div className='col-4'>-</div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.id_card_issue_date_ad ?? ''}</strong>
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Date of Issuance (B.S.)</strong>
            </div>
            <div className='col-4'>-</div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.id_card_issue_date_bs ?? ''}</strong>
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Date of Expiry (A.D.)</strong>
            </div>
            <div className='col-4'>-</div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.id_card_expiry_date_ad ?? ''}</strong>
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Date of Expiry (B.S.)</strong>
            </div>
            <div className='col-4'>-</div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.id_card_expiry_date_bs ?? ''}</strong>
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Place of Issuance</strong>
            </div>
            <div className='col-4'>-</div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.id_card_issue_district?.title ?? ''}</strong>
            </div>
          </div>
        </>
      )}

      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-5'>Identity Issued By</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.unique_id_issued_by?.title ?? ''}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>PAN Number</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.pan_number ?? ''}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-5'>PAN Registration Date (B.S.)</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.pan_registration_date_bs ?? ''}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-5'>PAN Registration Date (A.D.)</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.pan_registration_date_ad ?? ''}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-5'>PAN Issue District</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.pan_issue_district?.title ?? ''}</strong>
        </div>
      </div>
    </>
  )
}

export default DocumentDetailsUpdateSection
