export const actionTypes = {
  GET_ALL_MASTER_DATA_LIST_START: 'GET_ALL_MASTER_DATA_LIST_START',
  GET_ALL_MASTER_DATA_LIST_SUCCESS: 'GET_ALL_MASTER_DATA_LIST_SUCCESS',
  GET_ALL_MASTER_DATA_LIST_FINISH: 'GET_ALL_MASTER_DATA_LIST_FINISH',

  GET_CUSTOMER_CLASSIFICATION_LIST_START: 'GET_CUSTOMER_CLASSIFICATION_LIST_START',
  GET_CUSTOMER_CLASSIFICATION_LIST_SUCCESS: 'GET_CUSTOMER_CLASSIFICATION_LIST_SUCCESS',
  GET_CUSTOMER_CLASSIFICATION_LIST_FINISH: 'GET_CUSTOMER_CLASSIFICATION_LIST_FINISH',

  GET_ACCOUNT_CLASS_LIST_START: 'GET_ACCOUNT_CLASS_LIST_START',
  GET_ACCOUNT_CLASS_LIST_SUCCESS: 'GET_ACCOUNT_CLASS_LIST_SUCCESS',
  GET_ACCOUNT_CLASS_LIST_FINISH: 'GET_ACCOUNT_CLASS_LIST_FINISH',

  GET_ACCOUNT_TYPE_LIST_START: 'GET_ACCOUNT_TYPE_LIST_START',
  GET_ACCOUNT_TYPE_LIST_SUCCESS: 'GET_ACCOUNT_TYPE_LIST_SUCCESS',
  GET_ACCOUNT_TYPE_LIST_FINISH: 'GET_ACCOUNT_TYPE_LIST_FINISH',

  GET_CUSTOMER_CATEGORY_LIST_START: 'GET_CUSTOMER_CATEGORY_LIST_START',
  GET_CUSTOMER_CATEGORY_LIST_SUCCESS: 'GET_CUSTOMER_CATEGORY_LIST_SUCCESS',
  GET_CUSTOMER_CATEGORY_LIST_FINISH: 'GET_CUSTOMER_CATEGORY_LIST_FINISH',

  GET_TAX_CATEGORY_LIST_START: 'GET_TAX_CATEGORY_LIST_START',
  GET_TAX_CATEGORY_LIST_SUCCESS: 'GET_TAX_CATEGORY_LIST_SUCCESS',
  GET_TAX_CATEGORY_LIST_FINISH: 'GET_TAX_CATEGORY_LIST_FINISH',

  GET_TAX_GROUP_LIST_START: 'GET_TAX_GROUP_LIST_START',
  GET_TAX_GROUP_LIST_SUCCESS: 'GET_TAX_GROUP_LIST_SUCCESS',
  GET_TAX_GROUP_LIST_FINISH: 'GET_TAX_GROUP_LIST_FINISH',

  GET_INSTITUTIONAL_LIST_START: 'GET_INSTITUTIONAL_LIST_START',
  GET_INSTITUTIONAL_LIST_SUCCESS: 'GET_INSTITUTIONAL_LIST_SUCCESS',
  GET_INSTITUTIONAL_LIST_FINISH: 'GET_INSTITUTIONAL_LIST_FINISH',

  GET_MEDIA_LIST_START: 'GET_MEDIA_LIST_START',
  GET_MEDIA_LIST_SUCCESS: 'GET_MEDIA_LIST_SUCCESS',
  GET_MEDIA_LIST_FINISH: 'GET_MEDIA_LIST_FINISH',

  GET_SALUTATIONS_LIST_START: 'GET_SALUTATIONS_LIST_START',
  GET_SALUTATIONS_LIST_SUCCESS: 'GET_SALUTATIONS_LIST_SUCCESS',
  GET_SALUTATIONS_LIST_FINISH: 'GET_SALUTATIONS_LIST_FINISH',

  GET_ID_TYPES_LIST_START: 'GET_ID_TYPES_LIST_START',
  GET_ID_TYPES_LIST_SUCCESS: 'GET_ID_TYPES_LIST_SUCCESS',
  GET_ID_TYPES_LIST_FINISH: 'GET_ID_TYPES_LIST_FINISH',

  GET_EXPOSURES_LIST_START: 'GET_EXPOSURES_LIST_START',
  GET_EXPOSURES_LIST_SUCCESS: 'GET_EXPOSURES_LIST_SUCCESS',
  GET_EXPOSURES_LIST_FINISH: 'GET_EXPOSURES_LIST_FINISH',

  GET_RISK_CATEGORIES_START: 'GET_RISK_CATEGORIES_START',
  GET_RISK_CATEGORIES_SUCCESS: 'GET_RISK_CATEGORIES_SUCCESS',
  GET_RISK_CATEGORIES_FINISH: 'GET_RISK_CATEGORIES_FINISH',

  GET_GUARDIAN_TYPES_START: 'GET_GUARDIAN_TYPES_START',
  GET_GUARDIAN_TYPES_SUCCESS: 'GET_GUARDIAN_TYPES_SUCCESS',
  GET_GUARDIAN_TYPES_FINISH: 'GET_GUARDIAN_TYPES_FINISH',

  GET_ANCILLARY_REMARKS_START: 'GET_ANCILLARY_REMARKS_START',
  GET_ANCILLARY_REMARKS_SUCCESS: 'GET_ANCILLARY_REMARKS_SUCCESS',
  GET_ANCILLARY_REMARKS_FINISH: 'GET_ANCILLARY_REMARKS_FINISH',

  GET_DOCUMENT_TYPES_LIST_START: 'GET_DOCUMENT_TYPES_LIST_START',
  GET_DOCUMENT_TYPES_LIST_SUCCESS: 'GET_DOCUMENT_TYPES_LIST_SUCCESS',
  GET_DOCUMENT_TYPES_LIST_FINISH: 'GET_DOCUMENT_TYPES_LIST_FINISH',

  GET_INCOME_SOURCES_START: 'GET_INCOME_SOURCES_START',
  GET_INCOME_SOURCES_SUCCESS: 'GET_INCOME_SOURCES_SUCCESS',
  GET_INCOME_SOURCES_FINISH: 'GET_INCOME_SOURCES_FINISH',

  GET_EMPLOYMENT_STATUS_START: 'GET_EMPLOYMENT_STATUS_START',
  GET_EMPLOYMENT_STATUS_SUCCESS: 'GET_EMPLOYMENT_STATUS_SUCCESS',
  GET_EMPLOYMENT_STATUS_FINISH: 'GET_EMPLOYMENT_STATUS_FINISH',

  GET_KYC_LEVEL_CATEGORY_START: 'GET_KYC_LEVEL_CATEGORY_START',
  GET_KYC_LEVEL_CATEGORY_SUCCESS: 'GET_KYC_LEVEL_CATEGORY_SUCCESS',
  GET_KYC_LEVEL_CATEGORY_FINISH: 'GET_KYC_LEVEL_CATEGORY_FINISH',

  GET_OCCUPATION_START: 'GET_OCCUPATION_START',
  GET_OCCUPATION_SUCCESS: 'GET_OCCUPATION_SUCCESS',
  GET_OCCUPATION_FINISH: 'GET_OCCUPATION_FINISH',

  GET_ADDITIONAL_OCCUPATIONS_START: 'GET_ADDITIONAL_OCCUPATIONS_START',
  GET_ADDITIONAL_OCCUPATIONS_SUCCESS: 'GET_ADDITIONAL_OCCUPATIONS_SUCCESS',
  GET_ADDITIONAL_OCCUPATIONS_FINISH: 'GET_ADDITIONAL_OCCUPATIONS_FINISH',

  GET_QUALIFICATIONS_START: 'GET_QUALIFICATIONS_START',
  GET_QUALIFICATIONS_SUCCESS: 'GET_QUALIFICATIONS_SUCCESS',
  GET_QUALIFICATIONS_FINISH: 'GET_QUALIFICATIONS_FINISH',

  GET_ACCOUNT_PURPOSES_START: 'GET_ACCOUNT_PURPOSES_START',
  GET_ACCOUNT_PURPOSES_SUCCESS: 'GET_ACCOUNT_PURPOSES_SUCCESS',
  GET_ACCOUNT_PURPOSES_FINISH: 'GET_AACCOUNT_PURPOSES_FINISH',

  GET_INDIVIDUAL_UNIQUE_ID_START: 'GET_INDIVIDUAL_UNIQUE_ID_START',
  GET_INDIVIDUAL_UNIQUE_ID_SUCCESS: 'GET_INDIVIDUAL_UNIQUE_ID_SUCCESS',
  GET_INDIVIDUAL_UNIQUE_ID_FINISH: 'GET_INDIVIDUAL_UNIQUE_ID_FINISH',

  GET_MARITAL_STATUS_START: 'GET_MARITAL_STATUS_START',
  GET_MARITAL_STATUS_SUCCESS: 'GET_MARITAL_STATUS_SUCCESS',
  GET_MARITAL_STATUS_FINISH: 'GET_MARITAL_STATUS_FINISH',

  GET_ACCOUNT_CATEGORIES_START: 'GET_ACCOUNT_CATEGORIES_START',
  GET_ACCOUNT_CATEGORIES_SUCCESS: 'GET_ACCOUNT_CATEGORIES_SUCCESS',
  GET_ACCOUNT_CATEGORIES_FINISH: 'GET_ACCOUNT_CATEGORIES_FINISH',

  GET_LINKED_ENTITIES_RELATIONSHIP_START: 'GET_LINKED_ENTITIES_RELATIONSHIP_START',
  GET_LINKED_ENTITIES_RELATIONSHIP_SUCCESS: 'GET_LINKED_ENTITIES_RELATIONSHIP_SUCCESS',
  GET_LINKED_ENTITIES_RELATIONSHIP_FINISH: 'GET_LINKED_ENTITIES_RELATIONSHIP_FINISH',

  GET_CORPORATE_REGISTRATIONS_START: 'GET_CORPORATE_REGISTRATIONS_START',
  GET_CORPORATE_REGISTRATIONS_SUCCESS: 'GET_CORPORATE_REGISTRATIONS_SUCCESS',
  GET_CORPORATE_REGISTRATIONS_FINISH: 'GET_CORPORATE_REGISTRATIONS_FINISH',

  GET_COUNTRIES_LISTS_START: 'GET_COUNTRIES_LISTS_START',
  GET_COUNTRIES_LISTS_SUCCESS: 'GET_COUNTRIES_LISTS_SUCCESS',
  GET_COUNTRIES_LISTS_FINISH: 'GET_COUNTRIES_LISTS_FINISH',

  GET_NATIONALITIES_LISTS_START: 'GET_NATIONALITIES_LISTS_START',
  GET_NATIONALITIES_LISTS_SUCCESS: 'GET_NATIONALITIES_LISTS_SUCCESS',
  GET_NATIONALITIES_LISTS_FINISH: 'GET_NATIONALITIES_LISTS_FINISH',

  GET_CURRENCY_LISTS_START: 'GET_CURRENCY_LISTS_START',
  GET_CURRENCY_LISTS_SUCCESS: 'GET_CURRENCY_LISTS_SUCCESS',
  GET_CURRENCY_LISTS_FINISH: 'GET_CURRENCY_LISTS_FINISH',

  GET_KYC_REQUEST_TYPES_LISTS_START: 'GET_KYC_REQUEST_TYPES_LISTS_START',
  GET_KYC_REQUEST_TYPES_SUCCESS: 'GET_KYC_REQUEST_TYPES_SUCCESS',
  GET_KYC_REQUEST_TYPES_FINISH: 'GET_KYC_REQUEST_TYPES_FINISH',
}
