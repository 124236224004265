import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import {Form, Formik} from 'formik'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as Yup from 'yup'
import {FC, useState} from 'react'
import {Tab, Tabs} from 'react-bootstrap-v5'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import {useHistory, useLocation} from 'react-router-dom'
import DetailCell from '../../common/components/cpuChecker/ReviewComponent/IndividualJointMinor/DetailCell'
import {usePostExistingCIFBranchReview} from 'src/app/queries/ExistingCIF/post'
import {toast} from 'react-toastify'
import AccountStatusLog from '../../common/AccountStatusLog'

const validationSchema = Yup.object().shape({
  status: Yup.string().required('Status Type is required'),
  remarks: Yup.string().required('Remarks is required'),
})

const allStatusType = [
  {label: 'APPROVE', value: 'approve'},
  {label: 'REJECT', value: 'reject'},
  {label: 'REVERT BACK TO BRANCH MAKER', value: 'revert'},
]
const ExistingCIFReview: FC = () => {
  const location = useLocation()
  const history = useHistory()
  const data: any = location.state

  const [index, setIndex] = useState('1')

  const {mutate: postExistingCIFBranchReview, isLoading} = usePostExistingCIFBranchReview()

  return (
    <div className='modal-container '>
      <div className='heading__component__title fw-bolder mb-5' data-cy='dynamic-setting-heading'>
        {'New CIF and Account Review'}
      </div>

      <div className='row mb-6'>
        <div className='col-6'>
          <div className='d-flex justify-content-end'>{index}/3</div>
          <Tabs defaultActiveKey='ppSizePhoto' id='uncontrolled-tab-example'>
            <Tab eventKey='ppSizePhoto' title='PP Size Photo' onEntered={() => setIndex('1')}>
              <div className='card-body bg-white' style={{overflow: 'hidden'}}>
                <img
                  src={`${imageBaseUrl}/${data?.account_documents?.documents[0]?.photo_upload_1}`}
                  style={{
                    width: '400px',
                    height: '400px',
                    objectFit: 'contain',
                    border: '1px solid gray',
                    borderRadius: '10px',
                    marginTop: '10px',
                  }}
                  alt='PP Size Photo'
                />
              </div>
            </Tab>
            <Tab eventKey='aof' title='AOF' onEnter={() => setIndex('2')}>
              <div className='card-body bg-white' style={{overflow: 'hidden'}}>
                <img
                  src={`${imageBaseUrl}/${data?.account_documents?.documents?.[0]?.form_upload}`}
                  style={{
                    width: '400px',
                    height: '400px',
                    objectFit: 'contain',
                    border: '1px solid gray',
                    borderRadius: '10px',
                    marginTop: '10px',
                  }}
                  alt='AOF'
                />
              </div>
            </Tab>
            <Tab eventKey='citizenship' title='Citizenship' onEnter={() => setIndex('3')}>
              <div className='card-body bg-white' style={{overflow: 'hidden'}}>
                <img
                  src={`${imageBaseUrl}/${data?.account_documents?.documents?.[0]?.citizenship}`}
                  style={{
                    width: '400px',
                    height: '400px',
                    objectFit: 'contain',
                    border: '1px solid gray',
                    borderRadius: '10px',
                    marginTop: '10px',
                  }}
                  alt='Citizenship'
                />
              </div>
            </Tab>
          </Tabs>
        </div>
        <div className='col-md-6'>
          <div className='card'>
            <div className='card-body' style={{maxHeight: '640px', overflowY: 'auto'}}>
              <div className='row mb-5'>
                <DetailCell label='Full Name' value={data?.full_name} />
                <DetailCell label='Branch Location Code' value={data?.branch_code} />
              </div>
              <hr />
              <div className='row'>
                <DetailCell label='Email' value={data?.email} />
                <DetailCell label='Mobile Number' value={data?.mobile_number} />
              </div>
              <div className='row'>
                <DetailCell label='Bank Code' value={data?.bank_code} />
                <DetailCell label='Location' value={data?.branch} />
              </div>
              <div className='row'>
                <DetailCell label='Media' value={data?.media} />
                <DetailCell label='First Nominee' value={data?.first_nominee} />
              </div>
              <div className='row'>
                <DetailCell label='Second Nominee' value={data?.second_nominee} />
                <DetailCell label='Account Class' value={data?.account_class} />
              </div>
              <div className='row'>
                <div className='col-6'></div>
                <DetailCell label='Street Name' value={data?.street_name} />
              </div>
              <div className='row'>
                <DetailCell label='House Number' value={data?.house_number} />
                <DetailCell label='Tole' value={data?.tole} />
              </div>
              <div className='row'>
                <DetailCell label='Ward Number' value={data?.ward_number} />
                <DetailCell label='Municipality' value={data?.municipality?.title} />
              </div>
              <div className='row'>
                <div className='col-6'></div>
                <DetailCell label='Municipality(Other)' value={data?.municipality_other} />
              </div>
              <div className='row'>
                <DetailCell label='Province' value={data?.province?.title} />
                <DetailCell label='District' value={data?.district?.title} />
              </div>
              <div className='row'>
                <DetailCell label='Country' value={data?.country?.title} />
                <DetailCell label='Exposure' value={data?.exposure_category} />
              </div>
              <div className='row'>
                <DetailCell label='Interest Rate ' value={data?.interest_rate} />
              </div>
              <Formik
                initialValues={{status: '', remarks: ''}}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  postExistingCIFBranchReview(
                    {id: data?.id, payload: values},
                    {
                      onSuccess: (res) => {
                        if (res?.data?.resCod === '200') {
                          toast.success(res.data?.resDesc ?? 'Reviewed Successfully')
                          history.push('/existing-cif/existing-cif-listing/branch-checker')
                        }
                      },
                    }
                  )
                }}
              >
                {({handleChange, errors, touched, values, setFieldValue}) => (
                  <Form>
                    <div className='row '>
                      <div className='col-md-6'>
                        <FormSelect
                          labelClassName='col-md-12'
                          containerClassName='col-md-12'
                          label='Status Type'
                          name='status'
                          isClearable={true}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          options={allStatusType}
                          required={true}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                      <div className='border my-2 mb-6'></div>
                      <FormTextBox
                        containerClassName='col-md-12'
                        labelClassName='col-md-12'
                        type='text'
                        placeholder='Remarks'
                        label='Remarks'
                        name='remarks'
                        disabled={false}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required={true}
                        data-cy='add-id-number-text-field'
                        as='textarea'
                      />
                      <div className='d-flex justify-content-end mt-3'>
                        <button type='submit' className='btn btn-secondary mx-4'>
                          Back
                        </button>
                        <button type='submit' className='btn btn-primary'>
                          Send
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <AccountStatusLog data={data} />
    </div>
  )
}

export default ExistingCIFReview
