import {CustomerClassificationDataModel, MasterDataModel, DocumentTypeModel} from '../Model'
import {actionTypes} from './constants'

export const actions = {
  getAllMasterData: () => ({
    type: actionTypes.GET_ALL_MASTER_DATA_LIST_START,
  }),

  getCustomerClassification: () => ({
    type: actionTypes.GET_CUSTOMER_CLASSIFICATION_LIST_START,
  }),
  getCustomerClassificationSuccess: (data: CustomerClassificationDataModel[]) => ({
    type: actionTypes.GET_CUSTOMER_CLASSIFICATION_LIST_SUCCESS,
    payload: data,
  }),
  getCustomerClassificationFinish: () => ({
    type: actionTypes.GET_CUSTOMER_CLASSIFICATION_LIST_FINISH,
  }),

  getAccountClass: () => ({
    type: actionTypes.GET_ACCOUNT_CLASS_LIST_START,
  }),
  getAccountClassSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_ACCOUNT_CLASS_LIST_SUCCESS,
    payload: data,
  }),
  getAccountClassFinish: () => ({
    type: actionTypes.GET_ACCOUNT_CLASS_LIST_FINISH,
  }),

  getAccountType: () => ({
    type: actionTypes.GET_ACCOUNT_TYPE_LIST_START,
  }),
  getAccountTypeSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_ACCOUNT_TYPE_LIST_SUCCESS,
    payload: data,
  }),
  getAccountTypeFinish: () => ({
    type: actionTypes.GET_ACCOUNT_TYPE_LIST_FINISH,
  }),

  getCustomerCategory: () => ({
    type: actionTypes.GET_CUSTOMER_CATEGORY_LIST_START,
  }),
  getCustomerCategorySuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_CUSTOMER_CATEGORY_LIST_SUCCESS,
    payload: data,
  }),
  getCustomerCategoryFinish: () => ({
    type: actionTypes.GET_CUSTOMER_CATEGORY_LIST_FINISH,
  }),

  getTaxCategory: () => ({
    type: actionTypes.GET_TAX_CATEGORY_LIST_START,
  }),
  getTaxCategorySuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_TAX_CATEGORY_LIST_SUCCESS,
    payload: data,
  }),
  getTaxCategoryFinish: () => ({
    type: actionTypes.GET_TAX_CATEGORY_LIST_FINISH,
  }),

  getTaxGroup: () => ({
    type: actionTypes.GET_TAX_GROUP_LIST_START,
  }),
  getTaxGroupSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_TAX_GROUP_LIST_SUCCESS,
    payload: data,
  }),
  getTaxGroupFinish: () => ({
    type: actionTypes.GET_TAX_GROUP_LIST_FINISH,
  }),

  getInstitutional: () => ({
    type: actionTypes.GET_INSTITUTIONAL_LIST_START,
  }),
  getInstitutionalSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_INSTITUTIONAL_LIST_SUCCESS,
    payload: data,
  }),
  getInstitutionalFinish: () => ({
    type: actionTypes.GET_INSTITUTIONAL_LIST_FINISH,
  }),

  getMedia: () => ({
    type: actionTypes.GET_MEDIA_LIST_START,
  }),
  getMediaSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_MEDIA_LIST_SUCCESS,
    payload: data,
  }),
  getMediaFinish: () => ({
    type: actionTypes.GET_MEDIA_LIST_FINISH,
  }),

  getSalutations: () => ({
    type: actionTypes.GET_SALUTATIONS_LIST_START,
  }),
  getSalutationsSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_SALUTATIONS_LIST_SUCCESS,
    payload: data,
  }),
  getSalutationsFinish: () => ({
    type: actionTypes.GET_SALUTATIONS_LIST_FINISH,
  }),

  getIdTypes: () => ({
    type: actionTypes.GET_ID_TYPES_LIST_START,
  }),
  getIdTypesSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_ID_TYPES_LIST_SUCCESS,
    payload: data,
  }),
  getIdTypesFinish: () => ({
    type: actionTypes.GET_ID_TYPES_LIST_FINISH,
  }),

  getExposures: () => ({
    type: actionTypes.GET_EXPOSURES_LIST_START,
  }),
  getExposuresSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_EXPOSURES_LIST_SUCCESS,
    payload: data,
  }),
  getExposuresFinish: () => ({
    type: actionTypes.GET_EXPOSURES_LIST_FINISH,
  }),

  getRiskCategories: () => ({
    type: actionTypes.GET_RISK_CATEGORIES_START,
  }),
  getRiskCategoriesSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_RISK_CATEGORIES_SUCCESS,
    payload: data,
  }),
  getRiskCategoriesFinish: () => ({
    type: actionTypes.GET_RISK_CATEGORIES_FINISH,
  }),

  getGuardianTypes: () => ({
    type: actionTypes.GET_GUARDIAN_TYPES_START,
  }),
  getGuardianTypesSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_GUARDIAN_TYPES_SUCCESS,
    payload: data,
  }),
  getGuardianTypesFinish: () => ({
    type: actionTypes.GET_GUARDIAN_TYPES_FINISH,
  }),

  getAncillaryRemarks: () => ({
    type: actionTypes.GET_ANCILLARY_REMARKS_START,
  }),
  getAncillaryRemarksSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_ANCILLARY_REMARKS_SUCCESS,
    payload: data,
  }),
  getAncillaryRemarksFinish: () => ({
    type: actionTypes.GET_ANCILLARY_REMARKS_FINISH,
  }),

  getDocumentTypes: () => ({
    type: actionTypes.GET_DOCUMENT_TYPES_LIST_START,
  }),
  getDocumentTypesSuccess: (data: DocumentTypeModel[]) => ({
    type: actionTypes.GET_DOCUMENT_TYPES_LIST_SUCCESS,
    payload: data,
  }),
  getDocumentTypesFinish: () => ({
    type: actionTypes.GET_DOCUMENT_TYPES_LIST_FINISH,
  }),

  getIncomeSources: () => ({
    type: actionTypes.GET_INCOME_SOURCES_START,
  }),
  getIncomeSourcesSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_INCOME_SOURCES_SUCCESS,
    payload: data,
  }),
  getIncomeSourcesFinish: () => ({
    type: actionTypes.GET_INCOME_SOURCES_FINISH,
  }),

  getEmployementStatus: () => ({
    type: actionTypes.GET_EMPLOYMENT_STATUS_START,
  }),
  getEmployementStatusSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_EMPLOYMENT_STATUS_SUCCESS,
    payload: data,
  }),
  getEmployementStatusFinish: () => ({
    type: actionTypes.GET_EMPLOYMENT_STATUS_FINISH,
  }),

  getKycLevelCategories: () => ({
    type: actionTypes.GET_KYC_LEVEL_CATEGORY_START,
  }),
  getKycLevelCategoriesSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_KYC_LEVEL_CATEGORY_SUCCESS,
    payload: data,
  }),
  getKycLevelCategoriesFinish: () => ({
    type: actionTypes.GET_KYC_LEVEL_CATEGORY_FINISH,
  }),

  getOccupation: () => ({
    type: actionTypes.GET_OCCUPATION_START,
  }),
  getOccupationSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_OCCUPATION_SUCCESS,
    payload: data,
  }),
  getOccupationFinish: () => ({
    type: actionTypes.GET_OCCUPATION_FINISH,
  }),

  getAdditionalOccupation: () => ({
    type: actionTypes.GET_ADDITIONAL_OCCUPATIONS_START,
  }),
  getAdditionalOccupationSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_ADDITIONAL_OCCUPATIONS_SUCCESS,
    payload: data,
  }),
  getAdditionalOccupationFinish: () => ({
    type: actionTypes.GET_ADDITIONAL_OCCUPATIONS_FINISH,
  }),

  getQualification: () => ({
    type: actionTypes.GET_QUALIFICATIONS_START,
  }),
  getQualificationSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_QUALIFICATIONS_SUCCESS,
    payload: data,
  }),
  getQualificationFinish: () => ({
    type: actionTypes.GET_QUALIFICATIONS_FINISH,
  }),

  getAccountPurposes: () => ({
    type: actionTypes.GET_ACCOUNT_PURPOSES_START,
  }),
  getAccountPurposesSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_ACCOUNT_PURPOSES_SUCCESS,
    payload: data,
  }),
  getAccountPurposesFinish: () => ({
    type: actionTypes.GET_ACCOUNT_PURPOSES_FINISH,
  }),

  getIndividualUniqueIds: () => ({
    type: actionTypes.GET_INDIVIDUAL_UNIQUE_ID_START,
  }),
  getIndividualUniqueIdsSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_INDIVIDUAL_UNIQUE_ID_SUCCESS,
    payload: data,
  }),
  getIndividualUniqueIdsFinish: () => ({
    type: actionTypes.GET_INDIVIDUAL_UNIQUE_ID_FINISH,
  }),

  getMaritalStatus: () => ({
    type: actionTypes.GET_MARITAL_STATUS_START,
  }),
  getMaritalStatusSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_MARITAL_STATUS_SUCCESS,
    payload: data,
  }),
  getMaritalStatusFinish: () => ({
    type: actionTypes.GET_MARITAL_STATUS_FINISH,
  }),

  getAccountCategories: () => ({
    type: actionTypes.GET_ACCOUNT_CATEGORIES_START,
  }),
  getAccountCategoriesSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_ACCOUNT_CATEGORIES_SUCCESS,
    payload: data,
  }),
  getAccountCategoriesFinish: () => ({
    type: actionTypes.GET_ACCOUNT_CATEGORIES_FINISH,
  }),

  getLinkedEntitiesRelationship: () => ({
    type: actionTypes.GET_LINKED_ENTITIES_RELATIONSHIP_START,
  }),
  getLinkedEntitiesRelationshipSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_LINKED_ENTITIES_RELATIONSHIP_SUCCESS,
    payload: data,
  }),
  getLinkedEntitiesRelationshipFinish: () => ({
    type: actionTypes.GET_LINKED_ENTITIES_RELATIONSHIP_FINISH,
  }),

  getCorporateRegistrations: () => ({
    type: actionTypes.GET_CORPORATE_REGISTRATIONS_START,
  }),
  getCorporateRegistrationsSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_CORPORATE_REGISTRATIONS_SUCCESS,
    payload: data,
  }),
  getCorporateRegistrationsFinish: () => ({
    type: actionTypes.GET_CORPORATE_REGISTRATIONS_FINISH,
  }),

  getCountriesLists: () => ({
    type: actionTypes.GET_COUNTRIES_LISTS_START,
  }),
  getCountriesListsSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_COUNTRIES_LISTS_SUCCESS,
    payload: data,
  }),
  getCountriesListsFinish: () => ({
    type: actionTypes.GET_COUNTRIES_LISTS_FINISH,
  }),

  getNationalitiesLists: () => ({
    type: actionTypes.GET_NATIONALITIES_LISTS_START,
  }),

  getNationalitiesListsSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_NATIONALITIES_LISTS_SUCCESS,
    payload: data,
  }),

  getNationalitiesListsFinish: () => ({
    type: actionTypes.GET_NATIONALITIES_LISTS_FINISH,
  }),

  getCurrencyLists: () => ({
    type: actionTypes.GET_CURRENCY_LISTS_START,
  }),

  getCurrencyListsSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_CURRENCY_LISTS_SUCCESS,
    payload: data,
  }),

  getCurrencyListsFinish: () => ({
    type: actionTypes.GET_CURRENCY_LISTS_FINISH,
  }),

  getKycRequestTypes: () => ({
    type: actionTypes.GET_KYC_REQUEST_TYPES_LISTS_START,
  }),

  getKycRequestTypesSuccess: (data: MasterDataModel[]) => ({
    type: actionTypes.GET_KYC_REQUEST_TYPES_SUCCESS,
    payload: data,
  }),

  getKycRequestTypesFinish: () => ({
    type: actionTypes.GET_KYC_REQUEST_TYPES_FINISH,
  }),
}
