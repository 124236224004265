import axios from 'axios'
import {useQuery, useMutation} from 'react-query'
import {AccountStatusStateParamsModel} from 'src/app/modules/account/accountListing/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const ACCOUNT_STATUS_LISTS_API = `${API_URL}/newcif-newaccount/account-status`

// Query Key
const QUERY_KEY = 'allAccountStatusList'

export const useGetAccountStatusLists = (params: AccountStatusStateParamsModel) => {
  return useQuery([QUERY_KEY, params], async () => {
    const {data} = await axios.get(ACCOUNT_STATUS_LISTS_API, {
      params,
    })
    return data
  })
}
