import {Table} from 'rsuite'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {IoClose} from 'react-icons/io5'

const Cell = Table.Cell

type CIFCheckListProps = {
  onCancel: () => void
  data: any
  loading: boolean
}

const CIFCheckList = ({onCancel, data, loading}: CIFCheckListProps) => {
  const columns = [
    {
      label: 'CIF Number',
      dataKey: 'customer_no',
      width: 150,
      cell: <Cell dataKey='customer_no' />,
    },
    {
      label: 'Full Name',
      dataKey: 'full_name',
      width: 210,
      cell: <Cell dataKey='full_name' />,
    },
    {
      label: 'Father Name',
      dataKey: 'father_name',
      width: 210,
      cell: <Cell dataKey='father_name' />,
    },
    {
      label: 'Grand Father Name',
      dataKey: 'grandfather_name',
      width: 210,
      cell: <Cell dataKey='grandfather_name' />,
    },
    {
      label: 'ID Type',
      dataKey: 'unique_id_name',
      width: 150,
      cell: <Cell dataKey='unique_id_name' />,
    },
    {
      label: 'ID Number',
      dataKey: 'unique_id_no',
      width: 160,
      cell: <Cell dataKey='unique_id_no' />,
    },
  ]

  return (
    <div className='shadow p-3 bg-white rounded my-8'>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div className='heading__component__title fs-6 fw-bolder mb-2'>
          Found result({data?.cbs_data.length ?? 0})
        </div>
        <IoClose style={{cursor: 'pointer'}} size={24} onClick={onCancel} />
      </div>
      <div className='datatable'>
        <RSuiteTable columns={columns} data={data?.cbs_data} showLoading={loading} />
      </div>
    </div>
  )
}

export default CIFCheckList
