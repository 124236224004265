import {Close, Search} from '@rsuite/icons'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {FaPlus} from 'react-icons/fa'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {Input, InputGroup, Pagination, Table} from 'rsuite'
import {StateParamsModel} from 'src/app/modules/common/Model'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import * as newCIFRedux from '../redux'

const Cell = Table.Cell
const NewCIFListing = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [params, setParams] = useState<StateParamsModel>({
    status: 1,
    page: 1,
    limit: 10,
    orderBy: 'updated_at',
    order: 'desc',
  })

  const {data: newCIFListData, loading} = useSelector((state: any) => state.newCIFList)

  const data = newCIFListData?.new_accounts?.map((newCIF: any, i: number) => ({
    ...newCIF,
    sn: (params?.page - 1) * params?.limit + (i + 1),
    account_name: `${newCIF?.first_name} ${newCIF?.middle_name ? newCIF?.middle_name + ' ' : ''} ${
      newCIF?.last_name
    }`,
  }))

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    const isDraft = rowData.account_status.current_status === 'Saved as Draft - CPU'
    return (
      <Cell {...props} className='link-group'>
        <button
          className={
            isDraft ? 'dt-btn dt-btn-outline-primary dt-btn-sm' : 'btn btn-primary btn-sm '
          }
          onClick={() =>
            history.push({
              pathname: `/new-cif/new-cif-review/${rowData.id}`,
              state: rowData,
            })
          }
        >
          {isDraft ? 'Continue' : 'Review'}
        </button>
      </Cell>
    )
  }

  const columns = [
    {
      label: 'Primary CIF',
      dataKey: 'primary_cif_number',
      width: 150,
      cell: <Cell dataKey='primary_cif_number' />,
    },
    {
      label: 'Account Name',
      dataKey: 'account_name',
      cell: <Cell dataKey='account_name' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Branch Location',
      dataKey: 'branch_location_code',
      sortable: false,
      width: 150,
      cell: <Cell dataKey='branch_location_code' />,
    },
    {
      label: 'Status',
      dataKey: 'account_status.current_status',
      sortable: false,
      width: 400,
      cell: <Cell dataKey='account_status.current_status' />,
    },
    {
      label: 'Updated On',
      dataKey: 'account_status.updated_date',
      cell: <Cell dataKey='account_status.updated_date' />,
      sortable: false,
      width: 230,
    },
    {
      label: 'Remarks',
      dataKey: 'account_status.remarks',
      cell: <Cell dataKey='account_status.remarks' />,
      sortable: false,
      width: 230,
    },
    {
      label: 'Action',
      width: 160,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  useEffect(() => {
    dispatch(newCIFRedux.actions.getNewCIFList(params))
  }, [params])
  return (
    <div>
      <div className='shadow p-3 bg-white rounded'>
        <div className='row g-0 border-bottom align-items-center mb-3 py-3'>
          <div className='col-lg-6'>
            <div className='heading__component__title fw-bolder'>New CIF List</div>
          </div>
          <div className='col-lg-6'>
            <div className='d-flex justify-content-end'>
              <div
                className='sub_button'
                onClick={() => history.push('/create-new-cif-and-account/create-new-cif')}
              >
                <button className='dt-btn dt-btn-primary' data-cy='add-button'>
                  <FaPlus className='me-2' />
                  Create New CIF
                </button>
              </div>
            </div>
          </div>
        </div>
        <InputGroup inside style={{width: '30%', margin: '1rem 0'}}>
          <Input
            placeholder='Search'
            value={params.search}
            onChange={(value: string) => setParams({...params, search: value})}
            data-cy='search-field'
          />
          <InputGroup.Addon>
            {!isEmpty(params?.search) ? (
              <Close
                style={{cursor: 'pointer'}}
                onClick={() => setParams({...params, search: ''})}
                data-cy='search-close-button'
              />
            ) : (
              <Search />
            )}
            {/* <SearchIcon /> */}
          </InputGroup.Addon>
        </InputGroup>
        <div className='datatable'>
          <RSuiteTable columns={columns} data={data ?? []} showLoading={loading} />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={newCIFListData?.meta?.total ?? 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </div>
    </div>
  )
}
export default NewCIFListing
