import axios from 'axios'
import {toast} from 'react-toastify'
import {CORPORATE_ACCOUNT_PRELIMINARY} from './usePostCorporateAccountPreliminary'
import {useMutation} from 'react-query'

export interface KYCCPUCheckerFormPayload {
  status: string
  remarks: string
}

const postCorporateKYCCPUCheckerReview = async (id: string, payload: KYCCPUCheckerFormPayload) => {
  try {
    return await axios.post(
      `${CORPORATE_ACCOUNT_PRELIMINARY}/${id}/kyc-cpu-checker-review`,
      payload
    )
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message)
      console.error('Error while posting corporate KYC Form :: ', error)
    } else {
      console.log(error)
    }
  }
}

export const usePostCorporateKYCCPUCheckerReview = () =>
  useMutation({
    mutationFn: async ({id, payload}: {id: string; payload: KYCCPUCheckerFormPayload}) =>
      postCorporateKYCCPUCheckerReview(id, payload),
  })
