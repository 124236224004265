import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as customerClassificationListRedux from 'src/app/modules/masterData/redux'
import type {
  CustomerClassificationDataModel,
  MasterDataModel,
} from 'src/app/modules/masterData/Model'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import {RootState} from 'src/setup'
import {ErrorMessage} from 'formik'
import FormNepaliCalendarDatePicker from 'src/cms/helpers/components/forms/form-fields/FormNepaliCalendarDatePicker'
import {adToBs} from '@sbmdkl/nepali-date-converter'

interface BasicInformationSectionProps {
  data: any
  fullData: any
  cbsData: any
}

const BasicInformationSection = ({data, cbsData, fullData}: BasicInformationSectionProps) => {
  const dispatch = useDispatch()

  const {
    data: salutationList,
    loading: salutationLoading,
  }: {data: MasterDataModel[]; loading: boolean} = useSelector(
    (state: RootState) => state?.salutationList
  )
  const {data: maritalStatusList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.maritalStatusList
  )

  const [customerClassificationList, setCustomerClassificationList] = useState<
    Array<{label: string; value: string}>
  >([])

  const customerClassificationListData = useSelector(
    (state: any) => state?.customerClassificationList
  )
  const {data: nationalitiesList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.nationalitiesList
  )

  useEffect(() => {
    dispatch(customerClassificationListRedux.actions.getCustomerClassification())
  }, [])

  useEffect(() => {
    if (customerClassificationListData) {
      setCustomerClassificationList(
        customerClassificationListData?.data?.map(
          (customerClassification: CustomerClassificationDataModel) => ({
            label: customerClassification.title,
            value: customerClassification.id,
          })
        )
      )
    }
  }, [customerClassificationListData])

  return (
    <>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Customer Number</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{cbsData?.customer_no ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{fullData?.cif_number ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Account Number</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{cbsData?.account_number ?? '-'}</strong>
        </div>
        <div className='col-4'>
          {' '}
          <strong className='fs-6'>{fullData?.account_number ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>CIF Branch</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6 '>-</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6 '>{fullData?.branch ?? ''}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Salutation</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>-</strong>
        </div>
        <div className='col-4'>{data?.salutation ?? '-'}</div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>First Name</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.first_name ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Middle Name</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.middle_name ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Last Name</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.last_name ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Short Name</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          {' '}
          <strong className='fs-6'>{data?.short_name ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Full Name</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{cbsData?.full_name ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{fullData?.full_name ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Gender</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>-</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.gender ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Nationality</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.nationality?.title ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Date of Birth(B.S)</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.date_of_birth_bs ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Date of Birth(A.D)</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.date_of_birth_ad ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Marital Status</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.marital_status?.title ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Residental Status</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6' style={{textTransform: 'uppercase'}}>
            {data?.resident_status ?? '-'}
          </strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Introducer Name</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.introducer_name ?? '-'}</strong>
        </div>
      </div>
    </>
  )
}

export default BasicInformationSection
