import axios from 'axios'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL
const ACCOUNT_PRELIMINARY_URL = `${API_URL}/documents/upload`

type SubmitDocumentsPayload = {
  formData: FormData
}

const submitDocuments = async ({formData}: SubmitDocumentsPayload) => {
  try {
    const response = await axios.post(`${ACCOUNT_PRELIMINARY_URL}`, formData)
    return response
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const usePostDocumentsUpload = () => {
  return useMutation(submitDocuments, {
    onSuccess: (data) => {
      if (data?.data?.resCod == 200) {
        toast.success(data?.data?.resDesc ?? 'Documents submitted successfully!')
      }
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error.message ?? 'An error occurred'}`)
    },
  })
}

export default usePostDocumentsUpload
