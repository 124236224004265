import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL
const ACCOUNT_PRELIMINARY_API = `${API_URL}/newcif-newaccount`

type UpdatePayloadType = {
  id: string
  data: any
}

const updateAccountPreliminary = async (payload: UpdatePayloadType) => {
  const {id, data} = payload
  try {
    const response = await axios.patch(`${ACCOUNT_PRELIMINARY_API}/${id}`, data)
    return response.data
  } catch (error: any) {
    throw new Error(`${error.response?.data?.message || 'error updating account'}`)
  }
}

const useUpdateAccountPreliminary = () => {
  const queryClient = useQueryClient()
  return useMutation(updateAccountPreliminary, {
    onSuccess: (data) => {
      if (data?.resCod == 200) {
        toast.success(data?.resDesc ?? 'Preliminary Account updated successfully!')
      }
      queryClient.invalidateQueries('branchMakerNewCIFAndAccountList')
      queryClient.invalidateQueries('accountPreliminaryList')
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error.message || 'An error occurred'}`)
    },
  })
}

export default useUpdateAccountPreliminary
