import BackendSettings from '../modules/siteSettings/components/backendSettings/components'
import ContactSetting from '../modules/siteSettings/components/contactSettings/ContactSetting'
import KeySettings from '../modules/siteSettings/components/keySetting/components'
import ModuleManager from '../modules/siteSettings/components/moduleManager/components/ModuleManager'
import Settings from '../modules/siteSettings/components/settings/components'
import SmtpSettings from '../modules/siteSettings/components/smtpSetting/components'
import Departments from '../modules/userMgmt/components/departments/components'
import RoleManagementIndex from '../modules/userMgmt/components/roles/components/RoleMgmt'
import UserRoleManagementIndex from '../modules/userMgmt/components/userRoles/UserRole'
import UserManagementIndex from '../modules/userMgmt/components/users/components/users'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

import {RouteComponentProps} from 'react-router-dom'
import ContentManager from 'src/app/modules/cms/components/content/components/index'
import MenuManager from '../modules/cms/components/menu/components'

import Banner from '../modules/cms/components/banner/components'

import RoadBlockPopup from '../modules/cms/components/roadblockPopup/components'
import TextPopup from '../modules/cms/components/textPopup/components'

import FaqCategory from '../modules/cms/components/faqCategory/components'
import FaqManager from '../modules/cms/components/faqManager/components'

import EmailTemplate from '../modules/cms/components/emailTemplate/components'
import FormDownloadComponent from '../modules/formsAndDownload/components/formsDownload/component'

import CreateNewAccount from '../modules/CreateNewCIFAndCustomerAccount/createNewAccount/components'
import UpdateAndReview from '../modules/CreateNewCIFAndCustomerAccount/createNewAccount/components/UpdateAndReview'
import CreateNewCifPage from '../modules/CreateNewCIFAndCustomerAccount/createNewCif/component'
import BiometricVerificationPage from '../modules/CreateNewCIFAndCustomerAccount/verificationPages/biometricVerificationPage'
import AccountListing from '../modules/account/accountListing/components'
import AncillaryServiceRequestList from '../modules/ancillaryService/requestList/components'
import BranchCheckerNewCIFAccountList from '../modules/branchChecker/reviewCustomerAccountInformation/newCIFAndAccountList/components'
import BranchCheckerReviewPage from '../modules/branchChecker/reviewCustomerAccountInformation/newCIFAndAccountList/components/details'
import CorporateNewCIFAndAccountDetails from '../modules/branchChecker/reviewCustomerAccountInformation/newCIFAndAccountList/components/details/CorporateNewCIFAndAccountDetails'
import BranchMakerNewCIFAccountListPage from '../modules/branchMaker/newCIFAndAccountList/components/BranchMakerNewCIFAndAccountList'
import APIFailureList from '../modules/bulkAccountOpening/apiFailureList/components'
import APISuccessList from '../modules/bulkAccountOpening/apiSuccessList/components'
import ChildBatchList from '../modules/bulkAccountOpening/childBatchList/components'
import MasterBatchList from '../modules/bulkAccountOpening/masterBatchList/components'
import CPUCheckerNewCIFAndAccountList from '../modules/cpuChecker/newCIFAndAccountList/component'
import CPUCheckerNewCIFAndAccountIndividualJointMinorReviewPage from '../modules/cpuChecker/newCIFAndAccountList/component/Details'
import CPUCheckerNewCIFAndAccountCorproateBankReviewPage from '../modules/cpuChecker/newCIFAndAccountList/component/Details/CorporateBank'
import CPUMakerNewCIFAndAccountList from '../modules/cpuMaker/newCIFAndAccountList/components'
import IndividualJointMinorDetails from '../modules/cpuMaker/newCIFAndAccountList/components/Details'
import CorporateBankDetails from '../modules/cpuMaker/newCIFAndAccountList/components/Details/CorporateBank'
import ExistingCustomerVerification from '../modules/customerInformationVerification/existingCustomerVerification/components'
import ExistingCIF from '../modules/existingCIF/components'
import ExistingCIFListBranchChecker from '../modules/existingCIF/components/ExisitingCIFListBranchChecker'
import ExistingCIFListBranchMaker from '../modules/existingCIF/components/ExisitingCIFListBranchMaker'
import ExistingCIFReview from '../modules/existingCIF/components/ExistingCIFReviewPage'
import UpdateExisitingCIF from '../modules/existingCIF/components/UpdateExisitingCIF'
import KYCUpdatePage from '../modules/kyc/kycUpdate/components'
import KYCUpdateListPage from '../modules/kyc/kycUpdateList/components'
import KYCUpdateCPUCheckerReview from '../modules/kyc/kycUpdateList/components/CPUCheckerReview'
import KYCUpdateReview from '../modules/kyc/kycUpdateList/components/Review'
import LinkedEntitiesBranchCheckerList from '../modules/linkedEntitiesListing/branchChecker'
import LinkedEntitiesBranchMakerList from '../modules/linkedEntitiesListing/branchMaker'
import CreateLinkedEntities from '../modules/linkedEntitiesListing/components/CreateLinkedEntities'
import ReviewPage from '../modules/linkedEntitiesListing/components/ReviewPage'
import UpdateLinkedEntities from '../modules/linkedEntitiesListing/components/UpdateLinkedEntities'
import LinkedEntitiesStatus from '../modules/linkedEntitiesListing/components/linkedEntitiesStatus'
import NewCIFListing from '../modules/newCIF/newCIFListing/component'
import NewCIFReviewPage from '../modules/newCIF/newCIFListing/component/Review'
import NewCIFCheckerListing from '../modules/newCIF/newCIFListingChecker/component'
import NewCIFCheckerReviewPage from '../modules/newCIF/newCIFListingChecker/component/Review'
import HomepageSetting from '../modules/siteSettings/components/homepageSettings/components'
import BranchCheckerAncillaryLists from '../modules/ancillaryService/branchChecker/components'
import KYCBranchMakerReviewPage from '../modules/kyc/kycReviewBranchMaker/components'
import BranchCheckerAncillaryReviewPage from '../modules/ancillaryService/branchChecker/components/review'

export interface RouteModel {
  path?: any
  component?: React.ComponentType<RouteComponentProps>
  exact?: boolean
}

export const RouteList = [
  {
    path: '/dashboard',
    component: DashboardWrapper,
    exact: true,
  },
  {
    path: '/cms/content',
    component: ContentManager,
    exact: true,
  },

  {
    path: '/cms/menu-manager',
    component: MenuManager,
    exact: true,
  },
  {
    path: '/cms/banner',
    component: Banner,
    exact: true,
  },

  {
    path: '/popup/text-popup',
    component: TextPopup,
    exact: true,
  },

  {
    path: '/popup/road-block-popup',
    component: RoadBlockPopup,
    exact: true,
  },
  {
    path: '/cms/manage-faqs',
    component: FaqCategory,
    exact: true,
  },
  {
    path: '/cms/update-faqs/:id',
    component: FaqManager,
    exact: true,
  },
  {
    path: '/cms/email-template',
    component: EmailTemplate,
    exact: true,
  },

  {
    path: '/usermanagement/users',
    component: UserManagementIndex,
    exact: true,
  },
  {
    path: '/usermanagement/user-role',
    component: UserRoleManagementIndex,
    exact: true,
  },
  {
    path: '/usermanagement/role',
    component: RoleManagementIndex,
    exact: true,
  },
  {
    path: '/usermanagement/departments',
    component: Departments,
    exact: true,
  },
  {
    path: '/sitesetting/module',
    component: ModuleManager,
    exact: true,
  },
  {
    path: '/sitesetting/contact-setting',
    component: ContactSetting,
    exact: true,
  },
  {
    path: '/sitesetting/homepage-setting',
    component: HomepageSetting,
    exact: true,
  },
  {
    path: '/sitesetting/backend-setting',
    component: BackendSettings,
    exact: true,
  },
  {
    path: '/sitesetting/setting',
    component: Settings,
    exact: true,
  },
  {
    path: '/sitesetting/smtp-setting',
    component: SmtpSettings,
    exact: true,
  },
  {
    path: '/sitesetting/key-setting',
    component: KeySettings,
    exact: true,
  },

  //ENquiry Module

  {
    path: '/cms/forms-and-download',
    component: FormDownloadComponent,
    exact: true,
  },

  // bulk account opening
  {
    path: '/bulk-account-opening/master-batch-list',
    component: MasterBatchList,
    exact: true,
  },
  {
    path: '/bulk-account-opening/child-batch-list',
    component: ChildBatchList,
    exact: true,
  },
  {
    path: '/bulk-account-opening/api-success-list',
    component: APISuccessList,
    exact: true,
  },
  {
    path: '/bulk-account-opening/api-failure-list',
    component: APIFailureList,
    exact: true,
  },

  // Linked Entities Listing
  {
    path: '/linked-entities/linked-entities-status',
    component: LinkedEntitiesStatus,
    exact: true,
  },
  {
    path: '/branch-maker/linked-entities/linked-entities-listing',
    component: LinkedEntitiesBranchMakerList,
    exact: true,
  },
  {
    path: '/branch-maker/linked-entities/create-linked-entities',
    component: CreateLinkedEntities,
    exact: true,
  },
  {
    path: '/branch-checker/linked-entities/linked-entities-listing',
    component: LinkedEntitiesBranchCheckerList,
    exact: true,
  },
  {
    path: '/linked-entities/linked-entities-review/:id',
    component: ReviewPage,
    exact: true,
  },
  {
    path: '/branch-maker/linked-entities/review-linked-entities/:id',
    component: UpdateLinkedEntities,
    exact: true,
  },

  // create new cif and account
  {
    path: '/create-new-cif-and-account/create-new-account',
    component: CreateNewAccount,
    exact: true,
  },
  {
    path: '/create-new-cif-and-account/biometric-verification',
    component: BiometricVerificationPage,
    exact: true,
  },
  {
    path: '/create-new-cif-and-account/create-new-cif',
    component: CreateNewCifPage,
    exact: true,
  },
  {
    path: '/create-new-cif-and-account/update-and-review/:id',
    component: UpdateAndReview,
    exact: true,
  },
  {
    path: '/create-new-cif-and-account/biometric-verification/:id',
    component: BiometricVerificationPage,
    exact: true,
  },
  // branch maker
  {
    path: '/branch-maker/new-cif-and-account-list',
    component: BranchMakerNewCIFAccountListPage,
    exact: true,
  },

  // cpu maker
  {
    path: '/cpu-maker/new-cif-and-account-list',
    component: CPUMakerNewCIFAndAccountList,
    exact: true,
  },
  {
    path: '/cpu-maker/new-cif-and-account-list/:id',
    component: IndividualJointMinorDetails,
    exact: true,
  },
  {
    path: '/cpu-maker/new-cif-and-account-list/corporate-bank/:id',
    component: CorporateBankDetails,
    exact: true,
  },

  // cpu checker
  {
    path: '/cpu-checker/new-cif-and-account-list',
    component: CPUCheckerNewCIFAndAccountList,
    exact: true,
  },
  {
    path: '/cpu-checker/new-cif-and-account-list/:id',
    component: CPUCheckerNewCIFAndAccountIndividualJointMinorReviewPage,
    exact: true,
  },
  {
    path: '/cpu-checker/new-cif-and-account-list/corporate/:id',
    component: CPUCheckerNewCIFAndAccountCorproateBankReviewPage,
    exact: true,
  },

  //Existing CIF
  {
    path: '/existing-cif/create-new-account',
    component: ExistingCIF,
    exact: true,
  },
  {
    path: '/existing-cif/existing-cif-listing/branch-checker',
    component: ExistingCIFListBranchChecker,
    exact: true,
  },
  {
    path: '/existing-cif/existing-cif-listing/branch-maker',
    component: ExistingCIFListBranchMaker,
    exact: true,
  },
  {path: '/existing-cif/existing-cif-listing/:id', component: ExistingCIFReview, exact: true},
  {
    path: '/existing-cif/existing-cif-review/:id',
    component: UpdateExisitingCIF,
    exact: true,
  },
  // New CIF
  {path: '/new-cif/new-cif-listing', component: NewCIFListing, exact: true},
  {path: '/new-cif/new-cif-listing/checker', component: NewCIFCheckerListing, exact: true},
  {path: '/new-cif/new-cif-review/:id', component: NewCIFReviewPage, exact: true},
  {path: '/new-cif/new-cif-review/checker/:id', component: NewCIFCheckerReviewPage, exact: true},

  // KYC
  {path: '/kyc/kyc-update', component: KYCUpdatePage, exact: true},
  {path: '/kyc/kyc-update-list', component: KYCUpdateListPage, exact: true},
  {path: '/kyc/kyc-update-list/:id', component: KYCUpdateReview, exact: true},
  {
    path: '/kyc/kyc-update-list/branch-maker/:id',
    component: KYCBranchMakerReviewPage,
    exact: true,
  },
  {path: '/kyc/kyc-update-list/checker/:id', component: KYCUpdateCPUCheckerReview, exact: true},

  // Account
  {path: '/account/account-listing', component: AccountListing, exact: true},

  // Customer Information Verification
  {
    path: '/customer-information-verification/existing-customer-verification',
    component: ExistingCustomerVerification,
    exact: true,
  },

  // Ancillary Service
  {
    path: '/ancillary-service/request-list',
    component: AncillaryServiceRequestList,
    exact: true,
  },
  {
    path: '/ancillary-service/branch-checker-list',
    component: BranchCheckerAncillaryLists,
    exact: true,
  },
  {
    path: '/ancillary-service/branch-checker-review/:id',
    component: BranchCheckerAncillaryReviewPage,
    exact: true,
  },

  // branch checker
  {
    path: '/branch-checker/new-cif-and-account-list',
    component: BranchCheckerNewCIFAccountList,
    exact: true,
  },

  {
    path: '/branch-checker/new-cif-and-account-list/:id',
    component: BranchCheckerReviewPage,
    exact: true,
  },
  {
    path: '/branch-checker/new-cif-and-corporate-account-list/:id',
    component: CorporateNewCIFAndAccountDetails,
    exact: true,
  },

  // branch maker
  {
    path: '/branch-maker/new-cif-and-account-list',
    component: BranchMakerNewCIFAccountListPage,
    exact: true,
  },
]
