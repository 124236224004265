import {Data} from '@react-google-maps/api'
import {useState} from 'react'
import {Input, InputGroup, Pagination, Table} from 'rsuite'
import {StateParamsModel} from 'src/app/modules/common/Model'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import useGetKycLists from 'src/app/queries/Kyc Update/get/useGetKycLists'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {isEmpty} from 'lodash'
import {Close, Search} from '@rsuite/icons'
import {useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {RootState} from 'src/setup'

const Cell = Table.Cell

const InProgressKYCUpdateList = () => {
  const history = useHistory()

  const [params, setParams] = useState<StateParamsModel>({
    status: 'pending',
    page: 1,
    limit: 10,
    orderBy: 'updated_at',
    order: 'desc',
  })

  const {
    user: {
      data: {role},
    },
  } = useSelector((state: any) => state.auth)

  const {data: kycPendingLists, isLoading} = useGetKycLists(params)

  const data =
    kycPendingLists?.data?.kyc_logs?.map((kyclist: any, i: number) => ({
      ...kyclist,
      sn: (params?.page - 1) * params?.limit + (i + 1),
      account_name: `${kyclist?.first_name ?? ''} ${
        kyclist.middle_name ? kyclist.middle_name + ' ' : ''
      } ${kyclist?.last_name ?? ''}`,
      updatedOn: kyclist?.account_status?.updated_date ?? '',
      status: kyclist?.account_status?.current_status ?? '',
    })) ?? []

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }
  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    const isDraft = rowData.account_status.current_status === 'Save In Draft - CPU'
    return (
      <Cell {...props} className='link-group'>
        <button
          className={
            isDraft ? 'dt-btn dt-btn-outline-primary dt-btn-sm' : 'btn btn-primary btn-sm '
          }
          onClick={() =>
            history.push({
              pathname:
                role?.name === 'cpuchecker'
                  ? `/kyc/kyc-update-list/checker/${rowData?.id}`
                  : role.name === 'branchmaker'
                  ? `/kyc/kyc-update-list/branch-maker/${rowData?.id}`
                  : `/kyc/kyc-update-list/${rowData?.id}`,
              state: rowData,
            })
          }
        >
          {isDraft ? 'Continue' : 'Review'}
        </button>
      </Cell>
    )
  }

  const columns = [
    {
      label: 'Account Name',
      dataKey: 'account_name',
      cell: <Cell dataKey='account_name' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Account Number',
      dataKey: 'account_number',
      cell: <Cell dataKey='account_number' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'CIF Number',
      dataKey: 'cif_number',
      cell: <Cell dataKey='cif_number' />,
      sortable: false,
      width: 150,
    },
    {
      label: 'Branch',
      dataKey: 'branch',
      sortable: false,
      width: 150,
      cell: <Cell dataKey='branch' />,
    },
    {
      label: 'KYC Request Type',
      dataKey: 'kyc_request_type.title',
      sortable: false,
      width: 240,
      cell: <Cell dataKey='kyc_request_type.title' />,
    },
    {
      label: 'Remarks',
      dataKey: 'account_status.remarks',
      cell: <Cell dataKey='account_status.remarks' />,
      sortable: false,
      width: 230,
    },
    {
      label: 'Updated On',
      dataKey: 'updatedOn',
      cell: <Cell dataKey='updatedOn' />,
      sortable: false,
      width: 230,
    },
    {
      label: 'Action',
      width: 160,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]
  return (
    <div>
      <InputGroup inside style={{width: '30%', margin: '1rem 0'}}>
        <Input
          placeholder='Search'
          value={params.search}
          onChange={(value: string) => setParams({...params, search: value})}
          data-cy='search-field'
        />
        <InputGroup.Addon>
          {!isEmpty(params?.search) ? (
            <Close
              style={{cursor: 'pointer'}}
              onClick={() => setParams({...params, search: ''})}
              data-cy='search-close-button'
            />
          ) : (
            <Search />
          )}
        </InputGroup.Addon>
      </InputGroup>
      <div className='datatable'>
        <RSuiteTable columns={columns} data={data} showLoading={isLoading} />
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size='sm'
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={kycPendingLists?.data?.meta?.total ?? 0}
          limitOptions={[10, 20, 50, 100]}
          limit={params?.limit}
          activePage={params?.page}
          onChangePage={(value) => setParams({...params, page: value})}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </div>
  )
}

export default InProgressKYCUpdateList
