import DetailCell from '../DetailCell'

interface AccountInformationSectionProps {
  data?: any
}

const AccountInformationSection = ({data}: AccountInformationSectionProps) => {
  return (
    <>
      <div className='row'>
        <DetailCell label='Account Class' value={data?.kyc_information?.account_class?.name} />
        <DetailCell label='Branch' value={data?.kyc_information?.branch} />
      </div>
      <div className='row'>
        <DetailCell label='Date' value={data?.kyc_information?.date} />
        <DetailCell
          label='Account Category'
          value={data?.kyc_information?.account_category?.title}
        />
      </div>
      <div className='row'>
        <DetailCell
          label='Other Account Category'
          value={data?.kyc_information?.account_category_other}
        />
        <DetailCell
          label='Account Currency'
          value={data?.kyc_information?.account_currency?.title}
        />
      </div>
      <div className='row'>
        <DetailCell label='Account Purpose' value={data?.kyc_information?.account_purpose?.title} />
        <DetailCell
          label='Other Account Purpose'
          value={data?.kyc_information?.account_purpose_other}
        />
      </div>
      <div className='row'>
        <DetailCell
          label='Full Name'
          value={`${data?.first_name} ${data?.middle_name ? data?.middle_name + ' ' : ''} ${
            data?.last_name
          }`}
        />
        <DetailCell label='Cheque Book Name' value={data?.cheque_book_name} />
      </div>
      <div className='row'>
        <DetailCell label='Gender' value={data?.kyc_information?.gender} />
        <DetailCell label='Marital Status' value={data?.kyc_information?.marital_status?.title} />
      </div>
      <div className='row'>
        <DetailCell label='Date of Birth (B.S.)' value={data?.kyc_information?.date_of_birth_bs} />
        <DetailCell label='Date of Birth (A.D.)' value={data?.kyc_information?.date_of_birth_ad} />
      </div>
      <div className='row'>
        <DetailCell
          label='KYC Level Category'
          value={data?.kyc_information?.kyc_level_category?.title}
        />
        <DetailCell label='KYC Review Date' value={data?.kyc_information?.kyc_review_date} />
      </div>
      <div className='row'>
        <DetailCell label='Nationality' value={data?.nationality?.title} />
        <DetailCell label='PAN Number' value={data?.kyc_information?.pan_number} />
      </div>
    </>
  )
}

export default AccountInformationSection
