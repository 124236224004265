import * as Yup from 'yup'

export const kycReviewSchema = Yup.object().shape({
  customer_number: Yup.string().required('Required'),
  account_type: Yup.string().typeError('Invalid Account Type').required('Required'),
  branch: Yup.string().required('Required'),
  pep_confirmation: Yup.string().required('Required'),
  customer_category: Yup.string().required('Required'),
  customer_classification: Yup.string().required('Required'),

  aml_risk_rating: Yup.string().required('Required'),

  mobile_number: Yup.string()
    .matches(/^9\d{9}$/, 'Invalid Mobile Number')
    .required('Required'),
  email: Yup.string().email('Invalid Email').required('Required'),

  salutation: Yup.string().required('Required'),
  first_name: Yup.string().required('Required'),
  middle_name: Yup.string().nullable(),
  last_name: Yup.string().required('Required'),
  short_name: Yup.string().required('Required'),
  full_name: Yup.string().required('Required'),
  kyc_level_category: Yup.string().required('Required'),

  gender: Yup.string().required('Required'),
  marital_status: Yup.string().required('Required'),
  date_of_birth_bs: Yup.string().required('Required'),
  nationality: Yup.string().required('Required'),
  pan_number: Yup.string(),
  pan_registration_date_bs: Yup.string(),

  citizenship_number: Yup.string().when('preferred_identity', {
    is: (value: string) => value === 'citizenship',
    then: Yup.string()
      .matches(/^[0-9\-/]+$/, 'Invalid')
      .required('Required'),
    otherwise: Yup.string(),
  }),
  citizenship_issue_district: Yup.string().when('preferred_identity', {
    is: (value: string) => value === 'citizenship',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  citizenship_issue_date_bs: Yup.string().when('preferred_identity', {
    is: (value: string) => value === 'citizenship',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  passport_number: Yup.string().when('preferred_identity', {
    is: (value: string) => value === 'passport',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),

  passport_issue_district: Yup.string().when('preferred_identity', {
    is: (value: string) => value === 'passport',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),

  id_card_issuing_office: Yup.string().when('preferred_identity', {
    is: (value: string) => value === 'other',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  id_card_number: Yup.string().when('preferred_identity', {
    is: (value: string) => value === 'other',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  id_card_issue_date: Yup.string().when('preferred_identity', {
    is: (value: string) => value === 'other',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  id_card_issue_date_bs: Yup.string().when('preferred_identity', {
    is: (value: string) => value === 'other',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  id_card_expiry_date: Yup.string().when('preferred_identity', {
    is: (value: string) => value === 'other',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  id_card_expiry_date_bs: Yup.string().when('preferred_identity', {
    is: (value: string) => value === 'other',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  unique_id_issued_by: Yup.string().required('Required'),
  temporary_phone_number_residence: Yup.string().max(10, 'Invalid Phone Number'),
  temporary_phone_number_work: Yup.string().max(10, 'Invalid Phone Number'),
  landline_number: Yup.string().max(10, 'Invalid Phone Number'),

  permanent_phone_number_residence: Yup.string().max(10, 'Invalid Phone Number'),
  permanent_phone_number_work: Yup.string().max(10, 'Invalid Phone Number'),
  permanent_mobile_number: Yup.string().matches(/^9\d{9}$/, 'Invalid Mobile Number'),
  permanent_email: Yup.string().email('Invalid Email'),
  father_name: Yup.string()
    .required('Required')
    .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
  mother_name: Yup.string().matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
  grand_father_name: Yup.string()
    .required('Required')
    .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
  grand_mother_name: Yup.string().matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
  daughter_in_law_name: Yup.string().matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
  father_in_law_name: Yup.string().matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),

  occupation: Yup.string().required('Required'),
  source_of_income: Yup.string().required('Required'),
  employment_status: Yup.string().required('Required'),

  anticipated_annual_transaction: Yup.string().required('Required'),
  introducer_name: Yup.string().matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
  firstNomineeName: Yup.string().matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
  proof_address_document: Yup.string().required('Required'),
  secondNomineeName: Yup.string().matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
  resident_status: Yup.string().required('Required'),

  unique_id_issued_by_other: Yup.string(),
  education_qualification_others: Yup.string(),
  occupation_other: Yup.string(),
  source_of_income_other: Yup.string(),
  remarks: Yup.string().required('Required'),
})
