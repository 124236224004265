import {Form, Formik, FormikTouched} from 'formik'
import {useEffect, useState} from 'react'
import {IoMdRefresh} from 'react-icons/io'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'
import AccountStatusLog from 'src/app/modules/common/AccountStatusLog'
import * as cpuMakerReducer from 'src/app/modules/cpuMaker/newCIFAndAccountList/redux'
import {MasterDataModel} from 'src/app/modules/masterData/Model'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {RootState} from 'src/setup'
import {
  AccountInformationSection,
  CorrespondenceAddressSection,
  CustomerInformationSection,
  IdentificationDocumentSection,
  PermanentAddressSection,
  PersonalInformationSection,
  ProfessionRelatedInformationSection,
  ProfessionalInformationSection,
  RelativeInformationSection,
} from './review-form-field-sections'
import {cpuMakerIndividualMinorJointReviewSchema} from './validationSchema'
import * as Yup from 'yup'
import {KYCAdditionalForm} from 'src/app/modules/cpuMaker/newCIFAndAccountList/Model'
import {nanoid} from 'nanoid'
import moment from 'moment'
import {valueContainerCSS} from 'react-select/dist/declarations/src/components/containers'
import {isEqual} from 'lodash'

interface ReviewFormProps {
  data?: any
}

const ReviewForm = ({data}: ReviewFormProps) => {
  const temp_id = nanoid()
  const disptach = useDispatch()
  const history = useHistory()
  const [isRevertAction, setIsRevertAction] = useState(false)
  const [citizenshipNumber, setCitizenshipNumber] = useState<string>()
  const [passportNumber, setPassportNumber] = useState<string>()
  const [basicIdentification, setBasicIdentification] = useState<
    'citizenship' | 'passport' | 'other'
  >()
  const [idCardDetails, setIdCardDetails] = useState<{
    id_card_type: string
    id_card_number: string
  }>()

  const {
    user: {
      data: {branch},
    },
  } = useSelector((state: any) => state.auth)
  const {loading, success} = useSelector((state: RootState) => state.postAccountPreliminaryKYCForm)
  const {loading: additionalLoading, success: additionalSuccess} = useSelector(
    (state: RootState) => state.postAccountPreliminaryKYCAdditionalForm
  )
  const {data: accountPurposes}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.accountPurposeList
  )
  const {data: uniqueIdIssuedBy}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.individualUniqueIdList
  )
  const {data: qualifications}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.qualificationsList
  )
  const {data: occupations}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.occupationList
  )
  const {data: incomeSources}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.incomeSourceList
  )
  const {data: accountCategories}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.accountCategoriesList
  )
  const {data: countries}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.countriesList
  )
  const {
    data: accountTypes,
    loading: accountTypesLoading,
  }: {data: MasterDataModel[]; loading: boolean} = useSelector(
    (state: RootState) => state?.accountTypeList
  )
  const {data: maritalStatusList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.maritalStatusList
  )
  const {municipality} = useSelector((state: RootState) => state.place)

  useEffect(() => {
    if (isRevertAction) {
      if (success) {
        history.push('/cpu-maker/new-cif-and-account-list/')
      }
    } else {
      if (success && additionalSuccess) {
        history.push('/cpu-maker/new-cif-and-account-list/')
      }
    }
  }, [additionalSuccess, success, isRevertAction])

  const optionalValidationSchema = Yup.object().shape({
    account_purpose_other: Yup.string().when('account_purpose', {
      is: (value: string) =>
        value === accountPurposes.find((accountPurpose) => accountPurpose.title === 'Others')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    account_category_other: Yup.string().when('account_category', {
      is: (value: string) =>
        value ===
        accountCategories.find((accountCategory) => accountCategory.title === 'Others')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    unique_id_issued_by_other: Yup.string().when('unique_id_issued_by', {
      is: (value: string) => value === uniqueIdIssuedBy.find((uid) => uid.title === 'OTHERS')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    education_qualification_others: Yup.string().when('education_qualification', {
      is: (value: string) =>
        value === qualifications.find((qualification) => qualification.title === 'OTHERS')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    occupation_other: Yup.string().when('occupation', {
      is: (value: string) =>
        value === occupations.find((occupation) => occupation.title === 'Others')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    source_of_income_other: Yup.string().when('source_of_income', {
      is: (value: string) =>
        value === incomeSources.find((incomeSource) => incomeSource.title === 'Others')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),

    // dates validation
    date: Yup.string()
      .required('Required')
      .test('date-validation', function (value) {
        const isFuture = value && moment(value, 'YYYY-MM-DD').isAfter(moment())
        if (isFuture) {
          return false
        }
        return true
      }),

    date_of_birth_ad: Yup.string()
      .required('Required')
      .test('age-validation', function (value) {
        const {accountType} = this.parent
        const age = moment().diff(value, 'years')
        const isMinor =
          accountType === accountTypes.map((accountType) => accountType.title === 'Minor')
        if ((isMinor && age >= 18) || (!isMinor && age < 18)) {
          const message = isMinor
            ? 'Age must be less than 18 years'
            : 'Age must be minimum 18 years'
          return this.createError({
            message,
          })
        }
        return true
      }),
    pan_registration_date_ad: Yup.string().test(
      'date-validation',
      'Invalid Date',
      function (value) {
        const {date_of_birth_ad} = this.parent
        const isFuture = value && moment(value, 'YYYY-MM-DD').isAfter(moment())
        const isBeforeBirthDate =
          value && date_of_birth_ad && moment(value, 'YYYY-MM-DD').isBefore(date_of_birth_ad)
        if (isFuture || isBeforeBirthDate) {
          return false
        }
        return true
      }
    ),
    citizenship_issue_date_ad: Yup.string().when('basic_identification', {
      is: (value: string) => value === 'citizenship',
      then: Yup.string()
        .required('Required')
        .test('date-validation', 'Invalid Date', function (value) {
          const {date_of_birth_ad} = this.parent
          const isFuture = value && moment(value, 'YYYY-MM-DD').isAfter(moment())
          const isBeforeBirthDate =
            value &&
            date_of_birth_ad &&
            moment(value, 'YYYY-MM-DD').isBefore(moment(date_of_birth_ad, 'YYYY-MM-DD'))
          if (isFuture || isBeforeBirthDate) {
            return false
          }
          return true
        }),
      otherwise: Yup.string(),
    }),
    passport_issue_date: Yup.string().when('basic_identification', {
      is: (value: string) => value === 'passport',
      then: Yup.string()
        .required('Required')
        .test('date-validation', 'Invalid Date', function (value) {
          const {date_of_birth_ad} = this.parent
          const isFuture = value && moment(value, 'YYYY-MM-DD').isAfter(moment())
          const isBeforeBirthDate =
            value && date_of_birth_ad && moment(value, 'YYYY-MM-DD').isBefore(date_of_birth_ad)
          if (isFuture || isBeforeBirthDate) {
            return false
          }
          return true
        }),
      otherwise: Yup.string(),
    }),
    passport_expiry_date: Yup.string().when('basic_identification', {
      is: (value: string) => value === 'passport',
      then: Yup.string()
        .required('Required')
        .test('date-validation', 'Invalid Date', function (value) {
          const {date_of_birth_ad, passport_issue_date} = this.parent
          const isFutureFromRegistration =
            passport_issue_date &&
            value &&
            moment(value, 'YYYY-MM-DD').isAfter(moment(passport_issue_date, 'YYYY-MM-DD'))
          const isBeforeBirthDate =
            value && date_of_birth_ad && moment(value, 'YYYY-MM-DD').isBefore(date_of_birth_ad)
          if (!isFutureFromRegistration || isBeforeBirthDate) {
            return false
          }
          return true
        }),
      otherwise: Yup.string(),
    }),
    id_card_issue_date: Yup.string().when('basic_identification', {
      is: (value: string) => value === 'other',
      then: Yup.string()
        .required('Required')
        .test('date-validation', 'Invalid Date', function (value) {
          const {date_of_birth_ad} = this.parent
          const isFuture = value && moment(value, 'YYYY-MM-DD').isAfter(moment())
          const isBeforeBirthDate =
            value && date_of_birth_ad && moment(value, 'YYYY-MM-DD').isBefore(date_of_birth_ad)
          if (isFuture || isBeforeBirthDate) {
            return false
          }
          return true
        }),
      otherwise: Yup.string(),
    }),
    id_card_expiry_date: Yup.string().when('basic_identification', {
      is: (value: string) => value === 'other',
      then: Yup.string()
        .required('Required')
        .test('date-validation', 'Invalid Date', function (value) {
          const {date_of_birth_ad, id_card_issue_date} = this.parent
          const isFutureFromRegistration =
            id_card_issue_date &&
            value &&
            moment(value, 'YYYY-MM-DD').isAfter(moment(id_card_issue_date, 'YYYY-MM-DD'))
          const isBeforeBirthDate =
            value && date_of_birth_ad && moment(value, 'YYYY-MM-DD').isBefore(date_of_birth_ad)
          if (!isFutureFromRegistration || isBeforeBirthDate) {
            return false
          }
          return true
        }),
      otherwise: Yup.string(),
    }),
    kyc_review_date: Yup.string()
      .required('Required')
      .test('date-validation', 'Invalid Date', function (value) {
        const {date_of_birth_ad} = this.parent
        const isFuture = value && moment(value, 'YYYY-MM-DD').isAfter(moment())
        const isBeforeBirthDate =
          value && date_of_birth_ad && moment(value, 'YYYY-MM-DD').isBefore(date_of_birth_ad)
        if (!isFuture || isBeforeBirthDate) {
          return false
        }
        return true
      }),
    basic_identification: Yup.string()
      .required('Required')
      .test('minor-document', function (value) {
        const {accountType} = this.parent

        const isMinor =
          accountType === accountTypes.map((accountType) => accountType.title === 'Minor')

        if (isMinor && value === 'citizenship') {
          return this.createError({
            message: 'Minors cannot have citizenship',
          })
        } else if (isMinor && value === 'passport') {
          return this.createError({
            message: 'Minors cannot have Passport',
          })
        }

        return true
      }),
    spouse_name: Yup.string().when('marital_status', {
      is: (value: string) =>
        value &&
        value === maritalStatusList.find((maritalStatus) => maritalStatus.title === 'Married')?.id,
      then: Yup.string()
        .required('Required')
        .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
      otherwise: Yup.string().matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
    }),

    //Address Validations
    temporary_country: Yup.string().required('Required'),
    temporary_house_number: Yup.number().typeError('House numbers can only be in numbers'),
    temporary_tole: Yup.string().when('temporary_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string()
        .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed')
        .required('Required'),
      otherwise: Yup.string(),
    }),
    temporary_ward_number: Yup.number()
      .typeError('Ward Number can only be in numbers')
      .when('temporary_country', {
        is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
        then: Yup.number().typeError('Ward Number can only be in numbers').required('Required'),
        otherwise: Yup.number(),
      }),
    temporary_province: Yup.string().when('temporary_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    temporary_district: Yup.string().when('temporary_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    temporary_municipality: Yup.string().when('temporary_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    temporary_municipality_other: Yup.string().when('temporary_municipality', {
      is: (value: string) => value === municipality?.find((m) => m?.title === 'Others')?.id,
      then: Yup.string()
        .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed')
        .required('Required'),
      otherwise: Yup.string(),
    }),
    temporary_outside_nepal_address_line_1: Yup.string().when('temporary_country', {
      is: (value: string) => value !== countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Requierd'),
      otherwise: Yup.string(),
    }),
    temporary_outside_nepal_address_line_2: Yup.string().when('temporary_country', {
      is: (value: string) => value !== countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Requierd'),
      otherwise: Yup.string(),
    }),
    temporary_outside_nepal_address_line_3: Yup.string(),

    permanent_country: Yup.string().required('Required'),
    permanent_house_number: Yup.number().typeError('House Number can only be in numbers'),
    permanent_tole: Yup.string().when('permanent_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string()
        .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed')
        .required('Required'),
      otherwise: Yup.string(),
    }),
    permanent_ward_number: Yup.number().when('permanent_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.number().typeError('Ward Number can only be in numbers').required('Required'),
      otherwise: Yup.number(),
    }),
    permanent_province: Yup.string().when('permanent_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Requierd'),
      otherwise: Yup.string(),
    }),
    permanent_district: Yup.string().when('permanent_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Requierd'),
      otherwise: Yup.string(),
    }),
    permanent_municipality: Yup.string().when('permanent_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Requierd'),
      otherwise: Yup.string(),
    }),
    permanent_municipality_other: Yup.string().when('permanent_municipality', {
      is: (value: string) => value === municipality?.find((m) => m?.title === 'Others')?.id,
      then: Yup.string()
        .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed')
        .required('Required'),
      otherwise: Yup.string(),
    }),
    permanent_outside_nepal_address_line_1: Yup.string().when('permanent_country', {
      is: (value: string) => value !== countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    permanent_outside_nepal_address_line_2: Yup.string().when('permanent_country', {
      is: (value: string) => value !== countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Requierd'),
      otherwise: Yup.string(),
    }),
    permanent_outside_nepal_address_line_3: Yup.string(),
  })

  useEffect(() => {
    if (data?.id_type_id?.title === 'CITIZENSHIP CERTIFICATE') {
      setCitizenshipNumber(data?.id_number)
      if (data?.id_type_id) {
        setBasicIdentification(checkIndentification(data?.id_type_id?.code))
      }
    } else if (data?.id_type_id?.title === 'PASSPORT DOCUMENT') {
      setPassportNumber(data?.id_number)
      if (data?.id_type_id) {
        setBasicIdentification(checkIndentification(data?.id_type_id?.code))
      }
    } else {
      setIdCardDetails({id_card_number: data?.id_number, id_card_type: data?.id_type_id?.id})
      if (data?.id_type_id) {
        setBasicIdentification(checkIndentification(data?.id_type_id?.code))
      }
    }
  }, [data])

  const checkIndentification = (val: string) => {
    if (val == 'C') {
      return 'citizenship'
    } else if (val == 'P') {
      return 'passport'
    } else {
      return 'other'
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          accountType: data?.accountType ?? '',
          pep_confirmation: data?.pep_confirmation ?? '',
          branch_location_code: data?.branch_location_code ?? '',
          language: data?.language ?? '',
          institutional: data?.institutional ?? '',
          aml_risk_rating: data?.aml_risk_rating ?? '',
          customerCategoryId: data?.customerCategoryId?.code ?? '',
          media: data?.media ?? '',
          salutation: data?.salutation ?? '',
          first_name: data?.first_name ?? '',
          middle_name: data?.middle_name ?? '',
          last_name: data?.last_name ?? '',
          cheque_book_name: data?.cheque_book_name ?? '',
          customerClassificationId: data?.customerClassificationId?.id ?? '',
          taxCategoryId: data?.taxCategoryId?.cbs_code ?? '',
          taxGroupId: data?.taxGroupId?.cbs_code ?? '',
          exposureId: data?.exposureId?.code ?? '',
          cif_number: data?.registration_detail?.cif_number ?? '',
          account_number: data?.registration_detail?.account_number ?? '',
          customer_number: data?.customer_code ?? '',
          country_id: data?.country_id?.id ?? '',
          children:
            data?.children?.children.length > 0
              ? data?.children?.children
              : [{name_of_children: '', relation: ''}],

          institutions:
            data?.institutions?.institutions.length > 0
              ? data?.institutions?.institutions
              : [
                  {
                    name_of_related_employer: '',
                    business_address: '',
                    position: '',
                    yearly_remuneration: '',
                  },
                ],

          students:
            data?.students?.students.length > 0
              ? data?.students?.students
              : [{name_of_institute: '', student_address: '', phone_no: ''}],

          account_currency: data?.account_currency_id?.cbs_code ?? '',
          nationality: data?.nationality?.id ?? '',
          account_class: data?.accountClassId?.account_class,
          branch: branch?.code ?? '',
          date_of_birth_bs: data?.kyc_information?.date_of_birth_bs ?? '',
          date_of_birth_ad: data?.kyc_information?.date_of_birth_ad ?? '',
          date: data?.kyc_information?.date ?? moment().format('YYYY-MM-DD'),
          account_category: data?.kyc_information?.account_category?.id ?? '',
          account_category_other: data?.kyc_information?.account_category_other ?? '',
          account_purpose: data?.kyc_information?.account_purpose?.id ?? '',
          kyc_level_category: data?.kyc_information?.kyc_level_category?.id ?? '',
          gender: data?.kyc_information?.gender ?? 'Male',
          marital_status: data?.kyc_information?.marital_status?.id ?? '',
          basic_identification: basicIdentification ?? '',
          citizenship_number: data?.kyc_information?.citizenship_number ?? citizenshipNumber ?? '',
          citizenship_issue_district: data?.kyc_information?.citizenship_issue_district?.id ?? '',
          citizenship_issue_date_bs: data?.kyc_information?.citizenship_issue_date_bs ?? '',
          citizenship_issue_date_ad: data?.kyc_information?.citizenship_issue_date_ad ?? '',
          passport_number: data?.kyc_information?.passport_number ?? passportNumber ?? '',
          passport_issue_date: data?.kyc_information?.passpost_issue_date ?? '',
          passport_issue_district: data?.kyc_information?.passport_issue_district?.id ?? '',
          passport_expiry_date: data?.kyc_information?.passport_expiry_date ?? '',
          id_card_issue_district: data?.kyc_information?.id_card_issue_district ?? '',
          id_card_number:
            data?.kyc_information?.id_card_number ?? idCardDetails?.id_card_number ?? '',
          id_card_issue_date: data?.kyc_information?.id_card_issue_date ?? '',
          id_card_issue_date_bs: data?.kyc_information?.id_card_issue_date_bs ?? '',
          id_card_expiry_date: data?.id_card_expiry_date ?? '',
          id_card_expiry_date_bs: data?.kyc_information?.id_card_expiry_date_bs ?? '',
          unique_id_issued_by: data?.kyc_information?.unique_id_issued_by?.id ?? '',
          education_qualification: data?.kyc_information?.education_qualification?.id ?? '',
          temporary_country:
            data?.kyc_information?.temporary_country?.id ??
            countries.find((country) => country.title === 'Nepal')?.id ??
            '',
          temporary_house_number: data?.kyc_information?.temporary_house_number ?? '',
          temporary_tole: data?.kyc_information?.temporary_tole ?? '',
          temporary_ward_number: data?.kyc_information?.temporary_ward_number ?? '',
          temporary_province: data?.kyc_information?.temporary_province?.id ?? '',
          temporary_district: data?.kyc_information?.temporary_district?.id ?? '',
          temporary_municipality: data?.kyc_information?.temporary_municipality?.id ?? '',
          temporary_municipality_other: data?.kyc_information?.temporary_municipality_other ?? '',
          temporary_phone_number_residence:
            data?.kyc_information?.temporary_phone_number_residence ?? '',
          temporary_phone_number_work: data?.kyc_information?.temporary_phone_number_work ?? '',
          temporary_mobile_number:
            data?.kyc_information?.temporary_mobile_number ?? data?.mobile_number ?? '',
          temporary_email: data?.kyc_information?.temporary_email ?? data?.email ?? '',
          permanent_country:
            data?.kyc_information?.permanent_country?.id ??
            countries.find((country) => country.title === 'Nepal')?.id ??
            '',
          permanent_house_number: data?.kyc_information?.permanent_house_number ?? '',
          permanent_tole: data?.kyc_information?.permanent_tole ?? '',
          permanent_ward_number: data?.kyc_information?.permanent_ward_number ?? '',
          permanent_province: data?.kyc_information?.permanent_province?.id ?? '',
          permanent_district: data?.kyc_information?.permanent_district?.id ?? '',
          permanent_municipality: data?.kyc_information?.permanent_municipality?.id ?? '',
          permanent_municipality_other: data?.kyc_information?.permanent_municipality_other ?? '',
          permanent_phone_number_residence:
            data?.kyc_information?.permanent_phone_number_residence ?? '',
          permanent_phone_number_work: data?.kyc_information?.permanent_phone_number_work ?? '',
          permanent_mobile_number: data?.kyc_information?.permanent_mobile_number ?? '',
          permanent_email: data?.kyc_information?.permanent_email ?? '',
          spouse_name: data?.kyc_information?.spouse_name ?? '',
          father_name: data?.kyc_information?.father_name ?? '',
          mother_name: data?.kyc_information?.mother_name ?? '',
          grand_father_name: data?.kyc_information?.grand_father_name ?? '',
          grand_mother_name: data?.kyc_information?.grand_mother_name ?? '',
          guardian_name: data?.guardian_name ?? '',
          daughter_in_law_name: data?.kyc_information?.daughter_in_law_name ?? '',
          father_in_law_name: data?.kyc_information?.father_in_law_name ?? '',
          occupation: data?.kyc_information?.occupation?.id ?? '',
          additional_occupation: data?.kyc_information?.additional_occupation?.id ?? '',
          source_of_income: data?.kyc_information?.source_of_income?.id ?? '',
          employment_status: data?.kyc_information?.employment_status?.id ?? '',
          information_type: data?.kyc_information?.information_type ?? 'business',
          anticipated_annual_transaction:
            data?.kyc_information?.anticipated_annual_transaction ?? 'Upto Rs.1,00,000',
          introducer_name: data?.kyc_information?.introducer_name ?? '',
          kyc_review_date: data?.kyc_information?.kyc_review_date ?? '',
          firstNomineeName: data?.kyc_information?.firstNomineeName ?? '',
          proof_address_document: data?.kyc_information?.proof_address_document ?? '',
          secondNomineeName: data?.kyc_information?.secondNomineeName ?? '',
          account_purpose_other: data?.kyc_information?.account_purpose_other ?? '',
          unique_id_issued_by_other: data?.kyc_information?.unique_id_issued_by_other ?? '',
          education_qualification_others:
            data?.kyc_information?.education_qualification_others ?? '',
          occupation_other: data?.kyc_information?.occupation_other ?? '',
          source_of_income_other: data?.kyc_information?.source_of_income_other ?? '',
          resident_status: data?.kyc_information?.resident_status ?? '',
          pan_number: data?.kyc_information?.pan_number ?? '',
          pan_registration_date_bs: data?.kyc_information?.pan_registration_date_bs ?? '',
          pan_registration_date_ad: data?.kyc_information?.pan_registration_date_ad ?? '',
          temporary_outside_nepal_address_line_1:
            data?.kyc_information?.temporary_outside_nepal_address_line_1 ?? '',
          temporary_outside_nepal_address_line_2:
            data?.kyc_information?.temporary_outside_nepal_address_line_2 ?? '',
          temporary_outside_nepal_address_line_3:
            data?.kyc_information?.temporary_outside_nepal_address_line_3 ?? '',
          permanent_outside_nepal_address_line_1:
            data?.kyc_information?.permanent_outside_nepal_address_line_1 ?? '',
          permanent_outside_nepal_address_line_2:
            data?.kyc_information?.permanent_outside_nepal_address_line_2 ?? '',
          permanent_outside_nepal_address_line_3:
            data?.kyc_information?.permanent_outside_nepal_address_line_3 ?? '',
          type_of_identity_card: idCardDetails?.id_card_type ?? '',
          // temp_customer_id: data?.kyc_information?. temp_id,
          remarks: data?.kyc_information?.remarks ?? '',
        }}
        onSubmit={(values: any) => {
          setIsRevertAction(false)
          const additionalInformations: KYCAdditionalForm = {
            children: values.children,
          }
          if (values.information_type === 'business') {
            additionalInformations['institutions'] = values.institutions
            additionalInformations['students'] = null
          } else if (values.information_type === 'student') {
            additionalInformations['students'] = values.students
            additionalInformations['institutions'] = null
          }
          if (isEqual(values.children, [{name_of_children: '', relation: ''}])) {
            additionalInformations['children'] = null
          }

          if (values.basic_identification === 'citizenship') {
            values.passport_issue_date = ''
            values.passport_expiry_date = ''
            values.passport_issue_district = ''
            values.passport_number = ''

            values.id_card_number = ''
            values.id_card_issue_date = ''
            values.id_card_issue_date_bs = ''
            values.id_card_expiry_date = ''
            values.id_card_expiry_date_bs = ''
            values.id_card_issue_district = ''
            values.type_of_identity_card = ''
          } else if (values.basic_identification === 'passport') {
            values.citizenship_number = ''
            values.citizenship_issue_date_bs = ''
            values.citizenship_issue_date_ad = ''
            values.citizenship_issue_district = ''

            values.id_card_number = ''
            values.id_card_issue_date = ''
            values.id_card_issue_date_bs = ''
            values.id_card_expiry_date = ''
            values.id_card_expiry_date_bs = ''
            values.id_card_issue_district = ''
            values.type_of_identity_card = ''
          } else if (values.basic_identification === 'other') {
            values.citizenship_number = ''
            values.citizenship_issue_date_bs = ''
            values.citizenship_issue_date_ad = ''
            values.citizenship_issue_district = ''
            values.id_card_issue_district = values?.id_card_issue_district ?? ''

            values.passport_issue_date = ''
            values.passport_expiry_date = ''
            values.passport_issue_district = ''
            values.passport_number = ''
          }

          disptach(
            cpuMakerReducer.actions.postAccountPreliminaryKYCForm(
              data?.id,
              {
                ...values,
                currency_of_amount: 'NPR',
                unique_id_issued_by_corporate: 'None',
                permanent_house_number: values.permanent_house_number.toString(),
                permanent_phone_number_residence:
                  values.permanent_phone_number_residence.toString(),
                permanent_phone_number_work: values.permanent_phone_number_work.toString(),
                permanent_mobile_number: values.permanent_mobile_number.toString(),
                permanent_ward_number: values.permanent_ward_number.toString(),
                temporary_house_number: values.temporary_house_number.toString(),
                temporary_phone_number_residence:
                  values.temporary_phone_number_residence.toString(),
                temporary_phone_number_work: values.temporary_phone_number_work.toString(),
                temporary_mobile_number: values.temporary_mobile_number.toString(),
                temporary_ward_number: values.temporary_ward_number.toString(),
                action: 'save_and_continue',
              },
              additionalInformations
            )
          )
        }}
        validationSchema={cpuMakerIndividualMinorJointReviewSchema.concat(optionalValidationSchema)}
        enableReinitialize
      >
        {({
          touched,
          setFieldValue,
          errors,
          handleChange,
          values,
          validateForm,
          resetForm,
          validateField,
          getFieldMeta,
          setFieldTouched,
          setTouched,
          setFieldError,
          isValid,
          initialValues,
        }) => {
          const handleSaveOnly = async (e: React.MouseEvent<HTMLButtonElement>) => {
            setIsRevertAction(false)
            const additionalInformations: KYCAdditionalForm = {
              children: values.children,
            }
            if (values.information_type === 'business') {
              additionalInformations['institutions'] = values.institutions
            } else if (values.information_type === 'student') {
              additionalInformations['students'] = values.students
            }
            if (isEqual(values.children, [{name_of_children: '', relation: ''}])) {
              additionalInformations['children'] = null
            }
            if (
              isEqual(values.institutions, [
                {
                  name_of_related_employer: '',
                  business_address: '',
                  position: '',
                  yearly_remuneration: '',
                },
              ])
            ) {
              additionalInformations['institutions'] = null
            }

            if (values.basic_identification === 'citizenship') {
              values.passport_issue_date = ''
              values.passport_expiry_date = ''
              values.passport_issue_district = ''
              values.passport_number = ''

              values.id_card_number = ''
              values.id_card_issue_date = ''
              values.id_card_issue_date_bs = ''
              values.id_card_expiry_date = ''
              values.id_card_expiry_date_bs = ''
              values.id_card_issue_district = ''
              values.type_of_identity_card = ''
            } else if (values.basic_identification === 'passport') {
              values.citizenship_number = ''
              values.citizenship_issue_date_bs = ''
              values.citizenship_issue_date_ad = ''
              values.citizenship_issue_district = ''

              values.id_card_number = ''
              values.id_card_issue_date = ''
              values.id_card_issue_date_bs = ''
              values.id_card_expiry_date = ''
              values.id_card_expiry_date_bs = ''
              values.id_card_issue_district = ''
              values.type_of_identity_card = ''
            } else if (values.basic_identification === 'other') {
              values.citizenship_number = ''
              values.citizenship_issue_date_bs = ''
              values.citizenship_issue_date_ad = ''
              values.citizenship_issue_district = ''

              values.id_card_number = ''
              values.id_card_issue_date = ''
              values.id_card_issue_date_bs = ''
              values.id_card_expiry_date = ''
              values.id_card_expiry_date_bs = ''
              values.id_card_issue_district = ''
              values.type_of_identity_card = ''
            }

            disptach(
              cpuMakerReducer.actions.postAccountPreliminaryKYCForm(
                data?.id,
                {
                  ...values,
                  currency_of_amount: 'NPR',
                  unique_id_issued_by_corporate: 'None',
                  permanent_house_number: values.permanent_house_number.toString(),
                  permanent_phone_number_residence:
                    values.permanent_phone_number_residence.toString(),
                  permanent_phone_number_work: values.permanent_phone_number_work.toString(),
                  permanent_mobile_number: values.permanent_mobile_number.toString(),
                  permanent_ward_number: values.permanent_ward_number.toString(),
                  temporary_house_number: values.temporary_house_number.toString(),
                  temporary_phone_number_residence:
                    values.temporary_phone_number_residence.toString(),
                  temporary_phone_number_work: values.temporary_phone_number_work.toString(),
                  temporary_mobile_number: values.temporary_mobile_number.toString(),
                  temporary_ward_number: values.temporary_ward_number.toString(),
                  action: 'save',
                },
                additionalInformations
              )
            )
          }
          const handleRepair = async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            await validateField('remarks')
            setFieldTouched('remarks', true)
            if (getFieldMeta('remarks').error || !getFieldMeta('remarks').touched) {
              return
            }
            setIsRevertAction(true)
            disptach(
              cpuMakerReducer.actions.postAccountPreliminaryKYCForm(data?.id, {
                ...values,
                permanent_house_number: values.permanent_house_number.toString(),
                permanent_phone_number_residence:
                  values.permanent_phone_number_residence.toString(),
                permanent_phone_number_work: values.permanent_phone_number_work.toString(),
                permanent_mobile_number: values.permanent_mobile_number.toString(),
                permanent_ward_number: values.permanent_ward_number.toString(),
                temporary_house_number: values.temporary_house_number.toString(),
                temporary_phone_number_residence:
                  values.temporary_phone_number_residence.toString(),
                temporary_phone_number_work: values.temporary_phone_number_work.toString(),
                temporary_mobile_number: values.temporary_mobile_number.toString(),
                temporary_ward_number: values.temporary_ward_number.toString(),
                status: 'Repair',
                action: 'repair',
              })
            )
          }
          return (
            <Form>
              <div className='row'>
                <CustomerInformationSection
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                />
                <AccountInformationSection
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                />
                <PersonalInformationSection
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                  setFieldError={setFieldError}
                />
                <IdentificationDocumentSection
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                  initialValues={initialValues}
                />
                <hr />
                <CorrespondenceAddressSection
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                  initialValues={initialValues}
                />
                <hr />
                <PermanentAddressSection
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                  initialValues={initialValues}
                />
                <RelativeInformationSection
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                />
                <hr />
                <ProfessionalInformationSection
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                />
                <ProfessionRelatedInformationSection
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                />
                <AccountStatusLog data={data} />
                <div className='col-12 my-12'>
                  <FormTextBox
                    name='remarks'
                    label='Remarks'
                    onChange={handleChange}
                    containerClassName='col-12'
                    labelClassName='col-12'
                    as='textarea'
                    errors={errors}
                    touched={touched}
                    required
                  />
                </div>
                <div className='col-md-6 col-12 opacity-50'>
                  <div className='d-flex flex-column align-items-center justify-content-center text-center'>
                    <div
                      className='d-flex align-items-center justify-content-center mb-2'
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        resetForm()
                        toast.success('Form has been reest')
                      }}
                    >
                      <IoMdRefresh size={23} />
                      <span className='h4 ms-3 mb-n1'>Reset Form</span>
                    </div>
                    <span>Note: All the captured data will be cleared</span>
                  </div>
                </div>
                <div className='col-md-6 col-12 d-flex gap-1'>
                  <button
                    type='button'
                    onClick={handleRepair}
                    className='btn  btn-outline-warning'
                    style={{border: 1}}
                    disabled={loading}
                  >
                    Repair
                  </button>
                  <button
                    type='button'
                    onClick={handleSaveOnly}
                    className='btn  btn-outline-warning'
                    style={{border: 1}}
                    disabled={loading || additionalLoading}
                  >
                    Save
                  </button>
                  <button
                    type='submit'
                    className='btn btn-warning'
                    disabled={loading || additionalLoading}
                  >
                    Save & Continue
                  </button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default ReviewForm
