import {Table} from 'rsuite'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {columnModel} from 'src/cms/helpers/components/rsuiteTable/Models/columnModel'

const Cell = Table.Cell
interface PropietorDetailsInformationSectionProps {
  data: any
}

const PropietorDetailsInformationSection = ({data}: PropietorDetailsInformationSectionProps) => {
  const tableData = data?.directors?.directors.map((data: any, index: number) => ({
    ...data,
    sn: index + 1,
  }))
  const columns: columnModel[] = [
    {label: 'S.N', cell: <Cell dataKey='sn' />},
    {label: 'Title of Director', width: 350, cell: <Cell dataKey='title_of_director' />},
    {label: 'Detail of Director', width: 230, cell: <Cell dataKey='detail_of_director' />},
  ]
  return (
    <>
      <h2 className='text-center my-4'>
        DETAILS OF PROPRIETOR/PARTNERS/DIRECTORS/EXECUTIVE/COMMITTEE MEMBERS/MAJOR OFFICIALS
      </h2>
      <RSuiteTable columns={columns} data={tableData ?? []} />
    </>
  )
}

export default PropietorDetailsInformationSection
