import DetailCell from '../DetailCell'

interface TemporaryAddressInformationProps {
  data: any
}

const TemporaryAddressInformation = ({data}: any) => {
  return (
    <div className='my-10'>
      <h2 className='text-center my-4'>TEMPORARY</h2>
      <div className='row'>
        <DetailCell label='Country' value={data?.temporary_country?.title} />
      </div>
      <div className='row'>
        <DetailCell label='House Number' value={data?.temporary_house_number} />
        <DetailCell label='Street Name/Tole' value={data?.temporary_tole} />
      </div>
      <div className='row'>
        <DetailCell label='Ward Number' value={data?.temporary_ward_number} />
        <DetailCell label='Province' value={data?.temporary_province?.title} />
        <DetailCell label='District' value={data?.temporary_province?.title} />
      </div>
      <div className='row'>
        <DetailCell label='Municipality' value={data?.temporary_municipality?.title} />
        <DetailCell label='Other Municipality' value={data?.temporary_municipality_other} />
        <DetailCell label='Mobile Number' value={data?.temporary_mobile_number} />
        <DetailCell label='Phone No. (Resident)' value={data?.temporary_phone_number_residence} />
        <DetailCell label='Phone No. (Work)' value={data?.temporary_phone_number_work} />
        <DetailCell label='Email' value={data?.temporary_email} />
      </div>
    </div>
  )
}

export default TemporaryAddressInformation
