export const actionTypes = {
  SUBMIT_PRELIMINARY_FORM_START: 'SUBMIT_PRELIMINARY_FORM_START',
  SUBMIT_PRELIMINARY_FORM_SUCCESS: 'SUBMIT_PRELIMINARY_FORM_SUCCESS',
  SUBMIT_PRELIMINARY_FORM_FINISH: 'SUBMIT_PRELIMINARY_FORM_FINISH',

  SUBMIT_PRELIMINARY_DOCUMENTS_START: 'SUBMIT_PRELIMINARY_DOCUMENTS_START',
  SUBMIT_PRELIMINARY_DOCUMENTS_SUCCESS: 'SUBMIT_PRELIMINARY_DOCUMENTS_SUCCESS',
  SUBMIT_PRELIMINARY_DOCUMENTS_FINISH: 'SUBMIT_PRELIMINARY_DOCUMENTS_FINISH',
  RESET_PRELIMINARY_DOCUMENTS_STATE: 'RESET_PRELIMINARY_DOCUMENTS_STATE',

  SUBMIT_CORPORATE_PRELIMINARY_FORM_START: 'SUBMIT_CORPORATE_PRELIMINARY_FORM_START',
  SUBMIT_CORPORATE_PRELIMINARY_FORM_SUCCESS: 'SUBMIT_CORPORATE_PRELIMINARY_FORM_SUCCESS',
  SUBMIT_CORPORATE_PRELIMINARY_FORM_FINISH: 'SUBMIT_CORPORATE_PRELIMINARY_FORM_FINISH',
}
