import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const BRANCH_CHECKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_API = `${API_URL}/newcif-newaccount`

export const services = {
  getBranchCheckerNewCIFandAccountList: (params: ParamsModel) => {
    return axios.get(BRANCH_CHECKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_API, {params})
  },

  PostAccountPreliminaryBranchReview: (id: string, data: any) => {
    return axios.post(
      `${BRANCH_CHECKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_API}/${id}/branch-review`,
      data
    )
  },
  getBranchCheckerNewCIFandAccountListByID: (id: string) => {
    return axios.get(`${BRANCH_CHECKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_API}/${id}`)
  },
}
