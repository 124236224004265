import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface IAPIListState {
  loading: boolean
  success: boolean
  data: {
    bulk_accounts: Array<any>
  }
}

const initialAPIListState = {
  loading: false,
  success: false,
  data: {
    bulk_accounts: [],
  },
}

export const reducer = (
  state: IAPIListState = initialAPIListState,
  action: ActionWithPayload<IAPIListState>
) => {
  switch (action.type) {
    case actionTypes.GET_API_LIST_START:
      return {...state, loading: true}
    case actionTypes.GET_API_LIST_SUCCESS:
      return {...state, loading: false, success: true, data: action.payload}
    case actionTypes.GET_API_LIST_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}
