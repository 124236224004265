import {Table} from 'rsuite'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {columnModel} from 'src/cms/helpers/components/rsuiteTable/Models/columnModel'

const Cell = Table.Cell
interface BusinessDetailsInformationProps {
  data: any
}

const BusinessDetailsInformation = ({data}: BusinessDetailsInformationProps) => {
  const columns: columnModel[] = [
    {label: 'S.N', cell: <Cell dataKey='sn' />},
    {
      label: 'Name of Related Employer',
      width: 500,
      cell: <Cell dataKey='name_of_related_employer' />,
    },
    {label: 'Address', width: 230, cell: <Cell dataKey='business_address' />},
    {label: 'Position', width: 230, cell: <Cell dataKey='position' />},
    {label: 'Yearly Remuneration', width: 300, cell: <Cell dataKey='yearly_remuneration' />},
  ]

  const institutionsData = data?.institutions?.institutions.map((student: any, index: number) => ({
    ...student,
    sn: index + 1,
  }))
  return (
    <>
      <h2 className='text-center my-4'>DETAILS OF BUSINESS/PROFESSION</h2>
      <RSuiteTable columns={columns} data={institutionsData ?? []} />
    </>
  )
}

export default BusinessDetailsInformation
