import {Tab, Tabs} from 'react-bootstrap-v5'
import {CompletedKYCUpdateList, InProgressKYCUpdateList} from './tables'
import {useHistory} from 'react-router-dom'
import {useState} from 'react'
import {useSelector} from 'react-redux'

const KYCUpdateListPage = () => {
  const history = useHistory()

  const {
    user: {
      data: {role},
    },
  } = useSelector((state: any) => state.auth)

  const [activeKey, setActiveKey] = useState('inProgress')

  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='heading__component__title fw-bolder my-4'>KYC Update List</div>
        {role?.name === 'branchmaker' && (
          <button
            type='button'
            className='dt-btn dt-btn-primary h-50'
            onClick={() => {
              history.push('/kyc/kyc-update')
            }}
          >
            Update KYC
          </button>
        )}
      </div>
      <div className='modal-container shadow rounded' data-cy='new-cif-account-list-modal'>
        <div className='bg-white p-5'>
          <Tabs
            defaultActiveKey={activeKey}
            onSelect={(k) => setActiveKey(k ?? 'inProgress')}
            id='uncontrolled-tab-example'
            className='mb-5'
            data-cy='new-cif-account-list-tabs'
            unmountOnExit
            mountOnEnter
          >
            <Tab eventKey='inProgress' title='In Progress'>
              <div className='card-body bg-white'>
                <InProgressKYCUpdateList />
              </div>
            </Tab>
            <Tab eventKey='completed' title='Completed'>
              <div className='card-body bg-white'>
                <CompletedKYCUpdateList />
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  )
}

export default KYCUpdateListPage
