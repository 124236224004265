import {ParamsModel} from 'src/app/modules/common/Model'
import {KYCAdditionalForm} from '../Model'
import {actionTypes} from './constants'
import {ICPUMakerAndAccountList} from './reducer'

export const actions = {
  getCPUMakerNewCIFandAccountIndividualJointMinorListStart: (
    params: ParamsModel | undefined = {page: 1, limit: 10}
  ) => ({
    type: actionTypes.GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_MINOR_JOINT_MINOR_LIST_START,
    payload: {params},
  }),
  getCPUMakerNewCIFandAccountIndividualJointMinorListSuccess: (data: ICPUMakerAndAccountList) => ({
    type: actionTypes.GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_MINOR_JOINT_MINOR_LIST_SUCCESS,
    payload: data,
  }),
  getCPUMakerNewCIFandAccountIndividualJointMinorListFinish: () => ({
    type: actionTypes.GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_MINOR_JOINT_MINOR_LIST_FINISH,
  }),

  getCPUMakerNewCIFandAccountCorporateBankListStart: (
    params: ParamsModel | undefined = {page: 1, limit: 10}
  ) => ({
    type: actionTypes.GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_LIST_START,
    payload: {params},
  }),
  getCPUMakerNewCIFandAccountCorporateBankListSuccess: (data: ICPUMakerAndAccountList) => ({
    type: actionTypes.GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_SUCCESS,
    payload: data,
  }),
  getCPUMakerNewCIFandAccountCorporateBankListFinish: () => ({
    type: actionTypes.GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_FINISH,
  }),

  postAccountPreliminaryKYCForm: (id: string, data: any, additionalData?: any) => ({
    type: actionTypes.POST_ACCOUNT_PRELIMINARY_KYC_FORM_START,
    payload: {id, data, additionalData},
  }),
  postAccountPreliminaryKYCFormSuccess: () => ({
    type: actionTypes.POST_ACCOUNT_PRELIMINARY_KYC_FORM_SUCCESS,
  }),
  postAccountPreliminaryKYCFormFinish: () => ({
    type: actionTypes.POST_ACCOUNT_PRELIMINARY_KYC_FORM_FINISH,
  }),

  postAccountPreliminaryKYCAdditionalForm: (id: string, data: KYCAdditionalForm) => ({
    type: actionTypes.POST_ACCOUNT_PRELIMINARY_KYC_ADDITIONAL_FORM_START,
    payload: {id, data},
  }),
  postAccountPreliminaryKYCAdditionalFormSuccess: () => ({
    type: actionTypes.POST_ACCOUNT_PRELIMINARY_KYC_ADDITIONAL_FORM_SUCCESS,
  }),
  postAccountPreliminaryKYCAdditionalFormFinish: () => ({
    type: actionTypes.POST_ACCOUNT_PRELIMINARY_KYC_ADDITIONAL_FORM_FINISH,
  }),
}
