import ChildrenInformationSection from '../ChildrenInformationSection'
import DetailCell from '../DetailCell'
import StudentInformationSection from '../StudentInformationSection'

interface RelationshipInformationProps {
  data: any
}

const RelationshipInformation = ({data}: RelationshipInformationProps) => {
  return (
    <>
      <div className='row mt-5'>
        <DetailCell label='Father Name' value={data?.father_name} />
        <DetailCell label='Mother Name' value={data?.mother_name} />
        <DetailCell label='Grandfather Name' value={data?.grand_father_name} />
      </div>
      <div className='row mt-5'>
        <DetailCell label='Grandmother Name' value={data?.grand_mother_name} />
        <DetailCell label='Spouse Name' value={data?.spouse_name} />
        <DetailCell label='Daughter in Law Name' value={data?.daughter_in_law_name} />
      </div>
      <div className='row mt-5'>
        <DetailCell label='Father in Law Name' value={data?.father_in_law_name} />
      </div>
      <ChildrenInformationSection data={data} />
      <div className='row mt-5'>
        <DetailCell label='Occupation' value={data?.occupation?.title} />
      </div>
      <div className='row mt-5'>
        <DetailCell label='Other Occupation' value={data?.occupation_other?.title} />
        <DetailCell label='Additional Occupation' value={data?.additional_occupation?.title} />
        <DetailCell label='Source of Income' value={data?.source_of_income?.title} />
      </div>
      <div className='row mt-5'>
        <DetailCell label='Other Source of Income' value={data?.source_of_income_other} />
        <DetailCell label='Employment Status' value={data?.employment_status?.title} />
        <DetailCell label='Other Educational Status' value={data?.employment_status_other} />
      </div>
      <div className='row mt-5'>
        <DetailCell label='Currency of Amount' value={data?.currency_of_amount?.title} />
        <DetailCell label='Proof Address Document' value={data?.proof_address_document} />
        <DetailCell label='Residental Status' value={data?.resident_status?.title} />
      </div>
      <div className='row mt-5'>
        <DetailCell label='KYC Review Date' value={data?.kyc_review_date} />
      </div>
    </>
  )
}

export default RelationshipInformation
