import React, {useState} from 'react'
import {Modal, Button, Table} from 'rsuite'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {IoClose} from 'react-icons/io5'

const Cell = Table.Cell

type CIFCheckListProps = {
  onCancel: () => void
  data: any
  loading: boolean
}

const BlackListResultLists = ({onCancel, data, loading}: CIFCheckListProps) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [jsonData, setJsonData] = useState<any>(null)

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <button
          className='btn btn-primary btn-sm ms-3'
          onClick={() => {
            setJsonData(rowData)
            setModalVisible(true)
          }}
        >
          View Details
        </button>
      </Cell>
    )
  }

  const columns = [
    {
      label: 'Full Name',
      dataKey: 'Name',
      width: 250,
      cell: <Cell dataKey='Name' />,
    },
    {
      label: 'DOB',
      dataKey: 'DOB',
      width: 160,
      cell: <Cell dataKey='DOB' />,
    },
    {
      label: 'Father Name',
      dataKey: 'FatherName',
      width: 250,
      cell: <Cell dataKey='FatherName' />,
    },
    {
      label: 'Gender',
      dataKey: 'Gender',
      width: 160,
      cell: <Cell dataKey='Gender' />,
    },
    {
      label: 'Score',
      dataKey: 'score',
      width: 160,
      cell: <Cell dataKey='score' />,
    },
    {
      label: 'Source',
      dataKey: 'source',
      width: 160,
      cell: <Cell dataKey='source' />,
    },
    {
      label: 'Actions',
      dataKey: 'actions',
      width: 160,
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const renderNestedTable = (label: string, data: any) => {
    const nestedData = data.flatMap((entry: any, index: number) =>
      Object.entries(entry).map(([key, value]) => ({
        key: `${key} `,
        value,
      }))
    )

    return (
      <Table height={220} data={nestedData}>
        <Table.Column width={180}>
          <Table.HeaderCell>Title</Table.HeaderCell>
          <Table.Cell dataKey='key' />
        </Table.Column>
        <Table.Column width={360}>
          <Table.HeaderCell>Value</Table.HeaderCell>
          <Table.Cell dataKey='value' />
        </Table.Column>
      </Table>
    )
  }

  const renderDetailTable = () => {
    if (!jsonData) return null

    const detailData = Object.entries(jsonData).map(([key, value]) => ({
      key,
      value: Array.isArray(value) ? `${value.length} items` : value,
    }))

    return (
      <>
        <Table height={400} data={detailData}>
          <Table.Column width={180}>
            <Table.HeaderCell>Field</Table.HeaderCell>
            <Table.Cell dataKey='key' />
          </Table.Column>
          <Table.Column width={360}>
            <Table.HeaderCell>Value</Table.HeaderCell>
            <Table.Cell dataKey='value' />
          </Table.Column>
        </Table>
        {jsonData.Citizenships && (
          <div style={{marginTop: '10px'}}>
            <strong>Citizenships:</strong>
            {renderNestedTable('Citizenship', jsonData.Citizenships)}
          </div>
        )}
        {jsonData.BlackLists && (
          <div style={{marginTop: '10px'}}>
            <strong>Black Lists:</strong>
            {renderNestedTable('Black List', jsonData.BlackLists)}
          </div>
        )}
      </>
    )
  }

  return (
    <div className='my-8'>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div className='heading__component__title fs-6 fw-bolder mb-2'>
          Black List Search result ({data?.length ?? 0})
        </div>
      </div>
      <div className='datatable' style={{width: '100%'}}>
        <RSuiteTable columns={columns} data={data} showLoading={loading} showBorder={true} />
      </div>

      <Modal open={modalVisible} onClose={() => setModalVisible(false)}>
        <Modal.Header>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderDetailTable()}</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalVisible(false)} appearance='subtle'>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default BlackListResultLists
