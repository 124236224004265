import {adToBs, bsToAd} from '@sbmdkl/nepali-date-converter'
import {useSelector} from 'react-redux'
import {MasterDataModel} from 'src/app/modules/masterData/Model'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormNepaliCalendarDatePicker from 'src/cms/helpers/components/forms/form-fields/FormNepaliCalendarDatePicker'
import {RootState} from 'src/setup'

interface PersonalInformationSectionProps {
  handleChange: any
  touched: any
  errors: any
  setFieldValue: any
  values: any
}

const PersonalInformationSection = ({
  handleChange,
  touched,
  errors,
  setFieldValue,
  values,
}: PersonalInformationSectionProps) => {
  const {data: maritalStatusList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.maritalStatusList
  )
  const {data: nationalitiesList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.nationalitiesList
  )

  return (
    <>
      <div className=' d-flex my-7 col-12'>
        <label className='form-label fw-bolder text-dark fs-6 me-8 required '>Gender</label>
        <div className='d-flex'>
          {[
            {label: 'Male', value: 'Male'},
            {label: 'Female', value: 'Female'},
            {label: 'Other', value: 'Other'},
          ].map((gender) => (
            <FormRadio
              key={gender.value}
              checkBoxText={gender.label}
              errors={errors}
              name='gender'
              defaultValue={values.gender}
              onChange={handleChange}
              touched={touched}
              value={gender.value}
            />
          ))}
        </div>
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='marital_status'
          label='Maritial Status'
          containerClassName='col-12'
          options={maritalStatusList.map((maritalStatus) => ({
            label: maritalStatus.title,
            value: maritalStatus.id,
          }))}
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='pan_number'
          label='PAN Number'
          labelClassName='col-12'
          containerClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormNepaliCalendarDatePicker
          nepaliFormName='pan_registration_date_bs'
          englishFormName='pan_registration_date_ad'
          label='PAN Registration Date(B.S.)'
          setFieldValue={setFieldValue}
          touched={touched}
          values={values}
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          type='date'
          name='pan_registration_date_ad'
          label='PAN Registration Date(A.D)'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={(e: any) => {
            setFieldValue('pan_registration_date_bs', e.target.value && adToBs(e.target.value))
            handleChange(e)
          }}
          errors={errors}
          touched={touched}
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormNepaliCalendarDatePicker
          nepaliFormName='date_of_birth_bs'
          englishFormName='date_of_birth_ad'
          label='Date of Birth(B.S.)'
          setFieldValue={setFieldValue}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          type='date'
          name='date_of_birth_ad'
          label='Date of Birth(A.D.)'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={(e: any) => {
            setFieldValue('date_of_birth_bs', e.target.value && adToBs(e.target.value))
            handleChange(e)
          }}
          errors={errors}
          touched={touched}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='nationality'
          label='Nationality'
          containerClassName='col-12'
          options={
            nationalitiesList.map((nationality) => ({
              label: nationality.title,
              value: nationality.id,
            })) ?? []
          }
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
    </>
  )
}

export default PersonalInformationSection
