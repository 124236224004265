import {MasterDataModel} from 'src/app/modules/masterData/Model'
import DetailCell from '../DetailCell'
import {useSelector} from 'react-redux'
import {RootState} from 'src/setup'

interface PermanentAddressInformationSectionProps {
  data?: any
}

const PermanentAddressInformationSection = ({data}: PermanentAddressInformationSectionProps) => {
  const {data: countries}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.countriesList
  )
  return (
    <>
      <h2 className='text-center my-4'>PERMANENT ADDRESS</h2>
      {data?.kyc_information?.permanent_country?.title === 'Nepal' ? (
        <>
          <div className='row'>
            <DetailCell label='Street Name/Tole' value={data?.kyc_information?.permanent_tole} />
            <DetailCell
              label='House Number'
              value={data?.kyc_information?.permanent_house_number}
            />
          </div>
          <div className='row'>
            <DetailCell label='Ward Number' value={data?.kyc_information?.permanent_ward_number} />
          </div>
          <div className='row'>
            <DetailCell label='Country' value={data?.kyc_information?.permanent_country?.title} />
            <DetailCell label='Province' value={data?.kyc_information?.permanent_province?.title} />
          </div>
          <div className='row'>
            <DetailCell label='District' value={data?.kyc_information?.permanent_district?.title} />
            <DetailCell
              label='Municipality'
              value={data?.kyc_information?.permanent_municipality?.title}
            />
          </div>
          <div className='row'>
            <div className='col-6'></div>
            <DetailCell
              label='Other Municipality'
              value={data?.kyc_information?.permanent_municipality_other}
            />
          </div>
        </>
      ) : (
        <>
          <div className='row'>
            <DetailCell label='Country' value={data?.kyc_information?.permanent_country?.title} />
            <DetailCell
              label='Permanent Address Line 1'
              value={data?.kyc_information?.permanent_outside_nepal_address_line_1}
            />
          </div>
          <div className='row'>
            <DetailCell
              label='Permanent Address Line 2'
              value={data?.kyc_information?.permanent_outside_nepal_address_line_2}
            />
            <DetailCell
              label='Permanent Address Line 3'
              value={data?.kyc_information?.permanent_outside_nepal_address_line_3}
            />
          </div>
        </>
      )}
      <div className='row'>
        <DetailCell
          label='Phone No. (Res.)'
          value={data?.kyc_information?.permanent_phone_number_residence}
        />
        <DetailCell
          label='Phone No. (Work.)'
          value={data?.kyc_information?.permanent_phone_number_work}
        />
      </div>
      <div className='row'>
        <DetailCell label='Mobile No.' value={data?.kyc_information?.permanent_mobile_number} />
        <DetailCell label='Email' value={data?.kyc_information?.permanent_email} />
      </div>
    </>
  )
}

export default PermanentAddressInformationSection
