import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL
const ACCOUNT_PRELIMINARY_API = `${API_URL}/corporate-newcif-newaccount`

type PayloadType = {
  data: any
}

const postCorporateAccount = async (payload: PayloadType) => {
  try {
    const response = await axios.post(ACCOUNT_PRELIMINARY_API, payload.data)
    return response.data
  } catch (error: any) {
    throw new Error(`${error.response?.data?.message || error.message}`)
  }
}

const usePostCreateNewCorporateAccount = () => {
  const queryClient = useQueryClient()
  return useMutation(postCorporateAccount, {
    onSuccess: (data) => {
      if (data?.resCod == 200) {
        toast.success(data?.resDesc ?? 'Corporate Account Created successfully!')
      }
      queryClient.invalidateQueries('accountPreliminaryList')
      queryClient.invalidateQueries('branchCheckerNewCIFAndAccountList')
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error.message || 'An error occurred'}`)
    },
  })
}

export default usePostCreateNewCorporateAccount
