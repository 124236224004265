import axios from 'axios'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'
import {NEW_CIF_API} from '../api'

export interface NewCIFReviewPayload {
  status: string
  remarks: string
}

const postNewCIFReview = async (id: string, payload: NewCIFReviewPayload) => {
  try {
    return await axios.post(`${NEW_CIF_API}/${id}/review`, payload)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message)
      console.error('Error while posting corporate KYC Form :: ', error)
    } else {
      console.log(error)
    }
  }
}

export const usePostNewCIFReview = () =>
  useMutation({
    mutationFn: async ({id, payload}: {id: string; payload: NewCIFReviewPayload}) =>
      postNewCIFReview(id, payload),
  })
