import {useLocation} from 'react-router-dom'
import DocumentsPreview from './DocumentsPreview'
import ReviewForm from './ReviewForm'
import AccountStatusLog from 'src/app/modules/common/AccountStatusLog'

const KYCUpdateCPUCheckerReview = () => {
  const location = useLocation()
  const data: any = location.state
  return (
    <div className='row'>
      <div className='col-12 col-md-6'>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body pt-9 pb-9 '>
            <DocumentsPreview data={data} />
          </div>
        </div>
      </div>
      <div className='col-12 col-md-6'>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body pt-9 pb-9 ' style={{maxHeight: '100vh', overflowY: 'auto'}}>
            <ReviewForm data={data} />
          </div>
        </div>
      </div>
      <div className='col-12 '>
        <AccountStatusLog data={data} />
      </div>
    </div>
  )
}

export default KYCUpdateCPUCheckerReview
