import {adToBs, bsToAd} from '@sbmdkl/nepali-date-converter'
import {ErrorMessage} from 'formik'
import {useSelector} from 'react-redux'
import {MasterDataModel} from 'src/app/modules/masterData/Model'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormNepaliCalendarDatePicker from 'src/cms/helpers/components/forms/form-fields/FormNepaliCalendarDatePicker'
import {RootState} from 'src/setup'

interface IncorporationDetailsSectionProps {
  handleChange: any
  touched: any
  errors: any
  setFieldValue: any
  values: any
}

const IncorporationDetailsSection = ({
  handleChange,
  touched,
  errors,
  setFieldValue,
  values,
}: IncorporationDetailsSectionProps) => {
  const {district} = useSelector((state: RootState) => state.place)
  const {data: corporateRegistrationsList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.corporateRegistrationsList
  )

  return (
    <>
      <div className='col-12'>
        <h2 className='text-center'>INCORPORATION DETAILS</h2>
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='registration_number'
          label='Registration Number'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormNepaliCalendarDatePicker
          nepaliFormName='registration_date_bs'
          englishFormName='registration_date_ad'
          label='Registration Date (B.S)'
          setFieldValue={setFieldValue}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          type='date'
          name='registration_date_ad'
          label='Registration Date (A.D.)'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={(e: any) => {
            setFieldValue('registration_date_bs', e.target.value && adToBs(e.target.value))
            handleChange(e)
          }}
          errors={errors}
          touched={touched}
          required
        />
      </div>

      <div className='col-md-6 col-12'>
        <FormNepaliCalendarDatePicker
          nepaliFormName='registration_expiry_date_bs'
          englishFormName='registration_expiry_date_ad'
          label='Registration Expiry Date (B.S.)'
          setFieldValue={setFieldValue}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          type='date'
          name='registration_expiry_date_ad'
          label='Registration Expiry Date (A.D.)'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={(e: any) => {
            setFieldValue('registration_expiry_date_bs', e.target.value && adToBs(e.target.value))
            handleChange(e)
          }}
          errors={errors}
          touched={touched}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='registered_with'
          label='Registered With'
          containerClassName='col-12'
          labelClassName='col-12'
          options={
            corporateRegistrationsList.map((corporateRegistrations) => ({
              label: corporateRegistrations.title,
              value: corporateRegistrations.id,
            })) ?? []
          }
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='registered_with_other'
          label='Registered with Additional Information'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
        />
      </div>
      <div className='col-md-6'>
        <FormSelect
          name='issuing_place'
          label='Issuance Place'
          containerClassName='col-12'
          labelClassName='col-12'
          options={
            district?.map((districtData) => ({
              label:
                typeof districtData.title === 'string'
                  ? districtData.title
                  : districtData.title.toString(),
              value:
                typeof districtData.id === 'string' ? districtData.id : districtData.id.toString(),
            })) ?? []
          }
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='pan_number'
          label='PAN Number'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormNepaliCalendarDatePicker
          nepaliFormName='pan_registration_date_bs'
          englishFormName='pan_registration_date_ad'
          label='PAN Registration Date (B.S.)'
          setFieldValue={setFieldValue}
          touched={touched}
          values={values}
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          type='date'
          name='pan_registration_date_ad'
          label='PAN Registration Date (A.D)'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={(e: any) => {
            setFieldValue('pan_registration_date_bs', e.target.value && adToBs(e.target.value))
            handleChange(e)
          }}
          errors={errors}
          touched={touched}
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='description_of_business'
          label='Description of Business'
          labelClassName='col-12'
          containerClassName='col-12'
          as='textarea'
          onChange={handleChange}
          value={values.description_of_business}
          errors={errors}
          touched={touched}
        />
      </div>
    </>
  )
}

export default IncorporationDetailsSection
