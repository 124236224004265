import {Form, Formik} from 'formik'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import * as cpucheckerRedux from 'src/app/modules/cpuChecker/newCIFAndAccountList/redux'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {RootState} from 'src/setup'
import DetailCell from './DetailCell'
import {
  AccountInformationSection,
  BusinessDetailsInformation,
  ChildrenInformationSection,
  CustomerInformationSection,
  IdentificationDocumentSection,
  PermanentAddressInformationSection,
  ProfessionalInformationSection,
  RelativeInformationSection,
  TemporaryAddressInformationSection,
} from './sections'
import * as Yup from 'yup'
import StudentInformationSection from './sections/StudentInformationSection'
import {MdDataArray} from 'react-icons/md'

interface ReviewFormProps {
  data?: any
}

const cpuCheckerFormValidationSchema = Yup.object().shape({
  status: Yup.string().required('Required'),
  remarks: Yup.string().required('Required'),
})

const DetailsSection = ({data}: ReviewFormProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {loading, success} = useSelector((state: RootState) => state.cpuCheckerKYCReview)
  useEffect(() => {
    if (success) {
      history.push('/cpu-checker/new-cif-and-account-list')
    }
  }, [success])
  return (
    <>
      <CustomerInformationSection data={data} />
      <AccountInformationSection data={data} />
      <IdentificationDocumentSection data={data} />
      <hr />
      <TemporaryAddressInformationSection data={data} />
      <hr />
      <PermanentAddressInformationSection data={data} />
      <RelativeInformationSection data={data} />
      <ChildrenInformationSection data={data} />
      <hr />
      <ProfessionalInformationSection data={data} />
      <hr />
      {data?.kyc_information?.information_type === 'student' ? (
        <StudentInformationSection data={data} />
      ) : data?.kyc_information?.information_type === 'business' ? (
        <BusinessDetailsInformation data={data} />
      ) : null}
      <Formik
        initialValues={{status: '', remarks: ''}}
        onSubmit={(formData) => {
          dispatch(cpucheckerRedux.actions.postAccountPreliminaryKYCCPUChecker(data?.id, formData))
        }}
        validationSchema={cpuCheckerFormValidationSchema}
      >
        {({errors, handleChange, touched, setFieldValue, values}) => (
          <Form>
            <div className='row my-8'>
              <DetailCell
                label='Anticipated Annual Transaction'
                value={data?.kyc_information?.anticipated_annual_transaction}
              />
              <DetailCell label='Introducer Name' value={data?.kyc_information?.introducer_name} />
              <div className='col-12 col-md-6 col-lg-4'>
                <FormSelect
                  name='status'
                  label='Status'
                  containerClassName='col-12'
                  labelClassName='col-12'
                  options={[
                    {label: 'APPROVE', value: 'approve'},
                    {label: 'REVERT BACK TO CPU MAKER', value: 'revert_to_cpumaker'},
                    {label: 'REVERT BACK TO BRANCH MAKER', value: 'revert_to_branchmaker'},
                  ]}
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  values={values}
                  required
                />
              </div>
            </div>
            <div className='my-12 col-md-12'>
              <FormTextBox
                errors={errors}
                name='remarks'
                label='Remarks'
                containerClassName='col-12'
                labelClassName='col-12'
                onChange={handleChange}
                touched={touched}
                as='textarea'
                required
              />
            </div>
            <div className='d-flex gap-2 justify-content-end'>
              <button
                type='button'
                onClick={() => history.goBack()}
                className='btn btn-outline-warning'
              >
                Back
              </button>
              <button type='submit' className='btn-sm btn-warning' disabled={loading}>
                Approve & Send
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default DetailsSection
