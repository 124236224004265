import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as customerClassificationListRedux from 'src/app/modules/masterData/redux'
import type {
  CustomerClassificationDataModel,
  MasterDataModel,
} from 'src/app/modules/masterData/Model'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import {RootState} from 'src/setup'
import {ErrorMessage} from 'formik'
import FormNepaliCalendarDatePicker from 'src/cms/helpers/components/forms/form-fields/FormNepaliCalendarDatePicker'
import {adToBs} from '@sbmdkl/nepali-date-converter'

interface BasicInformationSectionProps {
  handleChange: any
  touched: any
  errors: any
  setFieldValue: any
  values: any
  initialValues: any
  data: any
}

const BasicInformationSection = ({
  handleChange,
  errors,
  touched,
  setFieldValue,
  values,
  initialValues,
  data,
}: BasicInformationSectionProps) => {
  const dispatch = useDispatch()

  const {
    data: salutationList,
    loading: salutationLoading,
  }: {data: MasterDataModel[]; loading: boolean} = useSelector(
    (state: RootState) => state?.salutationList
  )
  const {data: maritalStatusList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.maritalStatusList
  )

  const [customerClassificationList, setCustomerClassificationList] = useState<
    Array<{label: string; value: string}>
  >([])

  const customerClassificationListData = useSelector(
    (state: any) => state?.customerClassificationList
  )
  const {data: nationalitiesList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.nationalitiesList
  )

  useEffect(() => {
    dispatch(customerClassificationListRedux.actions.getCustomerClassification())
  }, [])

  useEffect(() => {
    if (customerClassificationListData) {
      setCustomerClassificationList(
        customerClassificationListData?.data?.map(
          (customerClassification: CustomerClassificationDataModel) => ({
            label: customerClassification.title,
            value: customerClassification.id,
          })
        )
      )
    }
  }, [customerClassificationListData])

  return (
    <>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Customer Number</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.cif_number ?? '-'}</strong>
        </div>
        <div className='col-4'></div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Account Number</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.account_number ?? '-'}</strong>
        </div>
        <div className='col-4'></div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>CIF Branch</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6 '>{data?.branch ?? ''}</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6 '>{data?.branch ?? ''}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Salutation</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.salutation ?? ''}</strong>
        </div>
        <div className='col-4'>
          <FormSelect
            name='salutation'
            label=' '
            containerClassName='col-12'
            labelClassName='col-12'
            options={
              salutationList.map((salutation) => ({
                label: salutation.title,
                value: salutation.title ?? '',
              })) ?? []
            }
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>First Name</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data.first_name ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            name='first_name'
            label=' '
            containerClassName='col-12'
            onChange={handleChange}
            errors={errors}
            touched={touched}
            disabled
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Middle Name</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.middle_name ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            name='middle_name'
            label=' '
            containerClassName='col-12'
            onChange={handleChange}
            errors={errors}
            touched={touched}
            disabled
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Last Name</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.last_name ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            name='last_name'
            label=' '
            containerClassName='col-12'
            onChange={handleChange}
            errors={errors}
            touched={touched}
            disabled
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Short Name</strong>
        </div>
        <div className='col-4'>
          {' '}
          <strong className='fs-6'>{data?.short_name ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            name='short_name'
            label=' '
            containerClassName='col-12'
            onChange={handleChange}
            errors={errors}
            touched={touched}
            disabled
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Full Name</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.full_name ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            name='full_name'
            label=' '
            containerClassName='col-12'
            onChange={handleChange}
            errors={errors}
            touched={touched}
            disabled
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Gender</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.gender ?? '-'}</strong>
        </div>
        <div className='col-4'>
          {['Male', 'Female', 'Other'].map((gender) => (
            <FormRadio
              name='gender'
              label=' '
              checkBoxText={gender}
              value={gender}
              containerClassName='ny-1'
              onChange={handleChange}
              errors={errors}
              touched={touched}
            />
          ))}
        </div>
        <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
          <ErrorMessage name='gender' component='div' className='field-error-message' />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Nationality</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.nationality?.title ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormSelect
            name='nationality'
            label=' '
            containerClassName='col-12'
            labelClassName='col-12'
            options={
              nationalitiesList.map((nationality) => ({
                label: nationality.title,
                value: nationality.id,
              })) ?? []
            }
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Date of Birth(B.S)</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.date_of_birth_bs ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormNepaliCalendarDatePicker
            englishFormName='date_of_birth_ad'
            nepaliFormName='date_of_birth_bs'
            setFieldValue={setFieldValue}
            touched={touched}
            values={values}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Date of Birth(A.D)</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.date_of_birth_ad ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            name='date_of_birth_ad'
            type='date'
            label=' '
            containerClassName='col-12'
            onChange={(e: any) => {
              setFieldValue('date_of_birth_bs', e.target.value && adToBs(e.target.value))
              handleChange(e)
            }}
            errors={errors}
            touched={touched}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Marital Status</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.marital_status?.title ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormSelect
            name='marital_status'
            label=' '
            containerClassName='col-12'
            labelClassName='col-12'
            options={
              maritalStatusList.map((maritalStatus) => ({
                label: maritalStatus.title,
                value: maritalStatus.id,
              })) ?? []
            }
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Residental Status</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6' style={{textTransform: 'uppercase'}}>
            {data?.resident_status ?? '-'}
          </strong>
        </div>
        <div className='col-4'>
          {[
            {label: 'Residental', value: 'residental'},
            {label: 'Non-residental', value: 'non-residental'},
          ].map((residentStatus) => (
            <FormRadio
              key={residentStatus.value}
              checkBoxText={residentStatus.label}
              errors={errors}
              name='resident_status'
              onChange={handleChange}
              touched={touched}
              value={residentStatus.value}
            />
          ))}
        </div>
        <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
          <ErrorMessage name='resident_status' component='div' className='field-error-message' />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Introducer Name</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.introducer_name ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            name='introducer_name'
            label=' '
            containerClassName='col-12'
            onChange={handleChange}
            errors={errors}
            touched={touched}
            disabled
          />
        </div>
      </div>
    </>
  )
}

export default BasicInformationSection
