import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {handleUppercaseChange} from 'src/app/modules/CreateNewCIFAndCustomerAccount/createNewAccount/components'
import * as placeReducer from 'src/app/modules/common'
import {MasterDataModel} from 'src/app/modules/masterData/Model'
import FormCheckbox from 'src/cms/helpers/components/forms/FormCheckbox'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {RootState} from 'src/setup'

interface PermanentAddressSectionProps {
  handleChange: any
  touched: any
  errors: any
  setFieldValue: any
  values: any
  initialValues: any
  data: any
}

const PermanentAddressSection = ({
  handleChange,
  touched,
  errors,
  setFieldValue,
  values,
  initialValues,
  data,
}: PermanentAddressSectionProps) => {
  const dispatch = useDispatch()

  const [sameAsCorrespondence, setSameAsCorrespondence] = useState(false)
  const [districtList, setDistrictList] = useState<{[key: string]: string | number}[]>()
  const [municipalityList, setMunicipalityList] = useState<{[key: string]: string | number}[]>()

  const {district, province, municipality, loading} = useSelector((state: RootState) => state.place)
  const {data: countries}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.countriesList
  )

  useEffect(() => {
    if (values.permanent_province) {
      const districts = district?.filter((d) => {
        return d.province_id === values.permanent_province
      })
      setDistrictList(districts)
    } else {
      setDistrictList(district)
    }
  }, [values.permanent_province, district])

  // Resetting Municipality on District change and both on province change
  useEffect(() => {
    if (!sameAsCorrespondence) {
      if (
        values.permanent_district &&
        initialValues?.permanent_municipality !== values.permanent_municipality
      ) {
        setFieldValue('permanent_district', '')
      }
    }
  }, [values.permanent_province, sameAsCorrespondence])

  useEffect(() => {
    if (!sameAsCorrespondence) {
      if (
        values.permanent_municipality &&
        initialValues?.permanent_municipality !== values.permanent_municipality
      ) {
        setFieldValue('permanent_municipality', '')
      }
    }
  }, [values.permanent_district, sameAsCorrespondence])

  useEffect(() => {
    if (values.permanent_district) {
      const municipalities = municipality?.filter((m) => {
        return m.district_id === values.permanent_district
      })
      setMunicipalityList(municipalities)
    } else {
      setMunicipalityList(municipality)
    }
  }, [values.permanent_district, municipality])

  useEffect(() => {
    if (sameAsCorrespondence) {
      setFieldValue('permanent_country', values.temporary_country)
      if (values.temporary_country === countries.find((country) => country.title === 'Nepal')?.id) {
        setFieldValue('permanent_house_number', values.temporary_house_number)
        setFieldValue('permanent_tole', values.temporary_tole)
        setFieldValue('permanent_ward_number', values.temporary_ward_number)
        setFieldValue('permanent_province', values.temporary_province)
        setFieldValue('permanent_district', values.temporary_district)
        setFieldValue('permanent_municipality', values.temporary_municipality)
        setFieldValue('permanent_municipality_other', values.temporary_municipality_other)
      } else {
        setFieldValue(
          'permanent_outside_nepal_address_line_1',
          values.temporary_outside_nepal_address_line_1
        )
        setFieldValue(
          'permanent_outside_nepal_address_line_2',
          values.temporary_outside_nepal_address_line_2
        )
        setFieldValue(
          'permanent_outside_nepal_address_line_3',
          values.temporary_outside_nepal_address_line_3
        )
      }
      setFieldValue('permanent_phone_number_residence', values.temporary_phone_number_residence)
      setFieldValue('permanent_phone_number_work', values.temporary_phone_number_work)
      setFieldValue('permanent_mobile_number', values.temporary_mobile_number)
      setFieldValue('permanent_email', values.temporary_email)
    }
  }, [
    sameAsCorrespondence,
    values.temporary_country,
    values.temporary_house_number,
    values.temporary_tole,
    values.temporary_province,
    values.temporary_district,
    values.temporary_municipality,
    values.temporary_municipality_other,
    values.temporary_phone_number_residence,
    values.temporary_phone_number_work,
    values.temporary_mobile_number,
    values.temporary_email,
    values.permanent_outside_nepal_address_line_1,
    values.permanent_outside_nepal_address_line_2,
    values.permanent_outside_nepal_address_line_3,
  ])

  useEffect(() => {
    dispatch(placeReducer.action.getProvince())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getDistrict())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getMunicipality())
  }, [])
  return (
    <>
      <p className='fs-2'>Permanent Address</p>
      <FormCheckbox
        name='checkBox'
        label=' '
        checkBoxText='Same as Correspondence Address'
        containerClassName='col-12 my-6'
        onChange={(e) => {
          setSameAsCorrespondence(e.target.checked)
        }}
        errors={errors}
        touched={touched}
      />
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>AddrLine1</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_AddrLine1 ?? '-'}</strong>
        </div>
        <div className='col-4'></div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>AddrLine2</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_AddrLine2 ?? '-'}</strong>
        </div>
        <div className='col-4'></div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>AddrLine3</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_AddrLine3 && '-'}</strong>
        </div>
        <div className='col-4'></div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>House Number</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_house_number ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            name='permanent_house_number'
            label=' '
            containerClassName='col-12'
            onChange={handleChange}
            errors={errors}
            touched={touched}
            disabled={sameAsCorrespondence}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Street Name/Tole</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_tole ?? '- '}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            name='permanent_tole'
            label=' '
            containerClassName='col-12'
            onChange={handleUppercaseChange(handleChange)}
            errors={errors}
            touched={touched}
            disabled={sameAsCorrespondence}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Ward Number</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_ward_number ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            name='permanent_ward_number'
            label=' '
            containerClassName='col-12'
            onChange={handleChange}
            errors={errors}
            touched={touched}
            disabled={sameAsCorrespondence}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Country</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_country?.title ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormSelect
            name='permanent_country'
            label=' '
            containerClassName='col-12'
            labelClassName='col-12'
            options={countries?.map((country) => ({label: country.title, value: country.id})) ?? []}
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
            disabled={sameAsCorrespondence}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Province</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_province?.title ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormSelect
            name='permanent_province'
            label=' '
            containerClassName='col-12'
            labelClassName='col-12'
            options={province?.map((provinceData) => ({
              label:
                typeof provinceData.title === 'string'
                  ? provinceData.title
                  : provinceData.title.toString(),
              value:
                typeof provinceData.id === 'string' ? provinceData.id : provinceData.id.toString(),
            }))}
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
            disabled={sameAsCorrespondence}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>District</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_district?.title ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormSelect
            name='permanent_district'
            label=' '
            containerClassName='col-12'
            labelClassName='col-12'
            options={districtList?.map((districtData) => ({
              label:
                typeof districtData.title === 'string'
                  ? districtData.title
                  : districtData.title.toString(),
              value:
                typeof districtData.id === 'string' ? districtData.id : districtData.id.toString(),
            }))}
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
            disabled={sameAsCorrespondence}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Municipality</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_municipality?.title ?? ''}</strong>
        </div>
        <div className='col-4'>
          <FormSelect
            name='permanent_municipality'
            label=' '
            containerClassName='col-12'
            labelClassName='col-12'
            options={municipalityList?.map((municipalityData) => ({
              label:
                typeof municipalityData.title === 'string'
                  ? municipalityData.title
                  : municipalityData.title.toString(),
              value:
                typeof municipalityData.id === 'string'
                  ? municipalityData.id
                  : municipalityData.id.toString(),
            }))}
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
            disabled={sameAsCorrespondence}
          />
        </div>
      </div>
    </>
  )
}

export default PermanentAddressSection
