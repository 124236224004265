import axios from 'axios'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const CUSTOMER_CLASSIFICATION_API_ENDPOINT = `${API_URL}/customer-classification-list`
export const DOCUMENT_TYPE_API_ENDPOINT = `${API_URL}/document-types`
export const MASTER_DATA_API = `${API_URL}/master-data`
export const services = {
  getCustomerClassification: () => {
    return axios.get(CUSTOMER_CLASSIFICATION_API_ENDPOINT)
  },
  getAllMasterData: () => {
    return axios.get(MASTER_DATA_API)
  },
  getDocumentTypes: () => {
    return axios.get(DOCUMENT_TYPE_API_ENDPOINT)
  },
}
