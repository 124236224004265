import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from 'src/app/modules/auth'
import * as profile from 'src/app/modules/profileComponent'
import {globalErrorReducer} from 'src/app/modules/errors/redux/reducer'
import * as siteModule from 'src/app/modules/siteSettings'
import * as userManagement from 'src/app/modules/userMgmt'
import * as userRole from 'src/app/modules/userMgmt/components/userRoles'
import * as departments from 'src/app/modules/userMgmt/components/departments'
import * as rolePermission from 'src/app/modules/common'
import * as cms from 'src/app/modules/cms'
import * as banner from 'src/app/modules/cms/components/banner/redux'

// master data
import * as masterData from 'src/app/modules/masterData/redux'

import * as textPopup from 'src/app/modules/cms/components/textPopup/redux'
import * as roadBlockPopup from 'src/app/modules/cms/components/roadblockPopup/redux'
import * as mediaManager from 'src/app/modules/common/components/fileManager'
import * as faqCategory from 'src/app/modules/cms/components/faqCategory/redux'
import * as faqManager from 'src/app/modules/cms/components/faqManager/redux'
import * as faqIconType from 'src/app/modules/cms/components/faqCategory/faqIconType/redux'

import * as emailTemplate from 'src/app/modules/cms/components/emailTemplate/redux'
import * as formsDownload from 'src/app/modules/formsAndDownload'
import * as accountPreliminary from 'src/app/modules/CreateNewCIFAndCustomerAccount/createNewAccount/redux'
import * as DuplicateCIF from 'src/app/pages/dashboard/redux'

// cpu maker
import * as cpuMakerNewCIFAndAccountList from 'src/app/modules/cpuMaker/newCIFAndAccountList/redux'

// branch checker
import * as branchCheckerNewCIFAndAccountList from 'src/app/modules/branchChecker/reviewCustomerAccountInformation/newCIFAndAccountList/redux'

// cpu checker
import * as cpuCheckerNewCIFAndAccountList from 'src/app/modules/cpuChecker/newCIFAndAccountList/redux'
import {link} from 'fs'

// bulk account list
import * as bulkAccountRedux from 'src/app/modules/bulkAccountOpening/masterBatchList/redux'
import * as apiListRedux from 'src/app/modules/bulkAccountOpening/apiSuccessList/redux'

// new cif
import * as newCIFRedux from 'src/app/modules/newCIF/newCIFListing/redux'

// new cif cpu checker
import * as newCIFCPUCheckerRedux from 'src/app/modules/newCIF/newCIFListingChecker/redux'

// existing cif
import * as existingCIFRedux from 'src/app/modules/existingCIF/redux'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  globalError: globalErrorReducer,
  smtp: siteModule.smtp.reducer,
  key: siteModule.key.reducer,
  groupSetting: siteModule.groupSetting.reducer,
  settingType: siteModule.settings.reducer,
  userManagement: userManagement.reducer,
  module: siteModule.module.reducer,
  permission: rolePermission.reducer,
  place: rolePermission.placeReducer,
  categoryType: rolePermission.categoryTypeReducer,
  customerTestimonialMediaType: rolePermission.mediaTypeReducer,
  role: userManagement.roles.reducer,
  userRoles: userRole.reducer,
  departments: departments.reducer,
  profile: profile.reducer,
  menuManager: cms.menu.reducer,
  content: cms.content.reducer,

  // master data
  customerClassificationList: masterData.customerClassificationListReducer,
  accountClassList: masterData.accountClassListReducer,
  accountTypeList: masterData.accountTypeListReducer,
  customerCategoryList: masterData.customerCategoryListReducer,
  taxCategoryList: masterData.taxCategoryListReducer,
  taxGroupList: masterData.taxGroupListReducer,
  institutionalList: masterData.institutionalListReducer,
  mediaList: masterData.mediaListReducer,
  salutationList: masterData.salutationListReducer,
  idTypeList: masterData.idTypesListReducer,
  exposureList: masterData.exposureListReducer,
  riskCategoryList: masterData.riskCategoriesListReducer,
  guardianTypeList: masterData.guardianTypesListReducer,
  ancillaryRemarkList: masterData.ancillaryRemarksListReducer,
  documentTypeList: masterData.documentTypeListReducer,
  incomeSourceList: masterData.incomeSourceListReducer,
  employmentStatusList: masterData.employmentStatusListReducer,
  kycLevelCategoryList: masterData.kycLevelCategoryListReducer,
  occupationList: masterData.occupationListReducer,
  additionalOccupationsList: masterData.additionalOccupationsListReducer,
  qualificationsList: masterData.qualificationsListReducer,
  accountPurposeList: masterData.accountPurposeReducer,
  individualUniqueIdList: masterData.individualUniqueIdsReducer,
  maritalStatusList: masterData.maritalStatusReducer,
  accountCategoriesList: masterData.accountCategoriesReducer,
  linkedEntitiesList: masterData.linkedEntitiesRelationshipReducer,
  corporateRegistrationsList: masterData.corporateRegistrationReducer,
  countriesList: masterData.countriesTypesReducer,
  nationalitiesList: masterData.nationalitiesTypesReducer,
  currenciesList: masterData.currencyTypesReducer,
  kycRequestTypesList: masterData.kycRequestTypesListsReducer,
  //CMS
  banner: banner.reducer,

  emailTemplate: emailTemplate.reducer,
  textPopup: textPopup.reducer,
  roadBlockPopup: roadBlockPopup.reducer,
  faqCategory: faqCategory.reducer,
  faqManager: faqManager.reducer,

  mediaManager: mediaManager.reducer,
  faqIconType: faqIconType.reducer,

  //Forms and Download
  formsAndDownload: formsDownload.formAndDownload.reducer,
  formsAndDownloadCategory: formsDownload.formAndDownload.reducer1,

  //Contact Person
  contactPerson: siteModule.contactPerson.reducer,

  // preliminary form
  accountPreliminary: accountPreliminary.reducer,
  accountPreliminaryCorporate: accountPreliminary.corporateReducer,
  accountPreliminaryDocuments: accountPreliminary.documentsReducer,

  // Duplicate CIF
  duplicateCifCheck: DuplicateCIF.reducer,

  //Homepage Setting
  homepageSetting: siteModule.homepageSetting.reducer,

  // cpu maker
  cpuMakerNewCIFAndAccountIndividualJointMinorList:
    cpuMakerNewCIFAndAccountList.individualJointMinorReducer,
  cpuMakerNewCIFAndAccountCorporateBankList: cpuMakerNewCIFAndAccountList.corporateBankReducer,
  postAccountPreliminaryKYCForm: cpuMakerNewCIFAndAccountList.postAccountPreliminaryKYCReducer,
  postAccountPreliminaryKYCAdditionalForm:
    cpuMakerNewCIFAndAccountList.postAccountPreliminaryKYCAdditionalReducer,

  // cpu checker\
  cpuCheckerNewCIFAndAccountIndividualJointMinorList:
    cpuCheckerNewCIFAndAccountList.individualJointMinorReducer,
  cpuCheckerNewCIFAndAccountCorporateBankList: cpuCheckerNewCIFAndAccountList.corporateBankReducer,
  cpuCheckerKYCReview:
    cpuCheckerNewCIFAndAccountList.postAccountPreliminaryKYCCPUCheckerReviewReducer,

  // branch checker
  branchCheckerNewCIFAndAccountIndividualJointMinorList:
    branchCheckerNewCIFAndAccountList.individualJointMinorReducer,
  postAccountPreliminaryBranchReview:
    branchCheckerNewCIFAndAccountList.postAccountPreliminaryBranchReviewReducer,
  branchCheckerNewCIFAndAccountDetailsById:
    branchCheckerNewCIFAndAccountList.individualJointMinorDetailsReducer,

  //bulk account opening
  bulkAccountList: bulkAccountRedux.reducer,
  failedMasterBatchList: bulkAccountRedux.failedMasterReducer,
  apiList: apiListRedux.reducer,
  bulkAccountOpenedList: bulkAccountRedux.bulkAcocuntOpenedReducer,

  // new cif
  newCIFList: newCIFRedux.reducer,

  // new cif checker
  newCIFCPUCheckerList: newCIFCPUCheckerRedux.reducer,

  // existing cif
  existingCIF: existingCIFRedux.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    auth.saga(),
    siteModule.smtp.saga(),
    siteModule.key.saga(),
    siteModule.settings.saga(),
    siteModule.groupSetting.saga(),
    siteModule.module.saga(),
    userRole.saga(),
    departments.saga(),
    rolePermission.saga(),
    userManagement.saga(),
    userManagement.roles.saga(),
    profile.saga(),
    cms.menu.saga(),
    cms.content.saga(),

    banner.saga(),

    masterData.saga(),

    textPopup.saga(),
    roadBlockPopup.saga(),

    mediaManager.saga(),
    faqCategory.saga(),
    faqManager.saga(),
    faqIconType.saga(),

    //Loan
    emailTemplate.saga(),
    formsDownload.formAndDownload.saga(),

    //Contact Person
    siteModule.contactPerson.saga(),

    //Homepage Setting
    siteModule.homepageSetting.saga(),

    // account preliminary
    accountPreliminary.saga(),
    accountPreliminary.documentsSaga(),
    accountPreliminary.corporateSaga(),
    // Duplicate CIF
    DuplicateCIF.saga(),

    // cpu maker
    cpuMakerNewCIFAndAccountList.saga(),

    // branch checker
    branchCheckerNewCIFAndAccountList.saga(),
    branchCheckerNewCIFAndAccountList.postAccountPreliminaryBranchReviewSaga(),
    branchCheckerNewCIFAndAccountList.accountDetailsSaga(),

    // cpu checker
    cpuCheckerNewCIFAndAccountList.saga(),

    // bulk account list
    bulkAccountRedux.saga(),
    apiListRedux.saga(),

    // new cif
    newCIFRedux.saga(),

    // new cif cpu checker
    newCIFCPUCheckerRedux.saga(),

    // existing cif
    existingCIFRedux.saga(),
  ])
}
