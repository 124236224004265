import DetailCell from '../DetailCell'

interface BasicInformationProps {
  data: any
}

const BasicInformation = ({data}: BasicInformationProps) => {
  return (
    <>
      <div className='row'>
        <DetailCell label='Primary CIF Number' value={data?.primary_cif_number} />
        <DetailCell label='Relationship' value={data?.relationship?.title} />
        <DetailCell label='Primary Account Number' value={data?.primary_ac_number} />
      </div>
      <div className='row'>
        <DetailCell label='Salutation' value={data?.salutation} />
        <DetailCell label='Full Name' value={data?.account_name} />
        <DetailCell label='Email' value={data?.email} />
      </div>
      <div className='row'>
        <DetailCell label='Mobile Number' value={data?.mobile_number} />
        <DetailCell label='Customer Category' value={data?.customer_category?.title} />
        <DetailCell label='Customer Classification' value={data?.customer_classification?.title} />
      </div>
      <div className='row'>
        <DetailCell label='Country' value={data?.country?.title} />
        <DetailCell label='Nationality' value={data?.nationality?.title} />
        <DetailCell label='Language' value={data?.language} />
      </div>
      <div className='row'>
        <DetailCell label='Location(Branch Location Code)' value={data?.branch_location_code} />
        <DetailCell label='Tax Category' value={data?.tax_category?.title} />
        <DetailCell label='Tax Group' value={data?.tax_group?.cbs_code} />
      </div>
      <div className='row'>
        <DetailCell label='AML Risk Rating' value={data?.aml_risk_rating} />
        <DetailCell label='PEP' value={data?.pep_confirmation} />
        <DetailCell label='Media' value={data?.media} />
      </div>
      <div className='row'>
        <DetailCell label='Exposure' value={data?.exposure?.title} />
        <DetailCell label='KYC Level Category' value={data?.kyc_level_category?.title} />
      </div>
      <div className='row'>
        <DetailCell label='Branch' value={data?.kyc_level_category?.title} />
        <DetailCell label='Date of Request' value={data?.date} />
        <DetailCell label='Gender' value={data?.gender} />
      </div>
      <div className='row'>
        <DetailCell label='Maritial Status' value={data?.marital_status?.title} />
        <DetailCell label='Date of Birth (B.S.)' value={data?.date_of_birth_bs} />
        <DetailCell label='Date of Birth (A.D.)' value={data?.date_of_birth_ad} />
      </div>
      <div className='row'>
        <DetailCell label='Nationality' value={data?.nationality?.title} />
        <DetailCell label='Pan Number' value={data?.pan_number} />
        <DetailCell label='Pan Registration Date (B.S.)' value={data?.pan_registration_date_bs} />
      </div>
      <div className='row'>
        <DetailCell label='Pan Registration Date (A.D.)' value={data?.pan_registration_date_ad} />
        <DetailCell label='Citizenship Number' value={data?.citizenship_number} />
        <DetailCell label='Citizenship Issue Date (B.S.)' value={data?.citizenship_issue_date_bs} />
      </div>
      <div className='row'>
        <DetailCell label='Citizenship Issue Date (A.D.)' value={data?.citizenship_issue_date_ad} />
        <DetailCell
          label='Citizenship Issue District'
          value={data?.citizenship_issue_district?.title}
        />
        <DetailCell label='Passport Number' value={data?.passport_number} />
      </div>
      <div className='row'>
        <DetailCell label='Passport Issue Date' value={data?.passport_issue_date} />
        <DetailCell label='Passport Expiry Date' value={data?.passport_expiry_date} />
        <DetailCell label='Passport Issue Place' value={data?.passport_issue_district?.title} />
      </div>
      <div className='row'>
        <DetailCell label='Type of ID Card' value={data?.type_of_identity_card} />
        <DetailCell label='ID Card Issue Date' value={data?.id_card_issue_date} />
        <DetailCell label='ID Card Expiry Date' value={data?.id_card_expiry_date} />
      </div>
      <div className='row'>
        <DetailCell label='ID Card Number' value={data?.id_card_number} />
        <DetailCell label='ID Card Issue Place' value={data?.id_card_issuing_place?.title} />
        <DetailCell label='Unique ID Card Issued By' value={data?.id_card_issuing_office?.title} />
      </div>
      <div className='row'>
        <DetailCell label='Other Unique ID Issued By' value={data?.id_card_issuing_office_other} />
        <DetailCell
          label='Other Unique ID Issued By(Corporate)'
          value={data?.id_card_issuing_office_corporate}
        />
        <DetailCell label='Visa Expiry Date' value={data?.visa_expiry_date} />
      </div>
      <div className='row'>
        <DetailCell label='Education Qualification' value={data?.education_qualification?.title} />
        <DetailCell
          label='Other Education Qualification'
          value={data?.education_qualification_others}
        />
      </div>
    </>
  )
}

export default BasicInformation
