import {ErrorMessage, FieldArray} from 'formik'
import {handleUppercaseChange} from 'src/app/modules/CreateNewCIFAndCustomerAccount/createNewAccount/components'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'

interface ProfessionRelatedInformationSectionProps {
  handleChange: any
  touched: any
  errors: any
  setFieldValue: any
  values: any
}

const ProfessionRelatedInformationSection = ({
  handleChange,
  errors,
  touched,
  setFieldValue,
  values,
}: ProfessionRelatedInformationSectionProps) => {
  return (
    <>
      {values.information_type === 'business' && (
        <FieldArray
          name='institutions'
          render={({push, remove}) => (
            <>
              <div className='col-12'>
                <h2 className='text-center required'>Details of Related Profession/Business</h2>
              </div>
              <div className='bg-secondary p-5 my-6'>
                {values.institutions && values.institutions.length > 0
                  ? values.institutions.map((_: undefined, index: number) => (
                      <div className='row mb-3' key={index}>
                        <div className='col-md-6 col-12'>
                          <FormTextBox
                            name={`institutions.${index}.name_of_related_employer`}
                            placeholder='Name of Related Profession/Business'
                            mainClassName='col-12'
                            containerClassName='col-12'
                            labelClassName='col-12'
                            onChange={handleUppercaseChange(handleChange)}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-12'>
                          <FormTextBox
                            name={`institutions.${index}.business_address`}
                            placeholder='Address'
                            mainClassName='col-12'
                            containerClassName='col-12'
                            labelClassName='col-12'
                            onChange={handleUppercaseChange(handleChange)}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-12'>
                          <FormTextBox
                            name={`institutions.${index}.position`}
                            placeholder='Position'
                            mainClassName='col-12'
                            containerClassName='col-12'
                            labelClassName='col-12'
                            onChange={handleUppercaseChange(handleChange)}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-12'>
                          <FormTextBox
                            name={`institutions.${index}.yearly_remuneration`}
                            placeholder='Approx Yearly Remuneration'
                            mainClassName='col-12'
                            containerClassName='col-12'
                            labelClassName='col-12'
                            onChange={handleUppercaseChange(handleChange)}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        {index > 0 && (
                          <div>
                            <button
                              className='btn btn-danger my-2'
                              onClick={(e) => {
                                e.preventDefault()
                                remove(index)
                              }}
                            >
                              Remove
                            </button>
                          </div>
                        )}
                      </div>
                    ))
                  : null}
                <button
                  className='btn btn-primary float-end'
                  onClick={(e) => {
                    e.preventDefault()
                    push({
                      name_of_related_employer: '',
                      business_address: '',
                      position: '',
                      yearly_remuneration: '',
                    })
                  }}
                >
                  Add
                </button>
              </div>
            </>
          )}
        />
      )}
      {values.information_type === 'student' && (
        <FieldArray
          name='students'
          render={({push, remove}) => (
            <>
              <div className='col-12'>
                <h2 className='text-center required'>For Students</h2>
              </div>
              <div className='bg-secondary p-5 my-6'>
                {values.students && values.students.length > 0
                  ? values.students.map((_: undefined, index: number) => (
                      <div className='row mb-3' key={index}>
                        <div className='col-md-6 col-12'>
                          <FormTextBox
                            name={`students.${index}.name_of_institute`}
                            placeholder='Name of Institute'
                            mainClassName='col-12'
                            containerClassName='col-12'
                            labelClassName='col-12'
                            onChange={handleUppercaseChange(handleChange)}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-12'>
                          <FormTextBox
                            name={`students.${index}.student_address`}
                            placeholder='Student Address'
                            mainClassName='col-12'
                            containerClassName='col-12'
                            labelClassName='col-12'
                            onChange={handleUppercaseChange(handleChange)}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-12'>
                          <FormTextBox
                            name={`students.${index}.phone_no`}
                            placeholder='Phone No.'
                            mainClassName='col-12'
                            containerClassName='col-12'
                            labelClassName='col-12'
                            onChange={handleUppercaseChange(handleChange)}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        {index > 0 && (
                          <div>
                            <button
                              className='btn btn-danger my-2'
                              onClick={(e) => {
                                e.preventDefault()
                                remove(index)
                              }}
                            >
                              Remove
                            </button>
                          </div>
                        )}
                      </div>
                    ))
                  : null}
                <button
                  className='btn btn-primary float-end'
                  onClick={(e) => {
                    e.preventDefault()
                    push({name_of_institute: '', student_address: '', phone_no: ''})
                  }}
                >
                  Add
                </button>
              </div>
            </>
          )}
        />
      )}
      <div className=' my-7 col-12'>
        <label className='form-label fw-bolder text-dark fs-6 mb-2 required '>
          Anticipated Annual Transaction
        </label>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
          }}
        >
          {[
            {label: 'Upto Rs.1,00,000', value: 'Upto Rs.1,00,000'},
            {label: 'Upto Rs.5,00,000', value: 'Upto Rs.5,00,000'},
            {label: 'Upto Rs.10,00,000', value: 'Upto Rs.10,00,000'},
            {label: 'Upto Rs.50,00,000', value: 'Upto Rs.50,00,000'},
            {label: 'Above Rs.50,00,000', value: 'Above Rs.50,00,000'},
          ].map((annualTransaction) => (
            <FormRadio
              key={annualTransaction.value}
              checkBoxText={annualTransaction.label}
              containerClassName='col-12 col-lg-4 mb-2'
              errors={errors}
              name='anticipated_annual_transaction'
              onChange={handleChange}
              touched={touched}
              value={annualTransaction.value}
            />
          ))}
        </div>
      </div>
      <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
        <ErrorMessage
          name='anticipated_annual_transaction'
          component='div'
          className='field-error-message'
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='introducer_name'
          label='Introducer Name'
          mainClassName='col-12'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleUppercaseChange(handleChange)}
          errors={errors}
          touched={touched}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          type='date'
          name='kyc_review_date'
          label='KYC Review Date'
          mainClassName='col-12'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleUppercaseChange(handleChange)}
          errors={errors}
          touched={touched}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='firstNomineeName'
          label='First Nominee Name'
          mainClassName='col-12'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleUppercaseChange(handleChange)}
          errors={errors}
          touched={touched}
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='proof_address_document'
          label='Proof Address Document'
          mainClassName='col-12'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleUppercaseChange(handleChange)}
          errors={errors}
          touched={touched}
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='secondNomineeName'
          label='Second Nominee Name'
          mainClassName='col-12'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleUppercaseChange(handleChange)}
          errors={errors}
          touched={touched}
        />
      </div>
      <div className=' d-flex my-7 col-12'>
        <label className='form-label fw-bolder text-dark fs-6 me-8 required '>
          Resident Status
        </label>
        <div className='d-flex'>
          {[
            {label: 'Resident', value: 'resident'},
            {label: 'Non-resident', value: 'non-resident'},
          ].map((residentStatus) => (
            <FormRadio
              key={residentStatus.value}
              checkBoxText={residentStatus.label}
              errors={errors}
              name='resident_status'
              onChange={handleChange}
              touched={touched}
              value={residentStatus.value}
            />
          ))}
        </div>
      </div>
      <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
        <ErrorMessage name='resident_status' component='div' className='field-error-message' />
      </div>
    </>
  )
}

export default ProfessionRelatedInformationSection
