import axios from 'axios'
import {toast} from 'react-toastify'
import {KYC_UPDATE_API} from '../api'
import {useMutation} from 'react-query'

export interface KYCUpdatePayload {
  kyc_request_type: string
  branch: string
  remarks: string
}

const updateKycLogsDetails = async (id: string, payload: KYCUpdatePayload) => {
  try {
    return await axios.patch(`${KYC_UPDATE_API}/${id}/update`, payload)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message ?? 'An error occurred')
    } else {
      console.log(error)
    }
  }
}

export const useUpdateKycLogsDetails = () =>
  useMutation({
    mutationFn: async ({id, payload}: {id: string; payload: KYCUpdatePayload}) =>
      updateKycLogsDetails(id, payload),
  })
