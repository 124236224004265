import {Form, Formik} from 'formik'
import {nanoid} from 'nanoid'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import FormCheckbox from 'src/cms/helpers/components/forms/FormCheckbox'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {RootState} from 'src/setup'
import * as Yup from 'yup'
import {DocumentTypeModel, MasterDataModel} from '../../masterData/Model'
import * as masterDataRedux from 'src/app/modules/masterData/redux'
import {usePostExistingCIFCreate} from 'src/app/queries/ExistingCIF/post'
import {handleUppercaseChange} from '../../CreateNewCIFAndCustomerAccount/createNewAccount/components'
import ExisitingFileUpload from '../../CreateNewCIFAndCustomerAccount/createNewAccount/components/FileUpload/ExisitingFileUpload'
import {check} from 'prettier'
import {set} from 'lodash'
import {toast} from 'react-toastify'
import {GithubPicker} from 'react-color'
import {useHistory} from 'react-router-dom'

const FORM_VALIDATION = Yup.object().shape({
  customer_number: Yup.string().required('Customer Number is required'),
  full_name: Yup.string()
    .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed')
    .required('Full Name is required'),
  bank_code: Yup.string().required('Bank Code is required'),
  branch: Yup.string().required('Location is required'),
  account_class: Yup.string().required('Account Class is required'),
  house_number: Yup.number().typeError('Only Numbers'),
  first_nominee: Yup.string().matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
  second_nominee: Yup.string().matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
  account_currency: Yup.string().required('Account Currency is required'),
  tole: Yup.string().required('Tole is required'),
  ward_number: Yup.number().typeError('Only Numbers').required('Ward Number is required'),
  municipality: Yup.string().required('Municipality is required'),
  province: Yup.string().required('Province is required'),
  district: Yup.string().required('District is required'),
  country: Yup.string().required('Country is required'),
  full_address: Yup.string().required('Full Address is required'),
  cheque_book_name: Yup.string().required('Cheque Book Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  mobile_number: Yup.string()
    .matches(/^9\d{9}$/, 'Invalid Mobile Number')
    .required('Mobile Number is required'),
  exposure_category: Yup.string().required('Exposure is required'),
  is_corporate: Yup.boolean().required('Upload Corporate Documents is required'),
})

const SAVE_FORM_VALIDATION = Yup.object().shape({
  customer_number: Yup.string().required('Customer Number is required'),
  full_name: Yup.string()
    .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed')
    .required('Full Name is required'),
  account_currency: Yup.string().required('Account Currency is required'),
  cheque_book_name: Yup.string().required('Cheque Book Name is required'),
})
interface ExisitingCIFCreateProps {
  cifData: any
}

const requiredDocumentCBSCode = [
  'photo_upload_1',
  'form_upload',
  'citizenship',
  'passport',
  'driving_license',
  'voters_id',
  'registration_id',
]

const corporateDocumentCBSCode = [
  'photo_upload_1',
  'form_upload',
  'citizenship',
  'passport',
  'driving_license',
  'voters_id',
  'registration_id',
  'registration_document',
  'financial_tax_clearance',
]

const ExisitingCIFCreate = ({cifData}: ExisitingCIFCreateProps) => {
  const dispatch = useDispatch()

  const [uploadedFiles, setUploadedFiles] = useState({})
  const [districtList, setDistrictList] = useState<{[key: string]: string | number}[]>()
  const [municipalityList, setMunicipalityList] = useState<{[key: string]: string | number}[]>()

  //handling file upload
  const [regenrateID, setRegenrateID] = useState(false)
  const [resetUploads, setResetUploads] = useState<boolean>(false)
  const [uniqueID, setUniqueID] = useState('')
  const [uploadDocumentsTypeId, setUploadDocumentsTypeId] = useState<string[]>([])
  const [missingDocs, setMissingDocs] = useState<string[]>([])
  const [isSave, setIsSave] = useState<boolean>(false)
  const history = useHistory()
  const {municipality, province: provinceList} = useSelector((state: RootState) => state.place)
  const {
    user: {
      data: {branch},
    },
  } = useSelector((state: any) => state.auth)
  const {data: mediaList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state?.mediaList
  )
  const {data: accountClasses}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state?.accountClassList
  )
  const {data: currencyLists}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state?.currenciesList
  )
  const {data: countries}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.countriesList
  )
  const {data: exposures}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state?.exposureList
  )

  const {
    data: documentTypeList,
    loading: documentTypeLoading,
  }: {data: DocumentTypeModel[]; loading: boolean} = useSelector(
    (state: RootState) => state?.documentTypeList
  )

  const {mutate: postExistingCIFCreate, isLoading: isExistingCIFCreateLoading} =
    usePostExistingCIFCreate()

  const handleFileUpload = (file: any, documentType: any) => {
    setUploadedFiles((prevFiles) => ({
      ...prevFiles,
      [documentType]: file,
    }))
  }

  useEffect(() => {
    dispatch(masterDataRedux.actions.getDocumentTypes())
  }, [])

  const findOutmissingDocuments = (iscorporate: boolean) => {
    const filteredDocumentTypes = documentTypeList?.filter((doc) =>
      iscorporate
        ? corporateDocumentCBSCode.includes(doc?.cbs_code ?? '')
        : requiredDocumentCBSCode.includes(doc?.cbs_code ?? '')
    )

    const requiredDocumentIds = filteredDocumentTypes.map((doc) => doc.id)

    let missingDocs = requiredDocumentIds.filter(
      (id: string) => !uploadDocumentsTypeId.includes(id)
    )

    const specialDocumentIds = new Set([
      'fccc0058-a453-4ba7-a203-d1ef938d72bc',
      'deefda48-919f-44ab-9301-b086d60a03da',
      '8e0f608a-39b8-48d5-86f0-aa6a4bbced80',
      '7e49648e-3793-44bb-9bb2-df9a83b3e215',
      'ca7e9e19-038c-4381-a217-694c236aba85',
      '5e7948b2-f684-43a0-b05e-03b70a57854b',
    ])
    const hasSpecialDocument = uploadDocumentsTypeId.some((id) => specialDocumentIds.has(id))

    if (hasSpecialDocument) {
      missingDocs = missingDocs.filter((docId: string) => !specialDocumentIds.has(docId))
    }

    setMissingDocs(missingDocs)
  }

  useEffect(() => {
    const generatedID = nanoid()
    setUniqueID(generatedID)
  }, [regenrateID])

  return (
    <>
      <div className='heading__component__title fw-bolder mb-5'>
        Create New Customer Account (Exisiting CIF)
      </div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-9'>
          <Formik
            initialValues={{
              customer_number: cifData?.data?.customer_no ?? '',
              full_name: cifData?.data?.full_name ?? '',
              bank_code: 'SBL',
              branch: branch?.title ?? '',
              first_nominee: '',
              second_nominee: '',
              account_currency:
                currencyLists.find((currency) => currency.cbs_code?.includes('NPR'))?.cbs_code ??
                '',
              house_number: '',
              street_name: '',
              tole: '',
              ward_number: '',
              municipality: '',
              province: '',
              district: '',
              country: countries.find((country) => country.title === 'Nepal')?.id ?? '',
              full_address: '',
              cheque_book_name: cifData?.data?.full_name ?? '',
              email: '',
              mobile_number: '',
              exposure_category:
                exposures.find((exposure) => exposure.title?.toUpperCase() === 'NEPAL')?.code ?? '',
              account_class: '',
              media: mediaList?.find((media) => media.title?.includes('CKPU'))?.code,
              branch_code: branch?.code ?? '',
              is_corporate: false,
            }}
            validationSchema={
              isSave
                ? SAVE_FORM_VALIDATION
                : FORM_VALIDATION.concat(
                    Yup.object().shape({
                      municipality_other: Yup.string().when('municipality', {
                        is: (value: string) =>
                          value === municipality?.find((m) => m?.title === 'Others')?.id,
                        then: Yup.string()
                          .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed')
                          .required('Required'),
                        otherwise: Yup.string(),
                      }),
                    })
                  )
            }
            onSubmit={(values) => {
              if (missingDocs.length < 1) {
                postExistingCIFCreate(
                  {
                    payload: {
                      ...values,
                      province: String(
                        provinceList?.find((p) => p.id === values.province)?.code ?? ''
                      ),
                      action: isSave ? 'save' : 'save_and_continue',
                      temp_customer_id: uniqueID,
                      is_corporate: values.is_corporate ? 'yes' : 'no',
                    },
                  },
                  {
                    onSuccess: (resp: any) => {
                      if (resp?.data?.resCod == '200') {
                        toast.success(resp?.data?.resDesc ?? 'CIF Created Successfully')
                        setRegenrateID((prev) => !prev)
                        history.push('/existing-cif/existing-cif-listing/branch-maker')
                      }
                    },
                  }
                )
              } else {
                toast.error('Please upload all required documents')
              }
            }}
            enableReinitialize={true}
          >
            {({handleSubmit, handleChange, values, errors, touched, setFieldValue}) => {
              useEffect(() => {
                setFieldValue(
                  'full_address',
                  `${values.tole}-${values.ward_number}${
                    values.house_number ? '-' + -values.house_number : ''
                  }`
                )
              }, [values.tole, values.ward_number, values.house_number])

              useEffect(() => {
                setFieldValue('cheque_book_name', values.full_name)
              }, [values.full_name, setFieldValue])

              return (
                <Form onSubmit={handleSubmit} className='row'>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      type='text'
                      placeholder='Customer Number'
                      label='Customer Number'
                      name='customer_number'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required={true}
                      disabled
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      type='text'
                      placeholder='Full Name'
                      label='Full Name'
                      name='full_name'
                      onChange={handleUppercaseChange(handleChange)}
                      errors={errors}
                      touched={touched}
                      required={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      type='text'
                      placeholder='Home Branch (Location Code)'
                      label='Home Branch (Location Code)'
                      name='branch_code'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required={true}
                      disabled
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      type='text'
                      placeholder='Bank Code'
                      label='Bank Code'
                      name='bank_code'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required={true}
                      disabled
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      type='text'
                      placeholder='Location'
                      label='Location'
                      name='branch'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required={true}
                      disabled
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      label='Media'
                      name='media'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={mediaList?.map((media) => ({
                        label: media.title,
                        value: media.code || '',
                      }))}
                      values={values}
                      setFieldValue={setFieldValue}
                      isClearable={true}
                      required={true}
                      disabled={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      type='text'
                      placeholder='First Nominee'
                      label='First Nominee'
                      name='first_nominee'
                      onChange={handleUppercaseChange(handleChange)}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      type='text'
                      placeholder='Second Nominee'
                      label='Second Nominee'
                      name='second_nominee'
                      onChange={handleUppercaseChange(handleChange)}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      label='Account Class'
                      name='account_class'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={
                        accountClasses?.map((accountClassItem) => ({
                          label: accountClassItem?.name?.toUpperCase() || '',
                          value: accountClassItem?.account_class || '',
                        })) ?? []
                      }
                      values={values}
                      setFieldValue={setFieldValue}
                      isClearable={true}
                      required={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      label='Account Currency'
                      name='account_currency'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={
                        currencyLists?.map((currency) => ({
                          label: currency?.title?.toUpperCase(),
                          value: currency?.cbs_code ?? '',
                        })) ?? []
                      }
                      values={values}
                      setFieldValue={setFieldValue}
                      isClearable={true}
                      required={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      type='text'
                      placeholder='House Number'
                      label='House Number'
                      name='house_number'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      type='text'
                      placeholder='Street Name'
                      label='Street Name'
                      name='street_name'
                      onChange={handleUppercaseChange(handleChange)}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      type='text'
                      placeholder='Tole'
                      label='Tole'
                      name='tole'
                      onChange={handleUppercaseChange(handleChange)}
                      errors={errors}
                      touched={touched}
                      required={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      type='text'
                      placeholder='Ward Number'
                      label='Ward Number'
                      name='ward_number'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      label='Country'
                      name='country'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={
                        countries?.map((country) => ({label: country.title, value: country.id})) ??
                        []
                      }
                      values={values}
                      setFieldValue={setFieldValue}
                      isClearable={true}
                      required={true}
                    />
                  </div>
                  <ProvinceFormField
                    values={values}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                  />
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      type='text'
                      placeholder='Full Address'
                      label='Full Address'
                      name='full_address'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required={true}
                      disabled
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      type='text'
                      placeholder='Cheque Book Name'
                      label='Cheque Book Name'
                      name='cheque_book_name'
                      onChange={handleUppercaseChange(handleChange)}
                      errors={errors}
                      touched={touched}
                      required={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      type='email'
                      placeholder='Email'
                      label='Email'
                      name='email'
                      onChange={handleUppercaseChange(handleChange)}
                      errors={errors}
                      touched={touched}
                      required={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      type='text'
                      placeholder='Mobile Number'
                      label='Mobile Number'
                      name='mobile_number'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      label='Exposure'
                      name='exposure_category'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={
                        exposures.map((exposure) => ({
                          label: exposure.title,
                          value: exposure.code ?? '',
                        })) ?? []
                      }
                      values={values}
                      setFieldValue={setFieldValue}
                      isClearable={true}
                      required={true}
                    />
                  </div>
                  <div className='row mb-6 py-4 border-bottom align-items-center col-sm-12 col-xs-12'>
                    <FormCheckbox
                      labelClassName='col-sm-12'
                      containerClassName='col-sm-12'
                      name='is_corporate'
                      touched={touched}
                      errors={errors}
                      onChange={(e) => setFieldValue('is_corporate', e?.target?.checked ?? false)}
                      checkBoxText={'Upload Corporate Documents?'}
                      label='Upload Corporate Documents'
                      required={true}
                    />
                  </div>
                  <div>
                    <p>NOTE: Only one of the Identification Document is mandatory.</p>
                    <p>
                      <span style={{fontWeight: 'bold'}}>
                        Please upload file of size mentioned here:{' '}
                      </span>{' '}
                      PP Size Photo (1 MB), Secondary Photo (1 MB), AOF (10 MB), Signature (1 MB),
                      KYC Form (5 MB), Citizenship (5 MB), Passport (5 MB), Driving License (5 MB),
                      Voter's ID (5 MB), Registration IDs (5 MB), Mandate Form
                    </p>
                  </div>
                  {documentTypeLoading ? (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        overflowX: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#888 #fff',
                      }}
                    >
                      {documentTypeList?.map((documentType) => (
                        <div key={documentType?.id} style={{marginRight: '10px'}}>
                          <ExisitingFileUpload
                            documentType={documentType?.title}
                            maxSize={Number(documentType?.max_size)}
                            onChange={handleFileUpload}
                            documentTypeId={documentType?.id}
                            uniqueID={uniqueID}
                            resetUploads={resetUploads}
                            setResetUploads={setResetUploads}
                            setUploadDocumentsTypeId={setUploadDocumentsTypeId}
                            isMissing={
                              isSave
                                ? false
                                : missingDocs.some((docId: string) => docId === documentType.id)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  <div className='d-flex justify-content-end mt-3'>
                    <button
                      onClick={() => {
                        setMissingDocs([])
                        setIsSave(true)
                      }}
                      type='submit'
                      className='dt-btn dt-btn-outline-primary dt-btn-sm me-2'
                    >
                      {isExistingCIFCreateLoading && isSave ? 'saving...' : 'Save'}
                    </button>
                    <button
                      onClick={() => {
                        setIsSave(false)
                        findOutmissingDocuments(values?.is_corporate)
                      }}
                      disabled={isExistingCIFCreateLoading}
                      type='submit'
                      className='btn btn-primary'
                    >
                      {isExistingCIFCreateLoading && !isSave ? 'saving....' : 'Save and Continue'}
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default ExisitingCIFCreate

const ProvinceFormField = ({
  values,
  setFieldValue,
  handleChange,
  errors,
  touched,
}: {
  values: any
  setFieldValue: any
  handleChange: any
  errors: any
  touched: any
}) => {
  const [districtList, setDistrictList] = useState<{[key: string]: string | number}[]>()
  const [municipalityList, setMunicipalityList] = useState<{[key: string]: string | number}[]>()
  const {district, province, municipality, loading} = useSelector((state: RootState) => state.place)

  useEffect(() => {
    if (values.province) {
      const districts = district?.filter((d) => {
        return d.province_id === values.province
      })
      setDistrictList(districts)
    } else {
      setDistrictList(district)
    }
  }, [values.province, district])

  // Resetting Municipality on District change and both on province change
  useEffect(() => {
    if (values.district) {
      setFieldValue('district', '')
    }
  }, [values.province])
  useEffect(() => {
    if (values.municipality) {
      setFieldValue('municipality', '')
    }
  }, [values.district])

  useEffect(() => {
    if (values.district) {
      const municipalities = municipality?.filter((m) => {
        return m.district_id === values.district || m?.title === 'Others'
      })
      setMunicipalityList(municipalities)
    } else {
      setMunicipalityList(municipality)
    }
  }, [values.district, municipality])
  return (
    <>
      <div className='col-md-4 col-sm-6 col-xs-12'>
        <FormSelect
          name='province'
          label='Province'
          containerClassName='col-12'
          labelClassName='col-12'
          options={
            province?.map((provinceData) => ({
              label:
                typeof provinceData.title === 'string'
                  ? provinceData.title
                  : provinceData.title.toString(),
              value:
                typeof provinceData.id === 'string' ? provinceData.id : provinceData.id.toString(),
            })) ?? []
          }
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className='col-md-4 col-sm-6 col-xs-12'>
        <FormSelect
          labelClassName='col-md-12'
          containerClassName='col-md-12'
          label='District'
          name='district'
          onChange={handleChange}
          errors={errors}
          touched={touched}
          options={
            districtList?.map((districtData) => ({
              label:
                typeof districtData.title === 'string'
                  ? districtData.title
                  : districtData.title.toString(),
              value:
                typeof districtData.id === 'string' ? districtData.id : districtData.id.toString(),
            })) ?? []
          }
          values={values}
          setFieldValue={setFieldValue}
          isClearable={true}
          required={true}
        />
      </div>
      <div className='col-md-4 col-sm-6 col-xs-12'>
        <FormSelect
          labelClassName='col-md-12'
          containerClassName='col-md-12'
          label='Municipality'
          name='municipality'
          errors={errors}
          touched={touched}
          options={
            municipalityList?.map((municipalityData) => ({
              label:
                typeof municipalityData.title === 'string'
                  ? municipalityData.title
                  : municipalityData.title.toString(),
              value:
                typeof municipalityData.id === 'string'
                  ? municipalityData.id
                  : municipalityData.id.toString(),
            })) ?? []
          }
          values={values}
          onChange={handleChange}
          setFieldValue={setFieldValue}
          isClearable={true}
          required={true}
        />
      </div>
      {values.municipality === municipality?.find((m) => m?.title === 'Others')?.id && (
        <div className='col-md-4 col-sm-6 col-xs-12'>
          <FormTextBox
            name='municipality_other'
            label='Other Municipality'
            containerClassName='col-12'
            labelClassName='col-12'
            onChange={handleUppercaseChange(handleChange)}
            errors={errors}
            touched={touched}
            required
          />
        </div>
      )}
    </>
  )
}
