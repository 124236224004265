import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {KYCAdditionalForm} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const CPU_CHECKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_API = `${API_URL}/newcif-newaccount/cpu-checker-list`
const CPU_CHECKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_API = `${API_URL}/corporate-newcif-newaccount/cpu-checker-list`
const ACOUNT_PRELIMINARY_INDIVIDUAL_JOINT_MINOR_API = `${API_URL}/newcif-newaccount`

export const services = {
  getCPUCheckerNewCIFandAccountIndividualJointMinorList: (params: ParamsModel) => {
    return axios.get(CPU_CHECKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_API, {params})
  },

  getCPUCheckerNewCIFandAccountCorporateBankList: (params: ParamsModel) => {
    return axios.get(CPU_CHECKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_API, {params})
  },

  postAccountPreliminaryKYCCPUCheckerReview: (id: string, data: any) => {
    return axios.post(
      `${ACOUNT_PRELIMINARY_INDIVIDUAL_JOINT_MINOR_API}/${id}/kyc-cpu-checker-review`,
      data
    )
  },
}
