import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL
const ACCOUNT_PRELIMINARY = `${API_URL}/linked-entities`

type typePayload = {
  id: string
  data: {
    status?: string
    remarks?: string
  }
}
const postLinkedEntitiesReview = async (payload: typePayload) => {
  const {id, data} = payload
  try {
    const response = await axios.post(`${ACCOUNT_PRELIMINARY}/${id}/branch-review`, data)
    return response
  } catch (error: any) {
    throw new Error(`${error.response.data.message ?? error.message}`)
  }
}

const usePostLinkedEntitiesBranchReview = () => {
  const queryClient = useQueryClient()
  return useMutation(postLinkedEntitiesReview, {
    onSuccess: (response) => {
      console.log(response?.data?.resCod)
      if (response?.data?.resCod == 200) {
        toast.success(response?.data?.resDesc ?? 'Review   submitted successfully!')
        queryClient.invalidateQueries('getlinkedEntitesList')
      }
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

export default usePostLinkedEntitiesBranchReview
