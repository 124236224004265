import React, {useState} from 'react'
import {Tab, Tabs} from 'react-bootstrap-v5'
import {CorporateBankTable, IndividualJointMinorTable} from './tables'

const AccountListing = () => {
  const [activeKey, setActiveKey] = useState('individualJointMinor')

  return (
    <>
      <div className='heading__component__title fw-bolder mb-5'>Account List</div>
      <div className='modal-container shadow rounded' data-cy='new-cif-account-list-modal'>
        <div className='bg-white p-5'>
          <Tabs
            defaultActiveKey={activeKey}
            onSelect={(k) => setActiveKey(k ?? 'individualJointMinor')}
            id='pilminary-new-cif-account-list-tabs'
            data-cy='new-cif-account-list-tabs'
          >
            <Tab
              eventKey='individualJointMinor'
              title='Individual/Joint/Minor'
              data-cy='tab-individual-joint-minor'
            >
              <div className='card-body bg-white' data-cy='tab-content-individual-joint-minor'>
                <IndividualJointMinorTable />
              </div>
            </Tab>
            <Tab eventKey='corporateBank' title='Corporate/Banks' data-cy='tab-corporate-banks'>
              <div className='card-body bg-white' data-cy='tab-content-corporate-banks'>
                <CorporateBankTable />
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  )
}

export default AccountListing
