import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Pagination, Table} from 'rsuite'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {BulkStateParamsModel} from '../../masterBatchList/Model'
import * as bulkAccountRedux from '../../masterBatchList/redux'
import {ParamsComponent} from '../../masterBatchList/components'

const Cell = Table.Cell

const ChildBatchList = () => {
  const disptach = useDispatch()
  const [params, setParams] = useState<BulkStateParamsModel>({
    table_name: 'child',
    page: 1,
    limit: 10,
    orderBy: 'updated_at',
    order: 'desc',
  })

  const {data: bulkAccountListData, loading} = useSelector((state: any) => state.bulkAccountList)

  const data =
    bulkAccountListData?.child_batches?.map((bulkAccount: any, i: number) => ({
      ...bulkAccount,
      sn: (params?.page - 1) * params?.limit + (i + 1),
    })) ?? []

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <button className='btn btn-primary btn-sm ms-3'>Review</button>
      </Cell>
    )
  }

  const columns = [
    {label: 'S.N.', dataKey: 'sn', width: 80, cell: <Cell dataKey='sn' />},
    {
      label: 'Child Batch Number',
      dataKey: 'batch_number',
      sortable: false,
      width: 250,
      cell: <Cell dataKey='batch_number' />,
    },
    {
      label: 'Master Batch Number',
      dataKey: 'matcher_batch.batch_number',
      sortable: false,
      width: 250,
      cell: <Cell dataKey='matcher_batch.batch_number' />,
    },
    {
      label: 'Total No. of Accounts',
      dataKey: 'matcher_batch.no_of_accounts',
      width: 200,
      cell: <Cell dataKey='matcher_batch.no_of_accounts' />,
    },
    {
      label: 'API Success Count',
      dataKey: 'apiSuccessCount',
      width: 150,
      cell: <Cell>0</Cell>,
    },
    {
      label: 'API Failure Count',
      dataKey: 'apiFailureCount',
      width: 150,
      cell: <Cell>0</Cell>,
    },
    {
      label: 'Child Batches',
      dataKey: 'childBatches',
      width: 150,
      cell: <Cell>0</Cell>,
    },
    {
      label: 'Batch Created On',
      dataKey: 'created_on',
      cell: <Cell dataKey='created_on' />,
      sortable: false,
      width: 150,
    },
    {
      label: 'Last Updated On',
      dataKey: 'lastUpdatedOn',
      cell: <Cell dataKey='lastUpdatedOn' />,
      sortable: false,
      width: 150,
    },
    {
      label: 'Status',
      dataKey: 'status',
      cell: <Cell dataKey='status' />,
      sortable: false,
      width: 230,
    },
  ]

  useEffect(() => {
    disptach(bulkAccountRedux.actions.getBulkAccountOpeningList(params))
  }, [params])

  return (
    <div>
      <div className='shadow p-3 bg-white rounded'>
        <div className='row g-0 border-bottom align-items-center mb-3 py-3'>
          <div className='col-lg-6'>
            <div className='heading__component__title fw-bolder'>Child Batch List</div>
          </div>
          <ParamsComponent params={params} setParams={setParams} />
        </div>
        <div className='datatable'>
          <RSuiteTable columns={columns} data={data} showLoading={loading} />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={bulkAccountListData?.meta?.total ?? 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </div>
    </div>
  )
}

export default ChildBatchList
