import {AccountPreliminaryPayload, CorporateAccountPayload} from '../Model'
import {actionTypes} from './constants'

export const actions = {
  submitPrelimaryForm: (data: AccountPreliminaryPayload) => ({
    type: actionTypes.SUBMIT_PRELIMINARY_FORM_START,
    payload: data,
  }),
  submitPreliminarySuccess: (data: AccountPreliminaryPayload) => ({
    type: actionTypes.SUBMIT_PRELIMINARY_FORM_SUCCESS,
    payload: data,
  }),
  submitPreliminaryFinish: () => ({
    type: actionTypes.SUBMIT_PRELIMINARY_FORM_FINISH,
  }),
  submitPreliminaryDocuments: (data: FormData, id: string) => ({
    type: actionTypes.SUBMIT_PRELIMINARY_DOCUMENTS_START,
    payload: {data, id},
  }),
  submitPreliminaryDocumentsSuccess: (data: any) => ({
    type: actionTypes.SUBMIT_PRELIMINARY_DOCUMENTS_SUCCESS,
    payload: data,
  }),
  submitPreliminaryDocumentsFinish: () => ({
    type: actionTypes.SUBMIT_PRELIMINARY_DOCUMENTS_FINISH,
  }),
  resetPreliminaryDocumentsState: () => ({
    type: actionTypes.RESET_PRELIMINARY_DOCUMENTS_STATE,
  }),

  submitCorporatePreliminaryForm: (data: CorporateAccountPayload) => ({
    type: actionTypes.SUBMIT_CORPORATE_PRELIMINARY_FORM_START,
    payload: data,
  }),
  submitCorporatePreliminarySuccess: (data: CorporateAccountPayload) => ({
    type: actionTypes.SUBMIT_CORPORATE_PRELIMINARY_FORM_SUCCESS,
    payload: data,
  }),
  submitCorporatePreliminaryFinish: () => ({
    type: actionTypes.SUBMIT_CORPORATE_PRELIMINARY_FORM_FINISH,
  }),
}
