import {call, put, takeLatest} from 'redux-saga/effects'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {services} from './service'
import {actions} from './action'
import {globalActionTypes} from '../../../errors/redux/constants'
import {actionTypes} from './constants'
import {toast} from 'react-toastify'

function* submitPrelimaryFormSaga(action: ActionModel) {
  try {
    const payload = action.payload
    const response: ResponseModel = yield call(services.postAccountPrelimiary, payload)
    yield put(actions.submitPreliminarySuccess(response.data))
    console.log(response.data)
    if (response?.data?.resCod == 200) {
      toast.success(response?.data?.resDesc ?? 'Preliminary form submitted successfully')
    }
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
  } finally {
    yield put(actions.submitPreliminaryFinish())
  }
}

function* submitPreliminaryDocumentsSaga(action: ActionModel) {
  try {
    console.log('Saga submitPreliminaryDocumentsSaga called with:', action)
    const {data, id} = action.payload as any
    const response: ResponseModel = yield call(services.postAccountPrelimiaryDocuments, data, id)
    yield put(actions.submitPreliminaryDocumentsSuccess(response.data))
    if (response?.data?.resCod == 200) {
      toast.success(response?.data?.resDesc ?? 'Documents upload successfully')
    }
  } catch (error: any) {
    console.error('API call failed, error:', error)
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
  } finally {
    yield put(actions.submitPreliminaryDocumentsFinish())
  }
}

function* submitCorporatePreliminaryFormSaga(action: ActionModel) {
  try {
    const payload = action.payload
    const response: ResponseModel = yield call(services.postCorporateAccountPrelimiary, payload)
    yield put(actions.submitCorporatePreliminarySuccess(response.data))
    if (response?.data?.resCod == 200) {
      toast.success(response?.data?.resDesc ?? 'Corporate form submitted successfully')
    }
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
  } finally {
    yield put(actions.submitCorporatePreliminaryFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.SUBMIT_PRELIMINARY_FORM_START, submitPrelimaryFormSaga)
}

export function* documentsSaga() {
  yield takeLatest(actionTypes.SUBMIT_PRELIMINARY_DOCUMENTS_START, submitPreliminaryDocumentsSaga)
}

export function* corporateSaga() {
  yield takeLatest(
    actionTypes.SUBMIT_CORPORATE_PRELIMINARY_FORM_START,
    submitCorporatePreliminaryFormSaga
  )
}
