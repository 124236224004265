import {ErrorMessage, FieldArray, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as menuRedux from 'src/app/modules/cms/components/menu'
import {IMenuState} from 'src/app/modules/cms/components/menu'
import {StateParamsModel} from 'src/app/modules/common/Model'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import * as Yup from 'yup'
import * as homepageSettingRedux from '../index'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  subtextEn: Yup.string().max(250, 'Subtext (EN) exceeds 250 characters').nullable(),
  subtextNp: Yup.string().max(250, 'Subtext (NP) exceeds 250 characters').nullable(),
})

const AddHomepageSetting = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const {homePageSection, homePageLayout, success, loading} = useSelector(
    (state: any) => state.homepageSetting
  )

  const {topMenu} = useSelector((state: IMenuState | any) => state.menuManager)

  const [imageEng, setImageEng] = useState('')
  const [layoutImage, setLayoutImage] = useState('')
  const [imageNp, setImageNp] = useState('')

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]

  useEffect(() => {
    if (success) {
      dispatch(homepageSettingRedux.actions.getHomepageSetting(params))
      isEmpty(editSelectedData)
        ? toast.success('Homepage setting added successfully')
        : toast.success('Homepage setting edited successfully')
      dispatch(homepageSettingRedux?.actions.addHomepageSettingReset())
      dispatch(homepageSettingRedux?.actions.updateHomepageSettingReset())
      handleClose()
    }
  }, [success])

  useEffect(() => {
    dispatch(homepageSettingRedux?.actions.getHomepageSection())
    dispatch(homepageSettingRedux?.actions.getHomepageLayout())
    dispatch(menuRedux?.actions.getTopMenu())
  }, [])

  const topMenuOptions = topMenu?.map((items: any) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))

  const sectionOptions = homePageSection?.map((data: any) => ({
    label: data.name,
    value: data.id,
  }))

  const layoutOptions = homePageLayout?.map((data: any) => ({
    label: data.name,
    value: data.id,
  }))

  const settings: ISettingTypeState = useSelector((state: any) => state.settingType)

  const showInterestTitle =
    settings?.data?.setting?.filter((d) => d.name === 'showInterestTitle')[0]?.value === '1'
      ? true
      : false

  const showHomepageStatus = checkBackendSetting('showHomepageStatus')

  return (
    <div className='modal-container' data-cy='modal-home-page-setting-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Homepage Settings </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                title: '',
                sectionId: '',
                layoutId: '',
                layoutImage: '',
                topmenuId: '',
                textEn: '',
                textNp: '',
                subtextEn: '',
                subtextNp: '',
                services: [
                  {
                    serviceTitle: '',
                    serviceTitleNp: '',
                    serviceLink: '',
                    serviceIcon: '',
                    description: '',
                    descriptionNp: '',
                    firstButton: '',
                    firstButtonNp: '',
                    firstButtonUrl: '',
                    secondButton: '',
                    secondButtonNp: '',
                    secondButtonUrl: '',
                    order: '',
                  },
                ],
                mobileAppSections: [{title: '', link: '', image: '', order: ''}],
                imageEn: '',
                imageNp: '',
                highlightedTextEn: '',
                highlightedTextNp: '',
                buttonTextEn: '',
                buttonTextNp: '',
                buttonUrl: '',
                buttonClass: '',
                secondbuttonTextEn: '',
                secondbuttonTextNp: '',
                secondbuttonUrl: '',
                secondbuttonClass: '',
                status: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values: any, {setSubmitting}) => {
                //hide order field from service
                // if (!showInterestTitle) {
                //   values.services.forEach((item: any, i: number) => {
                //     delete values.services[i].order
                //   })
                // }
                const formData = {
                  ...values,
                  layoutId: !isEmpty(values?.layoutId) ? values?.layoutId : null,
                  layoutImage: !isEmpty(values?.layoutImage) ? values?.layoutImage : null,
                  topmenuId: !isEmpty(values?.topmenuId) ? values?.topmenuId : null,
                  textEn: !isEmpty(values?.textEn) ? values?.textEn : null,
                  textNp: !isEmpty(values?.textNp) ? values?.textNp : null,
                  subtextEn: !isEmpty(values?.subtextEn) ? values?.subtextEn : null,
                  subtextNp: !isEmpty(values?.subtextNp) ? values?.subtextNp : null,
                  imageEn: !isEmpty(values?.imageEn) ? values?.imageEn : null,
                  imageNp: !isEmpty(values?.imageNp) ? values?.imageNp : null,
                  highlightedTextEn: !isEmpty(values?.highlightedTextEn)
                    ? values?.highlightedTextEn
                    : null,
                  highlightedTextNp: !isEmpty(values?.highlightedTextNp)
                    ? values?.highlightedTextNp
                    : null,
                  buttonTextEn: !isEmpty(values?.buttonTextEn) ? values?.buttonTextEn : null,
                  buttonTextNp: !isEmpty(values?.buttonTextNp) ? values?.buttonTextNp : null,
                  buttonUrl: !isEmpty(values?.buttonUrl) ? values?.buttonUrl : null,
                  buttonClass: !isEmpty(values?.buttonClass) ? values?.buttonClass : null,
                  secondbuttonTextEn: !isEmpty(values?.secondbuttonTextEn)
                    ? values?.secondbuttonTextEn
                    : null,
                  secondbuttonTextNp: !isEmpty(values?.secondbuttonTextNp)
                    ? values?.secondbuttonTextNp
                    : null,
                  secondbuttonUrl: !isEmpty(values?.secondbuttonUrl)
                    ? values?.secondbuttonUrl
                    : null,
                  secondbuttonClass: !isEmpty(values?.secondbuttonClass)
                    ? values?.secondbuttonClass
                    : null,
                  services: values?.services?.map((item: {[key: string]: string}) => ({
                    ...item,
                    id: !isEmpty(editSelectedData) ? item.id : null,
                    serviceIcon: !isEmpty(editSelectedData)
                      ? item.serviceIcon
                        ? item.serviceIcon
                        : null
                      : null,
                  })),
                  mobileAppSections: values?.mobileAppSections?.map(
                    (item: {[key: string]: string}) => ({
                      ...item,
                      id: !isEmpty(editSelectedData) ? item.id : null,
                    })
                  ),
                  status: values?.status === 'Active' ? true : false,
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    homepageSettingRedux.actions.updateHomepageSetting(
                      formData,
                      editSelectedData?.id
                    )
                  )
                } else {
                  dispatch(homepageSettingRedux.actions.addHomepageSetting(formData))
                }
              }}
              data-cy='home-page-setting-form'
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'title',
                      'sectionId',
                      'layoutId',
                      'layoutImage',
                      'topmenuId',
                      'textEn',
                      'textNp',
                      'services',
                      'mobileAppSections',
                      'subtextEn',
                      'subtextNp',
                      'imageEn',
                      'imageNp',
                      'highlightedTextEn',
                      'highlightedTextNp',
                      'buttonTextEn',
                      'buttonTextNp',
                      'buttonUrl',
                      'buttonClass',
                      'secondbuttonTextEn',
                      'secondbuttonTextNp',
                      'secondbuttonUrl',
                      'secondbuttonClass',
                      'status',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])
                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            containerClassName='col-md-12'
                            labelClassName='col-md-12'
                            placeholder='Select Menu'
                            label='Select Menu'
                            name='topmenuId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={topMenuOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            data-cy='home-page-drop-down-menu'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormInputMediaManager
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Layout Image'
                            name='layoutImage'
                            setFieldValue={setFieldValue}
                            setImageUrl={setLayoutImage}
                            value={values?.layoutImage}
                            data-cy='home-page-layout-image'
                          />
                          {!isEmpty(values?.layoutImage) ? (
                            <>
                              <li className='listing' data-cy='home-page-listing'>
                                <div className='thumbImageBlock'>
                                  <button
                                    type='button'
                                    title='Remove'
                                    className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                    onClick={() => {
                                      setLayoutImage('')
                                      setFieldValue('layoutImage', '')
                                    }}
                                    data-cy='home-page-thumb-image-remove-button'
                                  >
                                    X
                                  </button>

                                  <img
                                    className='thumbImage w-100 h-100'
                                    src={`${imageBaseUrl}/${values?.layoutImage}`}
                                    alt=''
                                    data-cy='home-page-thumb-image'
                                  />
                                </div>
                              </li>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Title'
                            name='title'
                            label='Title'
                            containerClassName='col-md-12'
                            labelClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                            disabled={showInterestTitle ? true : false}
                            data-cy='home-page-title-field'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            containerClassName='col-md-12'
                            placeholder='Select Section'
                            label='Section'
                            name='sectionId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={sectionOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            data-cy='home-page-section-field'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Text (EN)'
                            name='textEn'
                            label='Text (EN)'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='home-page-text-en-field'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Text (NP)'
                            name='textNp'
                            label='Text (NP)'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='home-page-text-np-field'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Subtext (EN)'
                            name='subtextEn'
                            label='Subtext (EN)'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='home-page-sub-text-en-field'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Subtext (NP)'
                            name='subtextNp'
                            label='Subtext (NP)'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='home-page-sub-text-np-field'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormInputMediaManager
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Image (EN)'
                            name='imageEn'
                            setFieldValue={setFieldValue}
                            setImageUrl={setImageEng}
                            value={values?.imageEn}
                            data-cy='home-page-image-en-field'
                          />
                          {!isEmpty(values?.imageEn) ? (
                            <>
                              <li className='listing'>
                                <div className='thumbImageBlock'>
                                  <button
                                    type='button'
                                    title='Remove'
                                    className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                    onClick={() => {
                                      setImageEng('')
                                      setFieldValue('imageEn', '')
                                    }}
                                    data-cy='home-page-thumb-image-field'
                                  >
                                    X
                                  </button>

                                  <img
                                    className='thumbImage w-100 h-100'
                                    src={`${imageBaseUrl}/${values?.imageEn}`}
                                    alt=''
                                  />
                                </div>
                              </li>
                            </>
                          ) : null}
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormInputMediaManager
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Image (NP)'
                            name='imageNp'
                            setFieldValue={setFieldValue}
                            setImageUrl={setImageNp}
                            value={values?.imageNp}
                            data-cy='home-page-image'
                          />
                          {!isEmpty(values?.imageNp) ? (
                            <>
                              <li className='listing'>
                                <div className='thumbImageBlock'>
                                  <button
                                    type='button'
                                    title='Remove'
                                    className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                    onClick={() => {
                                      setImageNp('')
                                      setFieldValue('imageNp', '')
                                    }}
                                    data-cy='modal-thumbnail-remove'
                                  >
                                    X
                                  </button>

                                  <img
                                    className='thumbImage w-100 h-100'
                                    src={
                                      values?.imageNp.slice(-3) == 'pdf'
                                        ? `/public/media/logos/pdfDefault.png`
                                        : `${imageBaseUrl}/${values?.imageNp}`
                                    }
                                    alt=''
                                    data-cy='home-page-image-np-thumb'
                                  />
                                </div>
                              </li>
                            </>
                          ) : null}
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='First Button Text (EN)'
                            name='buttonTextEn'
                            label='First Button Text (EN)'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='home-page-button-text'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='First Button Text (NP)'
                            name='buttonTextNp'
                            label='First Button Text (NP)'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='home-page-button-text-np'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='First Button Link'
                            name='buttonUrl'
                            label='First Button Link'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='home-page-first-button-link'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='First Button ClassName'
                            name='buttonClass'
                            label='First Button ClassName'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='home-page-first-button-class'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Second Button Text (EN)'
                            name='secondbuttonTextEn'
                            label='Second Button Text (EN)'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='home-page-secont-button-text'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Second Button Text (NP)'
                            name='secondbuttonTextNp'
                            label='Second Button Text (NP)'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='home-page-second-button-text'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Second Button Link'
                            name='secondbuttonUrl'
                            label='Second Button Link'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='home-page-second-button-link'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Second Button Classname'
                            name='secondbuttonClass'
                            label='Second Button Classname'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='home-page-second-button-class-name'
                          />
                        </div>

                        {showHomepageStatus === true && (
                          <div className='col-md-6 col-xs-12 mb-5'>
                            <label
                              className='form-label fw-bolder text-dark fs-6 required'
                              data-cy='home-page-status'
                            >
                              Status
                            </label>
                            <div className='d-flex ms-5'>
                              {statusOptions?.map((status: {label: string; value: string}) => (
                                <FormRadio
                                  key={status?.value}
                                  containerClassName=''
                                  label='Select Status'
                                  name='status'
                                  type='radio'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required={true}
                                  checkBoxText={status?.label}
                                  value={status?.value}
                                  data-cy='home-page-status-radio-button'
                                />
                              ))}
                            </div>
                            <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                              <ErrorMessage
                                name='status'
                                component='div'
                                className='field-error-message'
                                data-cy='home-page-status-field-error-message'
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className='row pt-5 border-top'>
                        <h2>Only for section with services</h2>
                        <div className='row'>
                          <FieldArray
                            name='services'
                            render={(arrayHelpers) => (
                              <div>
                                {values?.services && values?.services?.length > 0
                                  ? values?.services?.map((friend: any, index: any) => (
                                      <div className='row' key={index}>
                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            placeholder='Service Title (EN)'
                                            name={`services.${index}.serviceTitle`}
                                            label='Service Title (EN)'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                            data-cy='home-page-service-title'
                                          />
                                        </div>
                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            placeholder='Service Title (NP)'
                                            name={`services.${index}.serviceTitleNp`}
                                            label='Service Title (NP)'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                            data-cy='home-page-service-title-np'
                                          />
                                        </div>

                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            placeholder='Description (EN)'
                                            name={`services.${index}.description`}
                                            label='Description (EN)'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                            data-cy='home-page-description'
                                          />
                                        </div>
                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            placeholder='Description (NP)'
                                            name={`services.${index}.descriptionNp`}
                                            label='Description (NP)'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                            data-cy='home-page-description-np'
                                          />
                                        </div>
                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            placeholder='Order'
                                            name={`services.${index}.order`}
                                            label='Order'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                            data-cy='home-page-order'
                                          />
                                        </div>
                                        <div className='col-md-6 col-xs-12 mb-2'>
                                          <FormInputMediaManager
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            label='Service Icon'
                                            name={`services.${index}.serviceIcon`}
                                            setFieldValue={setFieldValue}
                                            setImageUrl={setImageEng}
                                            value={values?.services[index]?.serviceIcon}
                                            data-cy='home-page-service-icon'
                                          />
                                          {!isEmpty(values?.services[index]?.serviceIcon) ? (
                                            <>
                                              <li className='listing'>
                                                <div className='thumbImageBlock'>
                                                  <button
                                                    type='button'
                                                    title='Remove'
                                                    className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                                    onClick={() => {
                                                      setImageEng('')
                                                      setFieldValue(
                                                        `services.${index}.serviceIcon`,
                                                        ''
                                                      )
                                                    }}
                                                    data-cy='modal-thumbnail-remover'
                                                  >
                                                    X
                                                  </button>

                                                  <img
                                                    className='thumbImage w-100 h-100'
                                                    src={`${imageBaseUrl}/${values?.services[index]?.serviceIcon}`}
                                                    alt=''
                                                    data-cy='home-page-service-thumb-image'
                                                  />
                                                </div>
                                              </li>
                                            </>
                                          ) : null}
                                        </div>
                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            placeholder='First Button '
                                            name={`services.${index}.firstButton`}
                                            label='First Button '
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                            data-cy='home-page-service-first-button'
                                          />
                                        </div>
                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            placeholder='First Button (NP)'
                                            name={`services.${index}.firstButtonNp`}
                                            label='First Button (NP)'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            placeholder='First Button Url'
                                            name={`services.${index}.firstButtonUrl`}
                                            label='First Button Url'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            placeholder='Second Button '
                                            name={`services.${index}.secondButton`}
                                            label='Second Button '
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            placeholder='Second Button (NP)'
                                            name={`services.${index}.secondButtonNp`}
                                            label='Second Button (NP)'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            placeholder='Second Button Url'
                                            name={`services.${index}.secondButtonUrl`}
                                            label='Second Button Url'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            placeholder='Service Link'
                                            name={`services.${index}.serviceLink`}
                                            label='Service Link'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                        {/* {showInterestTitle && (
                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            placeholder='Order'
                                            name={`services.${index}.order`}
                                            label='Order'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                      )} */}
                                        {index > 0 && (
                                          <div className='col-md-12  justify-content-md-end text-end mb-2'>
                                            <button
                                              type='button'
                                              className='p-2 ps-5 pe-5 btn btn-secondary'
                                              onClick={() => arrayHelpers.remove(index)}
                                              data-cy='modal-field-remove'
                                            >
                                              Remove
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    ))
                                  : null}
                                {values?.services?.length == 4 ? null : (
                                  <div className=' d-flex justify-content-md-end mb-2 border-top border-light'>
                                    <button
                                      className='p-2 ps-5 pe-5 btn btn-primary'
                                      type='button'
                                      onClick={() =>
                                        arrayHelpers.push({
                                          id: null,
                                          serviceTitle: '',
                                          serviceTitleNp: '',
                                          serviceLink: '',
                                          serviceIcon: '',
                                          description: '',
                                          descriptionNp: '',
                                          firstButton: '',
                                          firstButtonNp: '',
                                          firstButtonUrl: '',
                                          secondButton: '',
                                          secondButtonNp: '',
                                          secondButtonUrl: '',
                                          order: '',
                                        })
                                      }
                                      data-cy='modal-field-add'
                                    >
                                      + Add More
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      {/* mobile App Section */}
                      <div className='row pt-5 border-top'>
                        <h2>Only for section with Mobile App</h2>
                        <div className='row'>
                          <FieldArray
                            name='mobileAppSections'
                            render={(arrayHelpers) => (
                              <div>
                                {values?.mobileAppSections && values?.mobileAppSections?.length > 0
                                  ? values?.mobileAppSections?.map((friend: any, index: any) => (
                                      <div className='row' key={index}>
                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            placeholder='Mobile App Title  (EN)'
                                            name={`mobileAppSections.${index}.title`}
                                            label='Mobile App Title (EN)'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>

                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            placeholder='Mobile App Link'
                                            name={`mobileAppSections.${index}.link`}
                                            label='Mobile App Link'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>

                                        <div className='col-md-6 col-xs-12 mb-2'>
                                          <FormInputMediaManager
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            label='Mobile App Icon'
                                            name={`mobileAppSections.${index}.image`}
                                            setFieldValue={setFieldValue}
                                            setImageUrl={setImageEng}
                                            value={values?.mobileAppSections[index]?.image}
                                          />
                                          {!isEmpty(values?.mobileAppSections[index]?.image) ? (
                                            <>
                                              <li className='listing'>
                                                <div className='thumbImageBlock'>
                                                  <button
                                                    type='button'
                                                    title='Remove'
                                                    className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                                    onClick={() => {
                                                      setImageEng('')
                                                      setFieldValue(
                                                        `mobileAppSections.${index}.image`,
                                                        ''
                                                      )
                                                    }}
                                                    data-cy='modal-thumbnail-removed'
                                                  >
                                                    X
                                                  </button>

                                                  <img
                                                    className='thumbImage w-100 h-100'
                                                    src={`${imageBaseUrl}/${values?.mobileAppSections[index]?.image}`}
                                                    alt=''
                                                  />
                                                </div>
                                              </li>
                                            </>
                                          ) : null}
                                        </div>

                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            placeholder='Order'
                                            name={`mobileAppSections.${index}.order`}
                                            label='Order'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>

                                        {index > 0 && (
                                          <div className='col-md-12  justify-content-md-end text-end mb-2'>
                                            <button
                                              type='button'
                                              className='p-2 ps-5 pe-5 btn btn-secondary'
                                              onClick={() => arrayHelpers.remove(index)}
                                              data-cy='modal-field-remover'
                                            >
                                              Remove
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    ))
                                  : null}
                                {values?.mobileAppSections?.length == 4 ? null : (
                                  <div className=' d-flex justify-content-md-end mb-2 border-top border-light'>
                                    <button
                                      className='p-2 ps-5 pe-5 btn btn-primary'
                                      type='button'
                                      onClick={() =>
                                        arrayHelpers.push({
                                          id: null,
                                          title: '',
                                          link: '',
                                          mobileIcon: '',
                                          order: '',
                                        })
                                      }
                                      data-cy='modal-field-adds'
                                    >
                                      + Add More
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <div className='row pt-5 border-top'>
                        <h2>Only for highlighted title text section</h2>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Highlighted Text (EN)'
                            name='highlightedTextEn'
                            label='Highlighted Text (EN)'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Highlighted Text (NP)'
                            name='highlightedTextNp'
                            label='Highlighted Text (NP)'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AddHomepageSetting
