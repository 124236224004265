import axios from 'axios'
import {useQuery} from 'react-query'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL
const LINKED_ENTITIES_LISTS = `${API_URL}/linked-entities/list`

const fetchLinkedEntitiesList = async (params: ParamsModel) => {
  try {
    const {data} = await axios.get(LINKED_ENTITIES_LISTS, {
      params,
    })
    return data
  } catch (error: any) {
    throw new Error(`Error fetching linked Entities Lists: ${error.message}`)
  }
}

const useGetLinkedEntitiesLists = (params: ParamsModel) => {
  return useQuery(['getlinkedEntitesList', params], () => fetchLinkedEntitiesList(params))
}

export default useGetLinkedEntitiesLists
