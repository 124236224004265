import {Tab, Tabs} from 'react-bootstrap-v5'
import {useState} from 'react'
import ClosedTableLists from './ClosedTableLists'
import RejectedTableLists from './RejectedTableLists'
const BranchCheckerAncillaryLists = () => {
  const [activeKey, setActiveKey] = useState('rejectedList')

  return (
    <div className='modal-container shadow rounded'>
      <div className='bg-white p-5'>
        <Tabs
          activeKey={activeKey}
          onSelect={(k) => setActiveKey(k ?? 'rejectedList')}
          id='branchChecker-anccillary-service-tabs'
          className='mb-5'
        >
          <Tab eventKey='rejectedList' title='Rejected Ancillary Services'>
            <div className='card-body bg-white'>
              <RejectedTableLists />
            </div>
          </Tab>
          <Tab eventKey='closedLists' title='Closed Ancillary Services'>
            <div className='card-body bg-white'>
              <ClosedTableLists />
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

export default BranchCheckerAncillaryLists
