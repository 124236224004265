import axios from 'axios'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'
import {KYC_UPDATE_API} from '../api'

type SubmitDocumentsPayload = {
  formData: FormData
  kycLogId: string
}

const submitDocuments = async ({formData, kycLogId}: SubmitDocumentsPayload) => {
  try {
    const response = await axios.post(`${KYC_UPDATE_API}/${kycLogId}/documents`, formData)
    return response
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const usePostKycUpdateDocument = () => {
  return useMutation(submitDocuments, {
    onSuccess: (data) => {
      if (data?.data?.resCod == 200) {
        toast.success(data?.data?.resDesc ?? 'Documents submitted successfully!')
      }
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error.message ?? 'An error occurred'}`)
    },
  })
}

export default usePostKycUpdateDocument
