import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface ICPUMakerAndAccountList {
  loading: boolean
  success: boolean
  data: {
    new_accounts: Record<string, string>[]
    meta: Record<string, number>
  }
}

const individualJointMinorInitialState: ICPUMakerAndAccountList = {
  loading: false,
  success: false,
  data: {new_accounts: [], meta: {}},
}

export const individualJointMinorReducer = (
  state: ICPUMakerAndAccountList = individualJointMinorInitialState,
  action: ActionWithPayload<ICPUMakerAndAccountList>
) => {
  switch (action.type) {
    case actionTypes.GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_MINOR_JOINT_MINOR_LIST_START:
      return {...state, loading: true}
    case actionTypes.GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_MINOR_JOINT_MINOR_LIST_SUCCESS:
      return {...state, loading: false, data: action.payload}
    case actionTypes.GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_MINOR_JOINT_MINOR_LIST_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface ICPUMakerAndAccountCorporateList {
  loading: boolean
  success: boolean
  data: {
    corporate_accounts: Record<string, string>[]
    meta: Record<string, number>
  }
}

const corporateBankInitialState: ICPUMakerAndAccountCorporateList = {
  loading: false,
  success: false,
  data: {corporate_accounts: [], meta: {}},
}

export const corporateBankReducer = (
  state: ICPUMakerAndAccountCorporateList = corporateBankInitialState,
  action: ActionWithPayload<ICPUMakerAndAccountCorporateList>
) => {
  switch (action.type) {
    case actionTypes.GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_LIST_START:
      return {...state, loading: true}
    case actionTypes.GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_SUCCESS:
      return {...state, loading: false, data: action.payload}
    case actionTypes.GET_CPU_MAKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IPostAccountPreliminaryKYCState {
  success: boolean
  loading: boolean
}

const accountPreliminaryKYCInitialState: IPostAccountPreliminaryKYCState = {
  loading: false,
  success: false,
}

export const postAccountPreliminaryKYCReducer = (
  state: IPostAccountPreliminaryKYCState = accountPreliminaryKYCInitialState,
  action: ActionWithPayload<IPostAccountPreliminaryKYCState>
) => {
  switch (action.type) {
    case actionTypes.POST_ACCOUNT_PRELIMINARY_KYC_FORM_START:
      return {...state, loading: true, success: false}
    case actionTypes.POST_ACCOUNT_PRELIMINARY_KYC_FORM_SUCCESS:
      return {...state, loading: false, success: true}
    case actionTypes.POST_ACCOUNT_PRELIMINARY_KYC_FORM_FINISH:
      return {...state, loading: false, success: false}
    default:
      return state
  }
}

export interface IPostAccountPreliminaryKYCAdditionalState {
  success: boolean
  loading: boolean
}

const accountPreliminaryKYCAdditionalInitialState: IPostAccountPreliminaryKYCAdditionalState = {
  loading: false,
  success: false,
}

export const postAccountPreliminaryKYCAdditionalReducer = (
  state: IPostAccountPreliminaryKYCAdditionalState = accountPreliminaryKYCAdditionalInitialState,
  action: ActionWithPayload<IPostAccountPreliminaryKYCAdditionalState>
) => {
  switch (action.type) {
    case actionTypes.POST_ACCOUNT_PRELIMINARY_KYC_FORM_START:
      return {...state, loading: true, success: false}
    case actionTypes.POST_ACCOUNT_PRELIMINARY_KYC_FORM_SUCCESS:
      return {...state, loading: false, success: true}
    case actionTypes.POST_ACCOUNT_PRELIMINARY_KYC_FORM_FINISH:
      return {...state, loading: false, success: false}
    default:
      return state
  }
}
