import axios from 'axios'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'
import {NEW_CIF_API} from '../api'

const patchCIF = async (id: string, payload: object) => {
  try {
    return await axios.patch(`${NEW_CIF_API}/${id}`, payload)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message)
      console.error('Error while posting New CIF :: ', error)
    } else {
      console.log(error)
    }
  }
}

export const usePatchCIF = () =>
  useMutation({
    mutationFn: async ({id, payload}: {id: string; payload: object}) => patchCIF(id, payload),
  })
