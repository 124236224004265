import {Tab, Tabs} from 'react-bootstrap-v5'
import {FailedTable, InProgressTable, VerifiedTable} from './tables'

const ExistingCustomerVerification = () => {
  return (
    <>
      <h1 className='my-4'>Account List</h1>
      <div className='modal-container shadow rounded' data-cy='new-cif-account-list-modal'>
        <div className='bg-white p-5'>
          <Tabs
            defaultActiveKey='inProgress'
            id='uncontrolled-tab-example'
            data-cy='new-cif-account-list-tabs'
          >
            <Tab
              eventKey='inProgress'
              title='Existing Customer (In Progress)'
              data-cy='tab-individual-joint-minor'
            >
              <div className='card-body bg-white' data-cy='tab-content-individual-joint-minor'>
                <InProgressTable />
              </div>
            </Tab>
            <Tab
              eventKey='verified'
              title='Existing Customer (Verified)'
              data-cy='tab-corporate-banks'
            >
              <div className='card-body bg-white' data-cy='tab-content-corporate-banks'>
                <VerifiedTable />
              </div>
            </Tab>
            <Tab eventKey='failed' title='Existing Customer (Failed)' data-cy='tab-corporate-banks'>
              <div className='card-body bg-white' data-cy='tab-content-corporate-banks'>
                <FailedTable />
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  )
}

export default ExistingCustomerVerification
