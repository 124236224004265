import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL
const ACCOUNT_PRELIMINARY_URL = `${API_URL}/corporate-newcif-newaccount`

type SubmitDocumentsPayload = {
  formData: FormData
  accountId: string
}

const submitCorporateDocuments = async ({formData, accountId}: SubmitDocumentsPayload) => {
  try {
    const response = await axios.post(`${ACCOUNT_PRELIMINARY_URL}/${accountId}/documents`, formData)
    return response
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const usePostCorporateAccountDocuments = () => {
  const queryClient = useQueryClient()
  return useMutation(submitCorporateDocuments, {
    onSuccess: (data) => {
      if (data?.data?.resCod == 200) {
        toast.success(data?.data?.resDesc ?? 'Documents submitted successfully!')
      }
      queryClient.invalidateQueries('branchCheckerNewCIFAndAccountList')
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error.message ?? 'An error occurred'}`)
    },
  })
}

export default usePostCorporateAccountDocuments
