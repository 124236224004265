import axios from 'axios'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL
const customerInfoByCIF = `${API_URL}/linked-entities`

const postCustomerInfoByCIF = async (payload: {cif_number: string}) => {
  try {
    const response = await axios.post(customerInfoByCIF, payload)
    return response.data
  } catch (error: any) {
    throw new Error(`${error.response?.data?.message || error.message}`)
  }
}

const usePostLinkedEntities = () => {
  return useMutation(postCustomerInfoByCIF)
}

export default usePostLinkedEntities
