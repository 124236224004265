import {useState} from 'react'
import {StateParamsModel} from 'src/app/modules/common/Model'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {Input, InputGroup, Pagination, Table} from 'rsuite'
import {Close, Search} from '@rsuite/icons'
import {isEmpty} from 'lodash'

const Cell = Table.Cell

const FailureList = () => {
  const [params, setParams] = useState<StateParamsModel>({
    status: 1,
    page: 1,
    limit: 10,
  })

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <button className='btn btn-primary btn-sm ms-3'>Review</button>
      </Cell>
    )
  }

  const columns = [
    {
      label: 'Account Number',
      dataKey: 'accountNumber',
      width: 200,
      cell: <Cell dataKey='accountNumber' />,
    },
    {
      label: 'CIF Number',
      dataKey: 'cifNumber',
      cell: <Cell dataKey='cifNumber' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Account Name',
      dataKey: 'accountName',
      sortable: false,
      width: 200,
      cell: <Cell dataKey='accountName' />,
    },
    {
      label: 'Company Category',
      dataKey: 'companyCategory',
      sortable: false,
      width: 200,
      cell: <Cell dataKey='companyCategory' />,
    },
    {
      label: 'Country',
      dataKey: 'country',
      cell: <Cell dataKey='country' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Updated On',
      dataKey: 'updatedOn',
      cell: <Cell dataKey='updatedOn' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Requested Services',
      dataKey: 'requestedServices',
      cell: <Cell dataKey='requestedServices' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Remarks',
      dataKey: 'remarks',
      cell: <Cell dataKey='remarks' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Action',
      width: 160,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  return (
    <>
      <InputGroup inside style={{width: '30%', margin: '1rem 0'}}>
        <Input
          placeholder='Search'
          value={params.search}
          onChange={(value: string) => setParams({...params, search: value})}
          data-cy='search-field'
        />
        <InputGroup.Addon>
          {!isEmpty(params?.search) ? (
            <Close
              style={{cursor: 'pointer'}}
              onClick={() => setParams({...params, search: ''})}
              data-cy='search-close-button'
            />
          ) : (
            <Search />
          )}
        </InputGroup.Addon>
      </InputGroup>
      <div className='datatable'>
        <RSuiteTable columns={columns} data={[]} showLoading={false} />
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size='sm'
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={0}
          limitOptions={[10, 20, 50, 100]}
          limit={params?.limit}
          activePage={params?.page}
          onChangePage={(value) => setParams({...params, page: value})}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </>
  )
}

export default FailureList
