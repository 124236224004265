import axios, {AxiosError} from 'axios'
import {useQuery} from 'react-query'
import {ParamsModel} from 'src/app/modules/common/Model'
import {KYC_UPDATE_API} from '../api'

const fetchKycLogsLists = async (params: ParamsModel) => {
  try {
    const {data} = await axios.get(KYC_UPDATE_API, {
      params,
    })
    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(`${error.response?.data?.message || 'Error Getting Kyc Logs Lists'}`)
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
}

const useGetKycLists = (params: ParamsModel) => {
  return useQuery(['getKycLogsLists', params], () => fetchKycLogsLists(params))
}

export default useGetKycLists
