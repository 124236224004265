import {MasterDataModel} from 'src/app/modules/masterData/Model'
import DetailCell from '../DetailCell'
import {useSelector} from 'react-redux'
import {RootState} from 'src/setup'

interface RegistrationAddressInformationSectionProps {
  data?: any
}

const RegistrationAddressInformationSection = ({
  data,
}: RegistrationAddressInformationSectionProps) => {
  const {data: countries}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.countriesList
  )
  return (
    <>
      <h2 className='text-center my-4'>REGISTRATION ADDRESS</h2>
      <div className='row'>
        <DetailCell label='Country' value={data?.kyc_information?.country?.title} />
      </div>
      {data?.kyc_information?.country.title === 'Nepal' ? (
        <>
          <div className='row'>
            <DetailCell label='House Number' value={data?.kyc_information?.house_number} />
            <DetailCell label='Street Name/Tole' value={data?.kyc_information?.tole} />
          </div>
          <div className='row'>
            <DetailCell label='Ward Number' value={data?.kyc_information?.ward_number} />
          </div>
          <div className='row'>
            <DetailCell label='Province' value={data?.kyc_information?.province?.title} />
            <DetailCell label='District' value={data?.kyc_information?.district?.title} />
          </div>
          <div className='row'>
            <DetailCell label='Municipality' value={data?.kyc_information?.municipality?.title} />
            <DetailCell
              label='Other Municipality'
              value={data?.kyc_information?.municipality_other}
            />
          </div>
        </>
      ) : (
        <>
          <div className='row'>
            <DetailCell
              label='Permanent Address Line 1'
              value={data?.kyc_information?.permanent_outside_nepal_address_line_1}
            />
          </div>
          <div className='row'>
            <DetailCell
              label='Permanent Address Line 2'
              value={data?.kyc_information?.permanent_outside_nepal_address_line_2}
            />
            <DetailCell
              label='Permanent Address Line 3'
              value={data?.kyc_information?.permanent_outside_nepal_address_line_3}
            />
          </div>
        </>
      )}
      <div className='row'>
        <DetailCell label='Phone No.' value={data?.kyc_information?.phone_number_residence} />
        <DetailCell label='Mobile No.' value={data?.kyc_information?.mobile_number} />
      </div>
      <div className='row'>
        <DetailCell label='Email' value={data?.kyc_information?.email} />
      </div>
    </>
  )
}

export default RegistrationAddressInformationSection
