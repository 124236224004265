import axios from 'axios'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'
import {BULK_ACCOUNT_OPENING} from '../api'

const uploadBulkAccount = async (payload: FormData, handleCloseUploadModal: () => void) => {
  try {
    return await axios
      .post(`${BULK_ACCOUNT_OPENING}/upload`, payload, {
        headers: {'Content-Type': 'multipart/form-data'},
      })
      .then(() => {
        toast.success('File Uploaded Successfully')
        handleCloseUploadModal()
      })
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message)
      console.error('Error while uploading bulk account opening file :: ', error)
    } else {
      console.log(error)
    }
  }
}

export const usePostUploadBulkAccount = () => {
  return useMutation({
    mutationFn: async ({
      formData,
      handleCloseUploadModal,
    }: {
      formData: FormData
      handleCloseUploadModal: () => void
    }) => uploadBulkAccount(formData, handleCloseUploadModal),
  })
}
