import moment from 'moment'
import * as Yup from 'yup'

export const cpuMakerCorporateBankSchema = Yup.object().shape({
  customer_number: Yup.string().required('Required'),
  account_number: Yup.string().required('Required'),
  account_class: Yup.string().typeError('Invalid Account Class').required('Required'),
  full_name: Yup.string().required('Required'),
  short_name: Yup.string().required('Required'),
  branch: Yup.string().required('Required'),
  date: Yup.string()
    .test('date-validation', 'Invalid Date', function (value) {
      const isFuture = value && moment(value, 'YYYY-MM-DD').isAfter(moment())
      if (isFuture) {
        return false
      }
      return true
    })
    .required('Required'),

  account_category: Yup.string().required('Required'),
  account_currency: Yup.string().required('Required'),
  account_purpose: Yup.string().required('Required'),
  pep_confirmation: Yup.string().required('Required'),
  customer_category: Yup.string().required('Required'),
  customer_classification: Yup.string().required('Required'),
  tax_category: Yup.string().required('Required'),
  tax_group: Yup.string().required('Required'),
  registration_number: Yup.string().required('Required'),
  registration_date_bs: Yup.string().required('Required'),
  registration_date_ad: Yup.string()
    .required('Required')
    .test('date-validation', 'Invalid Date', function (value) {
      const isFuture = value && moment(value, 'YYYY-MM-DD').isAfter(moment())
      if (isFuture) {
        return false
      }
      return true
    }),
  registration_expiry_date_bs: Yup.string().required('Required'),
  registration_expiry_date_ad: Yup.string()
    .required('Required')
    .test('date-validation', 'Invalid Date', function (value) {
      const {registration_date_ad} = this.parent
      const isBeforeRegistrationDate =
        value &&
        registration_date_ad &&
        moment(value, 'YYYY-MM-DD').isBefore(moment(registration_date_ad, 'YYYY-MM-DD'))
      if (isBeforeRegistrationDate) {
        return false
      }
      return true
    }),
  registered_with: Yup.string().required('Required'),
  registered_with_other: Yup.string(),
  issuing_place: Yup.string().required('Required'),
  pan_number: Yup.number().typeError('Pan Number must be in numbers'),
  pan_registration_date_bs: Yup.string(),
  pan_registration_date_ad: Yup.string().test('date-validation', 'Invalid Date', function (value) {
    const isFuture = value && moment(value, 'YYYY-MM-DD').isAfter(moment())
    if (isFuture) {
      return false
    }
    return true
  }),
  description_of_business: Yup.string(),
  authorized_capital: Yup.number().typeError('Amount must be in numbers'),
  paid_up_capital: Yup.number().typeError('Amount must be in numbers'),
  expected_source_of_fund: Yup.number().typeError('Amount must be in numbers'),
  expected_annual_business_turnover: Yup.number().typeError('Amount must be in numbers'),
  correspondence_phone_number: Yup.number()
    .typeError('Invalid Phone Number')
    .max(9999999999, 'Invalid Phone Number'),
  correspondence_email: Yup.string().email('Invalid Email').required('Required'),
  correspondence_mobile_number: Yup.string()
    .matches(/^9\d{9}$/, 'Invalid Mobile Number')
    .required('Required'),
  mobile_number: Yup.string()
    .matches(/^9\d{9}$/, 'Invalid Mobile Number')
    .required('Required'),
  phone_number: Yup.number()
    .typeError('Invalid Phone Number')
    .max(9999999999, 'Invalid Phone Number'),
  email: Yup.string().email('Invalid Email').required('Required'),
  industry_business_type: Yup.string().required('Required'),
  source_of_income: Yup.string().required('Required'),
  anticipated_annual_transaction: Yup.string().required('Required'),
  introducer_name: Yup.string()
    .required('Required')
    .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
  kyc_review_date: Yup.string()
    .required('Required')
    .test('date-validation', 'Invalid Date', function (value) {
      const isFuture = value && moment(value, 'YYYY-MM-DD').isAfter(moment())
      if (!isFuture) {
        return false
      }
      return true
    }),
  proof_address_document: Yup.string().required('Required'),
  remarks: Yup.string().required('Required'),
  kyc_level_category: Yup.string().required('Required'),
  annual_income: Yup.string().required('Required'),
  institutional: Yup.string().required('Required'),
})
