import {Formik} from 'formik'
import * as Yup from 'yup'
import {useState} from 'react'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import TermsAndConditionsModal from '../TermsAndConditionsModal'

interface VerificationFormProps {
  variant: 'email' | 'mobile' | 'both'
  setIsVerificationModalOpen?: (value: boolean) => void
  uniqueId?: string
  loading?: boolean
  openVerificationModal?: (type: string) => void
  setIsEmailVerified?: (value: boolean) => void
}

const VerificationForm = ({
  variant,
  setIsVerificationModalOpen,
  uniqueId,
  loading,
  openVerificationModal,
  setIsEmailVerified,
}: VerificationFormProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .length(6, 'Code must be exactly 6 digits')
      .matches(/^\d+$/, 'Code must be a number')
      .required('Code is required'),
  })

  const handleLoadingChange = (loading: boolean) => {
    setIsLoading(loading)
  }

  return (
    <div>
      <h1 className='heading__component__title fw-bolder' style={{textAlign: 'center'}}>
        {variant === 'mobile'
          ? 'Mobile Number Verification'
          : variant === 'email'
          ? 'Email Verification'
          : 'Both Email and Mobile Verification'}
      </h1>
      <div className='mt-10 mx-auto'>
        <p>
          Please enter the 6 digit{' '}
          <strong>{variant === 'mobile' ? 'Mobile Number' : 'Email'}</strong> Verification Code sent
          in the {variant === 'mobile' ? 'Mobile Number' : 'Email'}. If the verification code has
          not been received by the customer, click on resend code button.
        </p>
        <Formik
          initialValues={{code: ''}}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setIsModalOpen(true)
          }}
        >
          {({handleChange, touched, errors, values, handleSubmit}) => (
            <>
              <div className='card mb-5 mb-xl-10'>
                <div className='card-body pt-9 pb-9'>
                  <div className='row g-4 align-items-center'>
                    <div className='col-12 col-md-6'>
                      <TermsAndConditionsModal
                        open={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        uniqueId={uniqueId}
                        code={values.code}
                        onLoadingChange={handleLoadingChange}
                        setIsVerificationModalOpen={setIsVerificationModalOpen}
                        setIsEmailVerified={setIsEmailVerified}
                      />
                      <div className='col-md-12'>
                        <FormTextBox
                          name='code'
                          onChange={handleChange}
                          containerClassName='col-12'
                          mainClassName='col-12'
                          placeholder='ENTER CODE HERE'
                          touched={touched}
                          errors={errors}
                        />
                      </div>
                    </div>
                    <div className='col-12 col-md-6'>
                      <div className='d-flex mb-1' style={{gap: '0.5rem'}}>
                        <button
                          type='button'
                          className='btn-sm btn-warning'
                          onClick={() => {
                            handleSubmit()
                          }}
                          disabled={loading || isLoading}
                        >
                          {isLoading ? 'Validating...' : 'Validate'}
                        </button>
                        <button
                          onClick={() => {
                            openVerificationModal && openVerificationModal(variant)
                          }}
                          disabled={loading || isLoading}
                          className='dt-btn dt-btn-outline-primary dt-btn-sm'
                        >
                          Resend Code
                        </button>
                        {/* <button
                          style={{color: 'white'}}
                          className='btn-sm btn-danger btn-font-weight-bold'
                          onClick={() =>
                            setIsVerificationModalOpen && setIsVerificationModalOpen(false)
                          }
                        >
                          Skip Validation
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
      {loading && (
        <div className='loading-overlay'>
          <div className='loading-message'>OTP is generating...</div>
        </div>
      )}
      <style>
        {`
        .loading-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 9999;
        }
        .loading-message {
          color: white;
          font-size: 1.5rem;
        }
        `}
      </style>
    </div>
  )
}

export default VerificationForm
