import {useState} from 'react'
import {Pagination, Table} from 'rsuite'
import {StateParamsModel} from 'src/app/modules/common/Model'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {ParamsComponent} from './IndividualJointMinorTable'
import {useGetCorporateAccountStatusLists} from 'src/app/queries/corporateAccountPreliminary/get/useGetCorporateAccountStatusLists'

const Cell = Table.Cell

const CorporateBankTable = () => {
  const [params, setParams] = useState<StateParamsModel>({
    status: 1,
    page: 1,
    limit: 10,
    orderBy: 'updated_at',
    order: 'desc',
  })

  const {data: corporateAccountLists, isLoading} = useGetCorporateAccountStatusLists(params)

  const data =
    corporateAccountLists?.data?.corporate_accounts?.map((newAccount: any, i: number) => ({
      ...newAccount,
      sn: (params?.page - 1) * params?.limit + (i + 1),
      accountNumber: newAccount?.registration_detail?.account_number ?? '',
      cifNumber: newAccount?.registration_detail?.cif_number ?? '',
      updatedOn: newAccount?.account_status?.updated_date,
      status: newAccount?.account_status?.current_status,
      remarks: newAccount?.account_status?.remarks,
    })) ?? []

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  // const ActionCell = ({rowData, dataKey, ...props}: any) => {
  //   return (
  //     <Cell {...props} className='link-group'>
  //       <button className='btn btn-primary btn-sm ms-3'>Review</button>
  //     </Cell>
  //   )
  // }

  const columns = [
    {
      label: 'Account Number',
      dataKey: 'accountNumber',
      width: 200,
      cell: <Cell dataKey='accountNumber' />,
    },
    {
      label: 'Customer Code',
      dataKey: 'customer_code',
      cell: <Cell dataKey='customer_code' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Account Name',
      dataKey: 'full_name',

      width: 300,
      cell: <Cell dataKey='full_name' />,
    },
    {
      label: 'CIF Number',
      dataKey: 'cifNumber',
      cell: <Cell dataKey='cifNumber' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Location',
      dataKey: 'location',
      cell: <Cell dataKey='location' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Status',
      dataKey: 'status',
      cell: <Cell dataKey='status' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Created On',
      dataKey: 'updatedOn',
      cell: <Cell dataKey='updatedOn' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Remarks',
      dataKey: 'remarks',
      cell: <Cell dataKey='remarks' />,
      sortable: false,
      width: 200,
    },
    // {
    //   label: 'Action',
    //   width: 130,
    //   align: 'center',
    //   cell: <ActionCell dataKey='id' />,
    // },
  ]
  return (
    <>
      <ParamsComponent params={params} setParams={setParams} loading={isLoading} />
      <div className='datatable'>
        <RSuiteTable columns={columns} data={data} showLoading={isLoading} />
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size='sm'
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={corporateAccountLists?.data?.meta?.total ?? 0}
          limitOptions={[10, 20, 50, 100]}
          limit={params?.limit}
          activePage={params?.page}
          onChangePage={(value) => setParams({...params, page: value})}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </>
  )
}

export default CorporateBankTable
