import CPUCheckerIndividualJointMinorReviewComponent from 'src/app/modules/common/components/cpuChecker/ReviewComponent/IndividualJointMinor'

const CPUCheckerNewCIFAndAccountIndividualJointMinorReviewPage = () => {
  return (
    <div>
      <CPUCheckerIndividualJointMinorReviewComponent />
    </div>
  )
}

export default CPUCheckerNewCIFAndAccountIndividualJointMinorReviewPage
