import axios from 'axios'
import {useMutation} from 'react-query'
import {KYC_UPDATE_API} from '../api'
import {toast} from 'react-toastify'

const postToGetKycStore = async (payload: {account_number: string; action_btn: string}) => {
  try {
    const response = await axios.post(`${KYC_UPDATE_API}/store`, payload)
    return response.data
  } catch (error: any) {
    throw new Error(`${error.response?.data?.message || error.message}`)
  }
}

export const usePostToCheckKycUpdate = () => {
  return useMutation(postToGetKycStore, {
    onError: (error: Error) => {
      toast.error(`${error.message || 'An error occurred'}`)
    },
  })
}
