import React, {useState} from 'react'
import {Modal, Button, Table} from 'rsuite'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {IoClose} from 'react-icons/io5'

const Cell = Table.Cell

type CIFCheckListProps = {
  onCancel: () => void
  data: any
  loading: boolean
}

const GWLList = ({onCancel, data, loading}: CIFCheckListProps) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [jsonData, setJsonData] = useState<any>(null)
  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <button
          className='btn btn-primary btn-sm ms-3'
          onClick={() => {
            setJsonData(rowData)
            setModalVisible(true)
          }}
        >
          View Details
        </button>
      </Cell>
    )
  }

  const columns = [
    {
      label: 'Full Name',
      dataKey: 'name',
      flexGrow: 1,
      cell: <Cell dataKey='name' />,
    },
    {
      label: 'Score',
      dataKey: 'score',
      flexGrow: 1,
      cell: <Cell dataKey='score' />,
    },
    {
      label: 'Source',
      dataKey: 'source',
      flexGrow: 1,
      cell: <Cell dataKey='source' />,
    },
    {
      label: 'Actions',
      dataKey: 'actions',
      flexGrow: 1,
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const renderDetailTable = () => {
    if (!jsonData) return null

    return (
      <Table height={450} data={Object.entries(jsonData).map(([key, value]) => ({key, value}))}>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Title</Table.HeaderCell>
          <Table.Cell dataKey='key' />
        </Table.Column>
        <Table.Column flexGrow={2.2}>
          <Table.HeaderCell>Value</Table.HeaderCell>
          <Table.Cell dataKey='value' />
        </Table.Column>
      </Table>
    )
  }

  return (
    <div className='my-8'>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div className='heading__component__title fs-6 fw-bolder mb-2'>
          GWL Search result ({data?.length ?? 0})
        </div>
        {/* <IoClose style={{cursor: 'pointer'}} size={24} onClick={onCancel} /> */}
      </div>
      <div className='datatable' style={{width: '100%'}}>
        <RSuiteTable columns={columns} data={data} showLoading={loading} showBorder={true} />
      </div>

      <Modal open={modalVisible} onClose={() => setModalVisible(false)}>
        <Modal.Header>
          <Modal.Title>{`Customer Details OF ${jsonData?.name}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderDetailTable()}</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalVisible(false)} appearance='subtle'>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default GWLList
