import {call, put, takeLatest} from 'redux-saga/effects'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {services} from './service'
import {actions} from './action'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {actionTypes} from './constants'

function* checkDuplicateCifSaga(action: ActionModel) {
  try {
    const response: ResponseModel = yield call(services.checkDuplicateCif, action.payload)
    yield put(actions.checkDuplicateCifSuccess(response.data))
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    } else {
      yield put(actions.checkDuplicateCifFailure(error))
    }
  }
}

export function* saga() {
  yield takeLatest(actionTypes.CHECK_DUPLICATE_CIF_START, checkDuplicateCifSaga)
}
