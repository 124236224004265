import axios from 'axios'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'
import {NEW_CIF_API} from '../api'
import {KYCAdditionalForm} from 'src/app/modules/cpuMaker/newCIFAndAccountList/Model'

const postNewCIFAdditionalForm = async (id: string, payload: KYCAdditionalForm) => {
  try {
    return await axios.post(`${NEW_CIF_API}/${id}/additional-form`, payload)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message)
      console.error('Error while posting corporate KYC Form :: ', error)
    } else {
      console.log(error)
    }
  }
}

export const usePostNewCIFAdditionalForm = () =>
  useMutation({
    mutationFn: async ({id, payload}: {id: string; payload: KYCAdditionalForm}) =>
      postNewCIFAdditionalForm(id, payload),
  })
