import axios from 'axios'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL
const customerInfoByCIF = `${API_URL}/newcif-newaccount/get-customer-info-by-cif`

const postCustomerInfoByCIF = async (payload: {customer_no: string}) => {
  try {
    const response = await axios.post(customerInfoByCIF, payload)
    return response.data
  } catch (error: any) {
    throw new Error(`${error.response?.data?.message || error.message}`)
  }
}

const usePostCustomerInfoByCIF = () => {
  return useMutation(postCustomerInfoByCIF, {
    onSuccess: (data) => {
      if (data?.resCod == '200') {
        // toast.success(data?.resDesc ?? ' Customer Info Fetched successfully!')
      }
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error.message || 'An error occurred'}`)
    },
  })
}

export default usePostCustomerInfoByCIF
