import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'
import {IBranchCheckerAndAccountList} from './reducer'
import {BranchReviewPreliminaryForm} from '../Model'

export const actions = {
  getBranchCheckerNewCIFandAccountIndividualJointMinorListStart: (
    params: ParamsModel | undefined = {page: 1, limit: 10}
  ) => ({
    type: actionTypes.GET_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_LIST_START,
    payload: {params},
  }),
  getBranchCheckerNewCIFandAccountIndividualJointMinorListSuccess: (
    data: IBranchCheckerAndAccountList
  ) => ({
    type: actionTypes.GET_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_SUCCESS,
    payload: data,
  }),
  getBranchCheckerNewCIFandAccountIndividualJointMinorListFinish: () => ({
    type: actionTypes.GET_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_FINISH,
  }),

  postAccountPreliminaryBranchReviewStart: (id: string, data: BranchReviewPreliminaryForm) => ({
    type: actionTypes.POST_ACCOUNT_PRELIMINARY_BRANCH_REVIEW_START,
    payload: {id, data},
  }),

  postAccountPreliminaryBranchReviewSuccess: (data: any) => ({
    type: actionTypes.POST_ACCOUNT_PRELIMINARY_BRANCH_REVIEW_SUCCESS,
    payload: data,
  }),

  postAccountPreliminaryBranchReviewFinish: () => ({
    type: actionTypes.POST_ACCOUNT_PRELIMINARY_BRANCH_REVIEW_FINISH,
  }),

  getBranchCheckerNewCIFandAccountDetailsByIdStart: (id: string) => ({
    type: actionTypes.GET_NEW_CIF_AND_ACCOUNT_DETAILS_BY_ID_START,
    payload: {id},
  }),
  getBranchCheckerNewCIFandAccountDetailsByIdSuccess: (data: any) => ({
    type: actionTypes.GET_NEW_CIF_AND_ACCOUNT_DETAILS_BY_ID_SUCCESS,
    payload: data,
  }),
  getBranchCheckerNewCIFandAccountDetailsByIdFinish: () => ({
    type: actionTypes.GET_NEW_CIF_AND_ACCOUNT_DETAILS_BY_ID_FINISH,
  }),
}
