import {useState} from 'react'
import {Tab, Tabs} from 'react-bootstrap-v5'
import {imageBaseUrl} from 'src/cms/helpers/constants'

interface DocumentsPreviewProps {
  data?: any
}

const DocumentsPreview = ({data}: DocumentsPreviewProps) => {
  const [index, setIndex] = useState('1')
  return (
    <div>
      <div className='d-flex justify-content-end'>
        {index}/{data?.account_documents?.documents?.length ?? 0}
      </div>
      <Tabs
        defaultActiveKey={data?.account_documents?.documents?.[0]?.documentType?.cbs_code}
        id='uncontrolled-tab-example'
      >
        {data?.account_documents?.documents?.map(
          (
            document: {
              id: string
              file_path: string
              documentType: {title: string; cbs_code: string}
            },
            index: number
          ) => (
            <Tab
              eventKey={document?.documentType?.cbs_code}
              title={document?.documentType?.title}
              onEntered={() => setIndex((index + 1).toString())}
              key={document?.id}
            >
              <div className='card-body bg-white' style={{overflow: 'hidden'}}>
                <img
                  src={`${imageBaseUrl}/${document?.file_path}`}
                  style={{
                    width: '400px',
                    height: '400px',
                    objectFit: 'contain',
                    border: '1px solid gray',
                    borderRadius: '10px',
                    marginTop: '10px',
                  }}
                  alt='PP Size Photo'
                />
              </div>
            </Tab>
          )
        )}
      </Tabs>
    </div>
  )
}

export default DocumentsPreview
