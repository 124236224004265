import {Action} from 'redux'
import {CustomerClassificationDataModel, MasterDataModel, DocumentTypeModel} from '../Model'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface ICustomerListData {
  data: CustomerClassificationDataModel[]
  error: boolean
  loading: boolean
}

const initialCustomerClassificationListState: ICustomerListData = {
  data: [
    {
      id: '',
      title: '',
      cbs_code: '',
      order: 0,
      is_enabled: false,
    },
  ],
  loading: true,
  error: false,
}

export const customerClassificationListReducer = (
  state: ICustomerListData = initialCustomerClassificationListState,
  action: ActionWithPayload<ICustomerListData>
) => {
  switch (action.type) {
    case actionTypes.GET_CUSTOMER_CLASSIFICATION_LIST_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_CUSTOMER_CLASSIFICATION_LIST_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_CUSTOMER_CLASSIFICATION_LIST_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IAccountClassListState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialAccountClassListState: IAccountClassListState = {
  data: [
    {
      id: '',
      title: '',
      name: '',
      account_class: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const accountClassListReducer = (
  state: IAccountClassListState = initialAccountClassListState,
  action: ActionWithPayload<IAccountClassListState>
) => {
  switch (action.type) {
    case actionTypes.GET_ACCOUNT_CLASS_LIST_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_ACCOUNT_CLASS_LIST_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_ACCOUNT_CLASS_LIST_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IAccountTypeListState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialAccountTypeListState: IAccountTypeListState = {
  data: [
    {
      id: '',
      title: '',
      cbs_code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const accountTypeListReducer = (
  state: IAccountTypeListState = initialAccountTypeListState,
  action: ActionWithPayload<IAccountTypeListState>
) => {
  switch (action.type) {
    case actionTypes.GET_ACCOUNT_TYPE_LIST_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_ACCOUNT_TYPE_LIST_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_ACCOUNT_TYPE_LIST_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface ICustomerCategoryListState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialCustomerCateogoryListState: ICustomerCategoryListState = {
  data: [
    {
      id: '',
      title: '',
      cbs_code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const customerCategoryListReducer = (
  state: ICustomerCategoryListState = initialCustomerCateogoryListState,
  action: ActionWithPayload<ICustomerCategoryListState>
) => {
  switch (action.type) {
    case actionTypes.GET_CUSTOMER_CATEGORY_LIST_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_CUSTOMER_CATEGORY_LIST_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_CUSTOMER_CATEGORY_LIST_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface ITaxCategoryListState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialTaxCateogoryListState: ITaxCategoryListState = {
  data: [
    {
      id: '',
      title: '',
      cbs_code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const taxCategoryListReducer = (
  state: ITaxCategoryListState = initialTaxCateogoryListState,
  action: ActionWithPayload<ITaxCategoryListState>
) => {
  switch (action.type) {
    case actionTypes.GET_TAX_CATEGORY_LIST_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_TAX_CATEGORY_LIST_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_TAX_CATEGORY_LIST_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface ITaxGroupListState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialTaxGroupListState: ITaxGroupListState = {
  data: [
    {
      id: '',
      title: '',
      cbs_code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const taxGroupListReducer = (
  state: ITaxGroupListState = initialTaxGroupListState,
  action: ActionWithPayload<ITaxGroupListState>
) => {
  switch (action.type) {
    case actionTypes.GET_TAX_GROUP_LIST_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_TAX_GROUP_LIST_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_TAX_GROUP_LIST_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IInstitutionalListState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialInstitionalListState: IInstitutionalListState = {
  data: [
    {
      id: '',
      title: '',
      cbs_code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const institutionalListReducer = (
  state: IInstitutionalListState = initialInstitionalListState,
  action: ActionWithPayload<IInstitutionalListState>
) => {
  switch (action.type) {
    case actionTypes.GET_TAX_GROUP_LIST_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_TAX_GROUP_LIST_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_TAX_GROUP_LIST_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IMdediaListState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialMediaListState: IMdediaListState = {
  data: [
    {
      id: '',
      title: '',
      cbs_code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const mediaListReducer = (
  state: IMdediaListState = initialMediaListState,
  action: ActionWithPayload<IMdediaListState>
) => {
  switch (action.type) {
    case actionTypes.GET_MEDIA_LIST_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_MEDIA_LIST_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_MEDIA_LIST_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface ISalutationListState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialSalutationListState: ISalutationListState = {
  data: [
    {
      id: '',
      title: '',
      code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const salutationListReducer = (
  state: ISalutationListState = initialSalutationListState,
  action: ActionWithPayload<ISalutationListState>
) => {
  switch (action.type) {
    case actionTypes.GET_SALUTATIONS_LIST_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_SALUTATIONS_LIST_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_SALUTATIONS_LIST_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IdTypesListState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialIdTypesListState: IdTypesListState = {
  data: [
    {
      id: '',
      title: '',
      code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const idTypesListReducer = (
  state: IdTypesListState = initialIdTypesListState,
  action: ActionWithPayload<IdTypesListState>
) => {
  switch (action.type) {
    case actionTypes.GET_ID_TYPES_LIST_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_ID_TYPES_LIST_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_ID_TYPES_LIST_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IExposureListState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialExposureListState: IExposureListState = {
  data: [
    {
      id: '',
      title: '',
      code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const exposureListReducer = (
  state: IExposureListState = initialExposureListState,
  action: ActionWithPayload<IExposureListState>
) => {
  switch (action.type) {
    case actionTypes.GET_EXPOSURES_LIST_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_EXPOSURES_LIST_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_EXPOSURES_LIST_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IRiskCategoriesListState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialRiskCategoriesListState: IRiskCategoriesListState = {
  data: [
    {
      id: '',
      title: '',
      code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const riskCategoriesListReducer = (
  state: IRiskCategoriesListState = initialRiskCategoriesListState,
  action: ActionWithPayload<IRiskCategoriesListState>
) => {
  switch (action.type) {
    case actionTypes.GET_RISK_CATEGORIES_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_RISK_CATEGORIES_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_RISK_CATEGORIES_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IGuardianTypesListState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialGuardianTypesListState: IGuardianTypesListState = {
  data: [
    {
      id: '',
      title: '',
      fin_code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const guardianTypesListReducer = (
  state: IGuardianTypesListState = initialGuardianTypesListState,
  action: ActionWithPayload<IGuardianTypesListState>
) => {
  switch (action.type) {
    case actionTypes.GET_GUARDIAN_TYPES_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_GUARDIAN_TYPES_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_GUARDIAN_TYPES_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IAncillaryRemarksListState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialAncillaryRemarksListState: IAncillaryRemarksListState = {
  data: [
    {
      id: '',
      title: '',
      remarks: '',
      type: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const ancillaryRemarksListReducer = (
  state: IAncillaryRemarksListState = initialAncillaryRemarksListState,
  action: ActionWithPayload<IAncillaryRemarksListState>
) => {
  switch (action.type) {
    case actionTypes.GET_ANCILLARY_REMARKS_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_ANCILLARY_REMARKS_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_ANCILLARY_REMARKS_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IDocumentTypeListState {
  data: DocumentTypeModel[]
  error: boolean
  loading: boolean
}

const initialDocumentTypeListState: IDocumentTypeListState = {
  data: [
    {
      id: '',
      title: '',
      cbs_code: '',
      status: false,
      max_size: '',
    },
  ],
  loading: true,
  error: false,
}

export const documentTypeListReducer = (
  state: IDocumentTypeListState = initialDocumentTypeListState,
  action: ActionWithPayload<IDocumentTypeListState>
) => {
  switch (action.type) {
    case actionTypes.GET_DOCUMENT_TYPES_LIST_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_DOCUMENT_TYPES_LIST_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_DOCUMENT_TYPES_LIST_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IIncomeSourceListState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialIncomeSourceListState: IIncomeSourceListState = {
  data: [
    {
      id: '',
      title: '',
      fin_code: '',
      code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const incomeSourceListReducer = (
  state: IIncomeSourceListState = initialIncomeSourceListState,
  action: ActionWithPayload<IIncomeSourceListState>
) => {
  switch (action.type) {
    case actionTypes.GET_INCOME_SOURCES_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_INCOME_SOURCES_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_INCOME_SOURCES_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IEmploymentStatusListState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialEmploymentStatusListState: IEmploymentStatusListState = {
  data: [
    {
      id: '',
      title: '',
      fin_code: '',
      code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const employmentStatusListReducer = (
  state: IEmploymentStatusListState = initialEmploymentStatusListState,
  action: ActionWithPayload<IEmploymentStatusListState>
) => {
  switch (action.type) {
    case actionTypes.GET_EMPLOYMENT_STATUS_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_EMPLOYMENT_STATUS_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_EMPLOYMENT_STATUS_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}
export interface IKYCLevelCategoryListState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialKYCLevelCategoryListState: IKYCLevelCategoryListState = {
  data: [
    {
      id: '',
      title: '',
      fin_code: '',
      code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const kycLevelCategoryListReducer = (
  state: IKYCLevelCategoryListState = initialKYCLevelCategoryListState,
  action: ActionWithPayload<IKYCLevelCategoryListState>
) => {
  switch (action.type) {
    case actionTypes.GET_KYC_LEVEL_CATEGORY_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_KYC_LEVEL_CATEGORY_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_KYC_LEVEL_CATEGORY_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IOccupationListState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialOccupationListState: IOccupationListState = {
  data: [
    {
      id: '',
      title: '',
      fin_code: '',
      code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const occupationListReducer = (
  state: IOccupationListState = initialOccupationListState,
  action: ActionWithPayload<IOccupationListState>
) => {
  switch (action.type) {
    case actionTypes.GET_OCCUPATION_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_OCCUPATION_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_OCCUPATION_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IAdditionalOccupationsListState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialAdditionalOccupationsListState: IAdditionalOccupationsListState = {
  data: [
    {
      id: '',
      title: '',
      fin_code: '',
      code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const additionalOccupationsListReducer = (
  state: IAdditionalOccupationsListState = initialAdditionalOccupationsListState,
  action: ActionWithPayload<IAdditionalOccupationsListState>
) => {
  switch (action.type) {
    case actionTypes.GET_ADDITIONAL_OCCUPATIONS_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_ADDITIONAL_OCCUPATIONS_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_ADDITIONAL_OCCUPATIONS_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IQualificationsListState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialQualificationsListState: IQualificationsListState = {
  data: [
    {
      id: '',
      title: '',
      fin_code: '',
      code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const qualificationsListReducer = (
  state: IQualificationsListState = initialQualificationsListState,
  action: ActionWithPayload<IQualificationsListState>
) => {
  switch (action.type) {
    case actionTypes.GET_QUALIFICATIONS_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_QUALIFICATIONS_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_QUALIFICATIONS_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IAccountPurposeState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialAccountPurposeState: IAccountPurposeState = {
  data: [
    {
      id: '',
      title: '',
      fin_code: '',
      code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const accountPurposeReducer = (
  state: IAccountPurposeState = initialAccountPurposeState,
  action: ActionWithPayload<IAccountPurposeState>
) => {
  switch (action.type) {
    case actionTypes.GET_ACCOUNT_PURPOSES_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_ACCOUNT_PURPOSES_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_ACCOUNT_PURPOSES_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IIndividualUniqueIdsState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialIndividualUniqueIdsState: IIndividualUniqueIdsState = {
  data: [
    {
      id: '',
      title: '',
      fin_code: '',
      code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const individualUniqueIdsReducer = (
  state: IIndividualUniqueIdsState = initialIndividualUniqueIdsState,
  action: ActionWithPayload<IIndividualUniqueIdsState>
) => {
  switch (action.type) {
    case actionTypes.GET_INDIVIDUAL_UNIQUE_ID_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_INDIVIDUAL_UNIQUE_ID_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_INDIVIDUAL_UNIQUE_ID_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IMaritalStatusState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialMaritalStatusState: IMaritalStatusState = {
  data: [
    {
      id: '',
      title: '',
      fin_code: '',
      code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const maritalStatusReducer = (
  state: IMaritalStatusState = initialMaritalStatusState,
  action: ActionWithPayload<IMaritalStatusState>
) => {
  switch (action.type) {
    case actionTypes.GET_MARITAL_STATUS_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_MARITAL_STATUS_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_MARITAL_STATUS_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IAccountCategoriesState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialAccountCategoriesState: IAccountCategoriesState = {
  data: [
    {
      id: '',
      title: '',
      fin_code: '',
      code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const accountCategoriesReducer = (
  state: IAccountCategoriesState = initialAccountCategoriesState,
  action: ActionWithPayload<IAccountCategoriesState>
) => {
  switch (action.type) {
    case actionTypes.GET_ACCOUNT_CATEGORIES_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_ACCOUNT_CATEGORIES_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_ACCOUNT_CATEGORIES_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IAllLinkedEntitiesRelationshipState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialAllLinkedEntitiesRelationshipState: IAllLinkedEntitiesRelationshipState = {
  data: [
    {
      id: '',
      title: '',
      fin_code: '',
      code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const linkedEntitiesRelationshipReducer = (
  state: IAllLinkedEntitiesRelationshipState = initialAllLinkedEntitiesRelationshipState,
  action: ActionWithPayload<IAllLinkedEntitiesRelationshipState>
) => {
  switch (action.type) {
    case actionTypes.GET_LINKED_ENTITIES_RELATIONSHIP_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_LINKED_ENTITIES_RELATIONSHIP_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_LINKED_ENTITIES_RELATIONSHIP_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface ICorporateRegistrationsState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialCorporateRegistrationsState: ICorporateRegistrationsState = {
  data: [
    {
      id: '',
      title: '',
      fin_code: '',
      code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const corporateRegistrationReducer = (
  state: ICorporateRegistrationsState = initialCorporateRegistrationsState,
  action: ActionWithPayload<ICorporateRegistrationsState>
) => {
  switch (action.type) {
    case actionTypes.GET_CORPORATE_REGISTRATIONS_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_CORPORATE_REGISTRATIONS_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_CORPORATE_REGISTRATIONS_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface ICountriesTypesState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialCountriesTypesState: ICountriesTypesState = {
  data: [
    {
      id: '',
      title: '',
      title_np: '',
      country_code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const countriesTypesReducer = (
  state: ICountriesTypesState = initialCountriesTypesState,
  action: ActionWithPayload<ICountriesTypesState>
) => {
  switch (action.type) {
    case actionTypes.GET_COUNTRIES_LISTS_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_COUNTRIES_LISTS_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_COUNTRIES_LISTS_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IgetNationalitiesTypesState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialNationalitiesTypesState: IgetNationalitiesTypesState = {
  data: [
    {
      id: '',
      title: '',
      code: '',
      fin_code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const nationalitiesTypesReducer = (
  state: IgetNationalitiesTypesState = initialNationalitiesTypesState,
  action: ActionWithPayload<IgetNationalitiesTypesState>
) => {
  switch (action.type) {
    case actionTypes.GET_NATIONALITIES_LISTS_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_NATIONALITIES_LISTS_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_NATIONALITIES_LISTS_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IgetCurrencyTypesState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialCurrencyTypesState: IgetCurrencyTypesState = {
  data: [
    {
      id: '',
      title: '',
      currency_code: '',
      cbs_code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const currencyTypesReducer = (
  state: IgetCurrencyTypesState = initialCurrencyTypesState,
  action: ActionWithPayload<IgetCurrencyTypesState>
) => {
  switch (action.type) {
    case actionTypes.GET_CURRENCY_LISTS_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_CURRENCY_LISTS_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_CURRENCY_LISTS_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IKYCRequestTypesListsState {
  data: MasterDataModel[]
  error: boolean
  loading: boolean
}

const initialkycRequestTypesListsState: IKYCRequestTypesListsState = {
  data: [
    {
      id: '',
      title: '',
      code: '',
      fin_code: '',
      status: false,
    },
  ],
  loading: true,
  error: false,
}

export const kycRequestTypesListsReducer = (
  state: IKYCRequestTypesListsState = initialkycRequestTypesListsState,
  action: ActionWithPayload<IKYCRequestTypesListsState>
) => {
  switch (action.type) {
    case actionTypes.GET_KYC_REQUEST_TYPES_LISTS_START:
      return {...state, loading: true, error: false}
    case actionTypes.GET_KYC_REQUEST_TYPES_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload}
    case actionTypes.GET_KYC_REQUEST_TYPES_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}
