import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'
import {IBulkAccountOpenedList, IBulkAccountOpeningList, IFailedBulkAccountList} from './reducer'

export const actions = {
  getBulkAccountOpeningList: (params: ParamsModel | undefined = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_BULK_ACCOUNT_LIST_START,
    payload: {params},
  }),
  getBulkAccountOpeningListSuccess: (data: IBulkAccountOpeningList) => ({
    type: actionTypes.GET_BULK_ACCOUNT_LIST_SUCCESS,
    payload: data,
  }),
  getBulkAccountOpeningListFinish: () => ({
    type: actionTypes.GET_BULK_ACCOUNT_LIST_FINISH,
  }),

  getBulkAccountOpenedList: (params: ParamsModel | undefined = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_BULK_ACCOUNT_OPENED_LIST_START,
    payload: {params},
  }),
  getBulkAccountOpenedListSuccess: (data: IBulkAccountOpenedList) => ({
    type: actionTypes.GET_BULK_ACCOUNT_OPENED_LIST_SUCCESS,
    payload: data,
  }),
  getBulkAccountOpenedListFinish: () => ({
    type: actionTypes.GET_BULK_ACCOUNT_OPENED_LIST_FINISH,
  }),

  getFailedMasterBatchList: () => ({
    type: actionTypes.GET_FAILED_MASTER_BATCH_LIST_START,
  }),
  getFailedMasterBatchListSuccess: (data: IFailedBulkAccountList) => ({
    type: actionTypes.GET_FAILED_MASTER_BATCH_LIST_SUCCESS,
    payload: data,
  }),
  getFailedMasterBatchListFinish: () => ({
    type: actionTypes.GET_FAILED_MASTER_BATCH_LIST_FINISH,
  }),
}
