import axios from 'axios'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL
const CBS_DATA_API_BY_CIF = `${API_URL}/newcif-newaccount/search-customer`

type CustomerInfoByCIF = {
  father_name: string
  full_name: string
  unique_id_number: string
}

const postToGetCustomerInfo = async (payload: CustomerInfoByCIF) => {
  try {
    const response = await axios.post(CBS_DATA_API_BY_CIF, payload)
    return response.data
  } catch (error: any) {
    throw new Error(`${error.response?.data?.message || error.message}`)
  }
}

const usePostToSearchCustomer = () => {
  return useMutation(postToGetCustomerInfo, {
    onError: (error: Error) => {
      toast.error(`Error: ${error.message || 'An error occurred'}`)
    },
  })
}

export default usePostToSearchCustomer
