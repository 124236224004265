import React, {useState} from 'react'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {toast} from 'react-toastify'
import {Modal, Button} from 'rsuite'
import InfoCell from './component/InfoCell'
import usePostAccountPreliminary from 'src/app/queries/Account Preliminary/post/usePostAccountPreliminary'
import DocumentsPreview from 'src/app/modules/common/components/cpuMaker/IndividualJointMinor/DocumentsPreview'
import AccountStatusLog from 'src/app/modules/common/AccountStatusLog'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'

interface RouteParams {
  id: string
}

const validationSchema = Yup.object().shape({
  status: Yup.string().required('Status Type is required'),
  remarks: Yup.string().required('Remarks is required'),
})

const allStatusType = [
  {label: 'APPROVE', value: 'approve'},
  {label: 'REJECT', value: 'reject'},
  {label: 'REVERT BACK TO BRANCH MAKER', value: 'revert'},
]

const BranchCheckerReviewPage = () => {
  const {id} = useParams<RouteParams>()
  const history = useHistory()
  const {state: data}: {state: any} = useLocation()
  const {
    mutate: postResponse,
    data: respData,
    isLoading: postRespLoading,
  } = usePostAccountPreliminary()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const createFullName = (firstName: string, middleName: string, lastName: string) => {
    const fn = firstName || ''
    const mn = middleName || ''
    const ln = lastName || ''
    return `${fn} ${mn} ${ln}`.trim()
  }

  const getFieldValue = (field: any, key: string) => {
    return field ? field[key] : ''
  }

  const handleClose = (forceClose = false) => {
    if (forceClose) {
      setIsModalVisible(false)
      history.push('/branch-checker/new-cif-and-account-list')
    }
  }

  return (
    <div className='modal-container '>
      <div className='heading__component__title fw-bolder mb-5' data-cy='dynamic-setting-heading'>
        {'New CIF and Account Review'}
      </div>
      <div className='row mb-6'>
        <div className='col-md-6 mb-2'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-9 '>
              <DocumentsPreview data={data} />
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='card'>
            <div className='card-body' style={{maxHeight: '640px', overflowY: 'auto'}}>
              <div className='row'>
                <InfoCell label='Account Type' value={data?.accountType} />
                <InfoCell
                  label='Full Name'
                  value={createFullName(data?.first_name, data?.middle_name, data?.last_name)}
                />
              </div>
              <div className='row'>
                <InfoCell label='Email' value={data?.email} />
                <InfoCell label='Mobile Number' value={data?.mobile_number} />
              </div>
              <div className='row'>
                <InfoCell label='House No' value={data?.house_number ?? ''} />
                <InfoCell label='Street Name/Tole' value={data?.tole} />
              </div>
              <div className='row'>
                <InfoCell label='ID Type' value={getFieldValue(data?.id_type_id, 'title')} />
                <InfoCell label='ID Number' value={data?.id_number} />
              </div>
              <div className='row'>
                <InfoCell
                  label='Customer Category'
                  value={getFieldValue(data?.customerCategoryId, 'title')}
                />
                <InfoCell
                  label='Customer Classification'
                  value={getFieldValue(data?.customerClassificationId, 'title')}
                />
              </div>
              <div className='row'>
                <InfoCell label='Country' value={getFieldValue(data?.country_id, 'title')} />
                <InfoCell label='Nationality' value={getFieldValue(data?.nationality, 'title')} />
              </div>
              <div className='row'>
                <InfoCell label='Language' value={data?.language} />
                <InfoCell
                  label='Location(Branch Location Code)'
                  value={data?.branch_location_code}
                />
              </div>
              <div className='row'>
                <InfoCell
                  label='Tax Category'
                  value={getFieldValue(data?.taxCategoryId, 'title')}
                />
                <InfoCell label='Tax Group' value={getFieldValue(data?.taxGroupId, 'cbs_code')} />
              </div>
              <div className='row'>
                <InfoCell label='AML Risk Rating' value={data?.aml_risk_rating} />
                <InfoCell label='Cheque Book Name' value={data?.cheque_book_name} />
              </div>
              <div className='row'>
                <InfoCell
                  label='Account Class'
                  value={getFieldValue(data?.accountClassId, 'name')}
                />
                <InfoCell
                  label='Account Currency'
                  value={getFieldValue(data?.account_currency_id, 'title')}
                />
              </div>
              <div className='row'>
                <InfoCell label='Home Branch(Location Code)' value={data?.branch_location_code} />
                <InfoCell label='Location' value={data?.location} />
              </div>
              <div className='row'>
                <InfoCell label='PEP Confirmation' value={data?.pep_confirmation} />
                <InfoCell label='Exposure' value={getFieldValue(data?.exposureId, 'title')} />
              </div>
              <div className='row'>
                <InfoCell label='Media' value={data?.media} />
                <InfoCell label='Institutional' value={data?.institutional} />
              </div>
              <Formik
                initialValues={{status: '', remarks: ''}}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  postResponse(
                    {id, data: values},
                    {
                      onSuccess: (data) => {
                        if (values.status === 'approve') {
                          setIsModalVisible(true)
                        } else {
                          history.push('/branch-checker/new-cif-and-account-list')
                        }
                      },
                    }
                  )
                }}
              >
                {({handleChange, errors, touched, values, setFieldValue}) => (
                  <Form>
                    <div className='row '>
                      <div className='col-md-6'>
                        <FormSelect
                          labelClassName='col-md-12'
                          containerClassName='col-md-12'
                          label='Status Type'
                          name='status'
                          isClearable={true}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          options={allStatusType.map((item) => ({
                            label: item.label,
                            value: item.value,
                          }))}
                          required={true}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                      <div className='border my-2 mb-6'></div>
                      <FormTextBox
                        containerClassName='col-md-12'
                        labelClassName='col-md-12'
                        type='text'
                        placeholder='Remarks'
                        label='Remarks'
                        name='remarks'
                        disabled={false}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required={true}
                        data-cy='add-id-number-text-field'
                        as='textarea'
                      />
                      <div className='d-flex justify-content-end mt-3'>
                        <button
                          type='button'
                          onClick={() => history.goBack()}
                          className='btn btn-secondary mx-4'
                        >
                          Back
                        </button>
                        <button
                          disabled={postRespLoading}
                          type='submit'
                          className='btn btn-primary'
                        >
                          {postRespLoading ? 'Sending...' : 'Send'}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <AccountStatusLog data={data} />
      <Modal
        open={isModalVisible}
        onClose={() => {
          handleClose(false)
          history.push('/branch-checker/new-cif-and-account-list')
        }}
      >
        <div style={{border: '1px solid #ced4da', borderRadius: '4px', padding: '14px'}}>
          <Modal.Header className='text-center' style={{position: 'relative'}}>
            <Modal.Title>Success</Modal.Title>
            <button type='button' style={{display: 'none'}} onClick={() => handleClose(true)}>
              <span>&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>The opened Account Number and CIF Number are mentioned below.</p>
              <div className='d-flex justify-content-between'>
                <p>
                  Account Number:{' '}
                  <strong>{respData?.data?.data?.registration_detail?.account_number}</strong>
                </p>
                <p>
                  CIF Number:{' '}
                  <strong>{respData?.data?.data?.registration_detail?.cif_number}</strong>
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-around w-100'>
              <Button
                className='dt-btn dt-btn-outline-primary dt-btn-sm'
                onClick={() => console.log('Send Email')}
              >
                Send Email
              </Button>
              <Button
                className='dt-btn dt-btn-outline-primary dt-btn-sm'
                onClick={() => console.log('Send SMS')}
              >
                Send SMS
              </Button>
              <Button
                className='dt-btn dt-btn-outline-primary dt-btn-sm'
                onClick={() => {
                  handleClose(true)
                }}
              >
                Done
              </Button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  )
}

export default BranchCheckerReviewPage
