import React from 'react'
import DetailCell from '../DetailCell'

interface CustomerInformationSectionProps {
  data?: any
}

const CustomerInformationSection = ({data}: CustomerInformationSectionProps) => {
  return (
    <>
      <div className='row'>
        <DetailCell label='Customer Number' value={data?.customer_code} />
        <DetailCell label='Account No.' value={data?.registration_detail?.account_number} />
      </div>
      <div className='row'>
        <DetailCell label='Account Class' value={data?.accountClassId?.name} />
        <DetailCell label='Branch' value={data?.kyc_information?.branch} />
      </div>
      <div className='row'>
        <DetailCell label='Date' value={data?.kyc_information?.date} />
        <DetailCell label='Customer Category' value={data?.customerCategoryId?.title} />
      </div>
      <div className='row'>
        <DetailCell label='Tax Category' value={data?.taxCategoryId?.title} />
        <DetailCell label='Tax Group' value={data?.taxGroupId?.cbs_code} />
      </div>
      <div className='row'>
        <DetailCell label='Institutional' value={data?.institutional} />
        <DetailCell
          label='Account Category'
          value={data?.kyc_information?.account_category?.title}
        />
      </div>
      <div className='row'>
        <DetailCell
          label='Other Account Category'
          value={data?.kyc_information?.account_category_other}
        />
        <DetailCell
          label='Account Currency'
          value={data?.kyc_information?.account_currency?.title}
        />
      </div>
      <div className='row'>
        <DetailCell label='Account Purpose' value={data?.kyc_information?.account_purpose?.title} />
        <DetailCell
          label='Other Account Purpose'
          value={data?.kyc_information?.account_purpose_other}
        />
      </div>
      <div className='row'>
        <DetailCell label='Full Nmae' value={data?.full_name} />
        <DetailCell label='Short Nmae' value={data?.short_name} />
      </div>
      <div className='row'>
        <DetailCell
          label='KYC Level Category'
          value={data?.kyc_information?.kyc_level_category?.title}
        />
        <DetailCell label='PEP Confirmation' value={data?.pep_confirmation} />
      </div>
      <div className='row'>
        <DetailCell label='Customer Classification' value={data?.customerClassificationId?.title} />
      </div>
    </>
  )
}

export default CustomerInformationSection
