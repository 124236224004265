import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as placeReducer from 'src/app/modules/common'
import {RootState} from 'src/setup'
import {MasterDataModel} from 'src/app/modules/masterData/Model'
import {handleUppercaseChange} from 'src/app/modules/CreateNewCIFAndCustomerAccount/createNewAccount/components'

interface CorrespondenceAddressSectionProps {
  handleChange: any
  touched: any
  errors: any
  setFieldValue: any
  values: any
  initialValues: any
  data: any
}

const CorrespondenceAddressSection = ({
  handleChange,
  touched,
  errors,
  setFieldValue,
  values,
  initialValues,
  data,
}: CorrespondenceAddressSectionProps) => {
  const dispatch = useDispatch()

  const [districtList, setDistrictList] = useState<{[key: string]: string | number}[]>()
  const [municipalityList, setMunicipalityList] = useState<{[key: string]: string | number}[]>()

  const {district, province, municipality, loading} = useSelector((state: RootState) => state.place)
  const {data: countries}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.countriesList
  )

  useEffect(() => {
    if (values.temporary_province) {
      const districts = district?.filter((d) => {
        return d.province_id === values.temporary_province
      })
      setDistrictList(districts)
    } else {
      setDistrictList(district)
    }
  }, [values.temporary_province, district])

  // Resetting Municipality on District change and both on province change
  useEffect(() => {
    if (
      values.temporary_district &&
      initialValues?.temporary_district !== values?.temporary_district
    ) {
      setFieldValue('temporary_district', '')
    }
  }, [values.temporary_province])
  useEffect(() => {
    if (
      values.temporary_municipality &&
      initialValues?.temporary_municipality !== values?.temporary_municipality
    ) {
      setFieldValue('temporary_municipality', '')
    }
  }, [values.temporary_district])

  useEffect(() => {
    if (values.temporary_district) {
      const municipalities = municipality?.filter((m) => {
        return m.district_id === values.temporary_district
      })
      setMunicipalityList(municipalities)
    } else {
      setMunicipalityList(municipality)
    }
  }, [values.temporary_district, municipality])

  useEffect(() => {
    dispatch(placeReducer.action.getProvince())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getDistrict())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getMunicipality())
  }, [])
  return (
    <>
      <p className='fs-2'>Correspondance Address</p>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>AddrLine1</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_AddrLine1 ?? ''}</strong>
        </div>
        <div className='col-4'></div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>AddrLine2</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_AddrLine2 ?? '-'}</strong>
        </div>
        <div className='col-4'></div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>AddrLine3</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_AddrLine3 ?? '-'}</strong>
        </div>
        <div className='col-4'></div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>House Number</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_house_number ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            name='temporary_house_number'
            label=' '
            containerClassName='col-12'
            onChange={handleChange}
            errors={errors}
            touched={touched}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Street Name/Tole</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.temporary_tole ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            name='temporary_tole'
            label=' '
            containerClassName='col-12'
            onChange={handleUppercaseChange(handleChange)}
            errors={errors}
            touched={touched}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Ward Number</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.temporary_ward_number ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            name='temporary_ward_number'
            label=' '
            containerClassName='col-12'
            onChange={handleChange}
            errors={errors}
            touched={touched}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Country</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.temporary_country?.title ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormSelect
            name='temporary_country'
            label=' '
            containerClassName='col-12'
            labelClassName='col-12'
            options={countries?.map((country) => ({label: country.title, value: country.id})) ?? []}
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Province</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.temporary_province?.title ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormSelect
            name='temporary_province'
            label=' '
            containerClassName='col-12'
            labelClassName='col-12'
            options={province?.map((provinceData) => ({
              label:
                typeof provinceData.title === 'string'
                  ? provinceData.title
                  : provinceData.title.toString(),
              value:
                typeof provinceData.id === 'string' ? provinceData.id : provinceData.id.toString(),
            }))}
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>District</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.temporary_district?.title ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormSelect
            name='temporary_district'
            label=' '
            containerClassName='col-12'
            labelClassName='col-12'
            options={districtList?.map((districtData) => ({
              label:
                typeof districtData.title === 'string'
                  ? districtData.title
                  : districtData.title.toString(),
              value:
                typeof districtData.id === 'string' ? districtData.id : districtData.id.toString(),
            }))}
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Municipality</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.temporary_municipality?.title ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormSelect
            name='temporary_municipality'
            label=' '
            containerClassName='col-12'
            labelClassName='col-12'
            options={municipalityList?.map((municipalityData) => ({
              label:
                typeof municipalityData.title === 'string'
                  ? municipalityData.title
                  : municipalityData.title.toString(),
              value:
                typeof municipalityData.id === 'string'
                  ? municipalityData.id
                  : municipalityData.id.toString(),
            }))}
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
          />
        </div>
      </div>
    </>
  )
}

export default CorrespondenceAddressSection
