import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {MasterDataModel} from 'src/app/modules/masterData/Model'
import * as customerClassificationListRedux from 'src/app/modules/masterData/redux'
import {RootState} from 'src/setup'

interface CustomerProfileUpdateSectionProps {
  data: any
  cbsData: any
}

const CustomerProfileUpdateSection = ({data, cbsData}: CustomerProfileUpdateSectionProps) => {
  const dispatch = useDispatch()

  const customerClassificationListData = useSelector(
    (state: any) => state?.customerClassificationList
  )
  const {data: customerCategories}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.customerCategoryList
  )
  const {data: kycLevelCategories}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.kycLevelCategoryList
  )
  const {data: incomeSources}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.incomeSourceList
  )
  const {data: employmentStatusList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.employmentStatusList
  )
  const {data: occupations}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.occupationList
  )
  const {data: additionalOccupations}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.additionalOccupationsList
  )

  useEffect(() => {
    dispatch(customerClassificationListRedux.actions.getCustomerClassification())
  }, [])
  return (
    <>
      <p className='fs-2 fw-bold'>Customer Profile Update</p>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Customer Category</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.customer_category?.title ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Customer Classification</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.customer_classification?.title ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>AML Risk Rating</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.aml_risk_rating ?? ''}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>KYC Level Category</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.kyc_level_category?.title ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Pep Confirmation</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.pep_confirmation ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Occupation</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.occupation?.title ?? ''}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Source of Income</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.source_of_income?.title ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Employment Status</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.employment_status?.title ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Approx Yearly Remuneration</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.yearly_remuneration}</strong>
        </div>
      </div>
    </>
  )
}

export default CustomerProfileUpdateSection
