import {Form, Formik} from 'formik'
import {IoMdRefresh} from 'react-icons/io'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import * as cpuMakerReducer from 'src/app/modules/cpuMaker/newCIFAndAccountList/redux'
import {MasterDataModel} from 'src/app/modules/masterData/Model'
import {usePostCorporateKYCAdditionalForm} from 'src/app/queries/corporateAccountPreliminary/post/usePostCorporateKYCAdditionalForm'
import {RootState} from 'src/setup'
import * as Yup from 'yup'
import AccountStatusLog from '../../../AccountStatusLog'
import {
  AccountInformationSection,
  CorrespondenceAddressSection,
  CustomerInformationSection,
  IncorporationDetailsSection,
  OtherDetailsSection,
  ProprietaryInformationSection,
  RegistrationAddressSection,
} from './review-form-field-sections'
import {cpuMakerCorporateBankSchema} from './validationSchema'
import {usePostCorporateKYCForm} from 'src/app/queries/corporateAccountPreliminary/post/usePostCorporateKYCForm'
import moment from 'moment'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {useHistory} from 'react-router-dom'

interface ReviewFormProps {
  data?: any
}

const ReviewForm = ({data}: ReviewFormProps) => {
  const disptach = useDispatch()
  const history = useHistory()

  const {
    user: {
      data: {branch},
    },
  } = useSelector((state: any) => state.auth)
  const {data: accountCategories}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.accountCategoriesList
  )
  const {data: accountPurposes}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.accountPurposeList
  )
  const {data: incomeSources}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.incomeSourceList
  )
  const {data: countries}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.countriesList
  )
  const {municipality} = useSelector((state: RootState) => state.place)

  const {mutateAsync: postCorporateFormAsync, isLoading: isKYCLoading} = usePostCorporateKYCForm()
  const {mutateAsync: postCorporateAdditionalFormAsync, isLoading: isKYCAdditionalLoading} =
    usePostCorporateKYCAdditionalForm()

  const additionalValidationSchema = Yup.object().shape({
    account_category_other: Yup.string().when('account_category', {
      is: (value: string) =>
        value ===
        accountCategories.find((accountCategory) => accountCategory.title === 'Others')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    account_purpose_other: Yup.string().when('account_purpose', {
      is: (value: string) =>
        value === accountPurposes.find((accountPurpose) => accountPurpose.title === 'Others')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    source_of_income_other: Yup.string().when('account_purpose', {
      is: (value: string) =>
        value === incomeSources.find((incomeSource) => incomeSource.title === 'Others')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),

    // Address Information
    correspondence_country: Yup.string().required('Required'),
    correspondence_house_number: Yup.number().nullable(),
    correspondence_tole: Yup.string().when('correspondence_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string()
        .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed')
        .required('Required'),
      otherwise: Yup.string(),
    }),
    correspondence_ward_number: Yup.number().when('correspondence_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.number().typeError('Ward Number must be in numbers').required('Required'),
      otherwise: Yup.number(),
    }),
    correspondence_province: Yup.string().when('correspondence_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    correspondence_district: Yup.string().when('correspondence_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    correspondence_municipality: Yup.string().when('correspondence_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    correspondence_municipality_other: Yup.string().when('correspondence_municipality', {
      is: (value: string) => value === municipality?.find((m) => m?.title === 'Others')?.id,
      then: Yup.string()
        .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed')
        .required('Required'),
      otherwise: Yup.string(),
    }),
    correspondence_outside_nepal_address_line_1: Yup.string().when('correspondence_country', {
      is: (value: string) => value !== countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    correspondence_outside_nepal_address_line_2: Yup.string().when('correspondence_country', {
      is: (value: string) => value !== countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    correspondence_outside_nepal_address_line_3: Yup.string(),

    country: Yup.string().required('Required'),
    house_number: Yup.number().nullable(),
    tole: Yup.string()
      .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed')
      .when('country', {
        is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
        then: Yup.string()
          .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed')
          .required('Required'),
        otherwise: Yup.string(),
      }),
    ward_number: Yup.number()
      .typeError('Ward Number must be in numbers')
      .when('country', {
        is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
        then: Yup.number().typeError('Ward Number must be in numbers').required('Required'),
        otherwise: Yup.number(),
      }),
    province: Yup.string().when('country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    district: Yup.string().when('country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    municipality: Yup.string().when('country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    municipality_other: Yup.string().when('municipality', {
      is: (value: string) => value === municipality?.find((m) => m?.title === 'Others')?.id,
      then: Yup.string()
        .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed')
        .required('Required'),
      otherwise: Yup.string(),
    }),
    permanent_outside_nepal_address_line_1: Yup.string().when('country', {
      is: (value: string) => value !== countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    permanent_outside_nepal_address_line_2: Yup.string().when('country', {
      is: (value: string) => value !== countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    permanent_outside_nepal_address_line_3: Yup.string(),
  })
  return (
    <>
      <Formik
        initialValues={{
          action: '',
          customer_number: data?.customer_code ?? '',
          account_number: data?.registration_detail?.account_number ?? '',
          account_class: data?.accountClassId?.account_class ?? '',
          full_name: data?.full_name ?? '',
          short_name: data?.short_name ?? '',
          branch: branch?.code ?? '',
          date: data?.kyc_information?.date ?? moment().format('YYYY-MM-DD'),
          account_category: data?.kyc_information?.account_category?.id ?? '',
          account_category_other: data?.kyc_information?.account_category_other ?? '',
          account_currency: data?.account_currency_id?.cbs_code ?? '',
          account_purpose: data?.kyc_information?.account_purpose?.id ?? '',
          account_purpose_other: data?.kyc_information?.account_purpose_other ?? '',
          pep_confirmation: data?.pep_confirmation ?? '',
          customer_category: data?.customerCategoryId?.code ?? '',
          customer_classification: data?.customerClassificationId?.id ?? '',
          tax_category: data?.taxCategoryId?.cbs_code ?? '',
          tax_group: data?.taxGroupId?.cbs_code ?? '',
          registration_number: data?.kyc_information?.registration_number ?? '',
          registration_date_bs: data?.kyc_information?.registration_date_bs ?? '',
          registration_date_ad: data?.kyc_information?.registration_date_ad ?? '',
          registration_expiry_date_bs: data?.kyc_information?.registration_expiry_date_bs ?? '',
          registration_expiry_date_ad: data?.kyc_information?.registration_expiry_date_ad ?? '',
          registered_with: data?.kyc_information?.registered_with?.id ?? '',
          registered_with_other: data?.kyc_information?.registered_with_other ?? '',
          issuing_place: data?.kyc_information?.issuing_place?.id ?? '',
          pan_number: data?.kyc_information?.pan_number ?? '',
          pan_registration_date_bs: data?.kyc_information?.pan_registration_date_bs ?? '',
          pan_registration_date_ad: data?.kyc_information?.pan_registration_date_ad ?? '',
          description_of_business: data?.kyc_information?.description_of_business ?? '',
          authorized_capital: data?.kyc_information?.authorized_capital ?? '',
          paid_up_capital: data?.kyc_information?.paid_up_capital ?? '',
          expected_source_of_fund: data?.kyc_information?.expected_source_of_fund ?? '',
          expected_annual_business_turnover:
            data?.kyc_information?.expected_annual_business_turnover ?? '',
          house_number: data?.kyc_information?.house_number ?? '',
          cheque_book_name: data?.cheque_book_name ?? '',
          correspondence_house_number: data?.house_number ?? '',
          correspondence_tole: data?.tole ?? '',
          correspondence_ward_number: data?.kyc_information?.correspondence_ward_number ?? '',
          correspondence_country:
            data?.kyc_information?.correspondence_country?.id ??
            countries.find((country) => country.title === 'Nepal')?.id ??
            '',
          correspondence_province: data?.kyc_information?.correspondence_province?.id ?? '',
          correspondence_district: data?.kyc_information?.correspondence_district?.id ?? '',
          correspondence_municipality: data?.kyc_information?.correspondence_municipality?.id ?? '',
          correspondence_municipality_other:
            data?.kyc_information?.correspondence_municipality_other ?? '',
          correspondence_mobile_number:
            data?.kyc_information?.correspondence_mobile_number ?? data?.mobile_number ?? '',
          correspondence_phone_number: data?.kyc_information?.correspondence_phone_number ?? '',
          correspondence_email: data?.kyc_information?.correspondence_email ?? data?.email ?? '',
          tole: data?.kyc_information?.tole ?? '',
          ward_number: data?.kyc_information?.ward_number ?? '',
          country:
            data?.kyc_information?.country_id?.title ??
            countries.find((country) => country.title === 'Nepal')?.id ??
            '',
          province: data?.kyc_information?.province?.id ?? '',
          district: data?.kyc_information?.district?.id ?? '',
          municipality: data?.kyc_information?.municipality?.id ?? '',
          municipality_other: data?.kyc_information?.municipality_other ?? '',
          mobile_number: data?.mobile_number ?? '',
          phone_number: data?.kyc_information?.phone_number ?? '',
          email: data?.kyc_information?.email ?? '',
          industry_business_type: data?.kyc_information?.industry_business_type ?? '',
          source_of_income: data?.kyc_information?.source_of_income?.id ?? '',
          source_of_income_other: data?.kyc_information?.source_of_income_other ?? '',
          anticipated_annual_transaction:
            data?.kyc_information?.anticipated_annual_transaction ?? 'Upto 1 Mil',
          introducer_name: data?.kyc_information?.introducer_name ?? '',
          kyc_review_date: data?.kyc_information?.kyc_review_date ?? '',
          proof_address_document: data?.kyc_information?.proof_address_document ?? '',
          remarks: data?.kyc_information?.remarks ?? '',
          kyc_level_category: data?.kyc_information?.kyc_level_category?.id ?? '',
          annual_income: data?.annual_income ?? '',
          institutional: data?.institutional ?? '',
          cif_number: data?.registration_detail?.cif_number,
          correspondence_outside_nepal_address_line_1:
            data?.correspondence_outside_nepal_address_line_1 ?? '',
          correspondence_outside_nepal_address_line_2:
            data?.correspondence_outside_nepal_address_line_2 ?? '',
          correspondence_outside_nepal_address_line_3:
            data?.correspondence_outside_nepal_address_line_3 ?? '',
          permanent_outside_nepal_address_line_1:
            data?.permanent_outside_nepal_address_line_1 ?? '',
          permanent_outside_nepal_address_line_2:
            data?.permanent_outside_nepal_address_line_2 ?? '',
          permanent_outside_nepal_address_line_3:
            data?.permanent_outside_nepal_address_line_3 ?? '',
          children: [{name_of_children: '', relation: ''}],
          directors:
            data?.directors?.directors?.length > 0
              ? data?.directors?.directors
              : [
                  {
                    title_of_director: '',
                    detail_of_director: '',
                  },
                ],
          students: [{name_of_institute: '', student_address: '', phone_no: ''}],
        }}
        onSubmit={(values: any) => {
          postCorporateFormAsync(
            {
              id: data?.id,
              payload: {
                ...values,
                action: 'save_and_continue',
              },
            },
            {
              onSuccess: (res) => {
                console.log('success', res)
                if (res?.data?.resCod === '200') {
                  toast.success(res?.data?.resDesc ?? 'Saved and Forwarded to CPU Checker')
                  postCorporateAdditionalFormAsync({
                    id: data?.id,
                    payload: {directors: values.directors},
                  }).then(() => history.push('/cpu-maker/new-cif-and-account-list'))
                }
              },
            }
          )
        }}
        validationSchema={cpuMakerCorporateBankSchema.concat(additionalValidationSchema)}
      >
        {({
          touched,
          setFieldValue,
          errors,
          handleChange,
          handleBlur,
          values,
          validateField,
          handleSubmit,
          setFieldTouched,
          getFieldMeta,
          resetForm,
          initialValues,
        }) => {
          const handleSaveOnly = (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            postCorporateFormAsync(
              {
                id: data?.id,
                payload: {
                  ...values,
                  action: 'save',
                },
              },
              {
                onSuccess: (res) => {
                  if (res?.data?.resCod === '200') {
                    toast.success(res?.data?.resDesc ?? 'Form Saved Successfully')
                    postCorporateAdditionalFormAsync({
                      id: data?.id,
                      payload: {directors: values.directors},
                    }).then(() => {
                      history.push('/cpu-maker/new-cif-and-account-list')
                    })
                  }
                },
              }
            )
          }
          const handleRepair = async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            await validateField('remarks')
            setFieldTouched('remarks', true)
            if (getFieldMeta('remarks').error || !getFieldMeta('remarks').touched) {
              return
            }
            postCorporateFormAsync({
              id: data?.id,
              payload: {
                ...values,
                action: 'repair',
              },
            }).then((res) => {
              if (res?.data?.resCod === '200') {
                toast.success(res?.data?.resDesc ?? 'Reverted to Branch Maker')
                history.push('/cpu-maker/new-cif-and-account-list')
              }
            })
          }

          return (
            <Form>
              <div className='row'>
                <CustomerInformationSection
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                />
                <AccountInformationSection
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                />
                <hr />
                <IncorporationDetailsSection
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                />
                <hr />
                <OtherDetailsSection
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                />
                <hr />
                <CorrespondenceAddressSection
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                  initialValues={initialValues}
                />
                <hr />
                <RegistrationAddressSection
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                  initialValues={initialValues}
                />
                <hr />
                <ProprietaryInformationSection
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                />
                <AccountStatusLog data={data} />
                <div className='col-12 my-12'>
                  <FormTextBox
                    as='textarea'
                    name='remarks'
                    label='Remarks'
                    labelClassName='col-12'
                    containerClassName='col-12'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    required
                  />
                </div>
                <div className='col-md-6 col-12 opacity-50'>
                  <div className='d-flex flex-column align-items-center justify-content-center text-center'>
                    <div
                      className='d-flex align-items-center justify-content-center mb-2'
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        resetForm()
                        toast.success('Form has been reest')
                      }}
                    >
                      <IoMdRefresh size={23} />
                      <span className='h4 ms-3 mb-n1'>Reset Form</span>
                    </div>
                    <span>Note: All the captured data will be cleared</span>
                  </div>
                </div>
                <div className='col-md-6 col-12 d-flex gap-1'>
                  <button
                    type='button'
                    onClick={handleRepair}
                    className='btn  btn-outline-warning'
                    style={{border: 1}}
                    disabled={isKYCLoading}
                  >
                    Repair
                  </button>
                  <button
                    type='button'
                    onClick={handleSaveOnly}
                    className='btn  btn-outline-warning'
                    style={{border: 1}}
                    disabled={isKYCLoading || isKYCAdditionalLoading}
                  >
                    Save
                  </button>
                  <button
                    type='submit'
                    className='btn btn-warning'
                    disabled={isKYCAdditionalLoading || isKYCLoading}
                  >
                    Save & Continue
                  </button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default ReviewForm
