import {Action} from 'redux'
import {
  AccountPreliminaryPayload,
  initialFormData,
  AccountPreliminaryDocumentPayload,
  initialDocumentData,
  CorporateAccountPayload,
  initialCorporateFormData,
} from '../Model'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface AccountPreliminaryState {
  data: AccountPreliminaryPayload
  error: boolean
  loading: boolean
}

const initialAccountPreliminaryState: AccountPreliminaryState = {
  data: initialFormData,
  error: false,
  loading: false,
}

export const reducer = (
  state: AccountPreliminaryState = initialAccountPreliminaryState,
  action: ActionWithPayload<AccountPreliminaryState>
) => {
  switch (action.type) {
    case actionTypes.SUBMIT_PRELIMINARY_FORM_START:
      return {...state, loading: true, error: false}
    case actionTypes.SUBMIT_PRELIMINARY_FORM_SUCCESS:
      return {...state, loading: false, data: action.payload, error: false}
    case actionTypes.SUBMIT_PRELIMINARY_FORM_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface AccountPreliminaryDocumentsState {
  data: AccountPreliminaryDocumentPayload
  error: boolean
  loading: boolean
}

const initialAccountPreliminaryDocumentsState: AccountPreliminaryDocumentsState = {
  data: initialDocumentData,
  error: false,
  loading: false,
}

export const documentsReducer = (
  state: AccountPreliminaryDocumentsState = initialAccountPreliminaryDocumentsState,
  action: ActionWithPayload<AccountPreliminaryDocumentsState>
) => {
  switch (action.type) {
    case actionTypes.SUBMIT_PRELIMINARY_DOCUMENTS_START:
      return {...state, loading: true, error: false}
    case actionTypes.SUBMIT_PRELIMINARY_DOCUMENTS_SUCCESS:
      return {...state, loading: false, data: action.payload, error: false}
    case actionTypes.SUBMIT_PRELIMINARY_DOCUMENTS_FINISH:
      return {...state, loading: false}
    case actionTypes.RESET_PRELIMINARY_DOCUMENTS_STATE:
      return {...state, data: initialDocumentData}
    default:
      return state
  }
}

export interface CorporateAccountPreliminaryState {
  data: CorporateAccountPayload
  error: boolean
  loading: boolean
}

const initialCorporateAccountPreliminaryState: CorporateAccountPreliminaryState = {
  data: initialCorporateFormData,
  error: false,
  loading: false,
}

export const corporateReducer = (
  state: CorporateAccountPreliminaryState = initialCorporateAccountPreliminaryState,
  action: ActionWithPayload<CorporateAccountPreliminaryState>
) => {
  switch (action.type) {
    case actionTypes.SUBMIT_CORPORATE_PRELIMINARY_FORM_START:
      return {...state, loading: true, error: false}
    case actionTypes.SUBMIT_CORPORATE_PRELIMINARY_FORM_SUCCESS:
      return {...state, loading: false, data: action.payload, error: false}
    case actionTypes.SUBMIT_CORPORATE_PRELIMINARY_FORM_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}
