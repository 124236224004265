import {Form, Formik} from 'formik'
import {useEffect, useState} from 'react'
import {FaDownload, FaSpinner} from 'react-icons/fa'
import {useDispatch, useSelector} from 'react-redux'
import {Modal, Pagination, Table} from 'rsuite'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {RootState} from 'src/setup'
import {APIStateParamsModel} from '../../apiSuccessList/Model'
import {APIParamsComponent} from '../../apiSuccessList/components'
import * as apiListRedux from '../../apiSuccessList/redux'
import * as bulkAccountRedux from '../../masterBatchList/redux'
import {
  usePostDownloadBatch,
  usePostUplaodExistingBulkAccounts,
} from 'src/app/queries/BulkAccountOpening/post'
import * as Yup from 'yup'

const Cell = Table.Cell

const downloadBatchSchema = Yup.object().shape({
  batch_id: Yup.string().required('Required'),
})

const APIFailureList = () => {
  const dispatch = useDispatch()

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)
  const [params, setParams] = useState<APIStateParamsModel>({
    status: 'failure',
    page: 1,
    limit: 10,
    orderBy: 'updated_at',
    order: 'desc',
  })

  const {data: apiListData, loading}: apiListRedux.IAPIListState = useSelector(
    (state: any) => state.apiList
  )

  const data = apiListData?.bulk_accounts?.map((account: any, i: number) => ({
    ...account,
    sn: (params?.page - 1) * params?.limit + (i + 1),
    account_name: `${account?.first_name} ${account.middle_name ? account.middle_name + ' ' : ''} ${
      account?.last_name
    }`,
  }))

  const handleOpenDownloadModal = () => setIsDownloadModalOpen(true)
  const handleCloseDownloadModal = () => setIsDownloadModalOpen(false)
  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <button className='btn btn-primary btn-sm ms-3'>Review</button>
      </Cell>
    )
  }

  const columns = [
    {label: 'S.N.', dataKey: 'sn', width: 100, cell: <Cell dataKey='sn' />},
    {
      label: 'A/C Opening Date',
      dataKey: 'accountOpeningDate',
      width: 200,
      cell: <Cell dataKey='accountOpeningDate' />,
    },
    {
      label: 'CIF Number',
      dataKey: 'cif_number',
      width: 250,
      cell: <Cell dataKey='cif_number' />,
    },
    {
      label: 'Account Number',
      dataKey: 'account_number',
      cell: <Cell dataKey='account_number' />,
      sortable: false,
      width: 250,
    },
    {
      label: 'Customer Code',
      dataKey: 'customer_code',
      sortable: false,
      width: 250,
      cell: <Cell dataKey='customer_code' />,
    },
    {
      label: 'Account Name',
      dataKey: 'account_name',
      cell: <Cell dataKey='account_name' />,
      sortable: false,
      width: 230,
    },
    {
      label: 'Branch Location',
      dataKey: 'branch_location_code',
      cell: <Cell dataKey='branch_location_code' />,
      sortable: false,
      width: 230,
    },
  ]

  useEffect(() => {
    dispatch(apiListRedux.actions.getAPIList(params))
  }, [params])

  return (
    <div>
      <DownloadModal
        isDownloadModalOpen={isDownloadModalOpen}
        handleCloseDownloadModal={handleCloseDownloadModal}
      />
      <div className='shadow p-3 bg-white rounded'>
        <div className='row g-0 border-bottom align-items-center mb-3 py-3'>
          <div className='col-lg-6'>
            <div className='heading__component__title fw-bolder'>API Failure List</div>
          </div>
          <div className='col-lg-6'>
            <div className='d-flex justify-content-end'>
              <div className='sub_button' onClick={handleOpenDownloadModal}>
                <button className='dt-btn dt-btn-primary' data-cy='add-button'>
                  <FaDownload className='me-2' />
                  Download Batch
                </button>
              </div>
            </div>
          </div>
        </div>
        <APIParamsComponent params={params} setParams={setParams} />
        <div className='datatable'>
          <RSuiteTable columns={columns} data={data} showLoading={loading} />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </div>
    </div>
  )
}

const DownloadModal = ({
  isDownloadModalOpen,
  handleCloseDownloadModal,
}: {
  isDownloadModalOpen: boolean
  handleCloseDownloadModal: () => void
}) => {
  const dispatch = useDispatch()

  const uploadExistingInitialVales: {batch_id: string} = {
    batch_id: '',
  }

  const {data: failedMasterBatchList}: {data: bulkAccountRedux.IFailedBulkAccountList['data']} =
    useSelector((state: RootState) => state.failedMasterBatchList)

  const {mutate: downlaodBatch, isLoading} = usePostDownloadBatch()

  useEffect(() => {
    dispatch(bulkAccountRedux.actions.getFailedMasterBatchList())
  }, [])

  return (
    <Modal open={isDownloadModalOpen} onClose={handleCloseDownloadModal}>
      <Modal.Header>Download Batch</Modal.Header>
      <Formik
        initialValues={uploadExistingInitialVales}
        onSubmit={(values, {setFieldError}) => {
          downlaodBatch({
            payload: values,
            handleCloseDownloadModal,
          })
        }}
        validationSchema={downloadBatchSchema}
      >
        {({handleChange, setFieldValue, values, touched, errors}) => (
          <Form>
            <Modal.Body style={{minHeight: '10rem'}}>
              <FormSelect
                name='batch_id'
                label='SELECT A BATCH'
                setFieldValue={setFieldValue}
                containerClassName='mt-3 mb-10'
                onChange={handleChange}
                options={failedMasterBatchList?.master_batches?.map((failedMasterBatchData) => ({
                  label: failedMasterBatchData.batch_number,
                  value: failedMasterBatchData.id,
                }))}
                touched={touched}
                errors={errors}
                values={values}
                required
              />
            </Modal.Body>
            <Modal.Footer>
              <button type='submit' className='dt-btn dt-btn-primary' disabled={isLoading}>
                <span className='d-flex justify-content-center align-items-center'>
                  {isLoading && (
                    <div className='spinner-border spinner-border-sm text-light me-3' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                  )}
                  Download
                </span>
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default APIFailureList
