const DetailsRow = ({label, value}: {label: string; value: string}) => {
  return (
    <div className='mb-3 col-6'>
      <span className='fs-5 fw-bolder '>{label}: </span>
      <span className='fs-5 opacity-80'>{value}</span>
    </div>
  )
}

export default DetailsRow
