import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const NEW_CIF_API = `${API_URL}/new-cif/cpu-checker-list`

export const services = {
  getNewCIF: (params: ParamsModel) => {
    return axios.get(NEW_CIF_API, {params})
  },
}
