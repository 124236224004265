import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'
import {INewCIFState} from './reducer'

export const actions = {
  getNewCIFCheckerList: (params: ParamsModel | undefined = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_NEW_CIF_LIST_CPU_CHECKER_START,
    payload: {params},
  }),
  getNewCIFListCheckerSuccess: (data: INewCIFState) => ({
    type: actionTypes.GET_NEW_CIF_LIST_CPU_CHECKER_SUCCESS,
    payload: data,
  }),
  getNewCIFListCheckerFinish: () => ({
    type: actionTypes.GET_NEW_CIF_LIST_CPU_CHECKER_FINISH,
  }),
}
