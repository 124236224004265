import React, {useEffect, useState} from 'react'
import {Upload, Button} from 'antd'
import {UploadChangeParam, RcFile} from 'antd/lib/upload'
import {PlusCircleOutlined, DeleteOutlined} from '@ant-design/icons'
import {toast} from 'react-toastify'
import {UploadRequestOption} from 'rc-upload/lib/interface'
import usePostDocumentsUpload from 'src/app/queries/Account Preliminary/post/usePostDocumentsUpload'

interface FileUploadProps {
  documentType: string
  maxSize: number
  onChange: (file: RcFile, documentType: string) => void
  documentTypeId?: string
  uniqueID?: string
  resetUploads?: boolean
  setResetUploads?: (resetUploads: boolean) => void
  setUploadDocumentsTypeId?: any
  isMissing?: boolean
}

const ExisitingFileUpload: React.FC<FileUploadProps> = ({
  documentType,
  maxSize,
  onChange,
  documentTypeId = '',
  uniqueID,
  resetUploads,
  setResetUploads,
  setUploadDocumentsTypeId,
  isMissing,
}) => {
  const {mutate: uploadDocuments, isLoading: documentsLoading} = usePostDocumentsUpload()

  const [fileList, setFileList] = useState<any[]>([])

  const customRequest = (options: UploadRequestOption) => {
    const {file, onSuccess, onError} = options

    if (file instanceof File || file instanceof Blob) {
      const isValidSize = file.size / 1024 / 1024 < maxSize
      if (!isValidSize) {
        toast.error(`${documentType} file size should be less than ${maxSize} MB.`)
        return
      }

      const formData = new FormData()
      formData.append('documentTypeId', documentTypeId)
      formData.append('image', file)
      formData.append('temp_customer_id', uniqueID ?? '')
      setTimeout(() => {
        if (onSuccess) {
          onSuccess('ok')
        }
        {
          formData.append('model_type', 'ExistingCifAccount')
          uploadDocuments(
            {
              formData,
            },
            {
              onSuccess: (data: any) => {
                const uploadedId = formData.get('documentTypeId')
                setUploadDocumentsTypeId((prev: string) => [...prev, uploadedId])
              },
            }
          )
        }
      }, 0)
    } else {
      toast.error('Invalid file type:' + typeof file)
    }
  }

  // Effect to reset fileList when resetUploads is true
  useEffect(() => {
    if (resetUploads) {
      setFileList([])
      setResetUploads?.(false)
    }
  }, [resetUploads])

  return (
    <Upload
      customRequest={customRequest}
      maxCount={1}
      accept='.jpg,.jpeg,.png,.pdf'
      fileList={fileList}
      onChange={(info: UploadChangeParam) => {
        const {file} = info
        setFileList(info.fileList)
        if (file.status === 'done') {
          onChange(file.originFileObj as RcFile, documentType)
        }
      }}
      showUploadList={{
        showRemoveIcon: true,
        removeIcon: <DeleteOutlined style={{color: 'red'}} />,
      }}
    >
      <div
        style={{
          color: 'red',
          marginBottom: '4px',
          fontSize: '12px',
          visibility: isMissing ? 'visible' : 'hidden',
        }}
      >
        {documentType} is required
      </div>
      <Button icon={<PlusCircleOutlined />}>Upload {documentType}</Button>
    </Upload>
  )
}

export default ExisitingFileUpload
