import {ErrorMessage, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as Yup from 'yup'
//manual import
import * as bannerRedux from 'src/app/modules/cms/components/banner/redux'
import * as menuRedux from 'src/app/modules/cms/components/menu'
import {StateParamsModel} from 'src/app/modules/common/Model'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import {IMenuState} from '../../menu'
import {MenuModal} from '../../menu/Model/MenuModal'
import {ColorOptionModel} from '../Model'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}
const AddBanner = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const {colorOption, loading, success} = useSelector((state: any) => state.banner)
  const {topLeftMenu} = useSelector((state: IMenuState | any) => state.menuManager)
  const [imagePath, setImagePath] = useState<any>('')

  // const FORM_VALIDATION = {
  //   name: Yup.string().required('This field is required'),
  //   subtitle: Yup.string().max(250, 'Subtitle exceeds 250 characters').nullable(),
  //   subtitle_np: Yup.string().max(250, 'Subtitle nepali exceeds 250 characters').nullable(),
  //   image: Yup.string().required('This field is required'),
  //   status: Yup.string().required('Status is required'),
  // }

  useEffect(() => {
    dispatch(bannerRedux.actions.getColorOption())
    dispatch(menuRedux?.actions.getTopLeftMenu())
  }, [])

  const topMenuOptions = topLeftMenu?.map((items: MenuModal) => ({
    label: items.name,
    value: items.id,
    systemName: items.slug,
  }))

  const colorOptions = colorOption?.data?.backgroundcolor.map((items: ColorOptionModel) => ({
    label: items.name,
    value: items.id,
    hexValue: items.code,
  }))

  useEffect(() => {
    if (success) {
      dispatch(bannerRedux?.actions.getBanner(params))
      isEmpty(editSelectedData)
        ? toast.success('Banner added successfully')
        : toast.success('Banner edited successfully')
      dispatch(bannerRedux?.actions.createBannerReset())
      handleClose()
    }
  }, [success])

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]
  const showMenuDropdown = checkBackendSetting('menu')
  const showBackgroundColorDropdown = checkBackendSetting('backgroundColor')

  const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string().required('This field is required'),
    subtitle: Yup.string().max(250, 'Subtitle exceeds 250 characters').nullable(),
    subtitle_np: Yup.string().max(250, 'Subtitle nepali exceeds 250 characters').nullable(),
    image: Yup.string().required('This field is required'),
    status: Yup.string().required('Status is required'),
    backgroundcolorId: Yup.string().when('showBackgroundColorDropdown', {
      is: (value: any) => !value && showBackgroundColorDropdown,
      then: Yup.string().required('Background Color is required'),
      otherwise: Yup.string(),
    }),
  })
  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        size='lg'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Banner </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                name: '',
                title: '',
                title_np: '',
                subtitle: '',
                subtitle_np: '',
                image: '',
                backgroundcolorId: '',
                menus: null,
                first_button_text: '',
                first_button_text_np: '',
                second_button_text: '',
                second_button_text_np: '',
                first_button_url: '',
                second_button_url: '',
                show_first_button: true,
                show_second_button: true,
                status: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values: any) => {
                const formData = {
                  ...values,
                  menus: !isEmpty(values?.menus)
                    ? values?.menus?.map((item: string) => ({
                        id: item,
                      }))
                    : null,
                  backgroundcolorId: !isEmpty(values?.backgroundcolorId)
                    ? values?.backgroundcolorId
                    : null,
                  status: values?.status === 'Active' ? true : false,
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(bannerRedux.actions.updateBanner(formData, editSelectedData?.id))
                } else {
                  dispatch(bannerRedux.actions.CreateBanner(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, setFieldValue, values}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'name',
                      'title',
                      'menus',
                      'title_np',
                      'subtitle',
                      'subtitle_np',
                      'image',
                      'first_button_text',
                      'first_button_text_np',
                      'first_button_url',
                      'second_button_text',
                      'second_button_text_np',
                      'second_button_url',
                      'backgroundcolorId',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                    setFieldValue(
                      'backgroundcolorId',
                      editSelectedData?.bannerbackgroundcolor,
                      false
                    )
                  }
                }, [])
                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        {/* {topNavStatus === 'true' ? <div></div> : null} */}
                        {showMenuDropdown && (
                          <div className='col-md-6 col-sm-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Please select top menu'
                              label='Menu'
                              name='menus'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={topMenuOptions}
                              multiple={true}
                              values={values}
                              setFieldValue={setFieldValue}
                              // required
                            />
                          </div>
                        )}

                        {showBackgroundColorDropdown && (
                          <div className='col-md-6 col-sm-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Please select background color'
                              label='Background Color'
                              name='backgroundcolorId'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={colorOptions}
                              values={values}
                              setFieldValue={setFieldValue}
                              required
                            />
                          </div>
                        )}

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Enter Name'
                            name='name'
                            label='Name'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormInputMediaManager
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label=' Image '
                            name='image'
                            setFieldValue={setFieldValue}
                            setImageUrl={setImagePath}
                            value={values?.image}
                            required={true}
                            note='Recommended banner size of 1920*600px'
                          />

                          {!isEmpty(values?.image) ? (
                            <>
                              <li className='listing'>
                                <div className='thumbImageBlock'>
                                  <button
                                    type='button'
                                    title='Remove'
                                    className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                    onClick={() => {
                                      setImagePath('')
                                      setFieldValue('image', '')
                                    }}
                                    data-cy='modal-thumbnail-remove'
                                  >
                                    X
                                  </button>

                                  <img
                                    className='thumbImage w-100 h-100'
                                    src={`${imageBaseUrl}/${values?.image}`}
                                    alt=''
                                  />
                                </div>
                              </li>
                            </>
                          ) : null}
                        </div>

                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Enter Title (English)'
                            name='title'
                            label='Title (EN)'
                            containerClassName=''
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                          <div className='mb-3'>
                            <em>
                              <small>
                                {
                                  'Use <span> tag for color effect. Eg. <span>Highlighted</span> non highlighted'
                                }
                              </small>
                              <small className='d-block'>
                                Results: <span className='text-danger'>Highlighted</span> non
                                highlighted
                              </small>
                            </em>
                          </div>
                        </div>

                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Enter Title (Nepali)'
                            name='title_np'
                            label='Title (NP)'
                            containerClassName=''
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                          <div className='mb-3'>
                            <em>
                              <small>
                                {
                                  'Use <span> tag for color effect. Eg. <span>Highlighted</span> non highlighted'
                                }
                              </small>
                              <small className='d-block'>
                                Results: <span className='text-danger'>Highlighted</span> non
                                highlighted
                              </small>
                            </em>
                          </div>
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Subtitle (English)'
                            name='subtitle'
                            as='textarea'
                            label='Subtitle (EN)'
                            containerClassName=''
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Subtitle (Nepali)'
                            name='subtitle_np'
                            as='textarea'
                            label='Subtitle (NP)'
                            containerClassName=''
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='First Button Text (English)'
                            name='first_button_text'
                            label='First Button Text (EN)'
                            containerClassName=''
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='First Button Text (Nepali)'
                            name='first_button_text_np'
                            label='First Button Text (NP)'
                            containerClassName=''
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='First Button Url '
                            name='first_button_url'
                            label='First Button Url '
                            containerClassName=''
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Second Button Text (English)'
                            name='second_button_text'
                            label='Second Button Text (EN)'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Second Button Text (Nepali)'
                            name='second_button_text_np'
                            label='Second Button Text (NP)'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Second Button Url '
                            name='second_button_url'
                            label='Second Button Url'
                            containerClassName=''
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5 mt-3'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                checkBoxText={status?.label}
                                value={status?.value}
                                required={true}
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddBanner
