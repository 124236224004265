import {toast} from 'react-toastify'
import {call, delay, put, takeLatest} from 'redux-saga/effects'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {actions} from './action'
import {actionTypes} from './constants'
import {services} from './service'

function* getCPUCheckerNewCIFAndAccountIndividualJointMinorList(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(
      services.getCPUCheckerNewCIFandAccountIndividualJointMinorList,
      params
    )
    const data: any = response?.data?.data

    yield put(actions.getCPUCheckerNewCIFandAccountIndividualJointMinorListSuccess(data))
  } catch (error: any) {
    yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
  } finally {
    yield put(actions.getCPUCheckerNewCIFandAccountIndividualJointMinorListFinish())
  }
}

function* getCPUCheckerNewCIFAndAccountCorproateBankList(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(
      services.getCPUCheckerNewCIFandAccountCorporateBankList,
      params
    )
    const data: any = response?.data?.data

    yield put(actions.getCPUCheckerNewCIFandAccountCorporateBankListSuccess(data))
  } catch (error: any) {
    yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
  } finally {
    yield put(actions.getCPUCheckerNewCIFandAccountCorporateBankListFinish())
  }
}

function* postAccountPreliminaryKYCCPUCheckerReviewSaga(action: ActionModel) {
  try {
    const {id, data} = action.payload
    const response: ResponseModel = yield call(
      services.postAccountPreliminaryKYCCPUCheckerReview,
      id,
      data
    )
    toast.success(response?.data?.resDesc ?? 'Account Status updated Successfully')
    yield put(actions.postAccountPreliminaryKYCCPUCheckerSuccess())
  } catch (error: any) {
    yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
  } finally {
    yield put(actions.postAccountPreliminaryKYCCPUCheckerFinish())
  }
}

export function* saga() {
  yield takeLatest(
    actionTypes.GET_CPU_CHECKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_LIST_START,
    getCPUCheckerNewCIFAndAccountIndividualJointMinorList
  ),
    yield takeLatest(
      actionTypes.POST_ACCOUNT_PRELIMINARY_KYC_CPU_CHECKER_REVIEW_START,
      postAccountPreliminaryKYCCPUCheckerReviewSaga
    )
  yield takeLatest(
    actionTypes.GET_CPU_CHECKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_LIST_START,
    getCPUCheckerNewCIFAndAccountCorproateBankList
  )
}
