import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useLocation} from 'react-router-dom'
import * as masterDataRedux from 'src/app/modules/masterData/redux'
import AccountStatusLog from '../../../../AccountStatusLog'
import DetailsSection from './DetailsSection'
import DocumentsPreview from './DocumentsPreview'

const CPUCheckerIndividualJointMinorReviewComponent = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const data: any = location.state

  useEffect(() => {
    dispatch(masterDataRedux.actions.getAllMasterData())
  }, [])
  return (
    <div>
      <div className='row'>
        <div className='col-6'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-9 '>
              <DocumentsPreview data={data} />
            </div>
          </div>
        </div>
        <div className='col-6'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-9 ' style={{maxHeight: '100vh', overflowY: 'auto'}}>
              <DetailsSection data={data} />
            </div>
          </div>
        </div>
      </div>
      <AccountStatusLog data={data} />
    </div>
  )
}

export default CPUCheckerIndividualJointMinorReviewComponent
