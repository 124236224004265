export type ANY = any

export interface AccountPreliminaryPayload {
  accountTypeId: string
  first_name: string
  middle_name: string
  last_name: string
  full_name: string
  short_name: string
  salutation: string
  date_of_birth_bs: string
  date_of_birth_ad: string
  email: string
  idTypeId: string
  id_number: string
  pep_confirmation: string
  ancillary_services_request: string
  mobile_banking: string
  mobile_banking_no_reason: string
  other_mobile_banking_no_reason: string
  debit_card: string
  debit_card_no_reason: string
  other_debit_card_no_reason: string
  mobile_number: string
  house_number: string
  tole: string
  customerCategoryId: string
  customerClassificationId: string
  country_id: string
  nationality_id: string
  language: string
  location: string
  locationCode: string
  branchLocation: string
  taxCategoryId: string
  taxGroupId: string
  aml_risk_rating: string
  risk_category: string
  accountClassId: string
  account_currency_id: string
  cheque_book_name: string
  mediaId: string
  institutionalId: string
  exposureId: string
  annual_income: string
  guardian_name: string
  mobile_verification: string
  email_verification: string
  biometric_verification: number
}

export const initialFormData: AccountPreliminaryPayload = {
  accountTypeId: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  full_name: '',
  short_name: '',
  salutation: '',
  date_of_birth_bs: '',
  date_of_birth_ad: '',
  email: '',
  idTypeId: '',
  id_number: '',
  pep_confirmation: '',
  ancillary_services_request: '',
  mobile_banking: '',
  mobile_banking_no_reason: '',
  other_mobile_banking_no_reason: '',
  debit_card: '',
  debit_card_no_reason: '',
  other_debit_card_no_reason: '',
  mobile_number: '',
  house_number: '',
  tole: '',
  customerCategoryId: '',
  customerClassificationId: '',
  country_id: '',
  nationality_id: '',
  language: '',
  location: '',
  locationCode: '',
  branchLocation: '',
  taxCategoryId: '',
  taxGroupId: '',
  aml_risk_rating: '',
  risk_category: '',
  accountClassId: '',
  account_currency_id: '',
  cheque_book_name: '',
  mediaId: '',
  institutionalId: '',
  exposureId: '',
  annual_income: '',
  guardian_name: '',
  mobile_verification: '',
  email_verification: '',
  biometric_verification: 0,
}

export interface AccountPreliminaryDocumentPayload {
  document_type_id: string
  document_number: string
  document_image: string
}

export const initialDocumentData: AccountPreliminaryDocumentPayload = {
  document_type_id: '',
  document_number: '',
  document_image: '',
}

export interface CorporateAccountPayload extends AccountPreliminaryPayload {
  preferred_contact_person: string
  date_of_incorporation_ad: string
  date_of_incorporation_bs: string
  nationality: string
}

export const initialCorporateFormData: CorporateAccountPayload = {
  ...initialFormData,
  preferred_contact_person: '',
  date_of_incorporation_ad: '',
  date_of_incorporation_bs: '',
  nationality: '',
}
