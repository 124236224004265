import axios, {AxiosError} from 'axios'
import {CBS_DATA_API} from '../api'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'

const postCIFtoGETCustomerInfo = async (customer_no: string) => {
  try {
    const response = await axios.post(`${CBS_DATA_API}`, {customer_no})
    return response?.data
  } catch (error: any) {
    const axiosError = error as AxiosError
    toast.error(axiosError.response?.data.message)
    throw new Error(axiosError.response?.data.message)
  }
}

export const usePostCIFtoGETCustomerInfo = () => {
  return useMutation(postCIFtoGETCustomerInfo)
}
