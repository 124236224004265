import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {Pagination, Table} from 'rsuite'
import {StateParamsModel} from 'src/app/modules/common/Model'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import * as newCIFCPUCheckerRedux from '../redux'

const Cell = Table.Cell

const NewCIFCheckerListing = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [params, setParams] = useState<StateParamsModel>({
    status: 1,
    page: 1,
    limit: 10,
    orderBy: 'updated_at',
    order: 'desc',
  })

  const {data: newCIFCheckerListData, loading} = useSelector(
    (state: any) => state.newCIFCPUCheckerList
  )

  const data = newCIFCheckerListData?.new_accounts?.map((newCIF: any, i: number) => ({
    ...newCIF,
    sn: (params?.page - 1) * params?.limit + (i + 1),
    account_name: `${newCIF?.first_name} ${newCIF?.middle_name ? newCIF?.middle_name + ' ' : ''} ${
      newCIF?.last_name
    }`,
  }))

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <button
          className='btn btn-primary btn-sm ms-3'
          onClick={() =>
            history.push({
              pathname: `/new-cif/new-cif-review/checker/${rowData.id}`,
              state: rowData,
            })
          }
        >
          Review
        </button>
      </Cell>
    )
  }

  const columns = [
    {
      label: 'Primary CIF',
      dataKey: 'primary_cif_number',
      width: 150,
      cell: <Cell dataKey='primary_cif_number' />,
    },
    {
      label: 'Account Name',
      dataKey: 'account_name',
      cell: <Cell dataKey='account_name' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Branch Location',
      dataKey: 'branch_location_code',
      sortable: false,
      width: 150,
      cell: <Cell dataKey='branch_location_code' />,
    },
    {
      label: 'Status',
      dataKey: 'account_status.current_status',
      sortable: false,
      width: 400,
      cell: <Cell dataKey='account_status.current_status' />,
    },
    {
      label: 'Updated On',
      dataKey: 'account_status.updated_date',
      cell: <Cell dataKey='account_status.updated_date' />,
      sortable: false,
      width: 230,
    },
    {
      label: 'Remarks',
      dataKey: 'account_status.remarks',
      cell: <Cell dataKey='account_status.remarks' />,
      sortable: false,
      width: 230,
    },
    {
      label: 'Action',
      width: 160,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  useEffect(() => {
    dispatch(newCIFCPUCheckerRedux.actions.getNewCIFCheckerList(params))
  }, [params])
  return (
    <div>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='New CIF List'
          params={params}
          setParams={setParams}
          addShow={false}
          deleteShow={false}
        />
        <div className='datatable'>
          <RSuiteTable columns={columns} data={data ?? []} showLoading={loading} />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={newCIFCheckerListData?.meta?.total ?? 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </div>
    </div>
  )
}
export default NewCIFCheckerListing
