import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface IBulkAccountOpeningList {
  loading: boolean
  success: boolean
  data: {
    master_batches: Record<string, string>[]
    meta: Record<string, number>
  }
}

const initialState: IBulkAccountOpeningList = {
  success: false,
  loading: false,
  data: {
    master_batches: [],
    meta: {},
  },
}

export const reducer = (
  state: IBulkAccountOpeningList = initialState,
  action: ActionWithPayload<IBulkAccountOpeningList>
) => {
  switch (action.type) {
    case actionTypes.GET_BULK_ACCOUNT_LIST_START:
      return {...state, loading: true}
    case actionTypes.GET_BULK_ACCOUNT_LIST_SUCCESS:
      return {...state, loading: false, success: true, data: action.payload}
    case actionTypes.GET_BULK_ACCOUNT_LIST_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IBulkAccountOpenedList {
  loading: boolean
  success: boolean
  data: {
    new_accounts: Record<string, string>[]
    meta: Record<string, number>
  }
}

const initialBulkAccountOpenedState: IBulkAccountOpenedList = {
  success: false,
  loading: false,
  data: {
    new_accounts: [],
    meta: {},
  },
}

export const bulkAcocuntOpenedReducer = (
  state: IBulkAccountOpenedList = initialBulkAccountOpenedState,
  action: ActionWithPayload<IBulkAccountOpenedList>
) => {
  switch (action.type) {
    case actionTypes.GET_BULK_ACCOUNT_OPENED_LIST_START:
      return {...state, loading: true}
    case actionTypes.GET_BULK_ACCOUNT_OPENED_LIST_SUCCESS:
      return {...state, loading: false, success: true, data: action.payload}
    case actionTypes.GET_BULK_ACCOUNT_OPENED_LIST_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IFailedBulkAccountList {
  loading: boolean
  success: boolean
  data: {
    master_batches: {
      id: string
      batch_number: string
      status: string
      created_on: string
      no_of_accounts: string
    }[]
  }
}

const initialFailedMasterListState: IFailedBulkAccountList = {
  success: false,
  loading: false,
  data: {
    master_batches: [
      {
        id: '',
        batch_number: '',
        status: '',
        created_on: '',
        no_of_accounts: '',
      },
    ],
  },
}

export const failedMasterReducer = (
  state: IFailedBulkAccountList = initialFailedMasterListState,
  action: ActionWithPayload<IFailedBulkAccountList>
) => {
  switch (action.type) {
    case actionTypes.GET_FAILED_MASTER_BATCH_LIST_START:
      return {...state, loading: true}
    case actionTypes.GET_FAILED_MASTER_BATCH_LIST_SUCCESS:
      return {...state, loading: false, success: true, data: action.payload}
    case actionTypes.GET_FAILED_MASTER_BATCH_LIST_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}
