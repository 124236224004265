export const actionTypes = {
  GET_BULK_ACCOUNT_LIST_START: 'GET_BULK_ACCOUNT_LIST_START',
  GET_BULK_ACCOUNT_LIST_SUCCESS: 'GET_BULK_ACCOUNT_LIST_SUCCESS',
  GET_BULK_ACCOUNT_LIST_FINISH: 'GET_BULK_ACCOUNT_LIST_FINISH',

  GET_BULK_ACCOUNT_OPENED_LIST_START: 'GET_BULK_ACCOUNT_OPENED_LIST_START',
  GET_BULK_ACCOUNT_OPENED_LIST_SUCCESS: 'GET_BULK_ACCOUNT_OPENED_LIST_SUCCESS',
  GET_BULK_ACCOUNT_OPENED_LIST_FINISH: 'GET_BULK_ACCOUNT_OPENED_LIST_FINISH',

  GET_FAILED_MASTER_BATCH_LIST_START: 'GET_FAILED_MASTER_BATCH_LIST_START',
  GET_FAILED_MASTER_BATCH_LIST_SUCCESS: 'GET_FAILED_MASTER_BATCH_LIST_SUCCESS',
  GET_FAILED_MASTER_BATCH_LIST_FINISH: 'GET_FAILED_MASTER_BATCH_LIST_FINISH',
}
