import axios, {AxiosResponse} from 'axios'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const ACCOUNT_PRELIMINARY_API = `${API_URL}/newcif-newaccount`
const CORPORATE_ACCOUNT_PRELIMINARY_API = `${API_URL}/corporate-newcif-newaccount`

export const services = {
  postAccountPrelimiary: (data: any) => {
    return axios.post(ACCOUNT_PRELIMINARY_API, data)
  },
  postCorporateAccountPrelimiary: (data: any) => {
    return axios.post(`${CORPORATE_ACCOUNT_PRELIMINARY_API}`, data)
  },
  postAccountPrelimiaryDocuments: (data: FormData, id: string): Promise<AxiosResponse<any>> => {
    console.log(`Posting documents to URL: ${ACCOUNT_PRELIMINARY_API}/${id}/documents`)
    return axios.post(`${ACCOUNT_PRELIMINARY_API}/${id}/documents`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
}
