import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import type {
  CustomerClassificationDataModel,
  MasterDataModel,
} from 'src/app/modules/masterData/Model'
import * as customerClassificationListRedux from 'src/app/modules/masterData/redux'
import FormCheckbox from 'src/cms/helpers/components/forms/FormCheckbox'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {RootState} from 'src/setup'
import {ErrorMessage} from 'formik'
import {handleUppercaseChange} from 'src/app/modules/CreateNewCIFAndCustomerAccount/createNewAccount/components'

interface CIFInformationSectionProps {
  handleChange: any
  touched: any
  errors: any
  setFieldValue: any
  values: any
}

const CIFInformationSection = ({
  handleChange,
  errors,
  touched,
  values,
  setFieldValue,
}: CIFInformationSectionProps) => {
  const dispatch = useDispatch()

  const {
    data: salutationList,
    loading: salutationLoading,
  }: {data: MasterDataModel[]; loading: boolean} = useSelector(
    (state: RootState) => state?.salutationList
  )
  const customerClassificationListData = useSelector(
    (state: any) => state?.customerClassificationList
  )
  const {data: customerCategories}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.customerCategoryList
  )
  const {data: taxCategories}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.taxCategoryList
  )
  const {data: taxGroups}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.taxGroupList
  )
  const {data: medias}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.mediaList
  )
  const {data: relationships}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.linkedEntitiesList
  )
  const {data: countriesList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.countriesList
  )
  const {data: nationalities}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.nationalitiesList
  )
  const {data: exposures}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.exposureList
  )
  const {data: kycLevelCategories}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.kycLevelCategoryList
  )

  useEffect(() => {
    dispatch(customerClassificationListRedux.actions.getCustomerClassification())
  }, [])

  return (
    <>
      <div className='col-md-6 col-12'></div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='relationship'
          label='Relationship'
          containerClassName='col-12'
          options={
            relationships.map((relationship) => ({
              label: relationship.title,
              value: relationship.id,
            })) ?? []
          }
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className='col-12 mb-3'>
        <div className={`row mb-6`}>
          <label className={`mb-1 fw-bolder fs-6`}>
            <span className={'required'}>Salutation</span>
          </label>
          <div className='col-md-12' role='group' aria-labelledby='my-radio-group'>
            <div className='row'>
              {salutationLoading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : (
                salutationList?.map((salutation) => (
                  <FormRadio
                    key={salutation.id}
                    containerClassName='col-lg-1'
                    label='Salutation'
                    name='salutation'
                    type='radio'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    required={true}
                    checkBoxText={salutation.title}
                    value={salutation.title}
                  />
                ))
              )}
              <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                <ErrorMessage name='salutation' component='div' className='field-error-message' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='first_name'
          label='First Name'
          containerClassName='col-12'
          onChange={handleUppercaseChange(handleChange)}
          errors={errors}
          touched={touched}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='middle_name'
          label='Middle Name'
          containerClassName='col-12'
          onChange={handleUppercaseChange(handleChange)}
          errors={errors}
          touched={touched}
        />
      </div>
      <div className='col-12'>
        <FormTextBox
          name='last_name'
          label='Last Name'
          labelClassName='col-12'
          containerClassName='col-md-6 col-12'
          onChange={handleUppercaseChange(handleChange)}
          errors={errors}
          touched={touched}
          required
        />
      </div>
      <div className='col-12'>
        <FormSelect
          name='pep_confirmation'
          label='PEP Confirmation'
          labelClassName='col-12'
          containerClassName='col-md-6 col-12'
          options={[
            {label: 'YES', value: 'YES'},
            {label: 'NO', value: 'NO'},
          ]}
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className=' col-12 mb-3 fw-bolder'>
        <FormCheckbox
          labelClassName='col-sm-12'
          containerClassName='col-sm-12'
          name='pepCifCheck'
          touched={touched}
          errors={errors}
          onChange={handleChange}
          checkBoxText='I hereby acknowledge that I have checked the PEP search & multiple CIF of the customer in the application.'
          data-cy='CIF-check-box-field'
          required={true}
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='email'
          label='Email'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleUppercaseChange(handleChange)}
          errors={errors}
          touched={touched}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='mobile_number'
          label='Mobile Number'
          containerClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='customerCategoryId'
          label='Customer Category'
          labelClassName='col-12'
          containerClassName='col-12'
          options={customerCategories?.map((customerCategory) => ({
            label: customerCategory.title,
            value: customerCategory.code ?? '',
          }))}
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='customerClassificationId'
          label='Customer Classification'
          labelClassName='col-12'
          containerClassName='col-12'
          options={customerClassificationListData?.data?.map(
            (customerClassification: CustomerClassificationDataModel) => ({
              label: customerClassification.title,
              value: customerClassification.id,
            })
          )}
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          values={values}
          touched={touched}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='country_id'
          label='Country'
          labelClassName='col-12'
          containerClassName='col-12'
          options={
            countriesList.map((country) => ({label: country.title, value: country.id})) ?? []
          }
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='nationality'
          label='Nationality'
          containerClassName='col-12'
          options={
            nationalities.map((nationality) => ({
              label: nationality.title?.toUpperCase(),
              value: nationality.id,
            })) ?? []
          }
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          labelClassName='col-md-12'
          containerClassName='col-md-12'
          label='Language'
          name='language'
          onChange={handleChange}
          options={[{label: 'ENG', value: 'ENG'}]}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          values={values}
          required={true}
          disabled={true}
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='branchLocation'
          label='Location (Branch Location Code)'
          labelClassName='col-12'
          containerClassName='col-12'
          onChange={handleUppercaseChange(handleChange)}
          errors={errors}
          touched={touched}
          disabled
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='taxCategoryId'
          label='Tax Category'
          containerClassName='col-12'
          options={taxCategories?.map((taxCategory) => ({
            label: taxCategory.title,
            value: taxCategory.cbs_code ?? '',
          }))}
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='taxGroupId'
          label='Tax Group'
          containerClassName='col-12'
          options={taxCategories?.map((taxGroup) => ({
            label: taxGroup.cbs_code ?? ' ',
            value: taxGroup.cbs_code ?? '',
          }))}
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='aml_risk_rating'
          label='AML Risk Rating'
          labelClassName='col-12'
          containerClassName='col-12'
          options={[
            {label: 'LOW', value: 'LOW'},
            {label: 'MEDIUM', value: 'MEDIUM'},
            {label: 'HIGH', value: 'HIGH'},
          ]}
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='locationCode'
          label='Home Branch (Location Code)'
          labelClassName='col-12'
          containerClassName='col-12'
          onChange={handleUppercaseChange(handleChange)}
          errors={errors}
          touched={touched}
          required
          disabled
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='location'
          label='Location'
          labelClassName='col-12'
          containerClassName='col-12'
          onChange={handleUppercaseChange(handleChange)}
          errors={errors}
          touched={touched}
          required
          disabled
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='mediaId'
          label='Media'
          containerClassName='col-12'
          options={medias?.map((media) => ({
            label: media.title,
            value: media.code ?? '',
          }))}
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='institutionalId'
          label='Institutional'
          containerClassName='col-12'
          options={[
            {label: 'YES', value: 'YES'},
            {label: 'NO', value: 'NO'},
          ]}
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='exposureId'
          label='Exposure'
          containerClassName='col-12'
          options={
            exposures.map((exposure) => ({label: exposure.title, value: exposure.code ?? ''})) ?? []
          }
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='date'
          label='Date of Request'
          type='date'
          containerClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='kyc_level_category'
          label='KYC Level Category'
          containerClassName='col-12'
          labelClassName='col-12'
          options={
            kycLevelCategories.map((kycLevelCategory) => ({
              label: kycLevelCategory.title,
              value: kycLevelCategory.id,
            })) ?? []
          }
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
    </>
  )
}

export default CIFInformationSection
