import axios from 'axios'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const CHECK_DUPLICATE_CIF_API = `${API_URL}/newcif-newaccount/search-customer`

export const services = {
  checkDuplicateCif: (payload: any) => {
    return axios.post(CHECK_DUPLICATE_CIF_API, payload)
  },
}
