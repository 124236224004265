import {DuplicateCIFPayload} from '../Model'
import {actionTypes} from './constants'

export const actions = {
  checkDuplicateCifStart: (payload: DuplicateCIFPayload) => ({
    type: actionTypes.CHECK_DUPLICATE_CIF_START,
    payload,
  }),
  checkDuplicateCifSuccess: (data: any) => ({
    type: actionTypes.CHECK_DUPLICATE_CIF_SUCCESS,
    payload: data,
  }),
  checkDuplicateCifFailure: (error: any) => ({
    type: actionTypes.CHECK_DUPLICATE_CIF_FAILURE,
    payload: error,
  }),
  resetDuplicateCifState: () => ({
    type: actionTypes.RESET_DUPLICATE_CIF_STATE,
  }),
}
