import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'
import {IAPIListState} from './reducer'

export const actions = {
  getAPIList: (params: ParamsModel | undefined = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_API_LIST_START,
    payload: {params},
  }),
  getAPIListSuccess: (data: IAPIListState) => ({
    type: actionTypes.GET_API_LIST_SUCCESS,
    payload: data,
  }),
  getAPIListFinish: () => ({
    type: actionTypes.GET_API_LIST_FINISH,
  }),
}
