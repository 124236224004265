const DetailCell = ({label, value}: {label: string; value: string}) => {
  return (
    <div className='mb-3 col-6 '>
      <span className='form-review-field fw-bold fs-6 opacity-50'>{label}</span>
      <p className=' fs-3' style={{textTransform: 'uppercase'}}>
        {value || '-'}
      </p>
    </div>
  )
}

export default DetailCell
