import React, {useEffect, FC, useState} from 'react'
import {Formik, Form} from 'formik'
import {useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import {usePostCIFtoGETCustomerInfo} from 'src/app/queries/CBS'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {RootState} from 'src/setup'
import * as masterDataRedux from 'src/app/modules/masterData/redux'
import {DocumentTypeModel, MasterDataModel} from 'src/app/modules/masterData/Model'
import {usePostToCheckKycUpdate} from 'src/app/queries/Kyc Update'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import {toast} from 'react-toastify'
import {useUpdateKycLogsDetails} from 'src/app/queries/Kyc Update/patch/useUpdateKycLogsDetails'
import KycUpdateFileUpload from '../fileUpload'
import {useHistory} from 'react-router-dom'

interface CustomerData {
  data: {
    branch: string
    customer_no: string
    full_name: string
  }
}

const requiredDocumentCBSCode = [
  'photo_upload_1',
  'form_upload',
  'citizenship',
  'passport',
  'driving_license',
  'voters_id',
  'registration_id',
]

const KYCForm: FC = () => {
  const dispatch = useDispatch()
  const authResp = useSelector((state: any) => state.auth)
  const {user} = authResp
  const [uploadedFiles, setUploadedFiles] = useState({})
  const [resetUploads, setResetUploads] = useState<boolean>(false)
  const [uploadDocumentsTypeId, setUploadDocumentsTypeId] = useState<string[]>([])
  const [missingDocs, setMissingDocs] = useState<string[]>([])

  const {
    data: documentTypeList,
    loading: documentTypeLoading,
  }: {data: DocumentTypeModel[]; loading: boolean} = useSelector(
    (state: RootState) => state?.documentTypeList
  )

  const {data: kycRequestTypesList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state?.kycRequestTypesList
  )
  const {
    mutate: getCustomerInfoByCIF,
    data: customerData,
    isLoading,
  } = usePostCIFtoGETCustomerInfo()

  const {mutate: postTogetKycInfo, data: kycInfo, isLoading: kycLoading} = usePostToCheckKycUpdate()
  const {mutate: updateKycLogsDetails, isLoading: updateKyclogLoading} = useUpdateKycLogsDetails()
  const history = useHistory()
  useEffect(() => {
    dispatch(masterDataRedux.actions.getAllMasterData())
  }, [dispatch])

  useEffect(() => {
    dispatch(masterDataRedux.actions.getDocumentTypes())
  }, [dispatch])

  const initialCIFValues = {cifNumber: ''}
  const cifValidationSchema = Yup.object().shape({
    cifNumber: Yup.string().required('CIF Number is required'),
  })

  const initialCustomerDataValues = {
    branch: user?.data.branch.code ?? '',
    kyc_request_type: '',
    remarks: '',
  }

  const customerDataValidationSchema = Yup.object().shape({
    branch: Yup.string().required('Branch is required'),
    kyc_request_type: Yup.string().required('KYC Request Type is required'),
    remarks: Yup.string().required('Remarks are required'),
  })

  const handleCIFSubmit = (values: any) => {
    getCustomerInfoByCIF(values.cifNumber, {
      onSuccess: (resp: any) => {
        if (resp?.resCod == '200') {
          postTogetKycInfo({account_number: values.cifNumber, action_btn: 'check_kyc_update'})
        }
      },
    })
  }

  const handleCustomerDataSubmit = async (values: any, {setSubmitting}: any) => {
    try {
      if (missingDocs.length < 1) {
        updateKycLogsDetails(
          {payload: values, id: kycInfo?.data?.id},
          {
            onSuccess: (data: any) => {
              if (data?.data?.resCod == '200') {
                toast.success(data?.data?.resDesc || 'Forwarded to Branch Maker')
                history.push('/kyc/kyc-update-list')
              }
            },
          }
        )
      } else {
        toast.error('Please upload all the required documents')
      }
    } catch (error) {
    } finally {
      setSubmitting(false)
    }
  }

  const handleFileUpload = (file: any, documentType: any) => {
    setUploadedFiles((prevFiles) => ({
      ...prevFiles,
      [documentType]: file,
    }))
  }

  const findOutmissingDocuments = () => {
    const filteredDocumentTypes = documentTypeList?.filter((doc) =>
      requiredDocumentCBSCode.includes(doc?.cbs_code ?? '')
    )

    const requiredDocumentIds = filteredDocumentTypes.map((doc) => doc.id)

    let missingDocs = requiredDocumentIds.filter(
      (id: string) => !uploadDocumentsTypeId.includes(id)
    )

    const specialDocumentIds = new Set([
      'fccc0058-a453-4ba7-a203-d1ef938d72bc',
      'deefda48-919f-44ab-9301-b086d60a03da',
      '8e0f608a-39b8-48d5-86f0-aa6a4bbced80',
      '7e49648e-3793-44bb-9bb2-df9a83b3e215',
      'ca7e9e19-038c-4381-a217-694c236aba85',
      '5e7948b2-f684-43a0-b05e-03b70a57854b',
    ])
    const hasSpecialDocument = uploadDocumentsTypeId.some((id) => specialDocumentIds.has(id))

    if (hasSpecialDocument) {
      missingDocs = missingDocs.filter((docId: string) => !specialDocumentIds.has(docId))
    }

    setMissingDocs(missingDocs)
  }

  return (
    <div>
      <div className='heading__component__title fw-bolder mb-5'>KYC Update</div>

      <div className='shadow p-4 bg-white rounded'>
        <Formik
          initialValues={initialCIFValues}
          validationSchema={cifValidationSchema}
          onSubmit={handleCIFSubmit}
        >
          {({errors, handleChange, touched, values, handleSubmit}) => (
            <Form onSubmit={handleSubmit}>
              <div className='row align-items-center'>
                <div className='col-md-6'>
                  <FormTextBox
                    name='cifNumber'
                    label='CIF Number'
                    containerClassName='col-md-12'
                    labelClassName='col-md-12'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    required
                    disabled={kycInfo?.data?.id ? true : false}
                  />
                </div>
                {!kycInfo?.data?.id && (
                  <div className='col-md-6'>
                    <button type='submit' className='btn btn-primary' disabled={isLoading}>
                      Submit
                    </button>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>

        {customerData && (
          <Formik
            initialValues={initialCustomerDataValues}
            validationSchema={customerDataValidationSchema}
            onSubmit={handleCustomerDataSubmit}
          >
            {({errors, handleChange, touched, values, setFieldValue, isSubmitting}) => (
              <Form>
                <div className='row mb-8'>
                  <div className='col-12 col-md-6 d-flex justify-content-between'>
                    <span>
                      <strong className='fs-6'>Customer Number: </strong>
                      <span className='fs-6'> {customerData?.data.customer_no ?? ''}</span>
                    </span>
                    <span>
                      <strong className='fs-6'>Account Holder's Name: </strong>
                      <span className='fs-6'> {customerData?.data?.full_name ?? '-'}</span>
                    </span>
                  </div>
                </div>
                {kycInfo?.data?.id && (
                  <>
                    <div className='row mt-10'>
                      <div className='col-md-5  col-xs-12'>
                        <FormTextBox
                          name='branch'
                          label='Branch'
                          containerClassName='col-md-12'
                          labelClassName='col-md-12'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          required
                          disabled
                          value={values.branch}
                        />
                      </div>
                      <div className='col-md-5  col-xs-12'>
                        <FormSelect
                          labelClassName='col-md-12'
                          containerClassName='col-md-12'
                          label='Kyc Request Type'
                          name='kyc_request_type'
                          required={true}
                          isClearable={true}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          options={kycRequestTypesList.map((list) => ({
                            label: list?.title,
                            value: list?.id,
                          }))}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                      <div className='col-md-6  col-xs-12'>
                        <FormTextBox
                          name='remarks'
                          label='Remarks'
                          as='textarea'
                          type='text'
                          containerClassName='col-md-12'
                          labelClassName='col-md-12'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          required
                        />
                      </div>
                    </div>
                    <div>
                      <p>NOTE: Only one of the Identification Document is mandatory.</p>
                      <p>
                        <span style={{fontWeight: 'bold'}}>
                          Please upload file of size mentioned here:{' '}
                        </span>{' '}
                        PP Size Photo (1 MB), Secondary Photo (1 MB), AOF (10 MB), Signature (1 MB),
                        KYC Form (5 MB), Citizenship (5 MB), Passport (5 MB), Driving License (5
                        MB), Voter's ID (5 MB), Registration IDs (5 MB), Mandate Form
                      </p>
                    </div>
                    {documentTypeLoading ? (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                          overflowX: 'auto',
                          WebkitOverflowScrolling: 'touch',
                          scrollbarWidth: 'thin',
                          scrollbarColor: '#888 #fff',
                        }}
                      >
                        {documentTypeList?.map((documentType) => (
                          <div key={documentType?.id} style={{marginRight: '10px'}}>
                            <KycUpdateFileUpload
                              documentType={documentType?.title}
                              maxSize={Number(documentType?.max_size)}
                              onChange={handleFileUpload}
                              documentTypeId={documentType?.id}
                              resetUploads={resetUploads}
                              setResetUploads={setResetUploads}
                              kycLogId={kycInfo?.data?.id}
                              setUploadDocumentsTypeId={setUploadDocumentsTypeId}
                              isMissing={missingDocs.some(
                                (docId: string) => docId === documentType.id
                              )}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                    <div className='d-flex justify-content-end mt-7'>
                      <button
                        onClick={findOutmissingDocuments}
                        type='submit'
                        className='btn btn-warning'
                        disabled={updateKyclogLoading}
                      >
                        {updateKyclogLoading ? 'Saving...' : 'Save & Continue'}
                      </button>
                    </div>
                  </>
                )}
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  )
}

export default KYCForm
