import {useState} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {Input, InputGroup, Pagination, Table} from 'rsuite'
import {StateParamsModel} from 'src/app/modules/common/Model'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {Close, Search} from '@rsuite/icons'
import {isEmpty} from 'lodash'
import {useGetBranchCheckerAncillaryLists} from 'src/app/queries/Ancillary Services/branchCheckerAncillaryLists'

const Cell = Table.Cell

const ClosedTableLists = () => {
  const [params, setParams] = useState<StateParamsModel>({
    status: 'closed',
    page: 1,
    limit: 10,
  })

  const {data: closedList, isLoading: loading} = useGetBranchCheckerAncillaryLists(params)

  const data =
    closedList?.data?.new_accounts?.map((newAccount: any, i: number) => ({
      ...newAccount,
      sn: (params?.page - 1) * params?.limit + (i + 1),
      account_name: `${newAccount?.first_name} ${
        newAccount.middle_name ? newAccount.middle_name + ' ' : ''
      } ${newAccount?.last_name}`,
      updatedOn: newAccount?.account_status?.updated_date,
      status: newAccount?.account_status?.current_status,
      remarks: newAccount?.account_status?.remarks,
      customerCategory: newAccount?.customerCategoryId?.title,
      country: newAccount?.country_id?.title,
    })) ?? []

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const columns = [
    {
      label: 'Account Number',
      dataKey: 'account_number',
      width: 200,
      cell: <Cell dataKey='registration_detail.account_number' />,
    },
    {
      label: 'CIF Number',
      dataKey: 'cifNumber',
      cell: <Cell dataKey='registration_detail.cif_number' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Account Name',
      dataKey: 'account_name',
      cell: <Cell dataKey='account_name' />,
      sortable: false,
      width: 250,
    },
    {
      label: 'Customer Category',
      dataKey: 'customerCategory',
      sortable: false,
      width: 150,
      cell: <Cell dataKey='customerCategory' />,
    },
    {
      label: 'Country',
      dataKey: 'country',
      cell: <Cell dataKey='country' />,
      sortable: false,
      width: 230,
    },
    {
      label: 'Updated On',
      dataKey: 'updatedOn',
      cell: <Cell dataKey='updatedOn' />,
      sortable: false,
      width: 230,
    },
    {
      label: 'Rejected Service',
      dataKey: 'rejectedService',
      cell: <Cell dataKey='rejectedService' />,
      sortable: false,
      width: 230,
    },
    {
      label: 'Remarks',
      dataKey: 'remarks',
      cell: <Cell dataKey='remarks' />,
      sortable: false,
      width: 230,
    },
  ]

  return (
    <>
      <InputGroup inside style={{width: '30%', margin: '1rem 0'}}>
        <Input
          placeholder='Search'
          value={params.search}
          onChange={(value: string) => setParams({...params, search: value})}
          data-cy='search-field'
        />
        <InputGroup.Addon>
          {!isEmpty(params?.search) ? (
            <Close
              style={{cursor: 'pointer'}}
              onClick={() => setParams({...params, search: ''})}
              data-cy='search-close-button'
            />
          ) : (
            <Search />
          )}
        </InputGroup.Addon>
      </InputGroup>
      <div className='datatable'>
        <RSuiteTable columns={columns} data={data} showLoading={loading} />
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size='sm'
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={closedList?.data?.meta?.total ?? 0}
          limitOptions={[10, 20, 50, 100]}
          limit={params?.limit}
          activePage={params?.page}
          onChangePage={(value) => setParams({...params, page: value})}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </>
  )
}

export default ClosedTableLists
