import {imageBaseUrl} from 'src/cms/helpers/constants'

export const DashboardCardData = {
  branchmaker: [
    {
      label: 'Create New CIF & Customer Account',
      imageUrl: imageBaseUrl + '/uploads/new-customer.png',
      path: '/create-new-cif-and-account/create-new-account',
    },
    {
      label: 'Linked Entities Listings',
      imageUrl: imageBaseUrl + '/uploads/existing-account.png',
      path: '/branch-maker/linked-entities/linked-entities-listing',
    },
    {
      label: 'Existing CIF Create New Account Only',
      imageUrl: imageBaseUrl + '/uploads/existing-account.png',
      path: '/existing-cif/existing-cif-listing/branch-maker',
    },
    {
      label: 'KYC Update',
      imageUrl: imageBaseUrl + '/uploads/new-cif.png',
      path: '/kyc/kyc-update-list',
    },
    {
      label: 'Bulk Account Opening',
      imageUrl: imageBaseUrl + '/uploads/new-customer.png',
      path: '/bulk-account-opening/master-batch-list',
    },
  ],
  branchchecker: [
    {
      label: 'Review Customer Account Information',
      imageUrl: imageBaseUrl + '/uploads/existing-account.png',
      path: '/branch-checker/new-cif-and-account-list',
    },
    {
      label: 'Linked Entities Listings',
      imageUrl: imageBaseUrl + '/uploads/new-customer.png',
      path: '/branch-checker/linked-entities/linked-entities-listing',
    },
    {
      label: 'Existing CIF New Account Listing',
      imageUrl: imageBaseUrl + '/uploads/existing-account.png',
      path: '/existing-cif/existing-cif-listing/branch-checker',
    },
    {
      label: 'KYC Update List',
      imageUrl: imageBaseUrl + '/uploads/new-cif.png',
      path: '/kyc/kyc-update-list',
    },
    {
      label: 'Account Status Record',
      imageUrl: imageBaseUrl + '/uploads/new-customer.png',
      path: '/account/account-listing',
    },
  ],

  cpumaker: [
    {
      label: 'Review Customer Account Information',
      imageUrl: imageBaseUrl + '/uploads/existing-account.png',
      path: '/cpu-maker/new-cif-and-account-list',
    },
    {
      label: 'Create CIF Number',
      imageUrl: imageBaseUrl + '/uploads/new-cif.png',
      path: '/new-cif/new-cif-listing',
    },
    {
      label: 'KYC Update',
      imageUrl: imageBaseUrl + '/uploads/new-cif.png',
      path: '/kyc/kyc-update-list',
    },
    {
      label: 'Account Status Record',
      imageUrl: imageBaseUrl + '/uploads/new-customer.png',
      path: '/account/account-listing',
    },
  ],

  cpuchecker: [
    {
      label: 'Review Customer Account Information',
      imageUrl: imageBaseUrl + '/uploads/existing-account.png',
      path: '/cpu-checker/new-cif-and-account-list',
    },
    {
      label: 'Create CIF Number',
      imageUrl: imageBaseUrl + '/uploads/new-cif.png',
      path: '/new-cif/new-cif-listing/checker',
    },
    {
      label: 'KYC Update list',
      imageUrl: imageBaseUrl + '/uploads/new-cif.png',
      path: '/kyc/kyc-update-list',
    },
    {
      label: 'Account Status Record',
      imageUrl: imageBaseUrl + '/uploads/new-customer.png',
      path: '/account/account-listing',
    },
  ],
}
