import axios from 'axios'
import {useQuery, useMutation} from 'react-query'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const BRANCH_CHECKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_API = `${API_URL}/newcif-newaccount/branch-checker-list`

const QUERY_KEY = 'branchCheckerNewCIFAndAccountList'

export const useGetBranchCheckerNewCIFandAccountList = (params: ParamsModel) => {
  return useQuery([QUERY_KEY, params], async () => {
    const {data} = await axios.get(BRANCH_CHECKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_API, {
      params,
    })
    return data
  })
}
