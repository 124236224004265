import {ErrorMessage, Form, Formik} from 'formik'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as masterDataRedux from 'src/app/modules/masterData/redux'
import FormCheckbox from 'src/cms/helpers/components/forms/FormCheckbox'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {RootState} from 'src/setup'
import * as Yup from 'yup'
import {DocumentTypeModel, MasterDataModel} from '../../../masterData/Model'
import FileUploadComponent from '../components/FileUpload'

import {set} from 'lodash'
import {IoMdRefresh} from 'react-icons/io'
import {toast} from 'react-toastify'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import useUpdateAccountPreliminary from 'src/app/queries/Account Preliminary/patch/useUpdateAccountPreliminary'
import useUpdateCorporateAccount from 'src/app/queries/corporateAccountPreliminary/patch/useUpdateCorporateAccount'
import UpdateFileUpload from './FileUpload/UpdateFileUpload'
import {nanoid} from 'nanoid'
import {error} from 'console'

type InputChangeEvent = React.ChangeEvent<HTMLInputElement>
interface RouteParams {
  id: string
}

const SAVE_FORM_VALIDATION = Yup.object().shape({
  accountTypeId: Yup.string().required('Account Type is required'),
  salutation: Yup.string().required('Salutation is required'),
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  mobile_number: Yup.string()
    .matches(/^9\d{9}$/, 'Mobile Number must be exactly 10 digits and start with 9')
    .required('Mobile Number is required'),
})

const SAVE_FORM_VALIDATION_CORPORATE = Yup.object().shape({
  accountTypeId: Yup.string().required('Account Type is required'),
  full_name: Yup.string().required('Full Name is required'),
  short_name: Yup.string().required('Short Name is required'),
  salutation: Yup.string().required('Salutation is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  mobile_number: Yup.string()
    .matches(/^9\d{9}$/, 'Mobile Number must be exactly 10 digits and start with 9')
    .required('Mobile Number is required'),
  customerCategoryId: Yup.string().required('Customer Category is required'),
  pep_confirmation: Yup.string().required('PEP Confirmation is required'),
})

const FORM_VALIDATION = Yup.object().shape({
  accountTypeId: Yup.string().required('Account Type is required'),
  full_name: Yup.string().when('accountTypeId', {
    is: (value: any) => ['Joint', 'Corporate', 'Bank'].includes(value),
    then: Yup.string().required('Full Name is required'),
  }),
  salutation: Yup.string().required('Salutation is required'),
  short_name: Yup.string().when('accountTypeId', {
    is: (value: any) => ['Joint', 'Corporate', 'Bank'].includes(value),
    then: Yup.string().required('Short Name is required'),
  }),
  first_name: Yup.string().when('accountTypeId', {
    is: (value: string) => ['Individual', 'Joint', 'Minor'].includes(value),
    then: Yup.string()
      .matches(/^[A-Za-z]+$/, 'First Name must contain only letters')
      .required('First Name is required'),
  }),
  middle_name: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, 'Middle Name must contain only alphabets')
    .nullable(),
  last_name: Yup.string().when('accountTypeId', {
    is: (value: string) => ['Individual', 'Joint', 'Minor'].includes(value),
    then: Yup.string()
      .matches(/^[A-Za-z]+$/, 'Last Name must contain only letters')
      .required('Last Name is required'),
  }),
  idTypeId: Yup.string().required('ID Type is required'),
  id_number: Yup.string()
    .matches(/^[0-9\-\/]+$/, 'ID Number can only contain numbers, dashes, and slashes')
    .required('ID Number is required'),
  pep_confirmation: Yup.string().required('PEP Confirmation is required'),
  ancillary_services_request: Yup.string().when('accountTypeId', {
    is: (value: any) => ['Individual', 'Joint', 'Minor'].includes(value),
    then: Yup.string().required('Ancillary Services Request  is required'),
  }),
  mobile_banking: Yup.boolean().when('ancillary_services_request', {
    is: 'YES',
    then: Yup.boolean().nullable(),
  }),
  debit_card: Yup.boolean().when('ancillary_services_request', {
    is: 'YES',
    then: Yup.boolean().nullable(),
  }),
  mobile_banking_no_reason: Yup.string()
    .when(['ancillary_services_request', 'mobile_banking'], {
      is: (ancillary_services_request: any, mobile_banking: any) =>
        ancillary_services_request === 'YES' &&
        (mobile_banking == false || mobile_banking == '' || mobile_banking == null),
      then: Yup.string().required('Reason for Mobile Banking Rejection is required'),
    })
    .when(['ancillary_services_request', 'accountTypeId'], {
      is: (ancillary_services_request: any, accountTypeId: any) =>
        ancillary_services_request === 'NO' &&
        ['Individual', 'Joint', 'Minor'].includes(accountTypeId),
      then: Yup.string().required('Reason for Mobile Banking Rejection is required'),
    }),

  debit_card_no_reason: Yup.string()
    .when(['ancillary_services_request', 'debit_card'], {
      is: (ancillary_services_request: any, debit_card: any) =>
        ancillary_services_request === 'YES' &&
        (debit_card == false || debit_card == '' || debit_card == null),
      then: Yup.string().required('Reason for Debit Card Rejection is required'),
    })
    .when(['ancillary_services_request', 'accountTypeId'], {
      is: (ancillary_services_request: any, accountTypeId: any) =>
        ancillary_services_request === 'NO' &&
        ['Individual', 'Joint', 'Minor'].includes(accountTypeId),
      then: Yup.string().required('Reason for Debit Card Rejection is required'),
    }),
  pepCifSearch: Yup.boolean().oneOf([true], 'You must check the box to proceed'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  mobile_number: Yup.string()
    .matches(/^9\d{9}$/, 'Mobile Number must be exactly 10 digits and start with 9')
    .required('Mobile Number is required'),
  tole: Yup.string().required('Street Name/Tole is required'),
  house_number: Yup.string().nullable(),
  customerCategoryId: Yup.string().required('Customer Category is required'),
  nationality: Yup.string().required('Nationality is required'),
  language: Yup.string().required('Language is required'),
  taxGroupId: Yup.string().required('Tax Group is required'),
  account_currency_id: Yup.string().required('Account Currency is required'),
  cheque_book_name: Yup.string().required('Cheque Book Name is required'),
  exposureId: Yup.string().required('Exposure is required'),
  customerClassificationId: Yup.string().required('Customer Classification is required'),
  branchLocation: Yup.string().required('Location (Branch Location Code) is required'),
  location: Yup.string().required('Location is required'),
  aml_risk_rating: Yup.string().required('AML Risk Rating is required'),
  locationCode: Yup.string().required('Home Branch (Location Code) is required'),
  mediaId: Yup.string().required('Media is required'),
  country_id: Yup.string().required('Country is required'),
  institutionalId: Yup.string().required('insitutional Number is required'),
  guardian_name: Yup.string().when('accountTypeId', {
    is: 'Minor',
    then: Yup.string().required('Guardian Name is required'),
  }),
  annual_income: Yup.string().when('accountTypeId', {
    is: (value: any) => ['Corporate', 'Bank'].includes(value),
    then: Yup.string().required('Annual Income is required'),
  }),
  biometric_verification: Yup.boolean(),
  accountClassId: Yup.string().required('Account Class is required'),
})

const pepConfirmationOptions = [
  {label: 'YES', value: 'YES'},
  {label: 'NO', value: 'NO'},
]

const ancillaryServicesRequestOptions = [
  {label: 'YES', value: 'YES'},
  {label: 'NO', value: 'NO'},
]

const institutionalList = [
  {label: 'Yes', value: 'YES'},
  {label: 'No', value: 'NO'},
]

export const handleUppercaseChange =
  (handleChange: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    const {type, value, selectionStart, selectionEnd} = target

    const upperCasedValue = value.toUpperCase()

    target.value = upperCasedValue
    handleChange(e)

    // Restore the cursor position only for text and textarea types
    if (type === 'text' || type === 'textarea') {
      requestAnimationFrame(() => {
        target.setSelectionRange(selectionStart, selectionEnd)
      })
    }
  }

const UpdateAndReview = () => {
  const {id} = useParams<RouteParams>()
  const {state: newCIFAndAccountDetails}: {state: any} = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()
  const [uploadedFiles, setUploadedFiles] = useState({})
  const [isSave, setIsSave] = useState(false)
  const {data, loading}: {data: any; loading: boolean} = useSelector(
    (state: RootState) => state.accountPreliminary
  )
  const {mutate: updateAccount, isLoading: updateAccountLoading} = useUpdateAccountPreliminary()
  const {mutate: updateCorporateAccount, isLoading: updateCorporateAccountLoading} =
    useUpdateCorporateAccount()
  const {data: corporateData, loading: corporateLoading}: {data: any; loading: boolean} =
    useSelector((state: RootState) => state.accountPreliminaryCorporate)
  const {data: documentData, loading: documentLoading}: {data: any; loading: boolean} = useSelector(
    (state: RootState) => state.accountPreliminaryDocuments
  )
  // Redux States
  const {
    data: accountTypes,
    loading: accountTypesLoading,
  }: {data: MasterDataModel[]; loading: boolean} = useSelector(
    (state: RootState) => state?.accountTypeList
  )
  const {data: customerCategories}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state?.customerCategoryList
  )
  const {data: customerClassifications}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state?.customerClassificationList
  )
  const {data: accountClasses}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state?.accountClassList
  )
  const {data: taxGroups}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state?.taxGroupList
  )
  const {data: taxCategories}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state?.taxCategoryList
  )
  const {data: mediaList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state?.mediaList
  )
  const {
    data: salutationList,
    loading: salutationLoading,
  }: {data: MasterDataModel[]; loading: boolean} = useSelector(
    (state: RootState) => state?.salutationList
  )
  const {data: idTypesList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state?.idTypeList
  )
  const {data: exposureList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state?.exposureList
  )
  const {data: riskCategoryList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state?.riskCategoryList
  )
  const {data: guardianTypeList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state?.guardianTypeList
  )
  const {data: ancillaryRemarkList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state?.ancillaryRemarkList
  )
  const {data: countriesList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state?.countriesList
  )
  const {data: nationalityList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state?.nationalitiesList
  )
  const {data: currencyLists}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state?.currenciesList
  )
  const {
    data: documentTypeList,
    loading: documentTypeLoading,
  }: {data: DocumentTypeModel[]; loading: boolean} = useSelector(
    (state: RootState) => state?.documentTypeList
  )

  // const {data: newCIFAndAccountDetails, loading: detailsLoading} = useSelector(
  //   (state: RootState) => state.branchCheckerNewCIFAndAccountDetailsById
  // )

  const [seletctedAccountType, setSelectedAccountType] = useState<string>(
    newCIFAndAccountDetails?.accountType
  )
  useEffect(() => {
    dispatch(masterDataRedux.actions.getAllMasterData())
  }, [])
  useEffect(() => {
    dispatch(masterDataRedux.actions.getCustomerClassification())
  }, [])
  useEffect(() => {
    dispatch(masterDataRedux.actions.getDocumentTypes())
  }, [])

  // useEffect(() => {
  //   dispatch(newCIFandAccountListRedux.actions.getBranchCheckerNewCIFandAccountDetailsByIdStart(id))
  // }, [id])

  const handleFileUpload = (file: any, documentType: any) => {
    setUploadedFiles((prevFiles) => ({
      ...prevFiles,
      [documentType]: file,
    }))
  }

  const checkCorporateORBank =
    seletctedAccountType !== 'Corporate' && seletctedAccountType !== 'Bank'

  const [uploadDocumentsTypeId, setUploadDocumentsTypeId] = useState<string[]>([])
  const [missingDocs, setMissingDocs] = useState([])
  const [resetUploads, setResetUploads] = useState<boolean>(false)
  const [reinitializeValues, setReinitializeValues] = useState<boolean>(false)
  const getRequiredDocuments = (title: string) => {
    const accountType = accountTypes.find((type) => type.title === title)
    return accountType ? accountType?.documents : []
  }

  const findOutmissingDocuments = () => {
    const requiredDocuments = getRequiredDocuments(seletctedAccountType) || []
    let missingDocs = requiredDocuments.filter(
      (doc: any) => !uploadDocumentsTypeId.includes(doc?.id)
    )

    const specialDocumentIds = new Set([
      'fccc0058-a453-4ba7-a203-d1ef938d72bc',
      'deefda48-919f-44ab-9301-b086d60a03da',
      '8e0f608a-39b8-48d5-86f0-aa6a4bbced80',
      '7e49648e-3793-44bb-9bb2-df9a83b3e215',
      'ca7e9e19-038c-4381-a217-694c236aba85',
      '5e7948b2-f684-43a0-b05e-03b70a57854b',
    ])
    const hasSpecialDocument = uploadDocumentsTypeId.some((id) => specialDocumentIds.has(id))

    if (hasSpecialDocument) {
      missingDocs = missingDocs.filter(
        (doc: any) =>
          doc.id !== 'fccc0058-a453-4ba7-a203-d1ef938d72bc' &&
          doc.id !== 'deefda48-919f-44ab-9301-b086d60a03da' &&
          doc.id !== '8e0f608a-39b8-48d5-86f0-aa6a4bbced80' &&
          doc.id !== '7e49648e-3793-44bb-9bb2-df9a83b3e215' &&
          doc.id !== 'ca7e9e19-038c-4381-a217-694c236aba85' &&
          doc.id !== '5e7948b2-f684-43a0-b05e-03b70a57854b'
      )
    }
    setMissingDocs(missingDocs)
  }

  const getExistingFileName = (documentTypeId: string) => {
    const document = newCIFAndAccountDetails?.account_documents?.documents.find(
      (doc: any) => doc.documentType.id === documentTypeId
    )
    return document ? document.file_name : null
  }
  const [uniqueID, setUniqueID] = useState('')

  useEffect(() => {
    const generatedID = nanoid()
    setUniqueID(generatedID)
  }, [])
  return (
    <>
      <div className='heading__component__title fw-bolder mb-5'>Update New Customer Account</div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-9'>
          <Formik
            initialValues={{
              accountTypeId: seletctedAccountType ?? (newCIFAndAccountDetails?.accountType || ''),
              full_name: String(newCIFAndAccountDetails?.full_name || ''),
              short_name: String(newCIFAndAccountDetails?.short_name || ''),
              salutation: newCIFAndAccountDetails?.salutation,
              first_name: String(newCIFAndAccountDetails?.first_name || ''),
              middle_name: String(newCIFAndAccountDetails?.middle_name || ''),
              last_name: String(newCIFAndAccountDetails?.last_name || ''),
              idTypeId: String(
                checkCorporateORBank
                  ? newCIFAndAccountDetails?.id_type_id?.id || ''
                  : idTypesList.find((item) => item.title?.includes('REGISTRATION NO'))?.id || ''
              ),
              id_number: String(newCIFAndAccountDetails?.id_number || ''),
              pep_confirmation: newCIFAndAccountDetails?.pep_confirmation || '',
              ancillary_services_request: newCIFAndAccountDetails?.ancillary_services_request || '',
              mobile_banking_no_reason: newCIFAndAccountDetails?.mobile_banking_no_reason?.id || '',
              other_mobile_banking_no_reason:
                newCIFAndAccountDetails?.other_mobile_banking_no_reason || '',
              other_debit_card_no_reason: newCIFAndAccountDetails?.other_debit_card_no_reason || '',
              debit_card_no_reason: newCIFAndAccountDetails?.debit_card_no_reason?.id || '',
              mobile_banking: newCIFAndAccountDetails?.mobile_banking == 1 ? true : false,
              debit_card: newCIFAndAccountDetails?.debit_card == 1 ? true : false,
              email: newCIFAndAccountDetails?.email || '',
              mobile_number: newCIFAndAccountDetails?.mobile_number || '',
              tole: String(newCIFAndAccountDetails?.tole || ''),
              house_number: String(newCIFAndAccountDetails?.house_number || ''),
              customerCategoryId: String(newCIFAndAccountDetails?.customerCategoryId?.code || ''),
              country_id: newCIFAndAccountDetails?.country_id?.id || '',
              nationality: newCIFAndAccountDetails?.nationality?.id || '',
              language: newCIFAndAccountDetails?.language,
              accountClassId: newCIFAndAccountDetails?.accountClassId?.account_class || '',
              taxCategoryId: checkCorporateORBank
                ? taxCategories.find((taxCategory) => taxCategory.title?.includes('Individual'))
                    ?.cbs_code
                : seletctedAccountType === 'Corporate'
                ? taxCategories.find((taxCategory) => taxCategory.title?.includes('Corporate'))
                    ?.cbs_code
                : seletctedAccountType === 'Bank'
                ? taxCategories.find((taxCategory) => taxCategory.title?.includes('NoTax'))
                    ?.cbs_code
                : null,
              taxGroupId: checkCorporateORBank
                ? taxCategories.find((taxCategory) => taxCategory.title?.includes('Individual'))
                    ?.cbs_code
                : seletctedAccountType === 'Corporate'
                ? taxCategories.find((taxCategory) => taxCategory.title?.includes('Corporate'))
                    ?.cbs_code
                : seletctedAccountType === 'Bank'
                ? taxCategories.find((taxCategory) => taxCategory.title?.includes('NoTax'))
                    ?.cbs_code
                : null,
              account_currency_id: newCIFAndAccountDetails?.account_currency_id?.cbs_code || '',
              cheque_book_name: newCIFAndAccountDetails?.cheque_book_name,
              exposureId: newCIFAndAccountDetails?.exposureId?.code || '',
              customerClassificationId: newCIFAndAccountDetails?.customerClassificationId?.id || '',
              branchLocation: newCIFAndAccountDetails?.branch_location_code || '',
              aml_risk_rating: newCIFAndAccountDetails?.aml_risk_rating || '',
              locationCode: newCIFAndAccountDetails?.location_code || '',
              institutionalId: newCIFAndAccountDetails?.institutional || '',
              mediaId: mediaList?.find((media) => media.title?.includes('CKPU'))?.code,
              guardian_name: newCIFAndAccountDetails?.guardian_name || '',
              annual_income: newCIFAndAccountDetails?.annual_income || '',
              biometric_verification: newCIFAndAccountDetails?.biometric_verification || false,
              email_verification: newCIFAndAccountDetails?.email_verification || '',
              location: newCIFAndAccountDetails?.location || '',
              mobile_verification: newCIFAndAccountDetails?.mobile_verification || '',
              ancillary_remarks: newCIFAndAccountDetails?.ancillary_remarks || '',
              risk_category: newCIFAndAccountDetails?.risk_category || '',
              pepCifSearch: newCIFAndAccountDetails?.pepCifSearch || false,
            }}
            enableReinitialize={!reinitializeValues}
            validationSchema={
              isSave
                ? seletctedAccountType === 'Corporate' || seletctedAccountType === 'Bank'
                  ? SAVE_FORM_VALIDATION_CORPORATE
                  : SAVE_FORM_VALIDATION
                : FORM_VALIDATION
            }
            onSubmit={(values: any, {resetForm}: any) => {
              if (values.ancillary_services_request === 'NO') {
                values.mobile_banking = false
                values.debit_card = false
              }
              values.action = isSave ? 'save' : 'save_and_continue'
              values.temp_customer_id = uniqueID
              const filteredValues = Object.keys(values).reduce((acc: any, key) => {
                if (key === 'biometric_verification') {
                  acc[key] = values[key] ? 1 : 0
                } else if (values[key] !== '' && values[key] !== null) {
                  acc[key] = values[key]
                }
                return acc
              }, {})
              // values.risk_category = 'Risk Category'
              if (seletctedAccountType === 'Corporate' || seletctedAccountType === 'Bank') {
                if (missingDocs.length < 1) {
                  updateCorporateAccount(
                    {data: filteredValues, id: id},
                    {
                      onSuccess: () => {
                        history.push('/branch-maker/new-cif-and-account-list')
                      },
                    }
                  )
                } else {
                  toast.error('Please upload all required documents')
                }
              } else {
                if (missingDocs.length < 1) {
                  updateAccount(
                    {data: filteredValues, id: id},
                    {
                      onSuccess: () => {
                        history.push('/branch-maker/new-cif-and-account-list')
                      },
                    }
                  )
                } else {
                  toast.error('Please upload all required documents')
                }
              }
            }}
          >
            {({handleSubmit, handleChange, values, errors, touched, setFieldValue, resetForm}) => {
              useEffect(() => {
                if (
                  seletctedAccountType === 'Individual' ||
                  seletctedAccountType === 'Joint' ||
                  seletctedAccountType === 'Minor'
                ) {
                  setFieldValue(
                    'taxCategoryId',
                    taxCategories.find((taxCategory) => taxCategory.title?.includes('Individual'))
                      ?.cbs_code
                  )
                  setFieldValue(
                    'taxGroupId',
                    taxCategories.find((taxGroup) => taxGroup.title?.includes('Individual'))
                      ?.cbs_code
                  )
                  setFieldValue('institutionalId', institutionalList[1].value)
                  setFieldValue('annual_income', '')
                } else if (seletctedAccountType === 'Corporate') {
                  setFieldValue(
                    'taxCategoryId',
                    taxCategories.find((taxCategory) => taxCategory.title?.includes('Corporate'))
                      ?.cbs_code
                  )
                  setFieldValue(
                    'taxGroupId',
                    taxCategories.find((taxGroup) => taxGroup.title?.includes('Corporate'))
                      ?.cbs_code
                  )
                  setFieldValue('institutionalId', institutionalList[0].value)
                  setFieldValue(
                    'idTypeId',
                    idTypesList.find((item) => item.title?.includes('REGISTRATION NO'))?.id
                  )
                  setFieldValue('mobile_banking', false)
                  setFieldValue('debit_card', false)
                  setFieldValue('ancillary_services_request', '')
                  setFieldValue('mobile_banking_no_reason', '')
                  setFieldValue('debit_card_no_reason', '')
                  setFieldValue('other_mobile_banking_no_reason', '')
                  setFieldValue('other_debit_card_no_reason', '')
                  setFieldValue('guardian_name', '')
                  setFieldValue('first_name', '')
                  setFieldValue('middle_name', '')
                  setFieldValue('last_name', '')
                } else if (seletctedAccountType === 'Bank') {
                  setFieldValue(
                    'taxCategoryId',
                    taxCategories.find((taxCategory) => taxCategory.title?.includes('NoTax'))
                      ?.cbs_code
                  )
                  setFieldValue(
                    'taxGroupId',
                    taxCategories.find((taxGroup) => taxGroup.title?.includes('NoTax'))?.cbs_code
                  )
                  setFieldValue(
                    'idTypeId',
                    idTypesList.find((item) => item.title?.includes('REGISTRATION NO'))?.id
                  )
                  setFieldValue('institutionalId', institutionalList[0].value)
                  setFieldValue('mobile_banking', false)
                  setFieldValue('debit_card', false)
                  setFieldValue('ancillary_services_request', '')
                  setFieldValue('mobile_banking_no_reason', '')
                  setFieldValue('debit_card_no_reason', '')
                  setFieldValue('other_mobile_banking_no_reason', '')
                  setFieldValue('other_debit_card_no_reason', '')
                  setFieldValue('guardian_name', '')
                  setFieldValue('first_name', '')
                  setFieldValue('middle_name', '')
                  setFieldValue('last_name', '')
                }
              }, [seletctedAccountType])

              useEffect(() => {
                const firstNameValue = values?.first_name || ''
                const middleNameValue = values?.middle_name || ''
                const lastNameValue = values?.last_name || ''
                const newChequeBookName =
                  `${firstNameValue} ${middleNameValue} ${lastNameValue}`.trim()
                setFieldValue(
                  'cheque_book_name',
                  seletctedAccountType == 'Corporate' || seletctedAccountType == 'Bank'
                    ? values?.full_name || ''
                    : newChequeBookName || ''
                )
              }, [values.first_name, values.middle_name, values.last_name, values.full_name])

              useEffect(() => {
                const processedFullName = values?.full_name
                  .replace(/[^a-zA-Z ]/g, '')
                  .replace(/\s+/g, ' ')
                const generatedShortName = processedFullName.slice(0, 10)
                setFieldValue('short_name', generatedShortName ?? '')
              }, [values.full_name])

              return (
                <Form onSubmit={handleSubmit} className='row'>
                  <div className={`row mb-6`}>
                    <label className={`mb-1 fw-bolder fs-6`}>
                      <span className={'required'}>Account Type</span>
                    </label>
                    <div className='col-md-12' role='group' aria-labelledby='my-radio-group'>
                      <div className='row'>
                        {accountTypesLoading ? (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        ) : (
                          accountTypes?.map((accountType) => (
                            <FormRadio
                              key={accountType.id}
                              containerClassName='col-lg-2'
                              label='Account Type'
                              name='accountTypeId'
                              type='radio'
                              onChange={(e) => {
                                handleChange(e)
                                setSelectedAccountType(e.target?.getAttribute('data-label'))
                              }}
                              errors={errors}
                              touched={touched}
                              required={true}
                              checkBoxText={accountType?.title}
                              data-label={accountType?.title}
                              value={accountType?.title}
                            />
                          ))
                        )}
                        <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                          <ErrorMessage
                            name='accountTypeId'
                            component='div'
                            className='field-error-message'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {(seletctedAccountType === 'Joint' ||
                    seletctedAccountType === 'Corporate' ||
                    seletctedAccountType === 'Bank') && (
                    <>
                      <div className='col-md-4'>
                        <FormTextBox
                          containerClassName='col-md-12'
                          type='text'
                          placeholder='Full Name'
                          label='Full Name'
                          name='full_name'
                          disabled={false}
                          onChange={handleUppercaseChange(handleChange)}
                          errors={errors}
                          touched={touched}
                          required={true}
                          data-cy='add-full-name-text-field'
                        />
                      </div>
                      <div className='col-md-4'>
                        <FormTextBox
                          containerClassName='col-md-12'
                          type='text'
                          placeholder='Short Name'
                          label='Short Name'
                          name='short_name'
                          disabled={true}
                          onChange={handleUppercaseChange(handleChange)}
                          errors={errors}
                          touched={touched}
                          required={true}
                          data-cy='add-short-name-text-field'
                        />
                      </div>
                    </>
                  )}

                  <div className={`row mb-6`}>
                    <label className={`mb-1 fw-bolder fs-6`}>
                      <span className={'required'}>Salutation</span>
                    </label>
                    <div className='col-md-12' role='group' aria-labelledby='my-radio-group'>
                      <div className='row'>
                        {salutationLoading ? (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        ) : (
                          salutationList?.map((salutation) => (
                            <FormRadio
                              key={salutation.id}
                              containerClassName='col-lg-1'
                              label='Salutation'
                              name='salutation'
                              type='radio'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              required={true}
                              checkBoxText={salutation.title}
                              value={salutation.title}
                            />
                          ))
                        )}
                        <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                          <ErrorMessage
                            name='salutation'
                            component='div'
                            className='field-error-message'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {seletctedAccountType !== 'Corporate' && seletctedAccountType !== 'Bank' && (
                    <>
                      <div className='col-md-4 col-sm-6 col-xs-12'>
                        <FormTextBox
                          containerClassName='col-md-12'
                          labelClassName='col-md-12'
                          type='text'
                          placeholder='First Name'
                          label='First Name'
                          name='first_name'
                          disabled={false}
                          onChange={handleUppercaseChange(handleChange)}
                          errors={errors}
                          touched={touched}
                          required={true}
                          data-cy='add-first-name-text-field'
                        />
                      </div>
                      <div className='col-md-4 col-sm-6 col-xs-12'>
                        <FormTextBox
                          containerClassName='col-md-12'
                          labelClassName='col-md-12'
                          type='text'
                          placeholder='Middle Name'
                          label='Middle Name'
                          name='middle_name'
                          disabled={false}
                          onChange={handleUppercaseChange(handleChange)}
                          errors={errors}
                          touched={touched}
                          data-cy='add-middle-name-text-field'
                        />
                      </div>
                      <div className='col-md-4 col-sm-6 col-xs-12'>
                        <FormTextBox
                          containerClassName='col-md-12'
                          labelClassName='col-md-12'
                          type='text'
                          placeholder='Last Name'
                          label='Last Name'
                          name='last_name'
                          disabled={false}
                          onChange={handleUppercaseChange(handleChange)}
                          errors={errors}
                          touched={touched}
                          required={true}
                          data-cy='add-last-name-text-field'
                        />
                      </div>
                    </>
                  )}
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      label='ID Type'
                      name='idTypeId'
                      isClearable={true}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={idTypesList?.map((idType) => ({
                        label: idType.title,
                        value: idType.id,
                      }))}
                      required={true}
                      values={values}
                      setFieldValue={setFieldValue}
                      disabled={
                        seletctedAccountType === 'Corporate' || seletctedAccountType === 'Bank'
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      containerClassName='col-md-12'
                      labelClassName='col-md-12'
                      type='text'
                      placeholder='ID Number'
                      label='ID Number'
                      name='id_number'
                      disabled={false}
                      onChange={handleUppercaseChange(handleChange)}
                      errors={errors}
                      touched={touched}
                      required={true}
                      data-cy='add-id-number-text-field'
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      label='PEP Confirmation'
                      name='pep_confirmation'
                      required={true}
                      isClearable={true}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={pepConfirmationOptions}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  {checkCorporateORBank && (
                    <div className='col-sm-12'>
                      <FormSelect
                        labelClassName='col-md-12'
                        containerClassName='col-md-4'
                        label='Ancillary Services Request'
                        name='ancillary_services_request'
                        required={true}
                        isClearable={true}
                        onChange={(option: any) => {
                          handleChange(option)
                          // Reset fields when changing the selection
                          setFieldValue('mobile_banking_no_reason', '')
                          setFieldValue('debit_card_no_reason', '')
                        }}
                        errors={errors}
                        touched={touched}
                        options={ancillaryServicesRequestOptions}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                  )}

                  {values?.ancillary_services_request === 'NO' && checkCorporateORBank && (
                    <>
                      <div className='col-md-4 col-sm-12 col-xs-12'>
                        <FormSelect
                          labelClassName='col-md-12'
                          containerClassName='col-md-12'
                          label='Reason for Mobile Banking Rejection (Branch Maker)'
                          name='mobile_banking_no_reason'
                          isClearable={true}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          options={ancillaryRemarkList?.map((item) => ({
                            label: item.remarks?.toLocaleUpperCase() || '',
                            value: item.id,
                          }))}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                      <div className='col-md-4 col-sm-6 col-xs-12'>
                        {values?.mobile_banking_no_reason ===
                          ancillaryRemarkList.find((list) => list.remarks?.includes('Other'))
                            ?.id && (
                          <FormTextBox
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            type='text'
                            placeholder='Reason For Mobile Banking Rejection'
                            label='Other reason for mobile banking rejection'
                            name='other_mobile_banking_no_reason'
                            disabled={false}
                            onChange={handleUppercaseChange(handleChange)}
                            errors={errors}
                            touched={touched}
                            as='textarea'
                          />
                        )}
                      </div>
                      <div className='col-md-4'></div>
                      <div className='col-md-4 col-sm-12 col-xs-12'>
                        <FormSelect
                          labelClassName='col-md-12'
                          containerClassName='col-md-12'
                          label='Reason for Debit Card Rejection (Branch Maker)'
                          name='debit_card_no_reason'
                          isClearable={true}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          options={ancillaryRemarkList?.map((item) => ({
                            label: item.remarks?.toUpperCase() || '',
                            value: item.id,
                          }))}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                      <div className='col-md-4 col-sm-6 col-xs-12'>
                        {values?.debit_card_no_reason ===
                          ancillaryRemarkList.find((list) => list.remarks?.includes('Other'))
                            ?.id && (
                          <FormTextBox
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            type='text'
                            placeholder='Reason For Debit Card Rejection'
                            label='Other reason for debit card rejection'
                            name='other_debit_card_no_reason'
                            disabled={false}
                            onChange={handleUppercaseChange(handleChange)}
                            errors={errors}
                            touched={touched}
                            as='textarea'
                            required={true}
                          />
                        )}
                      </div>
                    </>
                  )}
                  {values?.ancillary_services_request === 'YES' && checkCorporateORBank && (
                    <>
                      <div className='row py-4'>
                        <div className='col-md-4 col-sm-4 col-xs-12'>
                          <FormCheckbox
                            labelClassName='col-sm-12'
                            containerClassName='col-md-12'
                            name='mobile_banking'
                            checked={values.mobile_banking}
                            touched={touched}
                            errors={errors}
                            onChange={(e) => {
                              handleChange(e)
                              if (e.target.checked) {
                                setFieldValue('mobile_banking_no_reason', '')
                              }
                            }}
                            checkBoxText={'Mobile Banking'}
                            data-cy='setting-check-box-field'
                            required={true}
                          />
                        </div>
                        {!values.mobile_banking && (
                          <>
                            <div className='col-md-4 col-sm-6 col-xs-12'>
                              <FormSelect
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                label='Reason for Mobile Banking Rejection (Branch Maker)'
                                name='mobile_banking_no_reason'
                                isClearable={true}
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                options={ancillaryRemarkList?.map((item) => ({
                                  label: item.remarks?.toLocaleUpperCase() || '',
                                  value: item.id,
                                }))}
                                values={values}
                                setFieldValue={setFieldValue}
                              />
                            </div>
                            {values?.mobile_banking_no_reason ===
                              ancillaryRemarkList.find((list) => list.remarks?.includes('Other'))
                                ?.id && (
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  type='text'
                                  placeholder='Reason For Mobile Banking Rejection'
                                  label='Other reason for mobile banking rejection'
                                  name='other_mobile_banking_no_reason'
                                  disabled={false}
                                  onChange={handleUppercaseChange(handleChange)}
                                  errors={errors}
                                  touched={touched}
                                  as='textarea'
                                  required={true}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      <div className='row'>
                        <div className='col-md-4 col-sm-6 col-xs-12'>
                          <FormCheckbox
                            labelClassName='col-sm-12'
                            containerClassName='col-md-12'
                            name='debit_card'
                            touched={touched}
                            checked={values.debit_card}
                            errors={errors}
                            onChange={(e) => {
                              handleChange(e)
                              if (e.target.checked) {
                                setFieldValue('debit_card_no_reason', '')
                              }
                            }}
                            checkBoxText={'Debit Card'}
                            data-cy='setting-check-box-field'
                            required={true}
                          />
                        </div>
                        {!values.debit_card && (
                          <>
                            <div className='col-md-4 col-sm-6 col-xs-12'>
                              <FormSelect
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                label='Reason for Debit Card Rejection (Branch Maker)'
                                name='debit_card_no_reason'
                                isClearable={true}
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                options={ancillaryRemarkList?.map((item) => ({
                                  label: item.remarks?.toUpperCase() || '',
                                  value: item.id,
                                }))}
                                values={values}
                                setFieldValue={setFieldValue}
                              />
                            </div>
                            {values?.debit_card_no_reason ===
                              ancillaryRemarkList.find((list) => list.remarks?.includes('Other'))
                                ?.id && (
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  type='text'
                                  placeholder='Reason For Debit Card Rejection'
                                  label='Other reason for debit card rejection'
                                  name='other_debit_card_no_reason'
                                  disabled={false}
                                  onChange={handleUppercaseChange(handleChange)}
                                  errors={errors}
                                  touched={touched}
                                  as='textarea'
                                  required={true}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}

                  <div className='row  mb-6 py-4  border-bottom align-items-center col-sm-12 col-xs-12'>
                    <FormCheckbox
                      labelClassName='col-sm-12'
                      containerClassName='col-sm-12'
                      name='pepCifSearch'
                      touched={touched}
                      errors={errors}
                      onChange={handleChange}
                      checked={values.pepCifSearch}
                      checkBoxText={
                        'I hereby acknowledge that I have checked the PEP search & multiple CIF of the customer in the application.'
                      }
                      label='PEP search & multiple CIF'
                      data-cy='CIF-check-box-field'
                      required={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      type='text'
                      placeholder='Email'
                      label='Email'
                      name='email'
                      onChange={handleUppercaseChange(handleChange)}
                      errors={errors}
                      touched={touched}
                      required={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      type='text'
                      placeholder='Mobile Number'
                      label='Mobile No'
                      name='mobile_number'
                      onChange={handleUppercaseChange(handleChange)}
                      errors={errors}
                      touched={touched}
                      required={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      type='text'
                      placeholder='House No.'
                      label='House No'
                      name='house_number'
                      onChange={handleUppercaseChange(handleChange)}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      type='text'
                      placeholder='Street Name/Tole'
                      label='Street Name/Tole'
                      name='tole'
                      onChange={handleUppercaseChange(handleChange)}
                      errors={errors}
                      touched={touched}
                      required={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      label='Customer Category'
                      name='customerCategoryId'
                      isClearable={true}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={customerCategories?.map((customerCategory) => ({
                        label: customerCategory.title,
                        value: customerCategory.code ?? '',
                      }))}
                      values={values}
                      setFieldValue={setFieldValue}
                      required={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      label='Customer Classification'
                      name='customerClassificationId'
                      isClearable={true}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={customerClassifications?.map((customerClassification) => ({
                        label: customerClassification.title,
                        value: customerClassification.id,
                      }))}
                      values={values}
                      setFieldValue={setFieldValue}
                      required={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      label='Country'
                      name='country_id'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={countriesList?.map((country) => ({
                        label: country.title?.toUpperCase(),
                        value: country.id,
                      }))}
                      values={values}
                      setFieldValue={setFieldValue}
                      isClearable={true}
                      required={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      label='Nationality'
                      name='nationality'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={nationalityList?.map((nationality) => ({
                        label: nationality.title?.toUpperCase(),
                        value: nationality?.id,
                      }))}
                      values={values}
                      setFieldValue={setFieldValue}
                      isClearable={true}
                      required={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      label='Language'
                      name='language'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={[{label: 'ENG', value: 'ENG'}]}
                      values={values}
                      setFieldValue={setFieldValue}
                      isClearable={true}
                      required={true}
                      disabled={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      type='text'
                      placeholder='Location Branch Location Code'
                      label='Location Branch( Location Code)'
                      name='branchLocation'
                      onChange={handleUppercaseChange(handleChange)}
                      errors={errors}
                      touched={touched}
                      required={true}
                      disabled={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      label='Tax Category'
                      name='taxCategoryId'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={taxCategories.map((taxCategory) => ({
                        label: taxCategory.title,
                        value: taxCategory.cbs_code ?? '',
                      }))}
                      values={values}
                      setFieldValue={setFieldValue}
                      isClearable={true}
                      required={true}
                      disabled={
                        seletctedAccountType === 'Corporate' || seletctedAccountType === 'Bank'
                          ? false
                          : true
                      }
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      label='Tax Group'
                      name='taxGroupId'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={taxCategories.map((taxCategory) => ({
                        label: taxCategory?.cbs_code ?? '',
                        value: taxCategory?.cbs_code ?? '',
                      }))}
                      values={values}
                      setFieldValue={setFieldValue}
                      isClearable={true}
                      required={true}
                      disabled={
                        seletctedAccountType === 'Corporate' || seletctedAccountType === 'Bank'
                          ? false
                          : true
                      }
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      label='AML Risk Rating'
                      name='aml_risk_rating'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={[
                        {label: 'LOW', value: 'LOW'},
                        {label: 'MEDIUM', value: 'MEDIUM'},
                        {label: 'HIGH', value: 'HIGH'},
                      ]}
                      values={values}
                      setFieldValue={setFieldValue}
                      isClearable={true}
                      required={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      label='Account Class'
                      name='accountClassId'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={accountClasses?.map((accountClassItem) => ({
                        label: accountClassItem?.name?.toUpperCase() || '',
                        value: accountClassItem?.account_class || '',
                      }))}
                      values={values}
                      setFieldValue={setFieldValue}
                      isClearable={true}
                      required={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      label='Account Currency'
                      name='account_currency_id'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={currencyLists?.map((currency) => ({
                        label: currency?.title?.toUpperCase(),
                        value: currency?.cbs_code ?? '',
                      }))}
                      values={values}
                      setFieldValue={setFieldValue}
                      isClearable={true}
                      required={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      type='text'
                      placeholder='Branch Location Code'
                      label='Home Branch( Location Code)'
                      name='locationCode'
                      onChange={handleUppercaseChange(handleChange)}
                      errors={errors}
                      touched={touched}
                      required={true}
                      disabled={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      type='text'
                      placeholder='Location'
                      label='Location'
                      name='location'
                      onChange={handleUppercaseChange(handleChange)}
                      errors={errors}
                      touched={touched}
                      required={true}
                      disabled={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      type='text'
                      placeholder='Cheque Book Name'
                      label='Cheque Book Name'
                      name='cheque_book_name'
                      onChange={handleUppercaseChange(handleChange)}
                      errors={errors}
                      touched={touched}
                      required={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      label='Media'
                      name='mediaId'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={mediaList?.map((media) => ({
                        label: media.title,
                        value: media.code || '',
                      }))}
                      values={values}
                      setFieldValue={setFieldValue}
                      isClearable={true}
                      required={true}
                      disabled={true}
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      label='Insitutional No'
                      name='institutionalId'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={institutionalList?.map((institutional) => ({
                        label: institutional.label,
                        value: institutional.value,
                      }))}
                      values={values}
                      setFieldValue={setFieldValue}
                      isClearable={true}
                      required={true}
                      disabled={
                        seletctedAccountType === 'Corporate' || seletctedAccountType === 'Bank'
                          ? false
                          : true
                      }
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-xs-12'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      label='Exposure'
                      name='exposureId'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={exposureList?.map((item) => ({
                        label: item.title,
                        value: item.code ?? '',
                      }))}
                      values={values}
                      setFieldValue={setFieldValue}
                      isClearable={true}
                      required={true}
                    />
                  </div>
                  {seletctedAccountType === 'Minor' && (
                    <div className='col-md-4 col-sm-6 col-xs-12'>
                      <FormTextBox
                        labelClassName='col-md-12'
                        containerClassName='col-md-12'
                        type='text'
                        placeholder='Guardian Name'
                        label='Guardian Name'
                        name='guardian_name'
                        onChange={handleUppercaseChange(handleChange)}
                        errors={errors}
                        touched={touched}
                        required={true}
                        data-cy='add-guardian-name-text-field'
                      />
                    </div>
                  )}
                  {(seletctedAccountType === 'Corporate' || seletctedAccountType === 'Bank') && (
                    <div className='col-md-4 col-sm-6 col-xs-12'>
                      <FormTextBox
                        labelClassName='col-md-12'
                        containerClassName='col-md-12'
                        type='text'
                        placeholder='Annual Income'
                        label='Annual Income'
                        name='annual_income'
                        onChange={handleUppercaseChange(handleChange)}
                        errors={errors}
                        touched={touched}
                        required={true}
                        data-cy='add-annual-income-text-field'
                      />
                    </div>
                  )}
                  <div className='row  mb-6 py-4  border-bottom align-items-center col-sm-12 col-xs-12'>
                    {seletctedAccountType !== 'Corporate' && seletctedAccountType !== 'Bank' && (
                      <FormCheckbox
                        labelClassName='col-sm-12'
                        containerClassName='col-sm-12'
                        name='biometric_verification'
                        touched={touched}
                        errors={errors}
                        checked={values.biometric_verification}
                        onChange={handleChange}
                        checkBoxText={
                          'I certify that I have completed biometric verification for the customer.'
                        }
                        label='Biometric Verification'
                        data-cy='BiometricVerification-check-box-field'
                        required={false}
                      />
                    )}
                  </div>
                  <div>
                    <p>NOTE: Only one of the Identification Document is mandatory.</p>
                    <p>
                      <span style={{fontWeight: 'bold'}}>
                        Please upload file of size mentioned here:{' '}
                      </span>{' '}
                      PP Size Photo (1 MB), Secondary Photo (1 MB), AOF (10 MB), Signature (1 MB),
                      KYC Form (5 MB), Citizenship (5 MB), Passport (5 MB), Driving License (5 MB),
                      Voter's ID (5 MB), Registration IDs (5 MB), Mandate Form
                    </p>
                  </div>
                  {documentTypeLoading ? (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        overflowX: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#888 #fff',
                      }}
                    >
                      {documentTypeList?.map((documentType) => (
                        <div key={documentType?.id} style={{marginRight: '10px'}}>
                          <UpdateFileUpload
                            documentType={documentType?.title}
                            maxSize={Number(documentType?.max_size)}
                            onChange={handleFileUpload}
                            documentTypeId={documentType?.id}
                            selectedAccountType={seletctedAccountType.toLowerCase()}
                            fileName={getExistingFileName(documentType.id)}
                            customerId={id}
                            resetUploads={resetUploads}
                            setResetUploads={setResetUploads}
                            setUploadDocumentsTypeId={setUploadDocumentsTypeId}
                            isMissing={
                              isSave
                                ? false
                                : missingDocs.some((doc: any) => doc?.id === documentType.id)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  <div className='d-flex justify-content-between my-6'>
                    <div className='col-3 opacity-50'>
                      <div className='d-flex flex-column align-items-center justify-content-center text-center'>
                        <div
                          className='d-flex align-items-center justify-content-center mb-2'
                          style={{cursor: 'pointer'}}
                          onClick={() => {
                            setResetUploads(true)
                            setReinitializeValues(true)
                            resetForm({
                              values: {
                                ...values,
                                full_name: '',
                                short_name: '',
                                salutation: '',
                                first_name: '',
                                middle_name: '',
                                last_name: '',
                                email: '',
                                mobile_number: '',
                                house_number: '',
                                tole: '',
                                customerCategoryId: '',
                                cheque_book_name: '',
                                accountClassId: '',
                                ancillary_services_request: '',
                                guardian_name: '',
                                annual_income: '',
                                pep_confirmation: '',
                                mobile_banking: false,
                                debit_card: false,
                                mobile_banking_no_reason: '',
                                debit_card_no_reason: '',
                                other_mobile_banking_no_reason: '',
                                other_debit_card_no_reason: '',
                                idTypeId: '',
                                id_number: '',
                                pepCifSearch: false,
                              },
                            })
                            setFieldValue('biometric_verification', false)
                            setFieldValue('pepCifSearch', '')
                            toast.success('Form Reset Successfully')
                          }}
                        >
                          <IoMdRefresh size={23} />
                          <span className='h4 ms-3 mb-n1'>Reset Form</span>
                        </div>
                        <span>Note: All the captured data will be cleared</span>
                      </div>
                    </div>
                    <div>
                      <button
                        type='submit'
                        onClick={() => {
                          setIsSave(true)
                          setMissingDocs([])
                        }}
                        className='btn btn-secondary mx-4'
                      >
                        {(updateCorporateAccountLoading || updateAccountLoading) && isSave
                          ? 'Savng.....'
                          : 'Save'}
                      </button>
                      <button
                        type='submit'
                        onClick={() => {
                          findOutmissingDocuments()
                          setIsSave(false)
                        }}
                        className='btn btn-primary'
                      >
                        {(updateCorporateAccountLoading || updateAccountLoading) && !isSave
                          ? 'Save & Continued...'
                          : 'Save & Continue'}
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default UpdateAndReview
