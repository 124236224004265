import axios from 'axios'
import {BULK_ACCOUNT_OPENING} from '../api'
import {toast} from 'react-toastify'
import {useMutation} from 'react-query'

const downloadBatch = async (payload: {batch_id: string}, handleCloseDownloadModal: () => void) => {
  try {
    const response = await axios
      .post(`${BULK_ACCOUNT_OPENING}/download-batch`, payload, {responseType: 'arraybuffer'})
      .then((res) => {
        const blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = 'BatchFile.xlsx'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
        toast.success('Download Started')
        handleCloseDownloadModal()
      })
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message)
      console.error('Error while uploading bulk account opening file :: ', error)
    } else {
      console.log(error)
    }
  }
}

export const usePostDownloadBatch = () =>
  useMutation({
    mutationFn: async ({
      payload,
      handleCloseDownloadModal,
    }: {
      payload: {batch_id: string}
      handleCloseDownloadModal: () => void
    }) => await downloadBatch(payload, handleCloseDownloadModal),
  })
