import {call, delay, put, takeLatest} from 'redux-saga/effects'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {services} from './service'
import {actions} from './action'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {actionTypes} from './constants'

function* getNewCIFCPUCheckerListSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(services.getNewCIF, params)
    const data: any = response?.data?.data

    yield put(actions.getNewCIFListCheckerSuccess(data))
  } catch (error: any) {
    yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
  } finally {
    yield put(actions.getNewCIFListCheckerFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_NEW_CIF_LIST_CPU_CHECKER_START, getNewCIFCPUCheckerListSaga)
}
