import KYCUpdateForm from './form/KYCUpdateForm'

const KYCBranchMakerReviewPage = () => {
  return (
    <div>
      <KYCUpdateForm />
    </div>
  )
}

export default KYCBranchMakerReviewPage
