import DetailCell from '../DetailCell'

interface IncorporateInformationSectionProps {
  data?: any
}

const IncorporationDetailsInformationSection = ({data}: IncorporateInformationSectionProps) => {
  return (
    <>
      <h2 className='text-center my-4'>INCORPORATION DETAILS</h2>
      <div className='row'>
        <DetailCell
          label='Registration Number'
          value={data?.kyc_information?.registration_number}
        />
        <DetailCell
          label='Registration Date (B.S)'
          value={data?.kyc_information?.registration_date_bs}
        />
      </div>
      <div className='row'>
        <DetailCell
          label='Registration Date (A.D)'
          value={data?.kyc_information?.registration_date_ad}
        />
        <DetailCell
          label='Registration Expiry Date (B.S)'
          value={data?.kyc_information?.registration_expiry_date_bs}
        />
      </div>

      <div className='row'>
        <DetailCell
          label='Registration Expiry Date (A.D)'
          value={data?.kyc_information?.registration_expiry_date_ad}
        />
        <DetailCell label='Registered With' value={data?.kyc_information?.registered_with?.title} />
      </div>
      <div className='row'>
        <div className='col-6'></div>
        <DetailCell
          label='Registered with Additional Information'
          value={data?.kyc_information?.registered_with_other}
        />
      </div>
      <div className='row'>
        <DetailCell label='Issuing Place' value={data?.kyc_information?.issuing_place?.title} />
        <DetailCell label='PAN Number' value={data?.kyc_information?.pan_number} />
      </div>
      <div className='row'>
        <DetailCell
          label='PAN Registration Date (B.S.)'
          value={data?.kyc_information?.pan_registration_date_bs}
        />
        <DetailCell
          label='PAN Registration Date (A.D.)'
          value={data?.kyc_information?.pan_registration_date_ad}
        />
      </div>
      <div className='row'>
        <DetailCell
          label='Description of Business'
          value={data?.kyc_information?.description_of_business}
        />
      </div>
    </>
  )
}

export default IncorporationDetailsInformationSection
