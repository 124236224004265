import {Form, Formik} from 'formik'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {MasterDataModel} from 'src/app/modules/masterData/Model'
import * as masterDataRedux from 'src/app/modules/masterData/redux'
import {usePostCIFtoGETCustomerInfo} from 'src/app/queries/CBS'
import {usePostCorporateKYCCPUCheckerReview} from 'src/app/queries/Kyc Update/post/usePostKYCCPUCheckerReview'
import {usePostKYCUpdate} from 'src/app/queries/Kyc Update/post/usePostKYCYUpdate'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {RootState} from 'src/setup'
import * as Yup from 'yup'
import {
  BasicInformationSection,
  CorrespondenceAddressSection,
  CustomerProfileUpdateSection,
  DocumentDetailsUpdateSection,
  FamilyAndTransactionDetailsSection,
  MobileAndEmailDetailsSection,
  PermanentAddressSection,
} from './review-form-field-sections'
import {toast} from 'react-toastify'

interface ReviewForm {
  data: any
}

const cpuCheckerFormValidationSchema = Yup.object().shape({
  status: Yup.string().required('Required'),
  remarks: Yup.string().required('Required'),
})

const ReviewForm = ({data}: ReviewForm) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const {mutate: postKYCUpdate} = usePostKYCUpdate()
  const {mutate: postKYCCPUCheckerReview, isLoading} = usePostCorporateKYCCPUCheckerReview()

  const {loading, success} = useSelector((state: RootState) => state.postAccountPreliminaryKYCForm)
  const {mutate: postCIFtoGETCustomerInfo, data: customerData} = usePostCIFtoGETCustomerInfo()

  const {loading: additionalLoading, success: additionalSuccess} = useSelector(
    (state: RootState) => state.postAccountPreliminaryKYCAdditionalForm
  )
  const {data: accountPurposes}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.accountPurposeList
  )
  const {data: uniqueIdIssuedBy}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.individualUniqueIdList
  )
  const {data: qualifications}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.qualificationsList
  )
  const {data: occupations}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.occupationList
  )
  const {data: incomeSources}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.incomeSourceList
  )
  const {data: accountCategories}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.accountCategoriesList
  )
  const {data: countries}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.countriesList
  )
  const {data: nationalities}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.nationalitiesList
  )
  const {
    data: accountTypes,
    loading: accountTypesLoading,
  }: {data: MasterDataModel[]; loading: boolean} = useSelector(
    (state: RootState) => state?.accountTypeList
  )
  const {data: maritalStatusList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.maritalStatusList
  )
  const {municipality} = useSelector((state: RootState) => state.place)

  useEffect(() => {
    dispatch(masterDataRedux.actions.getAllMasterData())
  }, [])
  useEffect(() => {
    if (data?.cif_number) {
      postCIFtoGETCustomerInfo(data?.cif_number)
    }
  }, [data?.cif_number])
  return (
    <>
      <div className='row'>
        <div className='row'>
          <div className='col-4'>
            <h2 className='fs-1'>Fields</h2>
          </div>
          <div className='col-4'>
            <h2 className='fs-1'>Current Info</h2>
          </div>
          <div className='col-4'>
            <h2 className='fs-1'>New Info</h2>
          </div>
        </div>
        <BasicInformationSection
          data={data?.kyc_update}
          fullData={data}
          cbsData={customerData?.data}
        />
        <hr />
        <CustomerProfileUpdateSection data={data?.kyc_update} cbsData={customerData?.data} />
        <hr />
        <MobileAndEmailDetailsSection data={data?.kyc_update} cbsData={customerData?.data} />
        <hr />
        <CorrespondenceAddressSection data={data?.kyc_update} cbsData={customerData?.data} />
        <hr />
        <PermanentAddressSection data={data?.kyc_update} cbsData={customerData?.data} />
        <FamilyAndTransactionDetailsSection data={data?.kyc_update} cbsData={customerData?.data} />
        <DocumentDetailsUpdateSection data={data?.kyc_update} cbsData={customerData?.data} />
        <hr />
        <Formik
          initialValues={{status: '', remarks: ''}}
          onSubmit={(formData) => {
            postKYCCPUCheckerReview(
              {id: data?.id, payload: formData},
              {
                onSuccess: (res) => {
                  if (res?.data?.resCod === '200') {
                    toast.success(res?.data?.resDesc ?? 'KYC Reviewed Successfully')
                    history.push('/kyc/kyc-update-list')
                  }
                },
              }
            )
          }}
          validationSchema={cpuCheckerFormValidationSchema}
        >
          {({errors, handleChange, touched, setFieldValue, values}) => (
            <Form>
              <div className='row my-8'>
                <div className='col-12 col-md-6 col-lg-4'>
                  <FormSelect
                    name='status'
                    label='Status'
                    containerClassName='col-12'
                    labelClassName='col-12'
                    options={[
                      {label: 'APPROVE', value: 'Approve'},
                      {label: 'REJECT', value: 'Rejected by CPU Checker'},
                      {label: 'REVERT BACK TO CPU MAKER', value: 'Reverted to CPU Maker'},
                      {label: 'REVERT BACK TO BRANCH MAKER', value: 'Reverted to Branch Maker'},
                    ]}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    values={values}
                    required
                  />
                </div>
              </div>
              <div className='my-12 col-md-12'>
                <FormTextBox
                  errors={errors}
                  name='remarks'
                  label='Remarks'
                  containerClassName='col-12'
                  labelClassName='col-12'
                  onChange={handleChange}
                  touched={touched}
                  as='textarea'
                  required
                />
              </div>
              <div className='d-flex gap-2 justify-content-end'>
                <button
                  type='button'
                  onClick={() => history.goBack()}
                  className='btn btn-outline-warning'
                  disabled={isLoading}
                >
                  Back
                </button>
                <button type='submit' className='btn-sm btn-warning' disabled={isLoading}>
                  Approve & Send
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

export default ReviewForm
