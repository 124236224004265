import {Form, Formik} from 'formik'
import moment from 'moment'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as masterDataRedux from 'src/app/modules/masterData/redux'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as Yup from 'yup'
import {
  BasicInformationSection,
  CorrespondenceAddressSection,
  CustomerProfileUpdateSection,
  DocumentDetailsUpdateSection,
  FamilyAndTransactionDetailsSection,
  MobileAndEmailDetailsSection,
  PermanentAddressSection,
} from './review-form-field-sections'
import {kycReviewSchema} from './validationSchema'
import {RootState} from 'src/setup'
import {MasterDataModel} from 'src/app/modules/masterData/Model'
import {usePostKYCUpdate} from 'src/app/queries/Kyc Update/post/usePostKYCYUpdate'
import {toast} from 'react-toastify'
import {useHistory} from 'react-router-dom'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'

interface ReviewForm {
  data: any
}

const ReviewForm = ({data}: ReviewForm) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const {mutate: postKYCUpdate, isLoading} = usePostKYCUpdate()

  const {loading, success} = useSelector((state: RootState) => state.postAccountPreliminaryKYCForm)
  const {loading: additionalLoading, success: additionalSuccess} = useSelector(
    (state: RootState) => state.postAccountPreliminaryKYCAdditionalForm
  )
  const {data: accountPurposes}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.accountPurposeList
  )
  const {data: uniqueIdIssuedBy}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.individualUniqueIdList
  )
  const {data: qualifications}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.qualificationsList
  )
  const {data: occupations}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.occupationList
  )
  const {data: incomeSources}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.incomeSourceList
  )
  const {data: accountCategories}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.accountCategoriesList
  )
  const {data: countries}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.countriesList
  )
  const {data: nationalities}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.nationalitiesList
  )
  const {
    data: accountTypes,
    loading: accountTypesLoading,
  }: {data: MasterDataModel[]; loading: boolean} = useSelector(
    (state: RootState) => state?.accountTypeList
  )
  const {data: maritalStatusList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.maritalStatusList
  )
  const {municipality} = useSelector((state: RootState) => state.place)

  const optionalValidationSchema = Yup.object().shape({
    unique_id_issued_by_other: Yup.string().when('unique_id_issued_by', {
      is: (value: string) => value === uniqueIdIssuedBy.find((uid) => uid.title === 'OTHERS')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    // education_qualification_others: Yup.string().when('education_qualification', {
    //   is: (value: string) =>
    //     value === qualifications.find((qualification) => qualification.title === 'OTHERS')?.id,
    //   then: Yup.string().required('Required'),
    //   otherwise: Yup.string(),
    // }),
    occupation_other: Yup.string().when('occupation', {
      is: (value: string) =>
        value === occupations.find((occupation) => occupation.title === 'Others')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    source_of_income_other: Yup.string().when('source_of_income', {
      is: (value: string) =>
        value === incomeSources.find((incomeSource) => incomeSource.title === 'Others')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),

    // dates validation

    date_of_birth_ad: Yup.string()
      .required('Required')
      .test('age-validation', function (value) {
        const {accountType} = this.parent
        const age = moment().diff(value, 'years')
        const isMinor =
          accountType === accountTypes.map((accountType) => accountType.title === 'Minor')
        if ((isMinor && age >= 18) || (!isMinor && age < 18)) {
          const message = isMinor
            ? 'Age must be less than 18 years'
            : 'Age must be minimum 18 years'
          return this.createError({
            message,
          })
        }
        return true
      }),
    pan_registration_date_ad: Yup.string().test(
      'date-validation',
      'Invalid Date',
      function (value) {
        const {date_of_birth_ad} = this.parent
        const isFuture = value && moment(value, 'YYYY-MM-DD').isAfter(moment())
        const isBeforeBirthDate =
          value && date_of_birth_ad && moment(value, 'YYYY-MM-DD').isBefore(date_of_birth_ad)
        if (isFuture || isBeforeBirthDate) {
          return false
        }
        return true
      }
    ),
    citizenship_issue_date_ad: Yup.string().when('preferred_identity', {
      is: (value: string) => value === 'citizenship',
      then: Yup.string()
        .required('Required')
        .test('date-validation', 'Invalid Date', function (value) {
          const {date_of_birth_ad} = this.parent
          const isFuture = value && moment(value, 'YYYY-MM-DD').isAfter(moment())
          const isBeforeBirthDate =
            value &&
            date_of_birth_ad &&
            moment(value, 'YYYY-MM-DD').isBefore(moment(date_of_birth_ad, 'YYYY-MM-DD'))
          if (isFuture || isBeforeBirthDate) {
            return false
          }
          return true
        }),
      otherwise: Yup.string(),
    }),
    passport_issue_date_ad: Yup.string().when('preferred_identity', {
      is: (value: string) => value === 'passport',
      then: Yup.string()
        .required('Required')
        .test('date-validation', 'Invalid Date', function (value) {
          const {date_of_birth_ad} = this.parent
          const isFuture = value && moment(value, 'YYYY-MM-DD').isAfter(moment())
          const isBeforeBirthDate =
            value && date_of_birth_ad && moment(value, 'YYYY-MM-DD').isBefore(date_of_birth_ad)
          if (isFuture || isBeforeBirthDate) {
            return false
          }
          return true
        }),
      otherwise: Yup.string(),
    }),
    passport_expiry_date_ad: Yup.string().when('preferred_identity', {
      is: (value: string) => value === 'passport',
      then: Yup.string()
        .required('Required')
        .test('date-validation', 'Invalid Date', function (value) {
          const {date_of_birth_ad, passport_issue_date_ad} = this.parent
          const isFutureFromRegistration =
            passport_issue_date_ad &&
            value &&
            moment(value, 'YYYY-MM-DD').isAfter(moment(passport_issue_date_ad, 'YYYY-MM-DD'))
          const isBeforeBirthDate =
            value && date_of_birth_ad && moment(value, 'YYYY-MM-DD').isBefore(date_of_birth_ad)
          if (!isFutureFromRegistration || isBeforeBirthDate) {
            return false
          }
          return true
        }),
      otherwise: Yup.string(),
    }),
    id_card_issue_date_ad: Yup.string().when('preferred_identity', {
      is: (value: string) => value === 'other',
      then: Yup.string()
        .required('Required')
        .test('date-validation', 'Invalid Date', function (value) {
          const {date_of_birth_ad} = this.parent
          const isFuture = value && moment(value, 'YYYY-MM-DD').isAfter(moment())
          const isBeforeBirthDate =
            value && date_of_birth_ad && moment(value, 'YYYY-MM-DD').isBefore(date_of_birth_ad)
          if (isFuture || isBeforeBirthDate) {
            return false
          }
          return true
        }),
      otherwise: Yup.string(),
    }),
    id_card_expiry_date_ad: Yup.string().when('preferred_identity', {
      is: (value: string) => value === 'other',
      then: Yup.string()
        .required('Required')
        .test('date-validation', 'Invalid Date', function (value) {
          const {date_of_birth_ad, id_card_issue_date_ad} = this.parent

          const isFutureFromRegistration =
            id_card_issue_date_ad &&
            value &&
            moment(value, 'YYYY-MM-DD').isAfter(moment(id_card_issue_date_ad, 'YYYY-MM-DD'))
          const isBeforeBirthDate =
            value && date_of_birth_ad && moment(value, 'YYYY-MM-DD').isBefore(date_of_birth_ad)
          if (!isFutureFromRegistration || isBeforeBirthDate) {
            return false
          }
          return true
        }),
      otherwise: Yup.string(),
    }),
    yearly_remuneration: Yup.number().typeError('Only Numbers').required('Required'),
    kyc_review_date: Yup.string()
      .required('Required')
      .test('date-validation', 'Invalid Date', function (value) {
        const {date_of_birth_ad} = this.parent
        const isFuture = value && moment(value, 'YYYY-MM-DD').isAfter(moment())
        const isBeforeBirthDate =
          value && date_of_birth_ad && moment(value, 'YYYY-MM-DD').isBefore(date_of_birth_ad)
        if (!isFuture || isBeforeBirthDate) {
          return false
        }
        return true
      }),
    update_kyc_date: Yup.string().required('Required'),
    preferred_identity: Yup.string()
      .required('Required')
      .test('minor-document', function (value) {
        const {accountType} = this.parent

        const isMinor =
          accountType === accountTypes.map((accountType) => accountType.title === 'Minor')

        if (isMinor && value === 'citizenship') {
          return this.createError({
            message: 'Minors cannot have citizenship',
          })
        } else if (isMinor && value === 'passport') {
          return this.createError({
            message: 'Minors cannot have Passport',
          })
        }

        return true
      }),
    spouse_name: Yup.string().when('marital_status', {
      is: (value: string) =>
        value &&
        value === maritalStatusList.find((maritalStatus) => maritalStatus.title === 'Married')?.id,
      then: Yup.string()
        .required('Required')
        .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
      otherwise: Yup.string().matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
    }),

    //Address Validations
    temporary_country: Yup.string().required('Required'),
    temporary_house_number: Yup.number().typeError('House numbers can only be in numbers'),
    temporary_tole: Yup.string().when('temporary_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string()
        .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed')
        .required('Required'),
      otherwise: Yup.string(),
    }),
    temporary_ward_number: Yup.number()
      .typeError('Ward Number can only be in numbers')
      .when('temporary_country', {
        is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
        then: Yup.number().typeError('Ward Number can only be in numbers').required('Required'),
        otherwise: Yup.number(),
      }),
    temporary_province: Yup.string().when('temporary_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    temporary_district: Yup.string().when('temporary_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    temporary_municipality: Yup.string().when('temporary_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    temporary_municipality_other: Yup.string().when('temporary_municipality', {
      is: (value: string) => value === municipality?.find((m) => m?.title === 'Others')?.id,
      then: Yup.string()
        .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed')
        .required('Required'),
      otherwise: Yup.string(),
    }),
    temporary_outside_nepal_address_line_1: Yup.string().when('temporary_country', {
      is: (value: string) => value !== countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Requierd'),
      otherwise: Yup.string(),
    }),
    temporary_outside_nepal_address_line_2: Yup.string().when('temporary_country', {
      is: (value: string) => value !== countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Requierd'),
      otherwise: Yup.string(),
    }),
    temporary_outside_nepal_address_line_3: Yup.string(),

    permanent_country: Yup.string().required('Required'),
    permanent_house_number: Yup.number().typeError('House Number can only be in numbers'),
    permanent_tole: Yup.string().when('permanent_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string()
        .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed')
        .required('Required'),
      otherwise: Yup.string(),
    }),
    permanent_ward_number: Yup.number().when('permanent_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.number().typeError('Ward Number can only be in numbers').required('Required'),
      otherwise: Yup.number(),
    }),
    permanent_province: Yup.string().when('permanent_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Requierd'),
      otherwise: Yup.string(),
    }),
    permanent_district: Yup.string().when('permanent_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Requierd'),
      otherwise: Yup.string(),
    }),
    permanent_municipality: Yup.string().when('permanent_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Requierd'),
      otherwise: Yup.string(),
    }),
    permanent_municipality_other: Yup.string().when('permanent_municipality', {
      is: (value: string) => value === municipality?.find((m) => m?.title === 'Others')?.id,
      then: Yup.string()
        .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed')
        .required('Required'),
      otherwise: Yup.string(),
    }),
    // permanent_outside_nepal_address_line_1: Yup.string().when('permanent_country', {
    //   is: (value: string) => value !== countries.find((country) => country.title === 'Nepal')?.id,
    //   then: Yup.string().required('Required'),
    //   otherwise: Yup.string(),
    // }),
    // permanent_outside_nepal_address_line_2: Yup.string().when('permanent_country', {
    //   is: (value: string) => value !== countries.find((country) => country.title === 'Nepal')?.id,
    //   then: Yup.string().required('Requierd'),
    //   otherwise: Yup.string(),
    // }),
    // permanent_outside_nepal_address_line_3: Yup.string(),
  })

  useEffect(() => {
    dispatch(masterDataRedux.actions.getAllMasterData())
  }, [])
  return (
    <>
      <Formik
        initialValues={{
          action: 'save_and_continue',
          customer_number: data?.cif_number ?? '',
          account_type: data?.account_type ?? '',
          branch: data?.branch ?? '',
          is_minor: data?.is_minor ?? '',
          constitution_code: data?.constitution_code ?? '',
          cif_location: data?.cif_location ?? '',
          salutation: data?.kyc_update?.salutation ?? '',
          first_name: data?.first_name ?? '',
          middle_name: data?.middle_name ?? '',
          last_name: data?.last_name ?? '',
          short_name: data?.short_name ?? '',
          full_name: data?.full_name ?? '',
          gender: data?.kyc_update?.gender ?? 'Male',
          nationality: data?.kyc_update?.nationality?.id ?? '',
          date_of_birth_bs: data?.kyc_update?.date_of_birth_bs ?? '',
          date_of_birth_ad: data?.kyc_update?.date_of_birth_ad ?? '',
          introducer_name: data?.kyc_update?.introducer_name ?? '',
          introducer_salutation: data?.introducer_salutation ?? '',
          customer_category: data?.kyc_update?.customer_category?.code ?? '',
          customer_classification: data?.kyc_update?.customer_classification?.id ?? '',
          aml_risk_rating: data?.kyc_update?.aml_risk_rating ?? '',
          kyc_level_category: data?.kyc_update?.kyc_level_category?.id ?? '',
          pep_confirmation: data?.kyc_update?.pep_confirmation ?? '',
          occupation: data?.kyc_update?.occupation?.id ?? '',
          occupation_other: data?.occupation_other ?? '',
          marital_status: data?.kyc_update?.marital_status?.id ?? '',
          resident_status: data?.resident_status ?? 'residental',
          source_of_income: data?.kyc_update?.source_of_income?.id ?? '',
          source_of_income_other: data?.kyc_update?.source_of_income_other ?? '',
          employment_status: data?.kyc_update?.employment_status?.id ?? '',
          employment_status_other: data?.employment_status_other ?? '',
          yearly_remuneration: data?.kyc_update?.yearly_remuneration ?? '',
          proof_address_document: data?.kyc_update?.proof_address_document ?? '',
          anticipated_annual_transaction: data?.kyc_update?.anticipated_annual_transaction ?? '',
          father_name: data?.kyc_update?.father_name ?? '',
          mother_name: data?.kyc_update?.mother_name ?? '',
          grand_father_name: data?.kyc_update?.grand_father_name ?? '',
          phone_email_id_COMMEML: data?.phone_email_id_COMMEML ?? '',
          phone_email_id_CELLPH: data?.phone_email_id_CELLPH ?? '',
          phone_email_id_HOMEPH1: data?.phone_email_id_HOMEPH1 ?? '',
          phone_email_id_WORKPH1: data?.phone_email_id_WORKPH1 ?? '',
          mobile_number: data?.kyc_update?.mobile_number ?? '',
          landline_number: data?.kyc_update?.landline_number ?? '',
          email: data?.kyc_update?.email ?? '',
          temporary_AddrLine1: data?.temporary_AddrLine1 ?? '',
          temporary_AddrLine2: data?.temporary_AddrLine2 ?? '',
          temporary_AddrLine3: data?.temporary_AddrLine3 ?? '',
          temporary_house_number: data?.kyc_update?.temporary_house_number ?? '',
          temporary_ward_number: data?.kyc_update?.temporary_ward_number ?? '',
          temporary_tole: data?.kyc_update?.temporary_tole ?? '',
          temporary_municipality: data?.kyc_update?.temporary_municipality?.id ?? '',
          temporary_municipality_other: data?.kyc_update?.temporary_municipality_other ?? '',
          temporary_district: data?.kyc_update?.temporary_district?.id ?? '',
          temporary_province: data?.kyc_update?.temporary_province?.id ?? '',
          temporary_country:
            data?.kyc_update?.temporary_country?.id ??
            countries.find((country) => country.title === 'Nepal')?.id ??
            '',
          permanent_AddrLine1: data?.permanent_AddrLine1 ?? '',
          permanent_AddrLine2: data?.permanent_AddrLine2 ?? '',
          permanent_AddrLine3: data?.permanent_AddrLine3 ?? '',
          permanent_house_number: data?.kyc_update?.permanent_house_number ?? '',
          permanent_ward_number: data?.kyc_update?.permanent_ward_number ?? '',
          permanent_tole: data?.kyc_update?.permanent_tole ?? '',
          permanent_municipality: data?.kyc_update?.permanent_municipality?.id ?? '',
          permanent_municipality_other: data?.permanent_municipality_other ?? '',
          permanent_district: data?.kyc_update?.permanent_district?.id ?? '',
          permanent_province: data?.kyc_update?.permanent_province?.id ?? '',
          permanent_country:
            data?.kyc_update?.permanent_country?.id ??
            countries.find((country) => country.title === 'Nepal')?.id ??
            '',
          pan_number: data?.kyc_update?.pan_number ?? '',
          pan_registration_date_bs: data?.kyc_update?.pan_registration_date_bs ?? '',
          pan_registration_date_ad: data?.kyc_update?.pan_registration_date_ad ?? '',
          pan_issue_district: data?.kyc_update?.pan_issue_district?.id ?? '',
          citizenship_number: data?.kyc_update?.citizenship_number ?? '',
          citizenship_issue_date_bs: data?.kyc_update?.citizenship_issue_date_bs ?? '',
          citizenship_issue_date_ad: data?.kyc_update?.citizenship_issue_date_ad ?? '',
          citizenship_issue_district: data?.kyc_update?.citizenship_issue_district?.id ?? '',
          passport_number: data?.kyc_update?.passport_number ?? '',
          passport_issue_date_bs: data?.kyc_update?.passport_issue_date_bs ?? '',
          passport_issue_date_ad: data?.kyc_update?.passport_issue_date_ad ?? '',
          passport_expiry_date_bs: data?.kyc_update?.passport_expiry_date_bs ?? '',
          passport_expiry_date_ad: data?.kyc_update?.passport_expiry_date_ad ?? '',
          passport_issue_district: data?.kyc_update?.passport_issue_district?.id ?? '',
          preferred_identity: data?.kyc_update?.preferred_identity ?? '',
          type_of_identity_card: data?.kyc_update?.type_of_identity_card?.id ?? '',
          id_card_number: data?.kyc_update?.id_card_number ?? '',
          id_card_issue_date_bs: data?.kyc_update?.id_card_issue_date_bs ?? '',
          id_card_issue_date_ad: data?.kyc_update?.id_card_issue_date_ad ?? '',
          id_card_expiry_date_bs: data?.kyc_update?.id_card_expiry_date_bs ?? '',
          id_card_expiry_date_ad: data?.kyc_update?.id_card_expiry_date_ad ?? '',
          id_card_issue_district: data?.kyc_update?.id_card_issue_district?.id ?? '',
          unique_id_issued_by: data?.kyc_update?.unique_id_issued_by?.id ?? '',
          unique_id_issued_by_other: data?.kyc_update?.unique_id_issued_by_other ?? '',
          email_verified: data?.email_verified ?? '',
          mobile_verified: data?.mobile_verified ?? '',
          kyc_review_date: data?.kyc_update?.kyc_review_date ?? '',
          update_kyc_date: data?.kyc_update?.update_kyc_date ?? '',
          spouse_name: data?.spouse_name ?? '',
          remarks: data?.remarks ?? '',
        }}
        key='kycUpdate'
        onSubmit={(values) => {
          if (values.preferred_identity === 'citizenship') {
            values.passport_issue_date_ad = ''
            values.passport_expiry_date_bs = ''
            values.passport_issue_district = ''
            values.passport_number = ''

            values.id_card_number = ''
            values.id_card_issue_date_ad = ''
            values.id_card_issue_date_bs = ''
            values.id_card_expiry_date_ad = ''
            values.id_card_expiry_date_bs = ''
            values.id_card_issue_district = ''
            values.type_of_identity_card = ''
          } else if (values.preferred_identity === 'passport') {
            values.citizenship_number = ''
            values.citizenship_issue_date_bs = ''
            values.citizenship_issue_date_ad = ''
            values.citizenship_issue_district = ''

            values.id_card_number = ''
            values.id_card_issue_date_ad = ''
            values.id_card_issue_date_bs = ''
            values.id_card_expiry_date_ad = ''
            values.id_card_expiry_date_bs = ''
            values.id_card_issue_district = ''
            values.type_of_identity_card = ''
          } else if (values.preferred_identity === 'other') {
            values.citizenship_number = ''
            values.citizenship_issue_date_bs = ''
            values.citizenship_issue_date_ad = ''
            values.citizenship_issue_district = ''

            values.id_card_number = ''
            values.id_card_issue_date_ad = ''
            values.id_card_issue_date_bs = ''
            values.id_card_expiry_date_ad = ''
            values.id_card_expiry_date_bs = ''
            values.id_card_issue_district = ''
            values.type_of_identity_card = ''
          }
          postKYCUpdate(
            {id: data?.id, payload: values},
            {
              onSuccess: (res) => {
                if (res?.data?.resCod === '200') {
                  toast.success(res?.data?.resDesc ?? 'KYC Update Reviewed Successfully')
                  history.push('/kyc/kyc-update-list')
                }
              },
            }
          )
        }}
        validationSchema={kycReviewSchema.concat(optionalValidationSchema)}
        enableReinitialize
      >
        {({
          touched,
          setFieldValue,
          errors,
          handleChange,
          handleBlur,
          values,
          initialValues,
          validateField,
          getFieldMeta,
          setFieldTouched,
        }) => {
          const handleSaveOnly = async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()

            if (values.preferred_identity === 'citizenship') {
              values.passport_issue_date_ad = ''
              values.passport_expiry_date_bs = ''
              values.passport_issue_district = ''
              values.passport_number = ''

              values.id_card_number = ''
              values.id_card_issue_date_ad = ''
              values.id_card_issue_date_bs = ''
              values.id_card_expiry_date_ad = ''
              values.id_card_expiry_date_bs = ''
              values.id_card_issue_district = ''
              values.type_of_identity_card = ''
            } else if (values.preferred_identity === 'passport') {
              values.citizenship_number = ''
              values.citizenship_issue_date_bs = ''
              values.citizenship_issue_date_ad = ''
              values.citizenship_issue_district = ''

              values.id_card_number = ''
              values.id_card_issue_date_ad = ''
              values.id_card_issue_date_bs = ''
              values.id_card_expiry_date_ad = ''
              values.id_card_expiry_date_bs = ''
              values.id_card_issue_district = ''
              values.type_of_identity_card = ''
            } else if (values.preferred_identity === 'other') {
              values.citizenship_number = ''
              values.citizenship_issue_date_bs = ''
              values.citizenship_issue_date_ad = ''
              values.citizenship_issue_district = ''

              values.id_card_number = ''
              values.id_card_issue_date_ad = ''
              values.id_card_issue_date_bs = ''
              values.id_card_expiry_date_ad = ''
              values.id_card_expiry_date_bs = ''
              values.id_card_issue_district = ''
              values.type_of_identity_card = ''
            }
            postKYCUpdate(
              {id: data?.id, payload: {...values, action: 'save'}},
              {
                onSuccess: (res) => {
                  if (res?.data?.resCod === '200') {
                    toast.success(res?.data?.resDesc ?? 'KYC Update Reviewed Successfully')
                    history.push('/kyc/kyc-update-list')
                  }
                },
              }
            )
          }
          const handleRepair = async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            await validateField('remarks')
            setFieldTouched('remarks', true)

            if (getFieldMeta('remarks').error || !getFieldMeta('remarks').touched) {
              return
            }
            postKYCUpdate(
              {id: data?.id, payload: {...values, action: 'repair'}},
              {
                onSuccess: (res) => {
                  if (res?.data?.resCod === '200') {
                    toast.success(res?.data?.resDesc ?? 'KYC Update Reviewed Successfully')
                    history.push('/kyc/kyc-update-list')
                  }
                },
              }
            )
          }
          console.log(errors)
          return (
            <Form>
              <div className='row'>
                <div className='row'>
                  <div className='col-4'>
                    <h2 className='fs-1'>Fields</h2>
                  </div>
                  <div className='col-4'>
                    <h2 className='fs-1'>Current Info</h2>
                  </div>
                  <div className='col-4'>
                    <h2 className='fs-1'>New Info</h2>
                  </div>
                </div>
                <BasicInformationSection
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                  initialValues={initialValues}
                  data={data}
                />
                <hr />
                <CustomerProfileUpdateSection
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                  initialValues={initialValues}
                  data={data}
                />
                <hr />
                <MobileAndEmailDetailsSection
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                  initialValues={initialValues}
                  data={data}
                />
                <hr />
                <CorrespondenceAddressSection
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                  initialValues={initialValues}
                  data={data}
                />
                <hr />
                <PermanentAddressSection
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                  initialValues={initialValues}
                  data={data}
                />
                <hr />
                <FamilyAndTransactionDetailsSection
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                  initialValues={initialValues}
                  data={data}
                />
                <hr />
                <DocumentDetailsUpdateSection
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                  initialValues={initialValues}
                  data={data}
                />
                <hr />
                <div className='row my-2'>
                  <div className='col-4'>
                    <strong className='fs-5'>KYC Review Date</strong>
                  </div>
                  <div className='col-4'>
                    <strong className='fs-6'>-</strong>
                  </div>
                  <div className='col-4'>
                    <FormTextBox
                      type='date'
                      name='kyc_review_date'
                      label=' '
                      containerClassName='col-12'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
                <div className='row my-2'>
                  <div className='col-4'>
                    <strong className='fs-5'>Select Update KYC Date</strong>
                  </div>
                  <div className='col-4'>
                    <strong className='fs-6'>-</strong>
                  </div>
                  <div className='col-4'>
                    <FormSelect
                      type='date'
                      name='update_kyc_date'
                      label=' '
                      options={[
                        {label: 'YES', value: 'Y'},
                        {label: 'NO', value: 'N'},
                      ]}
                      containerClassName='col-12'
                      onChange={handleChange}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      values={values}
                    />
                  </div>
                </div>
                <div className='col-12 mb-6'>
                  <FormTextBox
                    name='remarks'
                    as='textarea'
                    label='Remarks'
                    containerClassName='col-12'
                    labelClassName='col-12'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    required
                  />
                </div>
                <div className='col-md-6 col-12'></div>
                <div className='col-md-6 col-12 d-flex gap-1 justify-content-end'>
                  <button
                    type='button'
                    className='btn  btn-outline-warning'
                    onClick={handleRepair}
                    style={{border: 1}}
                    disabled={isLoading}
                  >
                    Repair
                  </button>
                  <button
                    type='button'
                    className='btn  btn-outline-warning'
                    onClick={handleSaveOnly}
                    disabled={isLoading}
                    style={{border: 1}}
                  >
                    Save
                  </button>
                  <button type='submit' className='btn btn-warning' disabled={isLoading}>
                    Save & Continue
                  </button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default ReviewForm
