import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface CheckDuplicateCifState {
  data: any | null
  error: any
  loading: boolean
}

const initialCheckDuplicateCifState: CheckDuplicateCifState = {
  data: null,
  error: null,
  loading: false,
}

export const reducer = (
  state: CheckDuplicateCifState = initialCheckDuplicateCifState,
  action: ActionWithPayload<any>
) => {
  switch (action.type) {
    case actionTypes.CHECK_DUPLICATE_CIF_START:
      return {...state, loading: true, error: null, data: null}
    case actionTypes.CHECK_DUPLICATE_CIF_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      }
    case actionTypes.CHECK_DUPLICATE_CIF_FAILURE:
      return {...state, loading: false, error: action.payload, data: null}
    case actionTypes.RESET_DUPLICATE_CIF_STATE:
      return initialCheckDuplicateCifState
    default:
      return state
  }
}
