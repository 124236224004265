import {handleUppercaseChange} from 'src/app/modules/CreateNewCIFAndCustomerAccount/createNewAccount/components'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'

interface FamilAndTransactionDetailsSectionProps {
  data: any
  cbsData: any
}

const FamilyAndTransactionDetailsSection = ({
  data,
  cbsData,
}: FamilAndTransactionDetailsSectionProps) => {
  return (
    <>
      <p className='fs-2 fw-bold'>Family and Transaction Details</p>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Grandfather Name</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{cbsData?.grandfather_name ?? '-'}</strong>
        </div>
        <div className='col-4'>-</div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Father Name</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{cbsData?.father_name ?? '-'}</strong>
        </div>
        <div className='col-4'>-</div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Mother Name</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.mother_name ?? ''}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Spouse Name</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.spouse_name ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-5'>Anticipated Annual Transaction</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.anticipated_annual_transaction ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Proof Address Document</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.proof_address_document ?? '-'}</strong>
        </div>
      </div>
    </>
  )
}

export default FamilyAndTransactionDetailsSection
