import {ErrorMessage} from 'formik'
import {useSelector} from 'react-redux'
import {MasterDataModel} from 'src/app/modules/masterData/Model'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {RootState} from 'src/setup'

interface ProfessionalInformationSectionProps {
  handleChange: any
  touched: any
  errors: any
  setFieldValue: any
  values: any
}

const ProfessionalInformationSection = ({
  handleChange,
  errors,
  touched,
  setFieldValue,
  values,
}: ProfessionalInformationSectionProps) => {
  const {data: incomeSources}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.incomeSourceList
  )
  const {data: employmentStatusList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.employmentStatusList
  )
  const {data: occupations}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.occupationList
  )
  const {data: additionalOccupations}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.additionalOccupationsList
  )
  return (
    <>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='occupation'
          label='Occupation'
          containerClassName='col-12'
          labelClassName='col-12'
          options={
            occupations.map((occupation) => ({
              label: occupation.title,
              value: occupation.id,
            })) ?? []
          }
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          values={values}
          touched={touched}
          required
        />
      </div>
      {values.occupation ===
        occupations.find((occupation) => occupation.title === 'Others')?.id && (
        <div className='col-md-6 col-12'>
          <FormTextBox
            name='occupation_other'
            label='Other Occupation'
            containerClassName='col-12'
            labelClassName='col-12'
            onChange={handleChange}
            errors={errors}
            touched={touched}
            required
          />
        </div>
      )}
      <div className='col-md-6 col-12'>
        <FormSelect
          name='additional_occupation'
          label='Additional Occupation'
          containerClassName='col-12'
          labelClassName='col-12'
          options={
            additionalOccupations.map((additionalOccupation) => ({
              label: additionalOccupation.title,
              value: additionalOccupation.id,
            })) ?? []
          }
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='source_of_income'
          label='Source of Income'
          containerClassName='col-12'
          labelClassName='col-12'
          options={
            incomeSources.map((incomeSource) => ({
              label: incomeSource.title,
              value: incomeSource.id,
            })) ?? []
          }
          setFieldValue={setFieldValue}
          onChange={handleChange}
          values={values}
          errors={errors}
          touched={touched}
          required
        />
      </div>
      {values.source_of_income ===
        incomeSources.find((incomeSource) => incomeSource.title === 'Others')?.id && (
        <div className='col-md-6 col-12'>
          <FormTextBox
            name='source_of_income_other'
            label='Other Occupation'
            containerClassName='col-12'
            labelClassName='col-12'
            onChange={handleChange}
            errors={errors}
            touched={touched}
            required
          />
        </div>
      )}
      <div className='col-md-6 col-12'>
        <FormSelect
          name='employment_status'
          label='Employment Status'
          containerClassName='col-12'
          labelClassName='col-12'
          options={
            employmentStatusList.map((employmentStatus) => ({
              label: employmentStatus.title,
              value: employmentStatus.id,
            })) ?? []
          }
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className=' d-flex my-7 col-12 w-50'>
        {[
          {label: 'Business/Professional', value: 'business'},
          {label: 'Student', value: 'student'},
        ].map((information) => (
          <FormRadio
            key={information.value}
            checkBoxText={information.label}
            errors={errors}
            name='information_type'
            onChange={handleChange}
            touched={touched}
            value={information.value}
          />
        ))}
      </div>
      <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
        <ErrorMessage name='information_type' component='div' className='field-error-message' />
      </div>
    </>
  )
}

export default ProfessionalInformationSection
