import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const BULK_ACCOUNT_OPENING_API = `${API_URL}/bulk-account-opening`

export const services = {
  getAPIList: async (params: ParamsModel) =>
    await axios.get(`${BULK_ACCOUNT_OPENING_API}/api-list`, {params}),
}
