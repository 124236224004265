import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as customerClassificationListRedux from 'src/app/modules/masterData/redux'
import type {
  CustomerClassificationDataModel,
  MasterDataModel,
} from 'src/app/modules/masterData/Model'
import {RootState} from 'src/setup'

interface CustomerInformationSectionProps {
  handleChange: any
  touched: any
  errors: any
  setFieldValue: any
  values: any
}

const CustomerInformationSection = ({
  handleChange,
  errors,
  touched,
  setFieldValue,
  values,
}: CustomerInformationSectionProps) => {
  const dispatch = useDispatch()

  const [customerClassificationList, setCustomerClassificationList] = useState<
    Array<{label: string; value: string}>
  >([])

  const customerClassificationListData = useSelector(
    (state: any) => state?.customerClassificationList
  )
  const {
    data: accountTypes,
    loading: accountTypesLoading,
  }: {data: MasterDataModel[]; loading: boolean} = useSelector(
    (state: RootState) => state?.accountTypeList
  )
  const {data: taxCategories}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.taxCategoryList
  )
  const {data: taxGroups}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.taxGroupList
  )
  const {data: mediaList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state?.mediaList
  )
  const {data: accountClasses}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state?.accountClassList
  )
  const {data: customerCategories}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.customerCategoryList
  )

  useEffect(() => {
    dispatch(customerClassificationListRedux.actions.getCustomerClassification())
  }, [])

  useEffect(() => {
    if (customerClassificationListData) {
      setCustomerClassificationList(
        customerClassificationListData?.data?.map(
          (customerClassification: CustomerClassificationDataModel) => ({
            label: customerClassification.title,
            value: customerClassification.id,
          })
        )
      )
    }
  }, [customerClassificationListData])

  return (
    <>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='customer_number'
          label='Customer No.'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
          disabled
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='account_number'
          label='Account Number'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
          disabled
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='account_class'
          label='Account Class'
          containerClassName='col-12'
          labelClassName='col-12'
          options={
            accountClasses.map((accountType) => ({
              label: accountType.name?.toUpperCase() ?? '',
              value: accountType.account_class ?? '',
            })) ?? []
          }
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          disabled
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='branch'
          label='Branch'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
          disabled
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='date'
          label='Date'
          type='date'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='customer_category'
          label='Customer Category'
          labelClassName='col-12'
          containerClassName='col-12'
          options={
            customerCategories.map((customerCategory) => ({
              label: customerCategory.title,
              value: customerCategory.code ?? '',
            })) ?? []
          }
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='tax_category'
          label='Tax Category'
          containerClassName='col-12'
          labelClassName='col-12'
          options={
            taxCategories?.map((taxCategory) => ({
              label: taxCategory.title,
              value: taxCategory.cbs_code ?? '',
            })) ?? []
          }
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='tax_group'
          label='Tax Group'
          containerClassName='col-12'
          labelClassName='col-12'
          options={
            taxCategories?.map((taxGroup) => ({
              label: taxGroup.cbs_code ?? '',
              value: taxGroup.cbs_code ?? '',
            })) ?? []
          }
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='institutional'
          label='Institutional'
          containerClassName='col-12'
          labelClassName='col-12'
          options={[
            {label: 'YES', value: 'YES'},
            {label: 'NO', value: 'NO'},
          ]}
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className='col-md-6 col-12'></div>
    </>
  )
}

export default CustomerInformationSection
