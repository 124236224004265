import {bsToAd} from '@sbmdkl/nepali-date-converter'
import {ErrorMessage, FormikErrors, FormikTouched} from 'formik'
import moment from 'moment'
import {NepaliDatePicker} from 'nepali-datepicker-reactjs'
import {NepaliDatePickerProps} from 'nepali-datepicker-reactjs/dist/NepaliDatePicker/Types'

interface FormNepaliCalendarDatePickerProps extends NepaliDatePickerProps {
  className?: string
  nepaliFormName: string
  englishFormName: string
  label?: string
  required?: boolean
  touched: FormikTouched<Record<string, boolean>>
  values: any
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<unknown>>
}

const FormNepaliCalendarDatePicker = ({
  values,
  setFieldValue,
  className,
  nepaliFormName,
  englishFormName,
  required,

  touched,
  label,
  ...props
}: FormNepaliCalendarDatePickerProps) => {
  return (
    <>
      {label ? (
        <label className={`mb-1 fw-bolder fs-6 col-12`}>
          <span className={required ? 'required' : ''}>{label}</span>
        </label>
      ) : null}
      <NepaliDatePicker
        value={values[nepaliFormName]}
        inputClassName={`form-control border nepali-date-input ${className ? className : ''}`}
        onChange={(value: string) => {
          touched[nepaliFormName] = true
          setFieldValue(nepaliFormName, value)
          setFieldValue(
            englishFormName,
            moment(moment(bsToAd(value)).toDate()).format('YYYY-MM-DD')
          )
        }}
        options={{calenderLocale: 'ne', valueLocale: 'en'}}
        {...props}
      />
      <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
        <ErrorMessage name={nepaliFormName} component='div' className='field-error-message' />
      </div>
    </>
  )
}

export default FormNepaliCalendarDatePicker
