import {Action} from 'redux'
import {actionTypes} from './constants'
import {CPUCheckerKYCReviewPayload} from '../Model'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface ICPUCheckerNewCifAndAccountIndividualJointMinorList {
  loading: boolean
  success: boolean
  data: {
    new_accounts: Record<string, string>[]
    meta: Record<string, number>
  }
}

const individualJointMinorInitialState: ICPUCheckerNewCifAndAccountIndividualJointMinorList = {
  loading: false,
  success: false,
  data: {new_accounts: [], meta: {}},
}

export const individualJointMinorReducer = (
  state: ICPUCheckerNewCifAndAccountIndividualJointMinorList = individualJointMinorInitialState,
  action: ActionWithPayload<ICPUCheckerNewCifAndAccountIndividualJointMinorList>
) => {
  switch (action.type) {
    case actionTypes.GET_CPU_CHECKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_LIST_START:
      return {...state, loading: true}
    case actionTypes.GET_CPU_CHECKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_SUCCESS:
      return {...state, loading: false, data: action.payload}
    case actionTypes.GET_CPU_CHECKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface ICPUCheckerNewCifAndAccountCorporateBankList {
  loading: boolean
  success: boolean
  data: {
    new_accounts: Record<string, string>[]
    meta: Record<string, number>
  }
}

const corporateBankInitialState: ICPUCheckerNewCifAndAccountCorporateBankList = {
  loading: false,
  success: false,
  data: {new_accounts: [], meta: {}},
}

export const corporateBankReducer = (
  state: ICPUCheckerNewCifAndAccountCorporateBankList = corporateBankInitialState,
  action: ActionWithPayload<ICPUCheckerNewCifAndAccountCorporateBankList>
) => {
  switch (action.type) {
    case actionTypes.GET_CPU_CHECKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_LIST_START:
      return {...state, loading: true}
    case actionTypes.GET_CPU_CHECKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_SUCCESS:
      return {...state, loading: false, data: action.payload}
    case actionTypes.GET_CPU_CHECKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IPostAccountPreliminaryKYCCPUCheckerReview {
  data: CPUCheckerKYCReviewPayload
  success: boolean
  loading: boolean
}

const accountPreliminaryKYCCPUCheckerReviewInitialState: IPostAccountPreliminaryKYCCPUCheckerReview =
  {
    data: {status: '', remarks: ''},
    loading: false,
    success: false,
  }

export const postAccountPreliminaryKYCCPUCheckerReviewReducer = (
  state: IPostAccountPreliminaryKYCCPUCheckerReview = accountPreliminaryKYCCPUCheckerReviewInitialState,
  action: ActionWithPayload<IPostAccountPreliminaryKYCCPUCheckerReview>
) => {
  switch (action.type) {
    case actionTypes.POST_ACCOUNT_PRELIMINARY_KYC_CPU_CHECKER_REVIEW_START:
      return {...state, loading: true, success: false}
    case actionTypes.POST_ACCOUNT_PRELIMINARY_KYC_CPU_CHECKER_REVIEW_SUCCESS:
      return {...state, loading: false, success: true}
    case actionTypes.POST_ACCOUNT_PRELIMINARY_KYC_CPU_CHECKER_REVIEW_FINISH:
      return {...state, loading: false, success: false}
    default:
      return state
  }
}
