import DocumentsPreview from './DocumentsPreview'
import ReviewForm from './ReviewForm'
import {useLocation} from 'react-router-dom'
import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import * as masterDataRedux from 'src/app/modules/masterData/redux'
import * as placeReducer from 'src/app/modules/common'

const NewCIFandAccountReviewPage = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const data: any = location.state

  useEffect(() => {
    dispatch(masterDataRedux.actions.getAllMasterData())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getProvince())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getDistrict())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getMunicipality())
  }, [])

  return (
    <div className='row'>
      <div className='col-6'>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body pt-9 pb-9 '>
            <DocumentsPreview data={data} />
          </div>
        </div>
      </div>
      <div className='col-6' style={{overflowX: 'hidden'}}>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body pt-9 pb-9 ' style={{maxHeight: '100vh', overflowY: 'auto'}}>
            <ReviewForm data={data} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewCIFandAccountReviewPage
