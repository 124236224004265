import {useEffect, useState} from 'react'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {Table, Pagination, InputGroup, Input} from 'rsuite'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import {StateParamsModel} from 'src/app/modules/common/Model'
import {useDispatch, useSelector} from 'react-redux'
import * as apiListRedux from '../redux'
import {APIStateParamsModel} from '../Model'
import {Form, Formik} from 'formik'
import moment from 'moment'
import DatePickerForGrievance from 'src/cms/helpers/components/forms/DatePickerForGrievance'
import {isEmpty} from 'lodash'
import CloseIcon from '@rsuite/icons/Close'
import SearchIcon from '@rsuite/icons/Search'
import Select from 'rsuite/SelectPicker'
import useSelection from 'antd/es/table/hooks/useSelection'
import {RootState} from 'src/setup'

const Cell = Table.Cell

const APISuccessList = () => {
  const dispatch = useDispatch()

  const [params, setParams] = useState<APIStateParamsModel>({
    status: 'success',
    page: 1,
    limit: 10,
    orderBy: 'updated_at',
    order: 'desc',
  })

  const {data: apiListData, loading}: apiListRedux.IAPIListState = useSelector(
    (state: any) => state.apiList
  )

  const data = apiListData?.bulk_accounts?.map((account: any, i: number) => ({
    ...account,
    sn: (params?.page - 1) * params?.limit + (i + 1),
    account_name: `${account?.first_name} ${account.middle_name ? account.middle_name + ' ' : ''} ${
      account?.last_name
    }`,
  }))

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <button className='btn btn-primary btn-sm ms-3'>Review</button>
      </Cell>
    )
  }

  const columns = [
    {label: 'S.N.', dataKey: 'sn', width: 100, cell: <Cell dataKey='sn' />},
    {
      label: 'A/C Opening Date',
      dataKey: 'accountOpeningDate',
      width: 200,
      cell: <Cell dataKey='accountOpeningDate' />,
    },
    {
      label: 'CIF Number',
      dataKey: 'cif_number',
      width: 250,
      cell: <Cell dataKey='cif_number' />,
    },
    {
      label: 'Account Number',
      dataKey: 'account_number',
      cell: <Cell dataKey='account_number' />,
      sortable: false,
      width: 250,
    },
    {
      label: 'Customer Code',
      dataKey: 'customer_code',
      sortable: false,
      width: 250,
      cell: <Cell dataKey='customer_code' />,
    },
    {
      label: 'Account Name',
      dataKey: 'account_name',
      cell: <Cell dataKey='account_name' />,
      sortable: false,
      width: 230,
    },
    {
      label: 'Branch Location',
      dataKey: 'branch_location_code',
      cell: <Cell dataKey='branch_location_code' />,
      sortable: false,
      width: 230,
    },
  ]

  useEffect(() => {
    dispatch(apiListRedux.actions.getAPIList(params))
  }, [params])

  return (
    <div>
      <div className='shadow p-3 bg-white rounded'>
        <div className='row g-0 border-bottom align-items-center mb-3 py-3'>
          <div className='col-lg-6 mb-3'>
            <div className='heading__component__title fw-bolder'>API Success List</div>
          </div>
          <APIParamsComponent params={params} setParams={setParams} />
        </div>
        <div className='datatable'>
          <RSuiteTable columns={columns} data={data} showLoading={loading} />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </div>
    </div>
  )
}

export const APIParamsComponent = ({
  params,
  setParams,
  loading = false,
}: {
  params: APIStateParamsModel
  setParams: React.Dispatch<React.SetStateAction<APIStateParamsModel>>
  loading?: boolean
}) => {
  return (
    <div className='d-flex mb-3'>
      <div className='d-flex'>
        <Formik
          initialValues={{
            fromDate: '',
            toDate: '',
            branch_name: '',
          }}
          onSubmit={(values) => {
            setParams({
              ...params,
              from_date: values.fromDate ? moment(values?.fromDate).format('YYYY-MM-DD') : '',
              to_date: values.toDate ? moment(values?.toDate).format('YYYY-MM-DD') : '',
              branch_name: values.branch_name,
            })
          }}
          data-cy='design-form'
        >
          {({touched, handleChange, errors, values, setFieldValue}) => {
            return (
              <Form>
                <div className='d-flex flex-row'>
                  <div className='col-4' style={{zIndex: 11}}>
                    <DatePickerForGrievance
                      containerClassName='col-12'
                      name='fromDate'
                      placeholderText='From date'
                      setFieldValue={setFieldValue}
                      value={values.fromDate || false}
                      touched={touched}
                      required={true}
                      showIcon={true}
                      errors={errors}
                    />
                  </div>
                  <div className='col-4 ms-5' style={{zIndex: 11}}>
                    <DatePickerForGrievance
                      containerClassName='col-12'
                      name='toDate'
                      placeholderText='To date'
                      setFieldValue={setFieldValue}
                      value={values.toDate || false}
                      touched={touched}
                      required={true}
                      showIcon={true}
                      errors={errors}
                    />
                  </div>
                  <div className='col-3 ms-5 d-flex align-items-center' style={{zIndex: 11}}>
                    <div className='w-100'>
                      <Select
                        data={[{label: 'HATTISAR', value: 'HATTISAR'}]}
                        size='lg'
                        placeholder='Status'
                        onChange={(value) => setFieldValue('status', value)}
                        style={{width: '100%', height: '100%'}}
                      />
                    </div>
                  </div>
                  <div className='col-1 ms-5'>
                    <button
                      type='submit'
                      disabled={loading}
                      className='btn btn-primary btn'
                      data-cy='design-filter-button'
                    >
                      Filter
                    </button>
                  </div>
                </div>
                <InputGroup inside style={{width: '35%', margin: '1rem 0'}}>
                  <Input
                    placeholder='Search'
                    value={params.search}
                    onChange={(value: string) => setParams({...params, search: value})}
                    data-cy='search-field'
                  />
                  <InputGroup.Addon>
                    {!isEmpty(params?.search) ? (
                      <CloseIcon
                        style={{cursor: 'pointer'}}
                        onClick={() => setParams({...params, search: ''})}
                        data-cy='search-close-button'
                      />
                    ) : (
                      <SearchIcon />
                    )}
                    {/* <SearchIcon /> */}
                  </InputGroup.Addon>
                </InputGroup>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default APISuccessList
