import React, {useState} from 'react'
import {Tabs, Tab} from 'react-bootstrap-v5'
import {FailureList, RequestList, SuccessList} from './tables'

const AncillaryServiceRequestList = () => {
  const [activeKey, setActiveKey] = useState('requestList')

  return (
    <>
      <div className='col-lg-6 mb-4'>
        <div className='heading__component__title fw-bolder'>Ancillary Services Request List</div>
      </div>
      <div className='modal-container shadow rounded' data-cy='new-cif-account-list-modal'>
        <div className='bg-white p-5'>
          <Tabs
            activeKey={activeKey}
            onSelect={(k) => setActiveKey(k ?? 'requestList')}
            id='uncontrolled-tab-example'
          >
            <Tab eventKey='requestList' title='Ancillary Services Request'>
              <div className='card-body bg-white' data-cy='tab-content-individual-joint-minor'>
                <RequestList />
              </div>
            </Tab>
            <Tab eventKey='failureList' title='Ancillary Services Failure List'>
              <div className='card-body bg-white'>
                <FailureList />
              </div>
            </Tab>
            <Tab eventKey='successList' title='Ancillary Services Success List'>
              <div className='card-body bg-white'>
                <SuccessList />
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  )
}

export default AncillaryServiceRequestList
