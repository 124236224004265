import CPUCheckerCorporateBankReviewComponent from 'src/app/modules/common/components/cpuChecker/ReviewComponent/CorporateBank'

const CPUCheckerNewCIFAndAccountCorproateBankReviewPage = () => {
  return (
    <div>
      <CPUCheckerCorporateBankReviewComponent />
    </div>
  )
}

export default CPUCheckerNewCIFAndAccountCorproateBankReviewPage
