import {Form, Formik} from 'formik'
import {FC, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import * as placeReducer from 'src/app/modules/common'
import * as masterDataRedux from 'src/app/modules/masterData/redux'
import {usePostCIFtoGETCustomerInfo} from 'src/app/queries/CBS'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as Yup from 'yup'
import ExisitingCIFCreate from './ExisitingCIFCreate'

const validationSchema = Yup.object().shape({
  cifNumber: Yup.string().required('CIF Number is required'),
})

const ExistingCIF: FC = () => {
  const dispatch = useDispatch()
  const {
    mutate: postCIFtoGETCustomerInfo,
    data: customerData,
    isLoading,
  } = usePostCIFtoGETCustomerInfo()

  useEffect(() => {
    dispatch(masterDataRedux.actions.getAllMasterData())
  }, [])
  useEffect(() => {
    dispatch(masterDataRedux.actions.getCustomerClassification())
  }, [])
  useEffect(() => {
    dispatch(masterDataRedux.actions.getDocumentTypes())
  }, [])

  useEffect(() => {
    dispatch(placeReducer.action.getProvince())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getDistrict())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getMunicipality())
  }, [])
  return (
    <>
      {customerData && customerData?.resCod === '200' ? null : (
        <>
          <div
            className='heading__component__title fw-bolder mb-5'
            data-cy='exsiting-cif-number-heading'
          >
            Existing CIF
          </div>
          <Formik
            initialValues={{cifNumber: ''}}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              postCIFtoGETCustomerInfo(values.cifNumber)
            }}
          >
            {({handleChange, errors, touched}) => (
              <div className='shadow p-8 bg-white rounded'>
                <Form>
                  <div className='row align-items-center'>
                    <div className='col-md-6'>
                      <FormTextBox
                        containerClassName='col-md-12'
                        type='text'
                        placeholder='Please Insert Your CIF Number'
                        label='CIF Number'
                        name='cifNumber'
                        disabled={false}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required={true}
                        data-cy='cif-number-text-field'
                      />
                    </div>
                    <div className='col-md-6'>
                      <button type='submit' className='btn btn-primary' disabled={isLoading}>
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </>
      )}

      {customerData && customerData?.resCod === '200' && (
        <ExisitingCIFCreate cifData={customerData} />
      )}
    </>
  )
}

export default ExistingCIF
