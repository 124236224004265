import {Tab, Tabs} from 'react-bootstrap-v5'
import CorporateBankTable from './table/CorporateBankAccountList'
import IndividualRejectedList from './table/IndividualRejectedList'
import IndividualJointMinorAccountList from './table/IndividualJointMinorAccountList'
import {useState} from 'react'
const BranchCheckerNewCIFAccountList = () => {
  const [activeKey, setActiveKey] = useState('individualJointMinor')

  return (
    <div className='modal-container shadow rounded' data-cy='new-cif-account-list-modal'>
      <div className='bg-white p-5'>
        <Tabs
          activeKey={activeKey}
          onSelect={(k) => setActiveKey(k ?? 'individualJointMinor')}
          id='branchChecker-new-cif-account-tabs'
          className='mb-5'
          data-cy='new-cif-account-list-tabs'
        >
          <Tab
            eventKey='individualJointMinor'
            title='Individual/Joint/Minor'
            data-cy='tab-individual-joint-minor'
          >
            <div className='card-body bg-white' data-cy='tab-content-individual-joint-minor'>
              <IndividualJointMinorAccountList />
            </div>
          </Tab>
          <Tab eventKey='corporateBank' title='Corporate/Banks' data-cy='tab-corporate-banks'>
            <div className='card-body bg-white' data-cy='tab-content-corporate-banks'>
              <CorporateBankTable />
            </div>
          </Tab>
          <Tab
            eventKey='individualRejectList'
            title='Individual/RejectList'
            data-cy='tab-individual-reject-list'
          >
            <div className='card-body bg-white' data-cy='tab-content-individual-reject-list'>
              <IndividualRejectedList />
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

export default BranchCheckerNewCIFAccountList
