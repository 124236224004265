import DetailCell from '../DetailCell'

interface PermanentAddressInformationProps {
  data: any
}

const PermanentAddressInformation = ({data}: PermanentAddressInformationProps) => {
  return (
    <div className='my-10'>
      <h2 className='text-center my-4'>PERMANENT</h2>
      <div className='row'>
        <DetailCell label='Country' value={data?.permanent_country?.title} />
      </div>
      <div className='row'>
        <DetailCell label='House Number' value={data?.permanent_house_number} />
        <DetailCell label='Street Name/Tole' value={data?.permanent_tole} />
      </div>
      <div className='row'>
        <DetailCell label='Ward Number' value={data?.permanent_ward_number} />
        <DetailCell label='Province' value={data?.permanent_province?.title} />
        <DetailCell label='District' value={data?.permanent_district?.title} />
      </div>
      <div className='row'>
        <DetailCell label='Municipality' value={data?.permanent_municipality?.title} />
        <DetailCell label='Other Municipality' value={data?.permanent_municipality_other} />
        <DetailCell label='Phone No. (Work)' value={data?.permanent_phone_number_work} />
      </div>
      <div className='row'>
        <DetailCell label='Phone No. (Resident)' value={data?.permanent_phone_number_residence} />
        <DetailCell label='Mobile Number' value={data?.permanent_mobile_number} />
        <DetailCell label='Email' value={data?.permanent_email} />
      </div>
    </div>
  )
}

export default PermanentAddressInformation
