import {call, delay, put, takeLatest} from 'redux-saga/effects'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {actions} from './action'
import {services} from './service'
import {actionTypes} from './constants'

function* getBulkAccountOpeningListSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(services.getAllBulkAccountOpening, params)
    const data: any = response?.data?.data

    yield put(actions.getBulkAccountOpeningListSuccess(data))
  } catch (error: any) {
    yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
  } finally {
    yield put(actions.getBulkAccountOpeningListFinish())
  }
}

function* getBulkAccountOpenedListSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(services.getBulkAccountOpenedList, params)
    const data: any = response?.data?.data

    yield put(actions.getBulkAccountOpenedListSuccess(data))
  } catch (error: any) {
    yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
  } finally {
    yield put(actions.getBulkAccountOpenedListFinish())
  }
}

function* getFailedMasterBatchListSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(services.getFailedMasterBatchList)
    const data: any = response?.data?.data

    yield put(actions.getFailedMasterBatchListSuccess(data))
  } catch (error: any) {
    yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
  } finally {
    yield put(actions.getFailedMasterBatchListFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_BULK_ACCOUNT_LIST_START, getBulkAccountOpeningListSaga),
    yield takeLatest(actionTypes.GET_FAILED_MASTER_BATCH_LIST_START, getFailedMasterBatchListSaga),
    yield takeLatest(actionTypes.GET_BULK_ACCOUNT_OPENED_LIST_START, getBulkAccountOpenedListSaga)
}
