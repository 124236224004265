import {Field, Formik, useField, useFormik} from 'formik'
import * as Yup from 'yup'
import {memo, useEffect, useRef, useState} from 'react'
import {Input, Pagination, SelectPicker, Table} from 'rsuite'
import {StateParamsModel} from 'src/app/modules/common/Model'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import DetailsRow from '../DetailsRow'
import usePostLinkedEntitiesBranchReview from 'src/app/queries/linkedEntities/post/usePostBranchReview'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import usePostLinkedEntities from 'src/app/queries/linkedEntities/post/usePostLinkedEntities'
import * as masterDataRedux from 'src/app/modules/masterData/redux'
import {snakeCase} from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from 'src/setup'

const Cell = Table.Cell

interface FormValues {
  status: {
    [key: string]: string
  }
  remarks: {
    [key: string]: string
  }
  cifCategory: {
    [key: string]: string
  }
}

const RemarksCell = memo(({rowData, formik, ...props}: any) => {
  const fieldName = `remarks[customerNumber${rowData?.customer_number}]`
  return (
    <Cell {...props} className='link-group'>
      <Input
        as='textarea'
        id={rowData?.customer_number}
        name={fieldName}
        value={formik.values.remarks[`customerNumber${rowData.customer_number}`] || ''}
        onChange={(value) => {
          formik.setFieldValue(fieldName, value)
        }}
      />
      {formik.touched.remarks &&
      formik.errors.remarks &&
      formik.errors.remarks[`customerNumber${rowData.customer_number}`] ? (
        <div style={{color: 'red'}}>
          {formik.errors.remarks[`customerNumber${rowData.customer_number}`]}
        </div>
      ) : null}
    </Cell>
  )
})

const ReviewPage = () => {
  const [customerNumber, setCustomerNumber] = useState<string>()
  const [loadingCustomerNumber, setLoadingCustomerNumber] = useState<string | null>(null)
  const [primaryCustomerId, setPrimaryCustomerId] = useState<string>('')
  const {mutate, isLoading} = usePostLinkedEntitiesBranchReview()
  const location = useLocation()
  const history = useHistory()
  const authResp = useSelector((state: any) => state.auth)
  const {user} = authResp
  const {id} = useParams() as {id: string}
  const {state: linkedData} = location as {state: any}
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(masterDataRedux.actions.getAllMasterData())
  }, [dispatch])

  const {data: linkedEntitiesList = [], loading} = useSelector(
    (state: RootState) => state?.linkedEntitiesList
  )
  const {
    mutate: searchLinkedEntities,
    data: linkedEntitiesData,
    isLoading: linkedEntitiesLoading,
  } = usePostLinkedEntities()
  useEffect(() => {
    searchLinkedEntities({cif_number: linkedData?.primary_customer_number})
  }, [linkedData?.primary_customer_number])

  const relationshipMap = linkedEntitiesList.reduce((acc: any, rel: any) => {
    acc[rel.id] = rel.title
    return acc
  }, {})

  const data =
    linkedEntitiesData?.data?.entities?.map((linkedLists: any, i: number) => ({
      ...linkedLists,
      sn: i + 1,
      relationship: relationshipMap[linkedLists.relationship],
      branch: user?.data.branch.code ?? '',
    })) ?? []

  const validateFields = (values: FormValues, customerNumber: string) => {
    const errors: Partial<FormValues> = {status: {}, remarks: {}, cifCategory: {}}
    const statusValue = values.status[`customerNumber${customerNumber}`]
    const remarksValue = values.remarks[`customerNumber${customerNumber}`]
    const cifCategoryValue = values.cifCategory[`customerNumber${customerNumber}`]
    if (!statusValue) {
      errors.status![`customerNumber${customerNumber}`] = 'Status is Required'
    }
    if (!remarksValue) {
      errors.remarks![`customerNumber${customerNumber}`] = 'Remarks is Required'
    }
    if (!cifCategoryValue) {
      errors.cifCategory![`customerNumber${customerNumber}`] = 'CIF Category is Required'
    }
    return errors
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      status: {},
      remarks: {},
      cifCategory: {},
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values, {setErrors}) => {
      if (customerNumber) {
        const errors = validateFields(values, customerNumber)
        if (
          Object.keys(errors.status!).length > 0 ||
          Object.keys(errors.remarks!).length > 0 ||
          Object.keys(errors.cifCategory!).length > 0
        ) {
          setErrors(errors)
        } else {
          const payload = {
            status: values.status[`customerNumber${customerNumber}`],
            remarks: values.remarks[`customerNumber${customerNumber}`],
            cifCategory: values.cifCategory[`customerNumber${customerNumber}`],
          }
          mutate(
            {id: primaryCustomerId, data: payload},
            {
              onSettled: () => {
                setLoadingCustomerNumber(null)
                searchLinkedEntities({cif_number: linkedData?.primary_customer_number})
              },
            }
          )
        }
      }
    },
  })

  // Cell Components
  const StatusSelectCell = ({rowData, ...props}: any) => {
    const fieldName = `status[customerNumber${rowData?.customer_number}]`
    return (
      <Cell {...props} className='link-group'>
        <SelectPicker
          style={{width: '100%'}}
          placeholder='Status'
          value={formik.values.status[`customerNumber${rowData?.customer_number}`] || ''}
          onChange={(value: string | null) => {
            formik.setFieldValue(fieldName, value || '')
          }}
          data={[
            {label: 'APPROVE', value: 'approve'},
            {label: 'REJECT', value: 'reject'},
            {label: 'REVERT BACK TO BRANCH MAKER', value: 'revert'},
          ]}
        />
        {formik.touched.status &&
        formik.errors.status &&
        formik.errors.status[`customerNumber${rowData.customer_number}`] ? (
          <div style={{color: 'red'}}>
            {formik.errors.status[`customerNumber${rowData.customer_number}`]}
          </div>
        ) : null}
      </Cell>
    )
  }

  const CIFCategorySelectCell = ({rowData, ...props}: any) => {
    const fieldName = `cifCategory[customerNumber${rowData?.customer_number}]`
    return (
      <Cell {...props} className='link-group'>
        <SelectPicker
          style={{width: '100%'}}
          placeholder='CIF Category'
          value={formik.values.cifCategory[`customerNumber${rowData?.customer_number}`] || ''}
          onChange={(value: string | null) => {
            formik.setFieldValue(fieldName, value || '')
          }}
          data={[
            {label: 'A', value: 'A'},
            {label: 'C', value: 'C'},
          ]}
        />
        {formik.touched.cifCategory &&
        formik.errors.cifCategory &&
        formik.errors.cifCategory[`customerNumber${rowData.customer_number}`] ? (
          <div style={{color: 'red'}}>
            {formik.errors.cifCategory[`customerNumber${rowData.customer_number}`]}
          </div>
        ) : null}
      </Cell>
    )
  }

  const ActionCell = ({rowData, ...props}: any) => {
    const buttonDisabled =
      rowData?.account_status?.current_status.toLowerCase() !== 'submitted to branch checker'
        ? true
        : false
    return (
      <Cell {...props} className='link-group'>
        <button
          disabled={
            (isLoading && loadingCustomerNumber === rowData?.customer_number) || buttonDisabled
          }
          className='btn btn-primary btn-sm ms-3'
          onClick={() => {
            setCustomerNumber(rowData?.customer_number)
            setLoadingCustomerNumber(rowData?.customer_number)
            setPrimaryCustomerId(rowData?.primary_customer_id)
            formik.submitForm()
          }}
        >
          {isLoading && loadingCustomerNumber === rowData?.customer_number
            ? 'Continue....'
            : 'Continue'}
        </button>
      </Cell>
    )
  }

  const columns = [
    {label: 'S.N.', dataKey: 'sn', width: 100, cell: <Cell dataKey='sn' />},
    {
      label: 'Customer Number',
      dataKey: 'customer_number',
      width: 180,
      cell: <Cell dataKey='customer_number' />,
    },
    {
      label: 'Customer Name',
      dataKey: 'customer_name',
      cell: <Cell dataKey='customer_name' />,
      sortable: false,
      width: 250,
    },
    {
      label: 'Relationship',
      dataKey: 'relationship',
      width: 150,
      cell: <Cell dataKey='relationship' />,
    },
    {
      label: 'Branch',
      dataKey: 'branch',
      sortable: false,
      width: 150,
      cell: <Cell dataKey='branch' />,
    },
    {
      label: 'CIF Category',
      dataKey: 'cifCategory',
      cell: <CIFCategorySelectCell />,
      sortable: false,
      width: 230,
    },
    {
      label: 'Status',
      dataKey: 'status',
      cell: <StatusSelectCell />,
      sortable: false,
      width: 230,
    },
    {
      label: 'Remarks',
      dataKey: 'customer_number',
      cell: <RemarksCell formik={formik} />,
      sortable: false,
      width: 230,
    },
    {
      label: 'Action',
      width: 160,
      align: 'center',
      cell: <ActionCell />,
    },
  ]

  return (
    <div>
      <div className='heading__component__title fw-bolder mb-4'>Linked Entities Review</div>
      <div className='shadow p-3 bg-white rounded'>
        <div className='my-6'>
          <DetailsRow
            label={'Primary Customer Number'}
            value={linkedData?.primary_customer_number ?? ''}
          />
          <DetailsRow label={'Primary A/C NO'} value={linkedData?.primary_account_number} />
          <DetailsRow label={'Primary Customer Name'} value={linkedData?.primary_customer_name} />
        </div>

        <div className='datatable'>
          <RSuiteTable
            columns={columns}
            data={data}
            showLoading={linkedEntitiesLoading || loading}
            rowHeight={80}
          />
        </div>
        <div className='d-flex justify-content-end my-4'>
          <button
            onClick={() => {
              history.push('/branch-checker/linked-entities/linked-entities-listing')
            }}
            className='dt-btn dt-btn-primary dt-btn-sm me-3'
          >
            Done
          </button>
          <button
            onClick={() => {
              history.goBack()
            }}
            className='dt-btn dt-btn-outline-primary dt-btn-sm me-3'
          >
            Return
          </button>
        </div>
      </div>
    </div>
  )
}

export default ReviewPage
