import {adToBs} from '@sbmdkl/nepali-date-converter'
import {useSelector} from 'react-redux'
import {MasterDataModel} from 'src/app/modules/masterData/Model'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormNepaliCalendarDatePicker from 'src/cms/helpers/components/forms/form-fields/FormNepaliCalendarDatePicker'
import {RootState} from 'src/setup'

interface DocumentDetailsSectionProps {
  handleChange: any
  touched: any
  errors: any
  setFieldValue: any
  values: any
  initialValues: any
  data: any
}

const DocumentDetailsUpdateSection = ({
  handleChange,
  errors,
  touched,
  setFieldValue,
  values,
  initialValues,
  data,
}: DocumentDetailsSectionProps) => {
  const {data: identityTypeList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.idTypeList
  )
  const {data: uniqueIdIssuedBy}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.individualUniqueIdList
  )

  const {district, loading} = useSelector((state: RootState) => state.place)

  return (
    <>
      <div className='col-12'>
        <p className='fs-2'>Document Details Update</p>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-5'>Prefered Identity</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6' style={{textTransform: 'uppercase'}}>
            {data?.preferred_identity ?? '-'}
          </strong>
        </div>
        <div className='col-4'>
          <FormSelect
            name='preferred_identity'
            label=' '
            containerClassName='col-12'
            labelClassName='col-12'
            options={[
              {label: 'Citizenship', value: 'citizenship'},
              {label: 'Passport', value: 'passport'},
              {label: 'Others', value: 'other'},
            ]}
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
          />
        </div>
      </div>
      {values.preferred_identity === 'citizenship' && (
        <>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-4'>Citizenship Number</strong>
            </div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.citizenship_number ?? '-'}</strong>
            </div>
            <div className='col-4'>
              <FormTextBox
                name='citizenship_number'
                label=' '
                containerClassName='col-12'
                onChange={handleChange}
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Citizenship Issue District</strong>
            </div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.citizenship_issue_district?.title ?? ''}</strong>
            </div>
            <div className='col-4'>
              <FormSelect
                name='citizenship_issue_district'
                label=' '
                containerClassName='col-12'
                labelClassName='col-12'
                options={
                  district?.map((districtData) => ({
                    label:
                      typeof districtData.title === 'string'
                        ? districtData.title
                        : districtData.title.toString(),
                    value:
                      typeof districtData.id === 'string'
                        ? districtData.id
                        : districtData.id.toString(),
                  })) ?? []
                }
                setFieldValue={setFieldValue}
                onChange={handleChange}
                errors={errors}
                touched={touched}
                values={values}
              />
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Citizenship Registration Date (B.S.)</strong>
            </div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.citizenship_issue_date_bs ?? ''}</strong>
            </div>
            <div className='col-4'>
              <FormNepaliCalendarDatePicker
                englishFormName='citizenship_issue_date_ad'
                nepaliFormName='citizenship_issue_date_bs'
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
              />
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Citizenship Registration Date (A.D.)</strong>
            </div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.citizenship_issue_date_ad ?? ''}</strong>
            </div>
            <div className='col-4'>
              <FormTextBox
                type='date'
                name='citizenship_issue_date_ad'
                label=' '
                containerClassName='col-12'
                onChange={(e: any) => {
                  setFieldValue(
                    'citizenship_issue_date_bs',
                    e.target.value && adToBs(e.target.value)
                  )
                  handleChange(e)
                }}
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
        </>
      )}

      {values.preferred_identity === 'passport' && (
        <>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-4'>Passport Number</strong>
            </div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.passport_number ?? ''}</strong>
            </div>
            <div className='col-4'>
              <FormTextBox
                name='passport_number'
                label=' '
                containerClassName='col-12'
                onChange={handleChange}
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Place of Issuance</strong>
            </div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.passport_issue_district?.title ?? ''}</strong>
            </div>
            <div className='col-4'>
              <FormSelect
                name='passport_issue_district'
                label=' '
                containerClassName='col-12'
                labelClassName='col-12'
                options={
                  district?.map((districtData) => ({
                    label:
                      typeof districtData.title === 'string'
                        ? districtData.title
                        : districtData.title.toString(),
                    value:
                      typeof districtData.id === 'string'
                        ? districtData.id
                        : districtData.id.toString(),
                  })) ?? []
                }
                setFieldValue={setFieldValue}
                onChange={handleChange}
                errors={errors}
                touched={touched}
                values={values}
              />
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Date of Issuance</strong>
            </div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.passport_issue_date_ad ?? ''}</strong>
            </div>
            <div className='col-4'>
              <FormTextBox
                type='date'
                name='passport_issue_date_ad'
                label=' '
                containerClassName='col-12'
                onChange={handleChange}
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Date of Expiry</strong>
            </div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.passport_expiry_date_ad ?? ''}</strong>
            </div>
            <div className='col-4'>
              <FormTextBox
                type='date'
                name='passport_expiry_date_ad'
                label=' '
                containerClassName='col-12'
                onChange={handleChange}
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
        </>
      )}

      {values.preferred_identity === 'other' && (
        <>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Type of Identity Card</strong>
            </div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.type_of_identity_card?.title ?? ''}</strong>
            </div>
            <div className='col-4'>
              <FormSelect
                name='type_of_identity_card'
                label=' '
                containerClassName='col-12'
                labelClassName='col-12'
                options={
                  identityTypeList.map((idType) => ({
                    label: idType.title,
                    value: idType.id,
                  })) ?? []
                }
                setFieldValue={setFieldValue}
                onChange={handleChange}
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-4'>ID Card Number</strong>
            </div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.id_card_number ?? ''}</strong>
            </div>
            <div className='col-4'>
              <FormTextBox
                name='id_card_number'
                label=' '
                containerClassName='col-12'
                onChange={handleChange}
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Date of Issuance (A.D.)</strong>
            </div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.id_card_issue_date_ad ?? ''}</strong>
            </div>
            <div className='col-4'>
              <FormTextBox
                type='date'
                name='id_card_issue_date_ad'
                label=' '
                containerClassName='col-12'
                onChange={(e: any) => {
                  setFieldValue('id_card_issue_date_bs', e.target.value && adToBs(e.target.value))
                  handleChange(e)
                }}
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Date of Issuance (B.S.)</strong>
            </div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.id_card_issue_date_bs ?? ''}</strong>
            </div>
            <div className='col-4'>
              <FormNepaliCalendarDatePicker
                englishFormName='id_card_issue_date_ad'
                nepaliFormName='id_card_issue_date_bs'
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
              />
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Date of Expiry (A.D.)</strong>
            </div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.id_card_expiry_date_ad ?? ''}</strong>
            </div>
            <div className='col-4'>
              <FormTextBox
                type='date'
                name='id_card_expiry_date_ad'
                label=' '
                containerClassName='col-12'
                onChange={(e: any) => {
                  setFieldValue('id_card_issue_date_bs', e.target.value && adToBs(e.target.value))
                  handleChange(e)
                }}
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Date of Expiry (B.S.)</strong>
            </div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.id_card_expiry_date_bs ?? ''}</strong>
            </div>
            <div className='col-4'>
              <FormNepaliCalendarDatePicker
                englishFormName='id_card_expiry_date_ad'
                nepaliFormName='id_card_expiry_date_bs'
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
              />
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-4'>
              <strong className='fs-5'>Place of Issuance</strong>
            </div>
            <div className='col-4'>
              <strong className='fs-6'>{data?.id_card_issue_district?.id ?? ''}</strong>
            </div>
            <div className='col-4'>
              <FormSelect
                name='id_card_issue_district'
                label=' '
                containerClassName='col-12'
                labelClassName='col-12'
                options={
                  district?.map((districtData) => ({
                    label:
                      typeof districtData.title === 'string'
                        ? districtData.title
                        : districtData.title.toString(),
                    value:
                      typeof districtData.id === 'string'
                        ? districtData.id
                        : districtData.id.toString(),
                  })) ?? []
                }
                setFieldValue={setFieldValue}
                onChange={handleChange}
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
        </>
      )}

      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-5'>Identity Issued By</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.unique_id_issued_by?.title ?? ''}</strong>
        </div>
        <div className='col-4'>
          <FormSelect
            name='unique_id_issued_by'
            label=' '
            containerClassName='col-12'
            labelClassName='col-12'
            options={
              uniqueIdIssuedBy.map((uid) => ({
                label: uid.title,
                value: uid.id,
              })) ?? []
            }
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
          />
        </div>
      </div>
      {values.unique_id_issued_by ===
        uniqueIdIssuedBy.find((uid) => uid.title === 'OTHERS')?.id && (
        <div className='row my-2'>
          <div className='col-4'>
            <strong className='fs-5'>Identity Issued By Other</strong>
          </div>
          <div className='col-4'>
            <strong className='fs-6'>{data?.unique_id_issued_by_other ?? ''}</strong>
          </div>
          <div className='col-4'>
            <FormTextBox
              name='unique_id_issued_by_other'
              label=' '
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={handleChange}
              errors={errors}
              touched={touched}
            />
          </div>
        </div>
      )}
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>PAN Number</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.citizenship_issue_district ?? ''}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            name='pan_number'
            label=' '
            containerClassName='col-12'
            onChange={handleChange}
            errors={errors}
            touched={touched}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-5'>PAN Registration Date (B.S.)</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.pan_registration_date_bs ?? ''}</strong>
        </div>
        <div className='col-4'>
          <FormNepaliCalendarDatePicker
            englishFormName='pan_registration_date_ad'
            nepaliFormName='pan_registration_date_bs'
            setFieldValue={setFieldValue}
            touched={touched}
            values={values}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-5'>PAN Registration Date (A.D.)</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.pan_registration_date_ad ?? ''}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            type='date'
            name='pan_registration_date_ad'
            label=' '
            containerClassName='col-12'
            onChange={(e: any) => {
              setFieldValue('pan_registration_date_bs', e.target.value && adToBs(e.target.value))
              handleChange(e)
            }}
            errors={errors}
            touched={touched}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-5'>PAN Issue District</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.pan_issue_district?.title ?? ''}</strong>
        </div>
        <div className='col-4'>
          <FormSelect
            name='pan_issue_district'
            label=' '
            containerClassName='col-12'
            labelClassName='col-12'
            options={
              district?.map((districtData) => ({
                label:
                  typeof districtData.title === 'string'
                    ? districtData.title
                    : districtData.title.toString(),
                value:
                  typeof districtData.id === 'string'
                    ? districtData.id
                    : districtData.id.toString(),
              })) ?? []
            }
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
          />
        </div>
      </div>
    </>
  )
}

export default DocumentDetailsUpdateSection
