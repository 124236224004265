import {MasterDataModel} from 'src/app/modules/masterData/Model'
import DetailCell from '../DetailCell'
import {useSelector} from 'react-redux'
import {RootState} from 'src/setup'

interface CorrespondenceAddressInformationSectionProps {
  data?: any
}

const CorrespondenceAddressInformationSection = ({
  data,
}: CorrespondenceAddressInformationSectionProps) => {
  const {data: countries}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.countriesList
  )
  return (
    <>
      <h2 className='text-center my-4'>CORRESPONDENCE ADDRESS</h2>
      {data?.kyc_information?.correspondence_country?.title === 'Nepal' ? (
        <>
          <div className='row'>
            <DetailCell
              label='Country'
              value={data?.kyc_information?.correspondence_country?.title}
            />
          </div>
          <div className='row'>
            <DetailCell
              label='House Number'
              value={data?.kyc_information?.correspondence_house_number}
            />
            <DetailCell
              label='Street Name/Tole'
              value={data?.kyc_information?.correspondence_tole}
            />
          </div>
          <div className='row'>
            <DetailCell
              label='Ward Number'
              value={data?.kyc_information?.correspondence_ward_number}
            />
          </div>
          <div className='row'>
            <DetailCell
              label='Province'
              value={data?.kyc_information?.correspondence_province?.title}
            />
            <DetailCell
              label='District'
              value={data?.kyc_information?.correspondence_district?.title}
            />
          </div>
          <div className='row'>
            <DetailCell
              label='Municipality'
              value={data?.kyc_information?.correspondence_municipality?.title}
            />
            <DetailCell
              label='Other Municipality'
              value={data?.kyc_information?.correspondence_municipality_other}
            />
          </div>
        </>
      ) : (
        <>
          <div className='row'>
            <DetailCell
              label='Correspondence Address Line 1'
              value={data?.kyc_information?.correspondence_outside_nepal_address_line_1}
            />
          </div>
          <div className='row'>
            <DetailCell
              label='Correspondence Address Line 2'
              value={data?.kyc_information?.correspondence_outside_nepal_address_line_2}
            />
            <DetailCell
              label='Correspondence Address Line 3'
              value={data?.kyc_information?.correspondence_outside_nepal_address_line_3}
            />
          </div>
        </>
      )}
      <div className='row'>
        <DetailCell
          label='Phone No.'
          value={data?.kyc_information?.correspondence_phone_number_residence}
        />
        <DetailCell
          label='Mobile No.'
          value={data?.kyc_information?.correspondence_mobile_number}
        />
      </div>
      <div className='row'>
        <DetailCell label='Email' value={data?.kyc_information?.correspondence_email} />
      </div>
    </>
  )
}

export default CorrespondenceAddressInformationSection
