export const actionTypes = {
  GET_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_LIST_START:
    'GET_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_START',
  GET_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_SUCCESS:
    'GET_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_SUCCESS',
  GET_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_FINISH:
    'GET_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_FINISH',

  POST_ACCOUNT_PRELIMINARY_BRANCH_REVIEW_START: 'POST_ACCOUNT_PRELIMINARY_BRANCH_REVIEW_START',
  POST_ACCOUNT_PRELIMINARY_BRANCH_REVIEW_SUCCESS: 'POST_ACCOUNT_PRELIMINARY_BRANCH_REVIEW_SUCCESS',
  POST_ACCOUNT_PRELIMINARY_BRANCH_REVIEW_FINISH: 'POST_ACCOUNT_PRELIMINARY_BRANCH_REVIEW_FINISH',

  GET_NEW_CIF_AND_ACCOUNT_DETAILS_BY_ID_START: 'GET_NEW_CIF_AND_ACCOUNT_DETAILS_BY_ID_START',
  GET_NEW_CIF_AND_ACCOUNT_DETAILS_BY_ID_SUCCESS: 'GET_NEW_CIF_AND_ACCOUNT_DETAILS_BY_ID_SUCCESS',
  GET_NEW_CIF_AND_ACCOUNT_DETAILS_BY_ID_FINISH: 'GET_NEW_CIF_AND_ACCOUNT_DETAILS_BY_ID_FINISH',
}
