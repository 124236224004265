import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {handleUppercaseChange} from 'src/app/modules/CreateNewCIFAndCustomerAccount/createNewAccount/components'
import * as placeReducer from 'src/app/modules/common'
import {MasterDataModel} from 'src/app/modules/masterData/Model'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {RootState} from 'src/setup'

interface PermanentAddressSectionProps {
  data: any
  cbsData: any
}

const PermanentAddressSection = ({data, cbsData}: PermanentAddressSectionProps) => {
  return (
    <>
      <p className='fs-2'>Permanent Address</p>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>AddrLine1</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_AddrLine1 ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>AddrLine2</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_AddrLine2 ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>AddrLine3</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_AddrLine3 && '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>House Number</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_house_number ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Street Name/Tole</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_tole ?? '- '}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Ward Number</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_ward_number ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Country</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_country?.title ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Province</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_province?.title ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>District</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_district?.title ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Municipality</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.permanent_municipality?.title ?? ''}</strong>
        </div>
      </div>
    </>
  )
}

export default PermanentAddressSection
