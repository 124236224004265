import {Close, Search} from '@rsuite/icons'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {Input, InputGroup, Pagination, Table} from 'rsuite'
import {StateParamsModel} from 'src/app/modules/common/Model'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import * as bulkAccountRedux from 'src/app/modules/bulkAccountOpening/masterBatchList/redux'

const Cell = Table.Cell

const IndividualMinorOnlineBulkReal = () => {
  const disptach = useDispatch()
  const history = useHistory()

  const [params, setParams] = useState<StateParamsModel>({
    type: 'Real',
    status: 1,
    page: 1,
    limit: 10,
    orderBy: 'updated_at',
    order: 'desc',
  })

  const {data: newCIFandAccountListData, loading} = useSelector(
    (state: any) => state.bulkAccountOpenedList
  )

  const data =
    newCIFandAccountListData?.new_accounts?.map((newAccount: any, i: number) => ({
      ...newAccount,
      sn: (params?.page - 1) * params?.limit + (i + 1),
      account_name: `${newAccount?.first_name} ${
        newAccount.middle_name ? newAccount.middle_name + ' ' : ''
      } ${newAccount?.last_name}`,
    })) ?? []

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    const isDraft = rowData.account_status.current_status === 'Saved as Draft - KYC'
    return (
      <Cell {...props} className='link-group'>
        <button
          className={
            isDraft ? 'dt-btn dt-btn-outline-primary dt-btn-sm' : 'btn btn-primary btn-sm '
          }
          onClick={() =>
            history.push({
              pathname: `/cpu-maker/new-cif-and-account-list/${rowData?.id}`,
              state: rowData,
            })
          }
        >
          {isDraft ? 'Continue' : 'Review'}
        </button>
      </Cell>
    )
  }

  const columns = [
    {
      label: 'Account Number',
      dataKey: 'registration_detail.account_number',
      width: 200,
      cell: <Cell dataKey='registration_detail.account_number' />,
    },
    {
      label: 'Customer Code',
      dataKey: 'customer_code',
      width: 200,
      cell: <Cell dataKey='customer_code' />,
    },
    {
      label: 'Account Name',
      dataKey: 'account_name',
      cell: <Cell dataKey='account_name' />,
      sortable: false,
      width: 250,
    },
    {
      label: 'CIF Number',
      dataKey: 'registration_detail.cif_number',
      cell: <Cell dataKey='registration_detail.cif_number' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Branch Location',
      dataKey: 'branch_location_code',
      sortable: false,
      width: 150,
      cell: <Cell dataKey='branch_location_code' />,
    },
    {
      label: 'Media',
      dataKey: 'media',
      cell: <Cell dataKey='media' />,
      sortable: false,
      width: 230,
    },
    {
      label: 'Status',
      dataKey: 'account_status.current_status',
      cell: <Cell dataKey='account_status.current_status' />,
      sortable: false,
      width: 230,
    },
    {
      label: 'Updated On',
      dataKey: 'account_status.updated_date',
      cell: <Cell dataKey='account_status.updated_date' />,
      sortable: false,
      width: 230,
    },
    {
      label: 'Action',
      width: 160,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  useEffect(() => {
    disptach(bulkAccountRedux.actions.getBulkAccountOpenedList(params))
  }, [params])
  return (
    <>
      <InputGroup inside style={{width: '30%', margin: '1rem 0'}}>
        <Input
          placeholder='Search'
          value={params.search}
          onChange={(value: string) => setParams({...params, search: value})}
          data-cy='search-field'
        />
        <InputGroup.Addon>
          {!isEmpty(params?.search) ? (
            <Close
              style={{cursor: 'pointer'}}
              onClick={() => setParams({...params, search: ''})}
              data-cy='search-close-button'
            />
          ) : (
            <Search />
          )}
          {/* <SearchIcon /> */}
        </InputGroup.Addon>
      </InputGroup>
      <div className='datatable'>
        <RSuiteTable columns={columns} data={data} showLoading={loading} />
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size='sm'
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={newCIFandAccountListData?.meta?.total ?? 0}
          limitOptions={[10, 20, 50, 100]}
          limit={params?.limit}
          activePage={params?.page}
          onChangePage={(value) => setParams({...params, page: value})}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </>
  )
}

export default IndividualMinorOnlineBulkReal
