import DetailCell from '../DetailCell'

interface IdentificationDocumentSectionProps {
  data?: any
}

const IdentificationDocumentSection = ({data}: IdentificationDocumentSectionProps) => {
  return (
    <>
      <div className='row'>
        <DetailCell
          label='PAN Registration Date (B.S.)'
          value={data?.kyc_information?.pan_registration_date_bs}
        />
        <DetailCell
          label='PAN Registration Date (A.D.)'
          value={data?.kyc_information?.pan_registration_date_ad}
        />
      </div>
      <div className='row'>
        <DetailCell label='Citizenhip Number' value={data?.kyc_information?.citizenship_number} />
        <DetailCell
          label='Citizenship Issue Date (B.S.)'
          value={data?.kyc_information?.citizenship_issue_date_bs}
        />
      </div>
      <div className='row'>
        <DetailCell
          label='Citizenship Issue Date (A.D.)'
          value={data?.kyc_information?.citizenship_issue_date_ad}
        />
        <DetailCell
          label='Citizenship Issue District'
          value={data?.kyc_information?.citizenship_issue_district?.title}
        />
      </div>
      <div className='row'>
        <DetailCell label='Passport Number' value={data?.kyc_information?.passport_number} />
        <DetailCell
          label='Passport Issue Date'
          value={data?.kyc_information?.passport_issue_date}
        />
      </div>
      <div className='row'>
        <DetailCell
          label='Passport Expiry Date'
          value={data?.kyc_information?.passport_expiry_date}
        />
        <DetailCell
          label='Passport Issue Place'
          value={data?.kyc_information?.passport_issue_district?.title}
        />
      </div>
      <div className='row'>
        <DetailCell label='Visa Expiry Date' value={data?.kyc_information?.visa_expiry_date} />
        <DetailCell
          label='Type of ID Card'
          value={data?.kyc_information?.type_of_identity_card?.title}
        />
      </div>
      <div className='row'>
        <div className='col-6'></div>
        <DetailCell
          label='ID Card Issue Date(A.D)'
          value={data?.kyc_information?.id_card_issue_date}
        />
      </div>
      <div className='row'>
        <DetailCell
          label='ID Card Issue Date (B.S.)'
          value={data?.kyc_information?.id_card_issue_date_bs}
        />
        <DetailCell
          label='ID Card Expiry Date'
          value={data?.kyc_information?.id_card_expiry_date}
        />
      </div>
      <div className='row'>
        <DetailCell label='ID Card Number' value={data?.kyc_information?.id_card_number} />
        <DetailCell
          label='ID Card Issue District'
          value={data?.kyc_information?.id_card_issue_district?.title}
        />
      </div>
      <div className='row'>
        <DetailCell
          label='Unique ID Issued By'
          value={data?.kyc_information?.unique_id_issued_by?.title}
        />
        <DetailCell
          label='Other Unique ID Issued By'
          value={data?.kyc_information?.unique_id_issued_by_other}
        />
      </div>
      <div className='row'>
        <DetailCell
          label='Other Unique ID Issued By(Corporate)'
          value={data?.kyc_information?.unique_id_issued_by_corporate?.title}
        />
        <DetailCell
          label='Education Qualification'
          value={data?.kyc_information?.education_qualification?.title}
        />
      </div>
      <div className='row'>
        <DetailCell
          label='Other Education Qualification'
          value={data?.kyc_information?.education_qualification_others}
        />
      </div>
    </>
  )
}

export default IdentificationDocumentSection
