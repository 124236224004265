import {Form, Select, SelectProps} from 'antd'
import {SizeType} from 'antd/lib/config-provider/SizeContext'
import {ErrorMessage, FormikErrors, FormikTouched} from 'formik'

interface ApplicationFromInputProps extends SelectProps {
  placeholder?: string
  classname?: string
  name: string
  label?: string
  required?: boolean
  errors: FormikErrors<Record<string, string>>
  touched: FormikTouched<Record<string, boolean>>
  size?: SizeType
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<unknown>>
}

const ApplicationFormSelect = ({
  placeholder,
  className,
  label,
  name,
  required = false,
  setFieldValue,
  errors,
  touched,
  size = 'large',
  ...props
}: ApplicationFromInputProps) => {
  return (
    <>
      {label ? (
        <Form.Item
          label={required ? `${label} *` : label}
          labelCol={{span: 24}}
          validateStatus={errors[name] && touched[name] ? 'error' : 'success'}
          help={touched[name] && errors[name]}
          htmlFor={`application-form-select-${name}`}
        >
          <Select
            showSearch
            id={`application-form-select-${name}`}
            placeholder={placeholder}
            className={` antd-input-lg ${className}`}
            size={size}
            onChange={(value) => {
              setFieldValue(name, value)
            }}
            optionFilterProp='children'
            filterOption={(input, option): boolean => {
              return (option?.label ?? '')?.toString().toLowerCase().includes(input.toLowerCase())
            }}
            status={errors[name] && touched[name] ? 'error' : undefined}
            {...props}
          />
        </Form.Item>
      ) : (
        <>
          <Select
            showSearch
            id={`application-form-select-${name}`}
            placeholder={placeholder}
            className={` antd-input-lg ${className}`}
            size={size}
            onChange={(value) => {
              setFieldValue(name, value)
            }}
            optionFilterProp='children'
            filterOption={(input, option): boolean => {
              return (option?.label ?? '')?.toString().toLowerCase().includes(input.toLowerCase())
            }}
            status={errors[name] && touched[name] ? 'error' : undefined}
            {...props}
          />
          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
            <ErrorMessage name={name} component='div' className='field-error-message' />
          </div>{' '}
        </>
      )}
    </>
  )
}

export default ApplicationFormSelect
