import {useEffect, useRef, useState} from 'react'
import useSubmitDocuments from 'src/app/queries/Account Preliminary/post/usePostDocumentsAccountPreliminary'

const PPSizePhotoSection = ({
  PPSizePhotoId,
  accountId,
  setResetForm,
  resetForm,
}: {
  PPSizePhotoId: string
  accountId: string
  setResetForm: (resetForm: boolean) => void
  resetForm: boolean
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [isCameraOn, setIsCameraOn] = useState(false)
  const [cameraError, setCameraError] = useState<string>()
  const [isImageCaptured, setIsImageCaptured] = useState(false)

  const {mutate: submitDocuments, isLoading: submitDocumentsLoading} = useSubmitDocuments()

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({video: true})
      if (videoRef.current) {
        videoRef.current.srcObject = stream
        setIsCameraOn(true)
      }
    } catch (error) {
      console.error('Error occurred while accessing the camera: ', error)
      setCameraError('Failed to access the camera')
    }
  }

  const stopCamera = () => {
    if (videoRef.current) {
      const stream = videoRef.current?.srcObject
      if (stream && stream instanceof MediaStream) {
        stream.getTracks().forEach((track) => track.stop())
      }
      videoRef.current.srcObject = null
      setIsCameraOn(false)
    }
  }

  const captureImage = () => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext('2d')
      const videoWidth = videoRef.current.videoWidth
      const videoHeight = videoRef.current.videoHeight
      const aspectRatio = videoWidth / videoHeight
      const canvasWidth = canvasRef.current.width
      const canvasHeight = canvasWidth / aspectRatio
      canvasRef.current.height = canvasHeight
      context?.drawImage(videoRef.current, 0, 0, canvasWidth, canvasHeight)
      stopCamera()
      setIsImageCaptured(true)
    } else {
      console.error('Video element and Canvas element are not available at the moment')
    }
  }

  const submitImage = () => {
    if (canvasRef.current) {
      canvasRef.current.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], 'pp-size-photo.png', {type: 'image/png'})
          const formData = new FormData()
          formData.append('image', blob, 'pp-size-photo.png')
          formData.append('documentTypeId', PPSizePhotoId)

          submitDocuments({formData, accountId: accountId})
        }
      }, 'image/png')
    }
  }

  useEffect(() => {
    if (canvasRef.current) {
      canvasRef.current.width = 368
    }
  }, [])

  useEffect(() => {
    if (resetForm && canvasRef.current) {
      const context = canvasRef.current.getContext('2d')
      context?.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
      setIsImageCaptured(false)
      setResetForm(false)
    }
  }, [resetForm, setResetForm])

  return (
    <div className='border'>
      <div className='bg-warning text-white p-2'>PP Size Photo</div>
      <div className='d-flex m-5 justify-content-between'>
        <div className='border d-flex flex-column' style={{width: '45%'}}>
          <div className='d-flex justify-content-center flex-grow-1'>
            <video
              ref={videoRef}
              className='mx-auto'
              autoPlay
              style={{width: '100%', height: 'auto'}}
            ></video>
          </div>
          <div className='bg-secondary p-3 mt-auto'>
            {!isCameraOn ? (
              <button className='btn-sm btn-success text-white' onClick={startCamera}>
                Open Camera
              </button>
            ) : (
              <button className='btn-sm btn-info text-white' onClick={captureImage}>
                Take Photo
              </button>
            )}
          </div>
        </div>
        <div className='border d-flex flex-column justify-content-between' style={{width: '45%'}}>
          <canvas ref={canvasRef} style={{width: '100%', height: 'auto'}} />
          {isImageCaptured && (
            <div className='bg-secondary p-3 mt-auto'>
              <button className='btn-sm btn-success text-white' onClick={submitImage}>
                {submitDocumentsLoading ? 'Sending...' : 'Confirm'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PPSizePhotoSection
