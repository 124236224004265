import {FC, useState} from 'react'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import CIFCheckList from './CIFCheckList'
import axios from 'axios'
import {toast} from 'react-toastify'
import {initialFormData} from '../Model'
import usePostToSearchCustomer from 'src/app/queries/CBS/post/usePostToSearchCustomer'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import {useSelector} from 'react-redux'
import {elasticSearchData} from './elasticSearchData'
import PepResultList from './PepResultList'
import EDDResultLists from './EDDResultLists'
import BlackListResultLists from './BlackListResultLists'
import GWLList from './GWLList'

const FORM_VALIDATION = Yup.object().shape({
  full_name: Yup.string().required("Please input the applicant's name!"),
  father_name: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Father's Name must contain only alphabets")
    .nullable(),
  unique_id_number: Yup.string()
    .matches(/^[0-9\/]+$/, 'ID Number must be a number')
    .nullable(),
})

const DuplicateCIFCheck: FC = () => {
  const settings: ISettingTypeState = useSelector((state: any) => state.settingType)
  const isElasticSearchLocal = checkBackendSetting('isLocal')
  const [groupedData, setGroupedData] = useState<any>({blacklist: [], edd: [], pep: []})
  const [showCIFList, setShowCIFList] = useState(false)
  const {mutate, isLoading, data} = usePostToSearchCustomer()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const groupBySource = (data: any) => {
    return data.reduce((acc: any, curr: any) => {
      if (!acc[curr.source]) {
        acc[curr.source] = []
      }
      acc[curr.source].push(curr)
      return acc
    }, {})
  }

  const generateToken = async () => {
    setIsSubmitting(true)

    try {
      const response = await axios.post(
        'https://customerscreeningpoc.machbank.com/api/login',
        {
          email: 'animesh.shrestha@amniltech.com',
          password: 'N!rvana3',
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      return response.data.access_token
    } catch (error) {
      toast.error('Error generating token')
      throw error
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleSubmit = async (values: any, {resetForm}: any) => {
    const filteredValues = Object.keys(values).reduce((acc: any, key) => {
      if (values[key] !== '') {
        acc[key] = values[key]
      }
      return acc
    }, {})

    try {
      if (isElasticSearchLocal) {
        mutate(filteredValues, {
          onSuccess: (data) => {
            if (data?.data?.cbs_data?.length > 0) {
              setShowCIFList(true)
            } else {
              setShowCIFList(false)
              toast.error('No CIF found')
            }
          },
        })
      } else {
        const token = await generateToken()
        const payload = {
          ...(values.full_name ? {name: values.full_name} : {}),
          ...(values.father_name ? {father_name: values.father_name} : {}),
          ...(values.unique_id_number ? {id_number: values.unique_id_number} : {}),
        }

        const response = await fetch(
          'https://customerscreeningpoc.machbank.com/api/customer-screening',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
          }
        )

        const data = await response.json()
        if (data?.data?.length === 0) {
          toast.error('No Customer Details Found')
        }
        const groupedData = groupBySource(data?.data)
        setGroupedData(groupedData)
      }
    } catch (error) {
      toast.error('Error during Customer Details')
    }
  }

  const handleCancel = () => {
    setShowCIFList(false)
  }

  return (
    <>
      <div className='card mb-5'>
        <div className='card-body pt-6 pb-6'>
          <div className='g-0 border-bottom heading__component__title fw-bolder mb-5'>
            Customer Information Verification
          </div>
          <Formik
            initialValues={initialFormData}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
          >
            {({handleChange, errors, touched}) => (
              <Form className='row g-3'>
                <div className='col-md-4'>
                  <FormTextBox
                    containerClassName='col-md-12'
                    labelClassName='col-md-12'
                    type='text'
                    label="Applicant's Name / Company Name"
                    name='full_name'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    required={true}
                    uppercase={true}
                  />
                </div>
                <div className='col-md-4'>
                  <FormTextBox
                    containerClassName='col-md-12'
                    labelClassName='col-md-12'
                    type='text'
                    label="Father's Name"
                    name='father_name'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    required={false}
                    uppercase={true}
                  />
                </div>
                <div className='col-md-4'>
                  <FormTextBox
                    containerClassName='col-md-12'
                    labelClassName='col-md-12'
                    type='text'
                    label='ID Number'
                    name='unique_id_number'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    required={false}
                    uppercase={true}
                  />
                </div>
                <div className='col-12 d-flex justify-content-end'>
                  <button type='submit' className='btn btn-primary me-2'>
                    {isLoading || isSubmitting ? 'CIF Checking....' : 'CIF Check'}
                  </button>
                  <button type='button' className='btn btn-secondary'>
                    PEP Check
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          {groupedData?.blacklist?.length > 0 && (
            <BlackListResultLists
              data={groupedData?.blacklist ?? []}
              onCancel={handleCancel}
              loading={false}
            />
          )}
          {groupedData?.edd?.length > 0 && (
            <EDDResultLists data={groupedData?.edd ?? []} onCancel={handleCancel} loading={false} />
          )}
          {groupedData?.pep?.length > 0 && (
            <PepResultList data={groupedData?.pep ?? []} onCancel={handleCancel} loading={false} />
          )}
          {groupedData?.gwl?.length > 0 && (
            <GWLList data={groupedData?.gwl ?? []} onCancel={handleCancel} loading={false} />
          )}
        </div>
      </div>
      {data && showCIFList && isElasticSearchLocal && (
        <CIFCheckList data={data?.data} onCancel={handleCancel} loading={isLoading} />
      )}
    </>
  )
}

export default DuplicateCIFCheck
