import {ParamsModel} from 'src/app/modules/common/Model'
import {CPUCheckerKYCReviewPayload, KYCAdditionalForm} from '../Model'
import {actionTypes} from './constants'
import {ICPUCheckerNewCifAndAccountIndividualJointMinorList} from './reducer'

export const actions = {
  getCPUCheckerNewCIFandAccountIndividualJointMinorListStart: (
    params: ParamsModel | undefined = {page: 1, limit: 10}
  ) => ({
    type: actionTypes.GET_CPU_CHECKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_LIST_START,
    payload: {params},
  }),
  getCPUCheckerNewCIFandAccountIndividualJointMinorListSuccess: (
    data: ICPUCheckerNewCifAndAccountIndividualJointMinorList
  ) => ({
    type: actionTypes.GET_CPU_CHECKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_SUCCESS,
    payload: data,
  }),
  getCPUCheckerNewCIFandAccountIndividualJointMinorListFinish: () => ({
    type: actionTypes.GET_CPU_CHECKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_FINISH,
  }),

  getCPUCheckerNewCIFandAccountCorporateBankListStart: (
    params: ParamsModel | undefined = {page: 1, limit: 10}
  ) => ({
    type: actionTypes.GET_CPU_CHECKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_LIST_START,
    payload: {params},
  }),
  getCPUCheckerNewCIFandAccountCorporateBankListSuccess: (
    data: ICPUCheckerNewCifAndAccountIndividualJointMinorList
  ) => ({
    type: actionTypes.GET_CPU_CHECKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_SUCCESS,
    payload: data,
  }),
  getCPUCheckerNewCIFandAccountCorporateBankListFinish: () => ({
    type: actionTypes.GET_CPU_CHECKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_FINISH,
  }),

  postAccountPreliminaryKYCCPUChecker: (id: string, data: CPUCheckerKYCReviewPayload) => ({
    type: actionTypes.POST_ACCOUNT_PRELIMINARY_KYC_CPU_CHECKER_REVIEW_START,
    payload: {id, data},
  }),
  postAccountPreliminaryKYCCPUCheckerSuccess: () => ({
    type: actionTypes.POST_ACCOUNT_PRELIMINARY_KYC_CPU_CHECKER_REVIEW_SUCCESS,
  }),
  postAccountPreliminaryKYCCPUCheckerFinish: () => ({
    type: actionTypes.POST_ACCOUNT_PRELIMINARY_KYC_CPU_CHECKER_REVIEW_FINISH,
  }),
}
