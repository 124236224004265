const PersonalDetails = ({data}: {data: any}) => {
  const createFullName = (firstName: string, middleName: string, lastName: string) => {
    const fn = firstName || ''
    const mn = middleName || ''
    const ln = lastName || ''
    return `${fn} ${mn} ${ln}`.trim()
  }
  return (
    <div>
      <table className='table table-striped'>
        <tr>
          <th colSpan={2}>
            <strong>Personal Details</strong>
          </th>
        </tr>
        <tbody>
          <tr>
            <th style={{width: '30%'}}>Customer Code</th>
            <td>{data?.customer_code || '-'}</td>
          </tr>
          <tr>
            <th>Full Name</th>
            <td>
              {data?.full_name
                ? data?.full_name
                : createFullName(data?.first_name, data?.middle_name, data?.last_name)}
            </td>
          </tr>
          <tr>
            <th>Mobile Number</th>
            <td>{data?.mobile_number}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{data?.email}</td>
          </tr>
          <tr>
            <th>Date of Birth(B.S)</th>
            <td>-</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default PersonalDetails
