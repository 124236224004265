import NewCifReviewForm from 'src/app/modules/common/components/newCIFMakerReview/component'

const NewCIFReviewPage = () => {
  return (
    <div>
      <h1>New CIF Review</h1>
      <NewCifReviewForm />
    </div>
  )
}

export default NewCIFReviewPage
