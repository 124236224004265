export type ANY = any

export interface DuplicateCIFPayload {
  father_name: string
  full_name: string
  unique_id_number: string
}

export const initialFormData: DuplicateCIFPayload = {
  father_name: '',
  full_name: '',
  unique_id_number: '',
}
