import axios from 'axios'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL
const VERIFY_OTP = `${API_URL}/newcif-newaccount/verify-otp`

const postVerifyOTP = async (newCIFNewAccount: string, code: string) => {
  try {
    return await axios.post(`${VERIFY_OTP}/${newCIFNewAccount}/${code}`)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message)
    } else {
      console.log(error)
    }
  }
}

export const usePostVerifyOTP = () =>
  useMutation({
    mutationFn: async ({newCIFNewAccount, code}: {newCIFNewAccount: string; code: string}) =>
      postVerifyOTP(newCIFNewAccount, code),
  })
