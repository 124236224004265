import {handleUppercaseChange} from 'src/app/modules/CreateNewCIFAndCustomerAccount/createNewAccount/components'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'

interface MobileAndEmailDetailsSectionProps {
  handleChange: any
  touched: any
  errors: any
  values: any
  setFieldValue: any
  initialValues: any
  data: any
}

const MobileAndEmailDetailsSection = ({
  handleChange,
  touched,
  errors,
  setFieldValue,
  values,
  initialValues,
  data,
}: MobileAndEmailDetailsSectionProps) => {
  return (
    <>
      <p className='fs-2 fw-bold'>Mobile and Email Details</p>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Mobile Number</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.mobile_number ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            name='mobile_number'
            label=' '
            containerClassName='col-12'
            onChange={handleChange}
            errors={errors}
            touched={touched}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Phone No.</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.landline_number ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            name='landline_number'
            label=' '
            containerClassName='col-12'
            onChange={handleChange}
            errors={errors}
            touched={touched}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Email</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.email ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            name='email'
            label=' '
            containerClassName='col-12'
            onChange={handleUppercaseChange(handleChange)}
            errors={errors}
            touched={touched}
          />
        </div>
      </div>
    </>
  )
}

export default MobileAndEmailDetailsSection
