import {Table} from 'rsuite'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {columnModel} from 'src/cms/helpers/components/rsuiteTable/Models/columnModel'

const Cell = Table.Cell

interface ChildrenInformationSectionProps {
  data: any
}

const ChildrenInformationSection = ({data}: ChildrenInformationSectionProps) => {
  const columns: columnModel[] = [
    {label: 'S.N', cell: <Cell dataKey='sn' />},
    {label: 'Name of Children', width: 250, cell: <Cell dataKey='name_of_children' />},
    {label: 'Address', width: 230, cell: <Cell dataKey='relation' />},
  ]
  const studentData = data?.children?.children.map((student: any, index: number) => ({
    ...student,
    sn: index + 1,
  }))
  return (
    <>
      <h2 className='text-center my-4'>CHILDREN INFORMATION</h2>
      <RSuiteTable columns={columns} data={studentData ?? []} />
    </>
  )
}

export default ChildrenInformationSection
