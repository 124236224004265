import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface INewCIFState {
  loading: boolean
  success: boolean
  data: {
    new_accounts: Record<string, string>[]
    meta: Record<string, number>
  }
}

const initialNewCIFState: INewCIFState = {
  loading: false,
  success: false,
  data: {new_accounts: [], meta: {}},
}

export const reducer = (
  state: INewCIFState = initialNewCIFState,
  action: ActionWithPayload<INewCIFState>
) => {
  switch (action.type) {
    case actionTypes.GET_NEW_CIF_LIST_CPU_CHECKER_START:
      return {...state, loading: true}
    case actionTypes.GET_NEW_CIF_LIST_CPU_CHECKER_SUCCESS:
      return {...state, loading: false, success: true, data: action.payload}
    case actionTypes.GET_NEW_CIF_LIST_CPU_CHECKER_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}
