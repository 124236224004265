import NewCIFandAccountCorporateBankReviewPage from 'src/app/modules/common/components/cpuMaker/CorporateBank'

const CorporateBankDetails = () => {
  return (
    <>
      <NewCIFandAccountCorporateBankReviewPage />
    </>
  )
}

export default CorporateBankDetails
