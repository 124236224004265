import {Form, Formik} from 'formik'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import * as cpucheckerRedux from 'src/app/modules/cpuChecker/newCIFAndAccountList/redux'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {RootState} from 'src/setup'
import * as Yup from 'yup'
import {
  BusinessInformationSection,
  CorrespondenceAddressInformationSection,
  CustomerInformationSection,
  IncorporationDetailsInformationSection,
  OtherDetailsInformationSection,
  PropietorDetailsInformationSection,
  RegistrationAddressInformationSection,
} from './sections'
import {usePostCorporateKYCCPUCheckerReview} from 'src/app/queries/corporateAccountPreliminary/post/usePostCPUCheckerReview'
import {toast} from 'react-toastify'

interface ReviewFormProps {
  data?: any
}

const cpuCheckerFormValidationSchema = Yup.object().shape({
  status: Yup.string().required('Required'),
  remarks: Yup.string().required('Required'),
})

const DetailsSection = ({data}: ReviewFormProps) => {
  const history = useHistory()

  const {loading, success} = useSelector((state: RootState) => state.cpuCheckerKYCReview)

  const {mutate: postCorporateKYCCPUCheckerReview} = usePostCorporateKYCCPUCheckerReview()

  useEffect(() => {
    if (success) {
      history.push('/cpu-checker/new-cif-and-account-list')
    }
  }, [success])
  return (
    <>
      <CustomerInformationSection data={data} />
      <hr />
      <IncorporationDetailsInformationSection data={data} />
      <hr />
      <OtherDetailsInformationSection data={data} />
      <hr />
      <CorrespondenceAddressInformationSection data={data} />
      <hr />
      <RegistrationAddressInformationSection data={data} />
      <hr />
      <PropietorDetailsInformationSection data={data} />
      <hr />
      <BusinessInformationSection data={data} />
      <hr />
      <Formik
        initialValues={{status: '', remarks: ''}}
        onSubmit={(formData) => {
          postCorporateKYCCPUCheckerReview(
            {id: data?.id, payload: formData},
            {
              onSuccess: (res) => {
                if (res?.data?.resCod === '200') {
                  toast.success(res?.data?.resDesc ?? 'Reviewed Successfully')
                  history.push('/cpu-checker/new-cif-and-account-list')
                }
              },
            }
          )
        }}
        validationSchema={cpuCheckerFormValidationSchema}
      >
        {({errors, handleChange, touched, setFieldValue, values}) => (
          <Form>
            <div className='row my-8'>
              <div className='col-12 col-md-6 col-lg-4'>
                <FormSelect
                  name='status'
                  label='Status'
                  containerClassName='col-12'
                  labelClassName='col-12'
                  options={[
                    {label: 'APPROVE', value: 'approve'},
                    {label: 'REVERT BACK TO CPU MAKER', value: 'revert_to_cpumaker'},
                    {label: 'REVERT BACK TO BRANCH MAKER', value: 'revert_to_branchmaker'},
                  ]}
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  values={values}
                  required
                />
              </div>
            </div>
            <div className='my-12 col-md-12'>
              <FormTextBox
                errors={errors}
                name='remarks'
                label='Remarks'
                containerClassName='col-12'
                labelClassName='col-12'
                onChange={handleChange}
                touched={touched}
                as='textarea'
                required
              />
            </div>
            <div className='d-flex gap-2 justify-content-end'>
              <button
                type='button'
                onClick={() => history.goBack()}
                className='btn btn-outline-warning'
              >
                Back
              </button>
              <button type='submit' className='btn-sm btn-warning' disabled={loading}>
                Approve & Send
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default DetailsSection
