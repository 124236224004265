import {Form, Formik} from 'formik'
import {useHistory, useLocation} from 'react-router-dom'
import {toast} from 'react-toastify'
import {usePostNewCIFReview} from 'src/app/queries/NewCIF/post'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import BasicInformation from './BasicInformation'
import BusinessDetailsInformation from './BusinessDetailInformation'
import DetailCell from './DetailCell'
import PermanentAddressInformation from './PermanentAddressInformation'
import RelationshipInformation from './RelationshipInformation'
import TemporaryAddressInformation from './TemporaryAddressInformation'

import * as Yup from 'yup'
import StudentInformationSection from './StudentInformationSection'
import AccountStatusLog from '../../AccountStatusLog'

const reviewSchema = Yup.object().shape({
  status: Yup.string().required('Required'),
  remarks: Yup.string().required('Required'),
})

const CIFDetails = () => {
  const location = useLocation()
  const history = useHistory()
  const data: any = location.state

  const {mutate: postNewCIFReview, isLoading} = usePostNewCIFReview()
  return (
    <div className=' card'>
      <div className='card-body'>
        <BasicInformation data={data} />
        <hr />
        <PermanentAddressInformation data={data} />
        <hr />
        <TemporaryAddressInformation data={data} />
        <hr />
        <RelationshipInformation data={data} />
        {data?.information_type === 'student' ? (
          <StudentInformationSection data={data} />
        ) : data?.information_type === 'business' ? (
          <BusinessDetailsInformation data={data} />
        ) : null}{' '}
        <Formik
          initialValues={{status: '', remarks: ''}}
          onSubmit={(values) => {
            postNewCIFReview(
              {id: data?.id, payload: values},
              {
                onSuccess: (res) => {
                  if (res?.data?.resCod === '200') {
                    toast.success(res?.data?.resDesc ?? 'CIF Reviewed Successfully')
                    history.push('/new-cif/new-cif-listing/checker')
                  }
                },
              }
            )
          }}
          validationSchema={reviewSchema}
        >
          {({errors, handleChange, touched, setFieldValue, values}) => (
            <Form>
              <div className='row my-8'>
                <DetailCell
                  label='Anticipated Annual Transaction'
                  value={data?.anticipated_annual_transaction}
                />
                <DetailCell label='Introducer Name' value={data?.introducer_name} />
                <div className='my-10'>
                  <AccountStatusLog data={data} />
                </div>
                <div className='col-12 col-md-6 col-lg-4'>
                  <FormSelect
                    name='status'
                    label='Status'
                    containerClassName='col-12'
                    labelClassName='col-12'
                    options={[
                      {label: 'Approve', value: 'approve'},
                      {label: 'Reject', value: 'reject'},
                      {label: 'Revert to CPU Maker', value: 'revert'},
                    ]}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    values={values}
                    required
                  />
                </div>
              </div>

              <div className='my-12'>
                <FormTextBox
                  name='remarks'
                  as='textarea'
                  label='Remarks'
                  containerClassName='col-12'
                  labelClassName='col-12'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  required
                />
              </div>
              <div className='d-flex gap-2 justify-content-end'>
                <button
                  type='button'
                  className='btn btn-outline-warning'
                  onClick={(e) => {
                    e.preventDefault()
                    history.goBack()
                  }}
                >
                  Back
                </button>
                <button type='submit' className='btn-sm btn-warning'>
                  Approve & Send
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default CIFDetails
