import {Form, Formik} from 'formik'
import {useEffect, useState} from 'react'
import {IoMdRefresh} from 'react-icons/io'
import {useDispatch, useSelector} from 'react-redux'
import * as masterDataRedux from 'src/app/modules/masterData/redux'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {
  CorrespondenceAddressSection,
  CustomerInformationSection,
  IdentificationDocumentSection,
  PermanentAddressSection,
  PersonalInformationSection,
  ProfessionRelatedInformationSection,
  ProfessionalInformationSection,
  RelativeInformationSection,
} from './review-form-field-sections'

import {isEqual} from 'lodash'
import moment from 'moment'
import {useHistory, useLocation} from 'react-router-dom'
import {toast} from 'react-toastify'
import AccountStatusLog from 'src/app/modules/common/AccountStatusLog'
import {KYCAdditionalForm} from 'src/app/modules/cpuMaker/newCIFAndAccountList/Model'
import {MasterDataModel} from 'src/app/modules/masterData/Model'
import {usePatchCIF} from 'src/app/queries/NewCIF/patch/usePatchCIF'
import {usePostNewCIFAdditionalForm} from 'src/app/queries/NewCIF/post/usePostNewCIFAdditionalInformation'
import {RootState} from 'src/setup'
import * as Yup from 'yup'
import {createNewCIFValidationSchema} from './validationSchema'
import {usePostCIFtoGETCustomerInfo} from 'src/app/queries/CBS'

const cifValidationSchema = Yup.object().shape({
  customer_no: Yup.number()
    .typeError('Only Numbers')
    .min(6, 'CIF Number must be at least 6 digits')
    .required('CIF Number is required'),
})

const NewCifReviewForm = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const formData: any = location.state

  const [primaryCIFNumber, setPrimaryCIFNumber] = useState('')

  const {mutate: postNewCIF, isLoading: isPostNewCIFLoading} = usePatchCIF()
  const {mutate: postNewCIFAdditional, isLoading: isPostNewCIFAdditionalLoading} =
    usePostNewCIFAdditionalForm()
  const {data, mutate: postCIFtoGETCustomerInfo, isLoading} = usePostCIFtoGETCustomerInfo()

  const {
    data: salutationList,
    loading: salutationLoading,
  }: {data: MasterDataModel[]; loading: boolean} = useSelector(
    (state: RootState) => state?.salutationList
  )
  const {data: accountPurposes}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.accountPurposeList
  )
  const {data: uniqueIdIssuedBy}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.individualUniqueIdList
  )
  const {data: qualifications}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.qualificationsList
  )
  const {data: occupations}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.occupationList
  )
  const {data: incomeSources}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.incomeSourceList
  )
  const {data: accountCategories}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.accountCategoriesList
  )
  const {data: countries}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.countriesList
  )
  const {data: taxCategories}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.taxCategoryList
  )
  const {
    data: accountTypes,
    loading: accountTypesLoading,
  }: {data: MasterDataModel[]; loading: boolean} = useSelector(
    (state: RootState) => state?.accountTypeList
  )
  const {data: maritalStatusList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.maritalStatusList
  )
  const {data: exposures}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.exposureList
  )
  const {data: nationalities}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.nationalitiesList
  )
  const {municipality, loading} = useSelector((state: RootState) => state.place)

  const optionalValidationSchema = Yup.object().shape({
    account_purpose_other: Yup.string().when('account_purpose', {
      is: (value: string) =>
        value === accountPurposes.find((accountPurpose) => accountPurpose.title === 'Others')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    account_category_other: Yup.string().when('account_category', {
      is: (value: string) =>
        value ===
        accountCategories.find((accountCategory) => accountCategory.title === 'Others')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    unique_id_issued_by_other: Yup.string().when('unique_id_issued_by', {
      is: (value: string) => value === uniqueIdIssuedBy.find((uid) => uid.title === 'OTHERS')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    education_qualification_others: Yup.string().when('education_qualification', {
      is: (value: string) =>
        value === qualifications.find((qualification) => qualification.title === 'OTHERS')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    occupation_other: Yup.string().when('occupation', {
      is: (value: string) =>
        value === occupations.find((occupation) => occupation.title === 'Others')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    source_of_income_other: Yup.string().when('source_of_income', {
      is: (value: string) =>
        value === incomeSources.find((incomeSource) => incomeSource.title === 'Others')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),

    // dates validation
    date: Yup.string()
      .required('Required')
      .test('date-validation', function (value) {
        const isFuture = value && moment(value, 'YYYY-MM-DD').isAfter(moment())
        if (isFuture) {
          return false
        }
        return true
      }),
    date_of_birth_ad: Yup.string()
      .required('Required')
      .test('age-validation', function (value) {
        const {accountType} = this.parent
        const age = moment().diff(value, 'years')
        const isMinor =
          accountType === accountTypes.map((accountType) => accountType.title === 'Minor')
        if ((isMinor && age >= 18) || (!isMinor && age < 18)) {
          const message = isMinor
            ? 'Age must be less than 18 years'
            : 'Age must be minimum 18 years'
          return this.createError({
            message,
          })
        }
        return true
      }),
    pan_registration_date_ad: Yup.string().test(
      'date-validation',
      'Invalid Date',
      function (value) {
        const {date_of_birth_ad} = this.parent
        const isFuture = value && moment(value, 'YYYY-MM-DD').isAfter(moment())
        const isBeforeBirthDate =
          value && date_of_birth_ad && moment(value, 'YYYY-MM-DD').isBefore(date_of_birth_ad)
        if (isFuture || isBeforeBirthDate) {
          return false
        }
        return true
      }
    ),
    citizenship_issue_date_ad: Yup.string().when('basic_identification', {
      is: (value: string) => value === 'citizenship',
      then: Yup.string()
        .required('Required')
        .test('date-validation', 'Invalid Date', function (value) {
          const {date_of_birth_ad} = this.parent
          const isFuture = value && moment(value, 'YYYY-MM-DD').isAfter(moment())
          const isBeforeBirthDate =
            value &&
            date_of_birth_ad &&
            moment(value, 'YYYY-MM-DD').isBefore(moment(date_of_birth_ad, 'YYYY-MM-DD'))
          if (isFuture || isBeforeBirthDate) {
            return false
          }
          return true
        }),
      otherwise: Yup.string(),
    }),
    passport_issue_date: Yup.string().when('basic_identification', {
      is: (value: string) => value === 'passport',
      then: Yup.string()
        .required('Required')
        .test('date-validation', 'Invalid Date', function (value) {
          const {date_of_birth_ad} = this.parent
          const isFuture = value && moment(value, 'YYYY-MM-DD').isAfter(moment())
          const isBeforeBirthDate =
            value && date_of_birth_ad && moment(value, 'YYYY-MM-DD').isBefore(date_of_birth_ad)
          if (isFuture || isBeforeBirthDate) {
            return false
          }
          return true
        }),
      otherwise: Yup.string(),
    }),
    passport_expiry_date: Yup.string().when('basic_identification', {
      is: (value: string) => value === 'passport',
      then: Yup.string()
        .required('Required')
        .test('date-validation', 'Invalid Date', function (value) {
          const {date_of_birth_ad, passport_issue_date} = this.parent
          const isFutureFromRegistration =
            passport_issue_date &&
            value &&
            moment(value, 'YYYY-MM-DD').isAfter(moment(passport_issue_date, 'YYYY-MM-DD'))
          const isBeforeBirthDate =
            value && date_of_birth_ad && moment(value, 'YYYY-MM-DD').isBefore(date_of_birth_ad)
          if (!isFutureFromRegistration || isBeforeBirthDate) {
            return false
          }
          return true
        }),
      otherwise: Yup.string(),
    }),
    id_card_issue_date_ad: Yup.string().when('basic_identification', {
      is: (value: string) => value === 'other',
      then: Yup.string()
        .required('Required')
        .test('date-validation', 'Invalid Date', function (value) {
          const {date_of_birth_ad} = this.parent
          const isFuture = value && moment(value, 'YYYY-MM-DD').isAfter(moment())
          const isBeforeBirthDate =
            value && date_of_birth_ad && moment(value, 'YYYY-MM-DD').isBefore(date_of_birth_ad)
          if (isFuture || isBeforeBirthDate) {
            return false
          }
          return true
        }),
      otherwise: Yup.string(),
    }),
    id_card_expiry_date_ad: Yup.string().when('basic_identification', {
      is: (value: string) => value === 'other',
      then: Yup.string()
        .required('Required')
        .test('date-validation', 'Invalid Date', function (value) {
          const {date_of_birth_ad, id_card_issue_date_ad} = this.parent
          const isFutureFromRegistration =
            id_card_issue_date_ad &&
            value &&
            moment(value, 'YYYY-MM-DD').isAfter(moment(id_card_issue_date_ad, 'YYYY-MM-DD'))
          const isBeforeBirthDate =
            value && date_of_birth_ad && moment(value, 'YYYY-MM-DD').isBefore(date_of_birth_ad)
          if (!isFutureFromRegistration || isBeforeBirthDate) {
            return false
          }
          return true
        }),
      otherwise: Yup.string(),
    }),
    kyc_review_date: Yup.string()
      .required('Required')
      .test('date-validation', 'Invalid Date', function (value) {
        const {date_of_birth_ad} = this.parent
        const isFuture = value && moment(value, 'YYYY-MM-DD').isAfter(moment())
        const isBeforeBirthDate =
          value && date_of_birth_ad && moment(value, 'YYYY-MM-DD').isBefore(date_of_birth_ad)
        if (!isFuture || isBeforeBirthDate) {
          return false
        }
        return true
      }),
    basic_identification: Yup.string()
      .required('Required')
      .test('minor-document', function (value) {
        const {accountType} = this.parent

        const isMinor =
          accountType === accountTypes.map((accountType) => accountType.title === 'Minor')

        if (isMinor && value === 'citizenship') {
          return this.createError({
            message: 'Minors cannot have citizenship',
          })
        } else if (isMinor && value === 'passport') {
          return this.createError({
            message: 'Minors cannot have Passport',
          })
        }

        return true
      }),
    spouse_name: Yup.string().when('marital_status', {
      is: (value: string) =>
        value &&
        value === maritalStatusList.find((maritalStatus) => maritalStatus.title === 'Married')?.id,
      then: Yup.string()
        .required('Required')
        .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
      otherwise: Yup.string().matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
    }),

    //Address Validations
    temporary_country: Yup.string().required('Required'),
    temporary_house_number: Yup.number().typeError('House numbers can only be in numbers'),
    temporary_tole: Yup.string().when('temporary_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string()
        .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed')
        .required('Required'),
      otherwise: Yup.string(),
    }),
    temporary_ward_number: Yup.number()
      .typeError('Ward Number can only be in numbers')
      .when('temporary_country', {
        is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
        then: Yup.number().typeError('Ward Number can only be in numbers').required('Required'),
        otherwise: Yup.number(),
      }),
    temporary_province: Yup.string().when('temporary_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    temporary_district: Yup.string().when('temporary_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    temporary_municipality: Yup.string().when('temporary_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    temporary_municipality_other: Yup.string().when('temporary_municipality', {
      is: (value: string) => value === municipality?.find((m) => m?.title === 'Others')?.id,
      then: Yup.string()
        .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed')
        .required('Required'),
      otherwise: Yup.string(),
    }),
    temporary_outside_nepal_address_line_1: Yup.string().when('temporary_country', {
      is: (value: string) => value !== countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Requierd'),
      otherwise: Yup.string(),
    }),
    temporary_outside_nepal_address_line_2: Yup.string().when('temporary_country', {
      is: (value: string) => value !== countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Requierd'),
      otherwise: Yup.string(),
    }),
    temporary_outside_nepal_address_line_3: Yup.string(),

    permanent_country: Yup.string().required('Required'),
    permanent_house_number: Yup.number().typeError('House Number can only be in numbers'),
    permanent_tole: Yup.string().when('permanent_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string()
        .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed')
        .required('Required'),
      otherwise: Yup.string(),
    }),
    permanent_ward_number: Yup.number().when('permanent_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.number().typeError('Ward Number can only be in numbers').required('Required'),
      otherwise: Yup.number(),
    }),
    permanent_province: Yup.string().when('permanent_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Requierd'),
      otherwise: Yup.string(),
    }),
    permanent_district: Yup.string().when('permanent_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Requierd'),
      otherwise: Yup.string(),
    }),
    permanent_municipality: Yup.string().when('permanent_country', {
      is: (value: string) => value === countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Requierd'),
      otherwise: Yup.string(),
    }),
    permanent_municipality_other: Yup.string().when('permanent_municipality', {
      is: (value: string) => value === municipality?.find((m) => m?.title === 'Others')?.id,
      then: Yup.string()
        .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed')
        .required('Required'),
      otherwise: Yup.string(),
    }),
    permanent_outside_nepal_address_line_1: Yup.string().when('permanent_country', {
      is: (value: string) => value !== countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    permanent_outside_nepal_address_line_2: Yup.string().when('permanent_country', {
      is: (value: string) => value !== countries.find((country) => country.title === 'Nepal')?.id,
      then: Yup.string().required('Requierd'),
      otherwise: Yup.string(),
    }),
    permanent_outside_nepal_address_line_3: Yup.string(),
  })

  useEffect(() => {
    dispatch(masterDataRedux.actions.getAllMasterData())
  }, [])

  useEffect(() => {
    if (formData?.primary_cif_number) {
      postCIFtoGETCustomerInfo(formData?.primary_cif_number)
    }
  }, [formData])
  return (
    <>
      <div className='shadow p-8 bg-white rounded'>
        <Formik
          initialValues={{
            children:
              formData?.children?.children.length > 0
                ? formData?.children?.children
                : [{name_of_children: '', relation: ''}],
            institutions:
              formData?.institutions?.institutions?.length > 0
                ? formData?.institutions?.institutions
                : [
                    {
                      name_of_related_employer: '',
                      business_address: '',
                      position: '',
                      yearly_remuneration: '',
                    },
                  ],
            students:
              formData?.students?.students?.length > 0
                ? formData?.students?.students
                : [{name_of_institute: '', student_address: '', phone_no: ''}],
            primaryCIFNumber: formData?.primary_cif_number ?? '',
            action: '',
            primary_cif_number: formData?.primary_cif_number ?? '',
            primary_ac_number: formData?.primary_ac_number ?? '',
            first_name: formData?.first_name ?? '',
            middle_name: formData?.middle_name ?? '',
            last_name: formData?.last_name ?? '',
            salutation: formData?.salutation ?? '',
            email: formData?.email ?? '',
            mobile_number: formData?.mobile_number ?? '',
            idTypeId: formData?.idTypeId?.id ?? '',
            id_number: formData?.id_number ?? '',
            pep_confirmation: formData?.pep_confirmation ?? '',
            pep_check: formData?.pep_check ?? '',
            house_number: formData?.house_number ?? '',
            street_name: formData?.street_name ?? '',
            tole: formData?.tole ?? '',
            customerCategoryId: formData?.customer_category?.code ?? '',
            customerClassificationId: formData?.customer_classification?.id ?? '',
            country_id:
              formData?.country?.id ??
              countries.find((country) => country.title === 'Nepal')?.id ??
              '',
            nationality: formData?.nationality?.id ?? '',
            language: formData?.language?.id ?? 'ENG',
            location: formData?.location ?? '',
            locationCode: formData?.location_code ?? '',
            branchLocation: formData?.branch_location_code ?? '',
            taxCategoryId: formData?.tax_category?.cbs_code ?? '',
            taxGroupId: formData?.tax_group?.cbs_code ?? '',
            aml_risk_rating: formData?.aml_risk_rating ?? '',
            mediaId: formData?.media ?? '',
            institutionalId: formData?.institutional ?? '',
            exposureId: formData?.exposure?.code ?? '',
            relationship: formData?.relationship?.id ?? '',
            date: formData?.date ?? '',
            kyc_level_category: formData?.kyc_level_category?.id ?? '',
            gender: formData?.gender ?? 'Male',
            marital_status: formData?.marital_status?.id ?? '',
            employment_status: formData?.employment_status?.id ?? '',
            employment_status_other: formData?.employment_status_other ?? '',
            date_of_birth_bs: formData?.date_of_birth_bs ?? '',
            date_of_birth_ad: formData?.date_of_birth_ad ?? '',
            pan_number: formData?.pan_number ?? '',
            pan_registration_date_bs: formData?.pan_registration_date_bs ?? '',
            pan_registration_date_ad: formData?.pan_registration_date_ad ?? '',
            citizenship_number: formData?.citizenship_number ?? '',
            citizenship_issue_date_bs: formData?.citizenship_issue_date_bs ?? '',
            citizenship_issue_date_ad: formData?.citizenship_issue_date_ad ?? '',
            citizenship_issue_district: formData?.citizenship_issue_district?.id ?? '',
            passport_number: formData?.passport_number ?? '',
            passport_issue_date: formData?.passport_issue_date ?? '',
            passport_expiry_date: formData?.passport_expiry_date ?? '',
            passport_issue_district: formData?.passport_issue_district?.id ?? '',
            type_of_identity_card: formData?.type_of_identity_card ?? '',
            id_card_number: formData?.id_card_number ?? '',
            id_card_issue_date_ad: formData?.id_card_issue_date_ad ?? '',
            id_card_issue_date: formData?.id_card_issue_date ?? '',
            id_card_expiry_date_ad: formData?.id_card_expiry_date_ad ?? '',
            id_card_expiry_date: formData?.id_card_expiry_date ?? '',
            id_card_issuing_office: formData?.id_card_issuing_office?.id ?? '',
            unique_id_issued_by: formData?.unique_id_issued_by?.id ?? '',
            unique_id_issued_by_corporate: formData?.unique_id_issued_by_corporate ?? '',
            unique_id_issued_by_other: formData?.unique_id_issued_by_other ?? '',
            unique_id_issued_by_corporate_other:
              formData?.unique_id_issued_by_corporate_other ?? '',
            education_qualification: formData?.education_qualification?.id ?? '',
            education_qualification_others: formData?.education_qualification_others ?? '',
            spouse_name: formData?.spouse_name ?? '',
            father_name: formData?.father_name ?? '',
            mother_name: formData?.mother_name ?? '',
            grand_father_name: formData?.grand_father_name ?? '',
            grand_mother_name: formData?.grand_mother_name ?? '',
            daughter_in_law_name: formData?.daughter_in_law_name ?? '',
            father_in_law_name: formData?.father_in_law_name ?? '',
            occupation: formData?.occupation?.id ?? '',
            occupation_other: formData?.occupation_other ?? '',
            additional_occupation: formData?.additional_occupation?.id ?? '',
            source_of_income: formData?.source_of_income?.id ?? '',
            source_of_income_other: formData?.source_of_income_other ?? '',
            anticipated_annual_transaction: formData?.anticipated_annual_transaction ?? '',
            basic_identification: formData?.basic_identification ?? 'citizenship',
            introducer_name: formData?.introducer_name ?? '',
            permanent_post_box: formData?.permanent_post_box ?? '',
            permanent_house_number: formData?.permanent_house_number ?? '',
            permanent_ward_number: formData?.permanent_ward_number ?? '',
            permanent_tole: formData?.permanent_tole ?? '',
            permanent_country:
              formData?.permanent_country?.id ??
              countries.find((country) => country.title === 'Nepal')?.id ??
              '',
            permanent_province: formData?.permanent_province?.id ?? '',
            permanent_district: formData?.permanent_district?.id ?? '',
            permanent_municipality: formData?.permanent_municipality?.id ?? '',
            permanent_municipality_other: formData?.permanent_municipality_other ?? '',
            permanent_phone_number_residence: formData?.permanent_phone_number_residence ?? '',
            permanent_phone_number_work: formData?.permanent_phone_number_work ?? '',
            permanent_mobile_number: formData?.permanent_mobile_number ?? '',
            permanent_email: formData?.permanent_email ?? '',
            temporary_post_box: formData?.temporary_post_box ?? '',
            temporary_house_number: formData?.temporary_house_number ?? '',
            temporary_ward_number: formData?.temporary_ward_number ?? '',
            temporary_tole: formData?.temporary_tole ?? '',
            temporary_country:
              formData?.temporary_country?.id ??
              countries.find((country) => country.title === 'Nepal')?.id ??
              '',
            temporary_province: formData?.temporary_province?.id ?? '',
            temporary_district: formData?.temporary_district?.id ?? '',
            temporary_municipality: formData?.temporary_municipality?.id ?? '',
            temporary_municipality_other: formData?.temporary_municipality_other ?? '',
            temporary_phone_number_residence: formData?.temporary_phone_number_residence ?? '',
            temporary_phone_number_work: formData?.temporary_phone_number_work ?? '',
            temporary_mobile_number: formData?.temporary_mobile_number ?? '',
            temporary_email: formData?.temporary_email ?? '',
            visa_expiry_date: formData?.visa_expiry_date ?? '',
            currency_of_amount: formData?.currency_of_amount ?? '',
            proof_address_document: formData?.proof_address_document ?? '',
            information_type: formData?.information_type ?? 'business',
            resident_status: formData?.resident_status ?? '',
            kyc_review_date: formData?.kyc_review_date ?? '',
            first_nominee: formData?.first_nominee ?? '',
            second_nominee: formData?.second_nominee ?? '',
            temporary_outside_nepal_address_line_1:
              formData?.temporary_outside_nepal_address_line_1 ?? '',
            temporary_outside_nepal_address_line_2:
              formData?.temporary_outside_nepal_address_line_2 ?? '',
            temporary_outside_nepal_address_line_3:
              formData?.temporary_outside_nepal_address_line_3 ?? '',
            permanent_outside_nepal_address_line_1:
              formData?.permanent_outside_nepal_address_line_1 ?? '',
            permanent_outside_nepal_address_line_2:
              formData?.permanent_outside_nepal_address_line_2 ?? '',
            permanent_outside_nepal_address_line_3:
              formData?.permanent_outside_nepal_address_line_3 ?? '',
            remarks: formData?.remarks ?? '',
          }}
          onSubmit={(values) => {
            const additionalInformations: KYCAdditionalForm = {
              children: values.children,
            }
            if (values.information_type === 'business') {
              additionalInformations['institutions'] = values.institutions
            } else if (values.information_type === 'student') {
              additionalInformations['students'] = values.students
            }
            if (isEqual(values.children, [{name_of_children: '', relation: ''}])) {
              additionalInformations['children'] = null
            }

            if (values.basic_identification === 'citizenship') {
              values.passport_issue_date = ''
              values.passport_expiry_date = ''
              values.passport_issue_district = ''
              values.passport_number = ''

              values.id_card_number = ''
              values.id_card_issue_date = ''
              values.id_card_issue_date_ad = ''
              values.id_card_expiry_date = ''
              values.id_card_expiry_date_ad = ''
              values.id_card_issuing_office = ''
              values.type_of_identity_card = ''
            } else if (values.basic_identification === 'passport') {
              values.citizenship_number = ''
              values.citizenship_issue_date_bs = ''
              values.citizenship_issue_date_ad = ''
              values.citizenship_issue_district = ''

              values.id_card_number = ''
              values.id_card_issue_date = ''
              values.id_card_issue_date_ad = ''
              values.id_card_expiry_date = ''
              values.id_card_expiry_date_ad = ''
              values.id_card_issuing_office = ''
              values.type_of_identity_card = ''
            } else if (values.basic_identification === 'other') {
              values.citizenship_number = ''
              values.citizenship_issue_date_bs = ''
              values.citizenship_issue_date_ad = ''
              values.citizenship_issue_district = ''

              values.id_card_number = ''
              values.id_card_issue_date = ''
              values.id_card_issue_date_ad = ''
              values.id_card_expiry_date = ''
              values.id_card_expiry_date_ad = ''
              values.id_card_issuing_office = ''
              values.type_of_identity_card = ''
            }

            postNewCIF(
              {
                id: formData.id,
                payload: {
                  ...values,
                  permanent_house_number: values?.permanent_house_number?.toString(),
                  permanent_phone_number_residence:
                    values?.permanent_phone_number_residence?.toString(),
                  permanent_phone_number_work: values?.permanent_phone_number_work?.toString(),
                  permanent_mobile_number: values?.permanent_mobile_number?.toString(),
                  permanent_ward_number: values?.permanent_ward_number?.toString(),

                  temporary_house_number: values?.temporary_house_number?.toString(),
                  temporary_phone_number_residence:
                    values?.temporary_phone_number_residence?.toString(),
                  temporary_phone_number_work: values?.temporary_phone_number_work?.toString(),
                  temporary_mobile_number: values?.temporary_mobile_number?.toString(),
                  temporary_ward_number: values?.temporary_ward_number?.toString(),

                  action: 'save_and_continue',
                },
              },
              {
                onSuccess: (res) => {
                  if (res?.data?.resCod === '200') {
                    toast.success(res?.data?.resDesc ?? 'New CIF Created Successfully')
                    postNewCIFAdditional(
                      {
                        id: res.data?.data?.id,
                        payload: additionalInformations,
                      },
                      {
                        onSuccess: (res) => {
                          if (res?.data?.resCod === '200') {
                            toast.success(
                              res?.data?.resDesc ??
                                'New CIF Additional Information Added successfully'
                            )
                          }
                        },
                      }
                    )
                    history.push('/new-cif/new-cif-listing')
                  }
                },
              }
            )
          }}
          validationSchema={createNewCIFValidationSchema.concat(optionalValidationSchema)}
          enableReinitialize={true}
        >
          {({
            touched,
            setFieldValue,
            errors,
            handleChange,
            values,
            resetForm,
            setFieldTouched,
            validateField,
            initialValues,
          }) => {
            const handleSaveOnly = async (e: React.MouseEvent<HTMLButtonElement>) => {
              // * This is the logic for validation of specific fields
              /*const fields = [
                'first_name',
                'last_name',
                'salutation',
                'mobile_number',
                'customerClassificationId',
                'location',
                'locationCode',
                'branchLocation',
                'kyc_level_category',
                'email',
              ]

              for (const field of fields) {
                await setFieldTouched(field, true, true)
                await validateField(field)
              }

              const hasErrors = fields.some((field) => (errors as {[key: string]: string})[field])

              if (hasErrors) {
                toast.error('Some informations are required for Save action.')
                return
              }*/

              const additionalInformations: KYCAdditionalForm = {
                children: values.children,
              }
              if (values.information_type === 'business') {
                additionalInformations['institutions'] = values.institutions
              } else if (values.information_type === 'student') {
                additionalInformations['students'] = values.students
              }
              if (isEqual(values.children, [{name_of_children: '', relation: ''}])) {
                additionalInformations['children'] = null
              }

              if (values.basic_identification === 'citizenship') {
                values.passport_issue_date = ''
                values.passport_expiry_date = ''
                values.passport_issue_district = ''
                values.passport_number = ''

                values.id_card_number = ''
                values.id_card_issue_date = ''
                values.id_card_issue_date_ad = ''
                values.id_card_expiry_date = ''
                values.id_card_expiry_date_ad = ''
                values.id_card_issuing_office = ''
                values.type_of_identity_card = ''
              } else if (values.basic_identification === 'passport') {
                values.citizenship_number = ''
                values.citizenship_issue_date_bs = ''
                values.citizenship_issue_date_ad = ''
                values.citizenship_issue_district = ''

                values.id_card_number = ''
                values.id_card_issue_date = ''
                values.id_card_issue_date_ad = ''
                values.id_card_expiry_date = ''
                values.id_card_expiry_date_ad = ''
                values.id_card_issuing_office = ''
                values.type_of_identity_card = ''
              } else if (values.basic_identification === 'other') {
                values.citizenship_number = ''
                values.citizenship_issue_date_bs = ''
                values.citizenship_issue_date_ad = ''
                values.citizenship_issue_district = ''

                values.id_card_number = ''
                values.id_card_issue_date = ''
                values.id_card_issue_date_ad = ''
                values.id_card_expiry_date = ''
                values.id_card_expiry_date_ad = ''
                values.id_card_issuing_office = ''
                values.type_of_identity_card = ''
              }

              if (values.basic_identification === 'citizenship') {
                values.passport_issue_date = ''
                values.passport_expiry_date = ''
                values.passport_issue_district = ''
                values.passport_number = ''

                values.id_card_number = ''
                values.id_card_issue_date = ''
                values.id_card_issue_date_ad = ''
                values.id_card_expiry_date = ''
                values.id_card_expiry_date_ad = ''
                values.id_card_issuing_office = ''
                values.type_of_identity_card = ''
              } else if (values.basic_identification === 'passport') {
                values.citizenship_number = ''
                values.citizenship_issue_date_bs = ''
                values.citizenship_issue_date_ad = ''
                values.citizenship_issue_district = ''

                values.id_card_number = ''
                values.id_card_issue_date = ''
                values.id_card_issue_date_ad = ''
                values.id_card_expiry_date = ''
                values.id_card_expiry_date_ad = ''
                values.id_card_issuing_office = ''
                values.type_of_identity_card = ''
              } else if (values.basic_identification === 'other') {
                values.citizenship_number = ''
                values.citizenship_issue_date_bs = ''
                values.citizenship_issue_date_ad = ''
                values.citizenship_issue_district = ''

                values.id_card_number = ''
                values.id_card_issue_date = ''
                values.id_card_issue_date_ad = ''
                values.id_card_expiry_date = ''
                values.id_card_expiry_date_ad = ''
                values.id_card_issuing_office = ''
                values.type_of_identity_card = ''
              }

              postNewCIF(
                {
                  id: formData.id,
                  payload: {
                    ...values,
                    permanent_house_number: values?.permanent_house_number?.toString(),
                    permanent_phone_number_residence:
                      values?.permanent_phone_number_residence?.toString(),
                    permanent_phone_number_work: values?.permanent_phone_number_work?.toString(),
                    permanent_mobile_number: values?.permanent_mobile_number?.toString(),
                    permanent_ward_number: values?.permanent_ward_number?.toString(),

                    temporary_house_number: values?.temporary_house_number?.toString(),
                    temporary_phone_number_residence:
                      values?.temporary_phone_number_residence?.toString(),
                    temporary_phone_number_work: values?.temporary_phone_number_work?.toString(),
                    temporary_mobile_number: values?.temporary_mobile_number?.toString(),
                    temporary_ward_number: values?.temporary_ward_number?.toString(),

                    action: 'save',
                  },
                },
                {
                  onSuccess: (res) => {
                    if (res?.data?.resCod === '200') {
                      toast.success(res?.data?.resDesc ?? 'New CIF Created Successfully')
                      postNewCIFAdditional(
                        {
                          id: res.data?.data?.id,
                          payload: additionalInformations,
                        },
                        {
                          onSuccess: (res) => {
                            if (res?.data?.resCod === '200') {
                              toast.success(
                                res?.data?.resDesc ??
                                  'New CIF Additional Information Added successfully'
                              )
                            }
                          },
                        }
                      )
                      history.push('/new-cif/new-cif-listing')
                    }
                  },
                }
              )
            }
            return (
              <Form>
                {data?.resCod === '200' && (
                  <div className='row'>
                    <FormTextBox
                      name='primary_cif_number'
                      label='Primary CIF No.'
                      containerClassName='col-10'
                      labelClassName='col-12'
                      onChange={(e: any) => {
                        setPrimaryCIFNumber(e?.target?.value)
                        handleChange(e)
                      }}
                      errors={errors}
                      touched={touched}
                      disabled={data?.resCod === '200'}
                      required
                    />
                    <div className='col-md-6 col-12'>
                      <div className=' d-flex my-7 col-12'>
                        <label className='form-label fw-bolder text-dark fs-6 me-8 required '>
                          Primary CIF Name:
                        </label>{' '}
                        {data?.data?.full_name ?? ''}
                      </div>
                    </div>
                  </div>
                )}
                <div className='row'>
                  <CustomerInformationSection
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                  <PersonalInformationSection
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                  <IdentificationDocumentSection
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                  <RelativeInformationSection
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                  <hr />
                  <CorrespondenceAddressSection
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    values={values}
                    initialValues={initialValues}
                  />
                  <hr />
                  <PermanentAddressSection
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    values={values}
                    initialValues={initialValues}
                  />
                  <ProfessionalInformationSection
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                  <ProfessionRelatedInformationSection
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                  <AccountStatusLog data={formData} />
                  <div className='col-12 my-12'>
                    <FormTextBox
                      name='remarks'
                      as='textarea'
                      containerClassName='col-12'
                      labelClassName='col-12'
                      label='Remarks'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                  <div className='col-md-6 col-12 opacity-50'>
                    <div className='d-flex flex-column align-items-center justify-content-center text-center'>
                      <div
                        className='d-flex align-items-center justify-content-center mb-2'
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                          resetForm()
                        }}
                      >
                        <IoMdRefresh size={23} />
                        <span className='h4 ms-3 mb-n1'>Reset Form</span>
                      </div>
                      <span>Note: All the captured data will be cleared</span>
                    </div>
                  </div>
                  <div className='col-md-6 col-12 d-flex gap-1'>
                    <button
                      type='button'
                      className='btn  btn-outline-warning'
                      style={{border: 1}}
                      onClick={handleSaveOnly}
                      disabled={isPostNewCIFLoading || isPostNewCIFAdditionalLoading}
                    >
                      Save
                    </button>
                    <button
                      type='submit'
                      className='btn btn-warning'
                      disabled={isPostNewCIFLoading || isPostNewCIFAdditionalLoading}
                    >
                      Save & Continue
                    </button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default NewCifReviewForm
