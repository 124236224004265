import {useState} from 'react'
import {Pagination, Table} from 'rsuite'
import {StateParamsModel} from 'src/app/modules/common/Model'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'

const Cell = Table.Cell

const mockData = [
  {
    accountNumber: '1234567890',
    cifNumber: 'CIF001',
    accountName: 'RamKrishna prasad upadhyay ',
    branchLocation: 'HATTISAR',
    verification: '',
    updatedOn: '2022-06-16 18:29:31',
    remarks: 'correct',
  },
  {
    accountNumber: '0987654321',
    cifNumber: 'CIF002',
    accountName: 'MUKESH RAI UP',
    branchLocation: 'HATTISAR',
    verification: '',
    updatedOn: '2020-08-12 13:47:52',
    remarks: '-',
  },
  {
    accountNumber: '1122334455',
    cifNumber: 'CIF003',
    accountName: 'HEX INC',
    branchLocation: 'HATTISAR',
    verification: '',
    updatedOn: '2020-07-12 13:59:42',
    remarks: '-',
  },
]

const InProgressTable = () => {
  const [params, setParams] = useState<StateParamsModel>({
    status: 1,
    page: 1,
    limit: 10,
  })

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <button className='btn btn-primary btn-sm ms-3'>Review</button>
      </Cell>
    )
  }

  const columns = [
    {
      label: 'Account Number',
      dataKey: 'accountNumber',
      width: 160,
      cell: <Cell dataKey='accountNumber' />,
    },
    {
      label: 'CIF Number',
      dataKey: 'cifNumber',
      cell: <Cell dataKey='cifNumber' />,
      width: 150,
    },
    {
      label: 'Account Name',
      dataKey: 'accountName',
      width: 250,
      cell: <Cell dataKey='accountName' />,
    },
    {
      label: 'Updated On',
      dataKey: 'updatedOn',
      cell: <Cell dataKey='updatedOn' />,
      sortable: false,
      width: 250,
    },
    {
      label: 'Verification',
      dataKey: 'verification',
      cell: <Cell dataKey='verification' />,
      width: 250,
    },
    {
      label: 'Status',
      dataKey: 'status',
      cell: <Cell dataKey='status' />,
      width: 350,
    },
    {
      label: 'Action',
      width: 130,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]
  return (
    <>
      <DesignComponent moduleName='' params={params} setParams={setParams} addShow={false} />
      <div className='datatable'>
        <RSuiteTable columns={columns} data={mockData} showLoading={false} />
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size='sm'
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={0}
          limitOptions={[10, 20, 50, 100]}
          limit={params?.limit}
          activePage={params?.page}
          onChangePage={(value) => setParams({...params, page: value})}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </>
  )
}

export default InProgressTable
