import {Form, Formik} from 'formik'
import {useState} from 'react'
import {Input, InputGroup, Pagination, Table} from 'rsuite'

import DatePickerForGrievance from 'src/cms/helpers/components/forms/DatePickerForGrievance'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import CloseIcon from '@rsuite/icons/Close'
import SearchIcon from '@rsuite/icons/Search'
import {isEmpty} from 'lodash'
import moment from 'moment'
import {AccountStatusStateParamsModel} from '../../Model'
import {useGetAccountStatusLists} from 'src/app/queries/Account Preliminary/get/useGetAccountStatusLists'

const Cell = Table.Cell

export const ParamsComponent = ({
  params,
  setParams,
  loading = false,
}: {
  params: AccountStatusStateParamsModel
  setParams: React.Dispatch<React.SetStateAction<AccountStatusStateParamsModel>>
  loading?: boolean
}) => {
  return (
    <div className='d-flex mb-3'>
      <div className='d-flex'>
        <Formik
          initialValues={{
            fromDate: '',
            toDate: '',
            cif_number: '',
          }}
          onSubmit={(values) => {
            setParams({
              ...params,
              from_date: values.fromDate ? moment(values?.fromDate).format('YYYY-MM-DD') : '',
              to_date: values.toDate ? moment(values?.toDate).format('YYYY-MM-DD') : '',
              cif_number: values?.cif_number,
            })
          }}
          data-cy='design-form'
        >
          {({touched, handleChange, errors, values, setFieldValue}) => {
            console.log('values', values.cif_number)
            return (
              <Form>
                <div className='d-flex flex-row align-items-center'>
                  <div className='col-4' style={{zIndex: 11}}>
                    <DatePickerForGrievance
                      containerClassName='col-12'
                      name='fromDate'
                      placeholderText='From date'
                      setFieldValue={setFieldValue}
                      value={values.fromDate || false}
                      touched={touched}
                      required={true}
                      showIcon={true}
                      errors={errors}
                    />
                  </div>
                  <div className='col-4 ms-5' style={{zIndex: 11}}>
                    <DatePickerForGrievance
                      containerClassName='col-12'
                      name='toDate'
                      placeholderText='To date'
                      setFieldValue={setFieldValue}
                      value={values.toDate || false}
                      touched={touched}
                      required={true}
                      showIcon={true}
                      errors={errors}
                    />
                  </div>
                  <div className='col-4 ms-3'>
                    <InputGroup
                      inside
                      style={{width: '100%', margin: '1rem 0', padding: '0.3rem 0'}}
                    >
                      <Input
                        placeholder='Search By CIF Number'
                        name='cif_number'
                        value={values.cif_number}
                        onChange={(value) => setFieldValue('cif_number', value)}
                        data-cy='search-field'
                      />
                      <InputGroup.Addon>
                        {!isEmpty(values.cif_number) ? (
                          <CloseIcon
                            style={{cursor: 'pointer'}}
                            onClick={() => setFieldValue('cif_number', '')}
                            data-cy='search-close-button'
                          />
                        ) : (
                          <SearchIcon />
                        )}
                      </InputGroup.Addon>
                    </InputGroup>
                  </div>
                  <div className='col-1 ms-5'>
                    <button
                      type='submit'
                      disabled={loading}
                      className='btn btn-primary btn'
                      data-cy='design-filter-button'
                    >
                      Search
                    </button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

const IndividualJointMinorTable = () => {
  const [params, setParams] = useState<AccountStatusStateParamsModel>({
    page: 1,
    limit: 10,
    orderBy: 'updated_at',
    order: 'desc',
  })

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const {data: accountList, isLoading} = useGetAccountStatusLists(params)

  const data =
    accountList?.data?.new_accounts?.map((newAccount: any, i: number) => ({
      ...newAccount,
      sn: (params?.page - 1) * params?.limit + (i + 1),
      account_name: `${newAccount?.first_name} ${
        newAccount.middle_name ? newAccount.middle_name + ' ' : ''
      } ${newAccount?.last_name}`,
      accountNumber: newAccount?.registration_detail?.account_number ?? '',
      cifNumber: newAccount?.registration_detail?.cif_number ?? '',
      updatedOn: newAccount?.account_status?.updated_date,
      status: newAccount?.account_status?.current_status,
      remarks: newAccount?.account_status?.remarks,
    })) ?? []

  const columns = [
    {
      label: 'Account Number',
      dataKey: 'accountNumber',
      width: 200,
      cell: <Cell dataKey='accountNumber' />,
    },
    {
      label: 'Customer Code',
      dataKey: 'customer_code',
      cell: <Cell dataKey='customer_code' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Account Name',
      dataKey: 'account_name',
      sortable: false,
      width: 200,
      cell: <Cell dataKey='account_name' />,
    },
    {
      label: 'CIF Number',
      dataKey: 'cifNumber',
      cell: <Cell dataKey='cifNumber' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Location',
      dataKey: 'location',
      cell: <Cell dataKey='location' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Status',
      dataKey: 'status',
      cell: <Cell dataKey='status' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Created On',
      dataKey: 'updatedOn',
      cell: <Cell dataKey='updatedOn' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Remarks',
      dataKey: 'remarks',
      cell: <Cell dataKey='remarks' />,
      sortable: false,
      width: 200,
    },
  ]

  return (
    <>
      <ParamsComponent params={params} setParams={setParams} loading={isLoading} />
      <div className='datatable'>
        <RSuiteTable columns={columns} data={data} showLoading={isLoading} />
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size='sm'
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={accountList?.data?.meta?.total ?? 0}
          limitOptions={[10, 20, 50, 100]}
          limit={params?.limit}
          activePage={params?.page}
          onChangePage={(value) => setParams({...params, page: value})}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </>
  )
}

export default IndividualJointMinorTable
