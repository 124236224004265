import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL
export const CORPORATE_ACCOUNT_PRELIMINARY = `${API_URL}/corporate-newcif-newaccount`

type typePayload = {
  id: string
  data: {
    action?: string
    remarks?: string
  }
}
const postCorporateAccountPreliminaryReview = async (payload: typePayload) => {
  const {id, data} = payload
  try {
    const response = await axios.post(`${CORPORATE_ACCOUNT_PRELIMINARY}/${id}/branch-review`, data)
    return response
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const usePostCorporateAccountPreliminary = () => {
  const queryClient = useQueryClient()
  return useMutation(postCorporateAccountPreliminaryReview, {
    onSuccess: (response) => {
      if (response?.data?.resCod == 200) {
        toast.success(response?.data?.resDesc ?? 'Review  status submitted successfully!')
        queryClient.invalidateQueries('getCorporateAccountLists')
        queryClient.invalidateQueries('branchCheckerNewCIFAndAccountList')
      }
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

export default usePostCorporateAccountPreliminary
