import * as Yup from 'yup'

export const createNewCIFValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid Email').required('Required'),
  mobile_number: Yup.string()
    .matches(/^9\d{9}$/, 'Invalid Mobile Number')
    .required('Required'),
  relationship: Yup.string().required('Required'),
  primary_cif_number: Yup.string().required('Required'),
  location: Yup.string().required('Required'),
  locationCode: Yup.string().required('Required'),
  branchLocation: Yup.string().required('Required'),
  pep_confirmation: Yup.string().required('Required'),
  customerCategoryId: Yup.string().required('Required'),
  customerClassificationId: Yup.string().required('Required'),
  country_id: Yup.string().required('Required'),
  language: Yup.string().required('Required'),
  taxCategoryId: Yup.string().required('Required'),
  taxGroupId: Yup.string().required('Required'),
  aml_risk_rating: Yup.string().required('Required'),
  mediaId: Yup.string().required('Required'),
  institutionalId: Yup.string().required('Required'),
  exposureId: Yup.string().required('Required'),

  salutation: Yup.string().required('Required'),
  first_name: Yup.string()
    .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed')
    .required('Required'),
  middle_name: Yup.string()
    .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed')
    .nullable(),
  last_name: Yup.string()
    .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed')
    .required('Required'),
  kyc_level_category: Yup.string().required('Required'),

  gender: Yup.string().required('Required'),
  marital_status: Yup.string().required('Required'),
  date_of_birth_bs: Yup.string().required('Required'),
  nationality: Yup.string().required('Required'),
  pan_number: Yup.string(),
  pan_registration_date_bs: Yup.string(),

  citizenship_number: Yup.string().when('basic_identification', {
    is: (value: string) => value === 'citizenship',
    then: Yup.string()
      .matches(/^[0-9\-/]+$/, 'Invalid')
      .required('Required'),
    otherwise: Yup.string(),
  }),
  citizenship_issue_district: Yup.string().when('basic_identification', {
    is: (value: string) => value === 'citizenship',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  citizenship_issue_date_bs: Yup.string().when('basic_identification', {
    is: (value: string) => value === 'citizenship',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  passport_number: Yup.string().when('basic_identification', {
    is: (value: string) => value === 'passport',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),

  passport_issue_district: Yup.string().when('basic_identification', {
    is: (value: string) => value === 'passport',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),

  id_card_issuing_office: Yup.string().when('basic_identification', {
    is: (value: string) => value === 'other',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  id_card_number: Yup.string().when('basic_identification', {
    is: (value: string) => value === 'other',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  id_card_issue_date_ad: Yup.string().when('basic_identification', {
    is: (value: string) => value === 'other',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  id_card_issue_date: Yup.string().when('basic_identification', {
    is: (value: string) => value === 'other',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  id_card_expiry_date_ad: Yup.string().when('basic_identification', {
    is: (value: string) => value === 'other',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  id_card_expiry_date: Yup.string().when('basic_identification', {
    is: (value: string) => value === 'other',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  unique_id_issued_by: Yup.string().required('Required'),
  education_qualification: Yup.string().required('Required'),
  temporary_phone_number_residence: Yup.string().max(10, 'Invalid Phone Number'),
  temporary_phone_number_work: Yup.string().max(10, 'Invalid Phone Number'),
  temporary_mobile_number: Yup.string()
    .matches(/^9\d{9}$/, 'Invalid Mobile Number')
    .required('Required'),
  temporary_email: Yup.string().email('Invalid Email').required('Required'),
  permanent_phone_number_residence: Yup.string().max(10, 'Invalid Phone Number'),
  permanent_phone_number_work: Yup.string().max(10, 'Invalid Phone Number'),
  permanent_mobile_number: Yup.string().matches(/^9\d{9}$/, 'Invalid Mobile Number'),
  permanent_email: Yup.string().email('Invalid Email'),
  father_name: Yup.string()
    .required('Required')
    .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
  mother_name: Yup.string().matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
  grand_father_name: Yup.string()
    .required('Required')
    .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
  grand_mother_name: Yup.string().matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
  daughter_in_law_name: Yup.string().matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
  father_in_law_name: Yup.string().matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
  children: Yup.array().of(
    Yup.object().shape({
      name_of_children: Yup.string()
        .nullable()
        .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
      relation: Yup.string()
        .nullable()
        .when('name_of_children', {
          is: (name: string) => name && name.trim().length > 0,
          then: Yup.string().required('Required'),
          otherwise: Yup.string(),
        }),
    })
  ),
  occupation: Yup.string().required('Required'),
  additional_occupation: Yup.string().required('Required'),
  source_of_income: Yup.string().required('Required'),
  employment_status: Yup.string().required('Required'),
  information_type: Yup.string().required('Required'),
  institutions: Yup.array().of(
    Yup.object().shape({
      name_of_related_employer: Yup.string()
        .test('is-required', 'Name of related employer is required', function (value) {
          if (
            this?.options?.context?.information_type === 'business' &&
            (value === undefined || value === '' || value === null)
          ) {
            return false
          }
          return true
        })
        .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
      business_address: Yup.string().test(
        'is-required',
        'Business address is required',
        function (value) {
          if (
            this?.options?.context?.information_type === 'business' &&
            (value === undefined || value === '' || value === null)
          ) {
            return false
          }
          return true
        }
      ),
      position: Yup.string().test('is-required', 'Position is required', function (value) {
        if (
          this?.options?.context?.information_type === 'business' &&
          (value === undefined || value === '' || value === null)
        ) {
          return false
        }
        return true
      }),
      yearly_remuneration: Yup.number()
        .typeError('Yearly Remuneration must be in numbers')
        .test('is-required', 'Yearly remuneration is required', function (value) {
          if (
            this?.options?.context?.information_type === 'business' &&
            (value === undefined || value === null)
          ) {
            return false
          }
          return true
        }),
    })
  ),
  students: Yup.array().of(
    Yup.object().shape({
      name_of_institute: Yup.string().test(
        'is-required',
        'Name of institute is required',
        function (value) {
          if (
            this?.options?.context?.information_type === 'student' &&
            (value === undefined || value === '' || value === null)
          ) {
            return false
          }
          return true
        }
      ),
      student_address: Yup.string().test(
        'is-required',
        'Student address is required',
        function (value) {
          if (
            this?.options?.context?.information_type === 'student' &&
            (value === undefined || value === '' || value === null)
          ) {
            return false
          }
          return true
        }
      ),
      phone_no: Yup.number()
        .typeError('Invalid Phone No.')
        .max(9999999999, 'Phone number cannot be more than 10 numbers')
        .test('is-required', 'Phone number is required', function (value) {
          if (
            this?.options?.context?.information_type === 'student' &&
            (value === undefined || value === null)
          ) {
            return false
          }
          return true
        }),
    })
  ),
  anticipated_annual_transaction: Yup.string().required('Required'),
  introducer_name: Yup.string()
    .required('Required')
    .matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
  firstNomineeName: Yup.string().matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
  proof_address_document: Yup.string(),
  secondNomineeName: Yup.string().matches(/^[A-Za-z\s.\-']+$/, 'Only letters are allowed'),
  resident_status: Yup.string().required('Required'),

  unique_id_issued_by_other: Yup.string(),
  education_qualification_others: Yup.string(),
  occupation_other: Yup.string(),
  source_of_income_other: Yup.string(),
  remarks: Yup.string().required('Required'),
})
