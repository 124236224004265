import axios from 'axios'
import {useQuery, useMutation} from 'react-query'
import {AccountStatusStateParamsModel} from 'src/app/modules/account/accountListing/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const CORPORATE_ACCOUNT_STATUS_LISTS_API = `${API_URL}/corporate-newcif-newaccount/account-status`

// Query Key
const QUERY_KEY = 'allCorporateAccountStatusList'

export const useGetCorporateAccountStatusLists = (params: AccountStatusStateParamsModel) => {
  return useQuery([QUERY_KEY, params], async () => {
    const {data} = await axios.get(CORPORATE_ACCOUNT_STATUS_LISTS_API, {
      params,
    })
    return data
  })
}
