import {FieldArray} from 'formik'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {handleUppercaseChange} from '../../../createNewAccount/components'
import {ApplicationFormSelect} from 'src/cms/helpers/components/forms/form-fields'
import {RootState} from 'src/setup'
import {MasterDataModel} from 'src/app/modules/masterData/Model'
import {useSelector} from 'react-redux'

interface RelativeInformationSectionProps {
  handleChange: any
  touched: any
  errors: any
  setFieldValue: any
  values: any
}

const RelativeInformationSection = ({
  handleChange,
  errors,
  touched,
  setFieldValue,
  values,
}: RelativeInformationSectionProps) => {
  const {data: maritalStatusList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.maritalStatusList
  )
  return (
    <>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='spouse_name'
          label='Spouse Name'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleUppercaseChange(handleChange)}
          errors={errors}
          touched={touched}
          required={
            values.marital_status ===
            maritalStatusList.find((maritalStatus) => maritalStatus.title === 'Married')?.id
          }
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='father_name'
          label='Father Name'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleUppercaseChange(handleChange)}
          errors={errors}
          touched={touched}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='mother_name'
          label='Mother Name'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleUppercaseChange(handleChange)}
          errors={errors}
          touched={touched}
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='grand_father_name'
          label='Grandfather Name'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleUppercaseChange(handleChange)}
          errors={errors}
          touched={touched}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='grand_mother_name'
          label='Grandmother Name'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleUppercaseChange(handleChange)}
          errors={errors}
          touched={touched}
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='daughter_in_law_name'
          label='Daughter In Law Name'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleUppercaseChange(handleChange)}
          errors={errors}
          touched={touched}
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='father_in_law_name'
          label='Father in Law Name'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleUppercaseChange(handleChange)}
          errors={errors}
          touched={touched}
        />
      </div>
      <div className='col-12 col-md-6 bg-secondary p-5 my-6'>
        <label className='form-label fw-bolder text-dark fs-6 me-8  '>Son/Daughter</label>
        <FieldArray
          name='children'
          render={({push, remove}) => (
            <>
              {values.children && values?.children?.length > 0
                ? values.children.map((_: undefined, index: number) => (
                    <div className='row' key={index}>
                      <FormTextBox
                        name={`children.${index}.name_of_children`}
                        placeholder='Name'
                        containerClassName='col-12'
                        labelClassName='col-12'
                        onChange={handleUppercaseChange(handleChange)}
                        errors={errors}
                        touched={touched}
                      />
                      <ApplicationFormSelect
                        name={`children.${index}.relation`}
                        className='col-md-5'
                        placeholder='Relation'
                        options={[
                          {label: 'SON', value: 'SON'},
                          {label: 'DAUGHTER', value: 'DAUGHTER'},
                        ]}
                        size='large'
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                      />
                      {index > 0 && (
                        <div>
                          <button
                            className='btn btn-danger my-2'
                            onClick={(e) => {
                              e.preventDefault()
                              remove(index)
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                    </div>
                  ))
                : null}

              <button
                className='btn btn-primary float-end'
                onClick={(e) => {
                  e.preventDefault()
                  push({name_of_children: '', relation: ''})
                }}
              >
                Add
              </button>
            </>
          )}
        />
      </div>
    </>
  )
}

export default RelativeInformationSection
