import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const BULK_ACCOUNT_OPENING_API = `${API_URL}/bulk-account-opening`

export const services = {
  getAllBulkAccountOpening: (params: ParamsModel) => {
    return axios.get(BULK_ACCOUNT_OPENING_API, {params})
  },

  getFailedMasterBatchList: () => {
    return axios.get(`${BULK_ACCOUNT_OPENING_API}/failed-master-batches`)
  },

  getBulkAccountOpenedList: (params: ParamsModel) => {
    return axios.get(`${BULK_ACCOUNT_OPENING_API}/account-opened-list`, {params})
  },
}
