import {call, put, takeLatest} from 'redux-saga/effects'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from '../../errors/redux/constants'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {services} from './service'
import {CustomerClassificationDataModel, MasterDataModel, DocumentTypeModel} from '../Model'
import {actions} from './action'

function* getCustomerClassificationListSaga(action: ActionModel) {
  try {
    const resposne: ResponseModel = yield call(services.getCustomerClassification)
    const data: CustomerClassificationDataModel[] = resposne.data?.data.customer_classifications

    yield put(actions.getCustomerClassificationSuccess(data))
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
  } finally {
    yield put(actions.getCustomerClassificationFinish())
  }
}

function* getDocumentTypesSaga(action: ActionModel) {
  try {
    const resposne: ResponseModel = yield call(services.getDocumentTypes)
    const data: DocumentTypeModel[] = resposne.data?.data.document_types

    yield put(actions.getDocumentTypesSuccess(data))
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
  } finally {
    yield put(actions.getDocumentTypesFinish())
  }
}

function* getMasterDataSaga(action: ActionModel) {
  try {
    const resposne: ResponseModel = yield call(services.getAllMasterData)
    const data: Record<string, MasterDataModel[]> = resposne.data?.data?.reduce(
      (a: object, v: {[key: string]: object}) => ({...a, ...v}),
      {}
    )
    yield put(actions.getAccountClassSuccess(data?.account_classes))
    yield put(actions.getAccountTypeSuccess(data?.account_types))
    yield put(actions.getCustomerCategorySuccess(data?.customer_categories))
    yield put(actions.getTaxCategorySuccess(data?.tax_categories))
    yield put(actions.getTaxGroupSuccess(data?.tax_groups))
    yield put(actions.getInstitutionalSuccess(data?.institutionals))
    yield put(actions.getSalutationsSuccess(data?.salutations))
    yield put(actions.getIdTypesSuccess(data?.id_types))
    yield put(actions.getExposuresSuccess(data?.exposures))
    yield put(actions.getRiskCategoriesSuccess(data?.risk_categories))
    yield put(actions.getGuardianTypesSuccess(data?.guardian_types))
    yield put(actions.getAncillaryRemarksSuccess(data?.ancillary_remarks))
    yield put(actions.getMediaSuccess(data?.medias))
    yield put(actions.getIncomeSourcesSuccess(data?.income_sources))
    yield put(actions.getEmployementStatusSuccess(data?.employment_statuses))
    yield put(actions.getKycLevelCategoriesSuccess(data?.kyc_categories))
    yield put(actions.getOccupationSuccess(data?.occupations))
    yield put(actions.getAdditionalOccupationSuccess(data?.additional_occupations))
    yield put(actions.getQualificationSuccess(data?.qualifications))
    yield put(actions.getAccountPurposesSuccess(data?.purposes))
    yield put(actions.getIndividualUniqueIdsSuccess(data?.individual_unique_ids))
    yield put(actions.getMaritalStatusSuccess(data?.marital_status))
    yield put(actions.getAccountCategoriesSuccess(data?.account_categories))
    yield put(actions.getLinkedEntitiesRelationshipSuccess(data?.relationships))
    yield put(actions.getCorporateRegistrationsSuccess(data?.corporate_registrations))
    yield put(actions.getCountriesListsSuccess(data?.countries))
    yield put(actions.getNationalitiesListsSuccess(data?.nationalities))
    yield put(actions.getCurrencyListsSuccess(data?.currencies))
    yield put(actions.getKycRequestTypesSuccess(data?.kyc_request_types))
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
  } finally {
    yield put(actions.getAccountClassFinish())
  }
}

export function* saga() {
  yield takeLatest(
    actionTypes.GET_CUSTOMER_CLASSIFICATION_LIST_START,
    getCustomerClassificationListSaga
  )
  yield takeLatest(actionTypes.GET_ALL_MASTER_DATA_LIST_START, getMasterDataSaga)
  yield takeLatest(actionTypes.GET_DOCUMENT_TYPES_LIST_START, getDocumentTypesSaga)
}
