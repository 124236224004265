import {ParamsModel} from '../../common/Model'
import {actionTypes} from './constants'
import {IExistingCIFListState} from './reducer'

export const actions = {
  getNewCIFList: (params: ParamsModel | undefined = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_EXISTING_CIF_START,
    payload: {params},
  }),
  getNewCIFListSuccess: (data: IExistingCIFListState) => ({
    type: actionTypes.GET_EXISTING_CIF_SUCCESS,
    payload: data,
  }),
  getNewCIFListFinish: () => ({
    type: actionTypes.GET_EXISTING_CIF_FINISH,
  }),
}
