import {ActionWithPayload} from '../../auth'
import {actionTypes} from './constants'

export interface IExistingCIFListState {
  loading: boolean
  success: boolean
  data: {
    new_accounts: Record<string, string>[]
    meta: Record<string, number>
  }
}

const initialExistingCIFState: IExistingCIFListState = {
  loading: false,
  success: false,
  data: {new_accounts: [], meta: {}},
}

export const reducer = (
  state: IExistingCIFListState = initialExistingCIFState,
  action: ActionWithPayload<IExistingCIFListState>
) => {
  switch (action.type) {
    case actionTypes.GET_EXISTING_CIF_START:
      return {...state, loading: true}
    case actionTypes.GET_EXISTING_CIF_SUCCESS:
      return {...state, loading: false, success: true, data: action.payload}
    case actionTypes.GET_EXISTING_CIF_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}
