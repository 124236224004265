import {useHistory} from 'react-router-dom'
import '../style/DashBoardCard.scss'

type DashBoardCardProps = {
  label: string
  imageUrl?: string
  path?: string
}

const DashBoardCard = ({label, imageUrl, path}: DashBoardCardProps) => {
  const history = useHistory()
  const handleCardClick = () => {
    if (path) {
      history.push(path)
    }
  }

  return (
    <div className='col-12 col-sm-6 col-md-4 col-xl-3 mb-4' onClick={handleCardClick}>
      <div className='card card-hover h-100'>
        <div className='card-body text-center'>
          {imageUrl && (
            <img
              src={imageUrl}
              alt={label}
              className='card-img-top mb-5 mx-auto w-auto'
              height='120px'
              width='120px'
            />
          )}
          <h5 className='card-title text-center'>{label}</h5>
        </div>
      </div>
    </div>
  )
}

export default DashBoardCard
