import {Field, FieldArray} from 'formik'
import {useSelector} from 'react-redux'
import {handleUppercaseChange} from 'src/app/modules/CreateNewCIFAndCustomerAccount/createNewAccount/components'
import {MasterDataModel} from 'src/app/modules/masterData/Model'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {RootState} from 'src/setup'
interface ProprietaryInformationSectionProps {
  handleChange: any
  touched: any
  errors: any
  setFieldValue: any
  values: any
}

const titleOfDirectorsArray = [
  {label: 'DIRECTOR OF ENGINEERING', value: 'DIRECTOR OF ENGINEERING'},
  {label: 'DIRECTOR OF PRODUCT MANAGEMENT', value: 'DIRECTOR OF PRODUCT MANAGEMENT'},
  {label: 'DIRECTOR OF OPERATIONS', value: 'DIRECTOR OF OPERATIONS'},
  {label: 'DIRECTOR OF SALES', value: 'DIRECTOR OF SALES'},
  {label: 'DIRECTOR OF MARKETING', value: 'DIRECTOR OF MARKETING'},
  {label: 'DIRECTOR OF FINANCE', value: 'DIRECTOR OF FINANCE'},
  {label: 'DIRECTOR OF HUMAN RESOURCES', value: 'DIRECTOR OF HUMAN RESOURCES'},
  {label: 'DIRECTOR OF CUSTOMER SUCCESS', value: 'DIRECTOR OF CUSTOMER SUCCESS'},
  {label: 'DIRECTOR OF INFORMATION TECHNOLOGY', value: 'DIRECTOR OF INFORMATION TECHNOLOGY'},
]

const industryBusinessType = [
  {label: 'TECHNOLOGY', value: 'TECHNOLOGY'},
  {label: 'HEALTHCARE', value: 'HEALTHCARE'},
  {label: 'FINANCE', value: 'FINANCE'},
  {label: 'RETAIL', value: 'RETAIL'},
  {label: 'EDUCATION', value: 'EDUCATION'},
  {label: 'MANUFACTURING', value: 'MANUFACTURING'},
  {label: 'HOSPITALITY', value: 'HOSPITALITY'},
  {label: 'TRANSPORTATION', value: 'TRANSPORTATION'},
  {label: 'REAL ESTATE', value: 'REAL ESTATE'},
  {label: 'ENTERTAINMENT', value: 'ENTERTAINMENT'},
]

const ProprietaryInformationSection = ({
  handleChange,
  touched,
  errors,
  setFieldValue,
  values,
}: ProprietaryInformationSectionProps) => {
  const {data: incomeSources}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.incomeSourceList
  )
  return (
    <>
      <FieldArray
        name='directors'
        render={({push, remove}) => (
          <>
            <div className='col-12'>
              <h2 className='text-center required'>
                Details of Proprietor/Partners/Directors/Executive Committee Members Major Officials
              </h2>
            </div>
            <div className='bg-secondary p-5 my-6'>
              {values.directors && values.directors.length > 0
                ? values.directors.map((_: undefined, index: number) => (
                    <div className='row mb-3' key={index}>
                      <div className='col-md-6 col-12'>
                        <FormSelect
                          name={`directors.${index}.title_of_director`}
                          placeholder='Title of Director'
                          containerClassName='col-12'
                          labelClassName='col-12'
                          label='Title of Director'
                          options={titleOfDirectorsArray ?? []}
                          setFieldValue={setFieldValue}
                          onChange={(option: any) =>
                            setFieldValue(`directors.${index}.title_of_director`, option.value)
                          }
                          arrValue={values?.directors?.[index]?.title_of_director ?? ''}
                          errors={errors}
                          touched={touched}
                          required
                        />
                      </div>
                      <div className='col-md-6 col-12'>
                        <FormTextBox
                          name={`directors.${index}.detadetail_of_directoril`}
                          placeholder='Approx Yearly Remuneration'
                          label='Detail'
                          mainClassName='col-12'
                          containerClassName='col-12'
                          labelClassName='col-12'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          required
                        />
                      </div>
                      {index > 0 && (
                        <div>
                          <button
                            className='btn btn-danger my-2'
                            onClick={(e) => {
                              e.preventDefault()
                              remove(index)
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                    </div>
                  ))
                : null}
              <button
                className='btn btn-primary float-end'
                onClick={(e) => {
                  e.preventDefault()
                  push({
                    title_of_director: '',
                    detail: '',
                  })
                }}
              >
                Add
              </button>
            </div>
          </>
        )}
      />
      <div className='col-md-6 col-12'>
        <FormSelect
          name='industry_business_type'
          containerClassName='col-12'
          labelClassName='col-12'
          label='Industry Business Type'
          options={industryBusinessType ?? []}
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormSelect
          name='source_of_income'
          containerClassName='col-12'
          labelClassName='col-12'
          label='Source of Income'
          options={
            incomeSources.map((incomeSource) => ({
              label: incomeSource.title,
              value: incomeSource.id,
            })) ?? []
          }
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
        />
      </div>
      {values.source_of_income ===
        incomeSources.find((incomeSource) => incomeSource.title === 'Others')?.id && (
        <div className='col-md-6 col-12'>
          <FormTextBox
            name='source_of_income_other'
            label='Other Occupation'
            containerClassName='col-12'
            labelClassName='col-12'
            onChange={handleChange}
            errors={errors}
            touched={touched}
            required
          />
        </div>
      )}
      <div className=' d-flex my-7 col-12'>
        <label className='form-label fw-bolder text-dark fs-6 me-5 required '>
          Anticipated Annual Transaction
        </label>
        <div className='d-flex flex-wrap'>
          {[
            {label: 'Upto 1 Mil', value: 'Upto 1 Mil'},
            {label: 'Upto 5 Mil', value: 'Upto 5 Mil'},
            {label: 'Upto 10 Mil', value: 'Upto 10 Mil'},
            {label: 'Upto 50 Mil', value: 'Upto 50 Mil'},
            {label: 'Above 50 Mil', value: 'Above 50 Mil'},
          ].map((annualTransaction) => (
            <FormRadio
              key={annualTransaction.value}
              checkBoxText={annualTransaction.label}
              containerClassName='col-md-3'
              errors={errors}
              name='anticipated_annual_transaction'
              onChange={handleChange}
              touched={touched}
              value={annualTransaction.value}
            />
          ))}
        </div>
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='introducer_name'
          label='Introducer Name'
          mainClassName='col-12'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleUppercaseChange(handleChange)}
          errors={errors}
          touched={touched}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          type='date'
          name='kyc_review_date'
          label='KYC Review Date'
          mainClassName='col-12'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
          required
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='proof_address_document'
          label='Proof Address Document'
          mainClassName='col-12'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleUppercaseChange(handleChange)}
          errors={errors}
          touched={touched}
          required
        />
      </div>
    </>
  )
}

export default ProprietaryInformationSection
