import DetailCell from '../DetailCell'

interface ProfessionalInformationSectionProps {
  data?: any
}

const ProfessionalInformationSection = ({data}: ProfessionalInformationSectionProps) => {
  return (
    <>
      <div className='row'>
        <DetailCell label='Occupation' value={data?.kyc_information?.occupation?.title} />
        <DetailCell label='Other Occupation' value={data?.kyc_information?.occupation_other} />
      </div>
      <div className='row'>
        <DetailCell
          label='Additional Occupation'
          value={data?.kyc_information?.additional_occupation?.title}
        />
        <DetailCell
          label='Soruce of Income'
          value={data?.kyc_information?.source_of_income?.title}
        />
      </div>
      <div className='row'>
        <DetailCell
          label='Other Source of Income'
          value={data?.kyc_information?.source_of_income_other}
        />
        <DetailCell
          label='Employment Status'
          value={data?.kyc_information?.employment_status?.title}
        />
      </div>
      <div className='row'>
        <DetailCell label='First Nominee' value={data?.kyc_information?.first_nominee_name} />
        <DetailCell label='Second Nominee' value={data?.kyc_information?.second_nominee_name} />
      </div>
      <div className='row'>
        <DetailCell
          label='Currency of Amount'
          value={data?.kyc_information?.currency_of_amount?.title}
        />
        <DetailCell
          label='Proof Address Document'
          value={data?.kyc_information?.proof_address_document}
        />
      </div>
      <div className='row'>
        <DetailCell label='Residental Status' value={data?.kyc_information?.resident_status} />
      </div>
    </>
  )
}

export default ProfessionalInformationSection
