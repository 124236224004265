import {handleUppercaseChange} from 'src/app/modules/CreateNewCIFAndCustomerAccount/createNewAccount/components'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'

interface FamilAndTransactionDetailsSectionProps {
  handleChange: any
  touched: any
  errors: any
  setFieldValue: any
  values: any
  initialValues: any
  data: any
}

const FamilyAndTransactionDetailsSection = ({
  handleChange,
  errors,
  touched,
  setFieldValue,
  values,
  initialValues,
  data,
}: FamilAndTransactionDetailsSectionProps) => {
  return (
    <>
      <p className='fs-2 fw-bold'>Family and Transaction Details</p>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Grandfather Name</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.grand_father_name ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            name='grand_father_name'
            label=' '
            containerClassName='col-12'
            onChange={handleUppercaseChange(handleChange)}
            errors={errors}
            touched={touched}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Father Name</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.father_name ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            name='father_name'
            label=' '
            containerClassName='col-12'
            onChange={handleUppercaseChange(handleChange)}
            errors={errors}
            touched={touched}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Mother Name</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.mother_name ?? ''}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            name='mother_name'
            label=' '
            containerClassName='col-12'
            onChange={handleUppercaseChange(handleChange)}
            errors={errors}
            touched={touched}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Spouse Name</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.spouse_name ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            name='spouse_name'
            label=' '
            containerClassName='col-12'
            onChange={handleUppercaseChange(handleChange)}
            errors={errors}
            touched={touched}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-5'>Anticipated Annual Transaction</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.anticipated_annual_transaction ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormSelect
            name='anticipated_annual_transaction'
            label=' '
            containerClassName='col-12'
            labelClassName='col-12'
            options={[
              {label: 'Upto Rs.1,00,000', value: 'Upto Rs.1,00,000'},
              {label: 'Upto Rs.5,00,000', value: 'Upto Rs.5,00,000'},
              {label: 'Upto Rs.10,00,000', value: 'Upto Rs.10,00,000'},
              {label: 'Upto Rs.50,00,000', value: 'Upto Rs.50,00,000'},
              {label: 'Above Rs.50,00,000', value: 'Above Rs.50,00,000'},
            ]}
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Proof Address Document</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.proof_address_document ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            name='proof_address_document'
            label=' '
            containerClassName='col-12'
            onChange={handleUppercaseChange(handleChange)}
            errors={errors}
            touched={touched}
          />
        </div>
      </div>
    </>
  )
}

export default FamilyAndTransactionDetailsSection
