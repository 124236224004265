import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {KYCAdditionalForm} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const CPU_MAKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_API = `${API_URL}/newcif-newaccount/cpu-maker-list`
const CPU_MAKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_FORM_API = `${API_URL}/newcif-newaccount`
const CPU_MAKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_API = `${API_URL}/corporate-newcif-newaccount/cpu-maker-list`

export const services = {
  getCPUMakerNewCIFandAccountList: (params: ParamsModel) => {
    return axios.get(CPU_MAKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_API, {params})
  },

  getCPUMakerNewCIFandAccountCorporateBankList: (params: ParamsModel) => {
    return axios.get(CPU_MAKER_NEW_CIF_AND_ACCOUNT_CORPORATE_BANK_API, {params})
  },

  postAccountPreliminaryKYCForm: (id: string, data: any) => {
    return axios.post(
      `${CPU_MAKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_FORM_API}/${id}/kyc-form`,
      data
    )
  },

  postAccountPreliminaryKYCAdditionalForm: (id: string, data: any) => {
    return axios.post(
      `${CPU_MAKER_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_FORM_API}/${id}/kyc-additional-form`,
      data
    )
  },
}
