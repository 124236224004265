import {useState} from 'react'
import {StateParamsModel} from 'src/app/modules/common/Model'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {Input, InputGroup, Pagination, Table} from 'rsuite'
import {Close, Search} from '@rsuite/icons'
import {isEmpty} from 'lodash'
import {useGetBranchMakerNewCIFandAccountList} from 'src/app/queries/Account Preliminary/get/useGetPreliminaryAccountList'

const Cell = Table.Cell

const RequestList = () => {
  const [params, setParams] = useState<StateParamsModel>({
    status: 1,
    page: 1,
    limit: 10,
  })

  const {data: requestList, isLoading} = useGetBranchMakerNewCIFandAccountList(params)

  const data =
    requestList?.data?.new_accounts?.map((newAccount: any, i: number) => ({
      ...newAccount,
      sn: (params?.page - 1) * params?.limit + (i + 1),
      account_name: `${newAccount?.first_name} ${
        newAccount.middle_name ? newAccount.middle_name + ' ' : ''
      } ${newAccount?.last_name}`,
      accountNumber: newAccount?.registration_detail?.account_number ?? '',
      cifNumber: newAccount?.registration_detail?.cif_number ?? '',
      updatedOn: newAccount?.account_status?.updated_date,
      status: newAccount?.account_status?.current_status,
      remarks: newAccount?.account_status?.remarks,
      customerCategory: newAccount?.customerCategoryId?.title,
      country: newAccount?.country_id?.title,
    })) ?? []

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const BadgeCell = ({rowData, dataKey, color, ...props}: any) => {
    return (
      <Cell {...props}>
        {rowData.mobile_banking === 1 && (
          <span className={`badge badge-${color}`}>Mobile Banking</span>
        )}
        {rowData.debit_card === 1 && (
          <span className={`badge badge-${color}`} style={{marginLeft: '4px'}}>
            Debit Card
          </span>
        )}
      </Cell>
    )
  }

  const columns = [
    {
      label: 'Account Number',
      dataKey: 'accountNumber',
      width: 200,
      cell: <Cell dataKey='accountNumber' />,
    },
    {
      label: 'CIF Number',
      dataKey: 'cifNumber',
      cell: <Cell dataKey='cifNumber' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Account Name',
      dataKey: 'account_name',
      sortable: false,
      width: 200,
      cell: <Cell dataKey='account_name' />,
    },
    {
      label: 'Customer Category',
      dataKey: 'customerCategory',
      sortable: false,
      width: 200,
      cell: <Cell dataKey='customerCategory' />,
    },
    {
      label: 'Country',
      dataKey: 'country',
      cell: <Cell dataKey='country' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Updated On',
      dataKey: 'updatedOn',
      cell: <Cell dataKey='updatedOn' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Requested Services',
      dataKey: 'requestedServices',
      cell: <BadgeCell dataKey='requestedServices' color='success' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Remarks',
      dataKey: 'remarks',
      cell: <Cell dataKey='remarks' />,
      sortable: false,
      width: 200,
    },
    // {
    //   label: 'Action',
    //   width: 160,
    //   align: 'center',
    //   cell: <ActionCell dataKey='id' />,
    // },
  ]
  return (
    <>
      <InputGroup inside style={{width: '30%', margin: '1rem 0'}}>
        <Input
          placeholder='Search'
          value={params.search}
          onChange={(value: string) => setParams({...params, search: value})}
          data-cy='search-field'
        />
        <InputGroup.Addon>
          {!isEmpty(params?.search) ? (
            <Close
              style={{cursor: 'pointer'}}
              onClick={() => setParams({...params, search: ''})}
              data-cy='search-close-button'
            />
          ) : (
            <Search />
          )}
        </InputGroup.Addon>
      </InputGroup>
      <div className='datatable'>
        <RSuiteTable columns={columns} data={data} showLoading={isLoading} />
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size='sm'
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={requestList?.data?.meta?.total ?? 0}
          limitOptions={[10, 20, 50, 100]}
          limit={params?.limit}
          activePage={params?.page}
          onChangePage={(value) => setParams({...params, page: value})}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </>
  )
}

export default RequestList
