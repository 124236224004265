import {handleUppercaseChange} from 'src/app/modules/CreateNewCIFAndCustomerAccount/createNewAccount/components'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'

interface MobileAndEmailDetailsSectionProps {
  data: any
  cbsData: any
}

const MobileAndEmailDetailsSection = ({data, cbsData}: MobileAndEmailDetailsSectionProps) => {
  return (
    <>
      <p className='fs-2 fw-bold'>Mobile and Email Details</p>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Mobile Number</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.mobile_number ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Phone No.</strong>
        </div>
        <div className='col-4'>-</div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.landline_number ?? '-'}</strong>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Email</strong>
        </div>
        <div className='col-4' style={{textTransform: 'uppercase'}}>
          <strong className='fs-6'>{cbsData?.email ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.email ?? '-'}</strong>
        </div>
      </div>
    </>
  )
}

export default MobileAndEmailDetailsSection
