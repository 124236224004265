import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {CustomerClassificationDataModel, MasterDataModel} from 'src/app/modules/masterData/Model'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {RootState} from 'src/setup'
import * as customerClassificationListRedux from 'src/app/modules/masterData/redux'

interface CustomerProfileUpdateSectionProps {
  handleChange: any
  touched: any
  errors: any
  setFieldValue: any
  values: any
  initialValues: any
  data: any
}

const CustomerProfileUpdateSection = ({
  handleChange,
  touched,
  errors,
  setFieldValue,
  values,
  initialValues,
  data,
}: CustomerProfileUpdateSectionProps) => {
  const dispatch = useDispatch()

  const customerClassificationListData = useSelector(
    (state: any) => state?.customerClassificationList
  )
  const {data: customerCategories}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.customerCategoryList
  )
  const {data: kycLevelCategories}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.kycLevelCategoryList
  )
  const {data: incomeSources}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.incomeSourceList
  )
  const {data: employmentStatusList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.employmentStatusList
  )
  const {data: occupations}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.occupationList
  )
  const {data: additionalOccupations}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.additionalOccupationsList
  )
  useEffect(() => {
    dispatch(customerClassificationListRedux.actions.getCustomerClassification())
  }, [])
  return (
    <>
      <p className='fs-2 fw-bold'>Customer Profile Update</p>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Customer Category</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.customer_category?.title ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormSelect
            name='customer_category'
            label=' '
            containerClassName='col-12'
            labelClassName='col-12'
            options={customerCategories?.map((customerCategory) => ({
              label: customerCategory.title,
              value: customerCategory.code ?? '',
            }))}
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Customer Classification</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.customer_classification?.title ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormSelect
            name='customer_classification'
            label=' '
            containerClassName='col-12'
            labelClassName='col-12'
            options={customerClassificationListData?.data?.map(
              (customerClassification: CustomerClassificationDataModel) => ({
                label: customerClassification.title,
                value: customerClassification.id,
              })
            )}
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>AML Risk Rating</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.aml_risk_rating ?? ''}</strong>
        </div>
        <div className='col-4'>
          <FormSelect
            name='aml_risk_rating'
            label=' '
            containerClassName='col-12'
            labelClassName='col-12'
            options={[
              {label: 'LOW', value: 'LOW'},
              {label: 'MEDIUM', value: 'MEDIUM'},
              {label: 'HIGH', value: 'HIGH'},
            ]}
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>KYC Level Category</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.kyc_level_category?.title ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormSelect
            name='kyc_level_category'
            label=' '
            containerClassName='col-12'
            labelClassName='col-12'
            options={
              kycLevelCategories.map((kycLevelCategory) => ({
                label: kycLevelCategory.title,
                value: kycLevelCategory.id,
              })) ?? []
            }
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Pep Confirmation</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.pep_confirmation ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormSelect
            name='pep_confirmation'
            label=' '
            containerClassName='col-12'
            labelClassName='col-12'
            options={[
              {label: 'YES', value: 'YES'},
              {label: 'NO', value: 'NO'},
            ]}
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Occupation</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.occupation?.title ?? ''}</strong>
        </div>
        <div className='col-4'>
          <FormSelect
            name='occupation'
            label=' '
            containerClassName='col-12'
            labelClassName='col-12'
            options={
              occupations.map((occupation) => ({
                label: occupation.title,
                value: occupation.id,
              })) ?? []
            }
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
          />
        </div>
      </div>
      {values.occupation ===
        occupations.find((occupation) => occupation.title === 'Others')?.id && (
        <div className='row my-2'>
          <div className='col-4'>
            <strong className='fs-4'>Other Occupation</strong>
          </div>
          <div className='col-4'>
            <strong className='fs-6'>{data?.occupation_other ?? ''}</strong>
          </div>
          <div className='col-4'>
            <FormTextBox
              name='occupation_other'
              label=' '
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={handleChange}
              errors={errors}
              touched={touched}
            />
          </div>
        </div>
      )}
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Source of Income</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.source_of_income?.title ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormSelect
            name='source_of_income'
            label=' '
            containerClassName='col-12'
            labelClassName='col-12'
            options={
              incomeSources.map((incomeSource) => ({
                label: incomeSource.title,
                value: incomeSource.id,
              })) ?? []
            }
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
          />
        </div>
      </div>
      {values.source_of_income ===
        incomeSources.find((incomeSource) => incomeSource.title === 'Others')?.id && (
        <div className='row my-2'>
          <div className='col-4'>
            <strong className='fs-4'>Other Source of Income</strong>
          </div>
          <div className='col-4'>
            <strong className='fs-6'>{data?.source_of_income_other ?? '-'}</strong>
          </div>
          <div className='col-4'>
            <FormTextBox
              name='source_of_income_other'
              label=' '
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={handleChange}
              errors={errors}
              touched={touched}
            />
          </div>
        </div>
      )}
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Employment Status</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.employment_status?.title ?? '-'}</strong>
        </div>
        <div className='col-4'>
          <FormSelect
            name='employment_status'
            label=' '
            containerClassName='col-12'
            labelClassName='col-12'
            options={
              employmentStatusList.map((employmentStatus) => ({
                label: employmentStatus.title,
                value: employmentStatus.id,
              })) ?? []
            }
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <strong className='fs-4'>Approx Yearly Remuneration</strong>
        </div>
        <div className='col-4'>
          <strong className='fs-6'>{data?.yearly_remuneration}</strong>
        </div>
        <div className='col-4'>
          <FormTextBox
            name='yearly_remuneration'
            label=' '
            containerClassName='col-12'
            onChange={handleChange}
            errors={errors}
            touched={touched}
          />
        </div>
      </div>
    </>
  )
}

export default CustomerProfileUpdateSection
