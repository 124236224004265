import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as placeReducer from 'src/app/modules/common'
import {MasterDataModel} from 'src/app/modules/masterData/Model'
import FormCheckbox from 'src/cms/helpers/components/forms/FormCheckbox'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {RootState} from 'src/setup'
import {handleUppercaseChange} from '../../../createNewAccount/components'

interface PersonalAddressSectionProps {
  handleChange: any
  touched: any
  errors: any
  setFieldValue: any
  values: any
}

const PersonalAddressSection = ({
  handleChange,
  touched,
  errors,
  setFieldValue,
  values,
}: PersonalAddressSectionProps) => {
  const [sameAsCorrespondence, setSameAsCorrespondence] = useState(false)
  const [districtList, setDistrictList] = useState<{[key: string]: string | number}[]>()
  const [municipalityList, setMunicipalityList] = useState<{[key: string]: string | number}[]>()

  const {data: countries}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.countriesList
  )

  const {district, province, municipality, loading} = useSelector((state: RootState) => state.place)

  useEffect(() => {
    if (values.permanent_province) {
      const districts = district?.filter((d) => {
        return d.province_id === values.permanent_province
      })
      setDistrictList(districts)
    } else {
      setDistrictList(district)
    }
  }, [values.permanent_province, district])

  useEffect(() => {
    if (values.permanent_district) {
      const municipalities = municipality?.filter((m) => {
        return m.district_id === values.permanent_district || m?.title === 'Others'
      })

      setMunicipalityList(municipalities)
    } else {
      setMunicipalityList(municipality)
    }
  }, [values.permanent_district, municipality])

  // Resetting Municipality on District change and both on province change
  useEffect(() => {
    if (!sameAsCorrespondence) {
      if (values.permanent_district) {
        setFieldValue('permanent_district', '')
      }
    }
  }, [values.permanent_province, sameAsCorrespondence])
  useEffect(() => {
    if (!sameAsCorrespondence) {
      if (values.permanent_municipality) {
        setFieldValue('permanent_municipality', '')
      }
    }
  }, [values.permanent_district, sameAsCorrespondence])

  useEffect(() => {
    if (sameAsCorrespondence) {
      setFieldValue('permanent_country', values.temporary_country)
      if (values.temporary_country === countries.find((country) => country.title === 'Nepal')?.id) {
        setFieldValue('permanent_house_number', values.temporary_house_number)
        setFieldValue('permanent_tole', values.temporary_tole)
        setFieldValue('permanent_ward_number', values.temporary_ward_number)
        setFieldValue('permanent_province', values.temporary_province)
        setFieldValue('permanent_district', values.temporary_district)
        setFieldValue('permanent_municipality', values.temporary_municipality)
        setFieldValue('permanent_municipality_other', values.temporary_municipality_other)
      } else {
        setFieldValue(
          'permanent_outside_nepal_address_line_1',
          values.temporary_outside_nepal_address_line_1
        )
        setFieldValue(
          'permanent_outside_nepal_address_line_2',
          values.temporary_outside_nepal_address_line_2
        )
        setFieldValue(
          'permanent_outside_nepal_address_line_3',
          values.temporary_outside_nepal_address_line_3
        )
      }
      setFieldValue('permanent_phone_number_residence', values.temporary_phone_number_residence)
      setFieldValue('permanent_phone_number_work', values.temporary_phone_number_work)
      setFieldValue('permanent_mobile_number', values.temporary_mobile_number)
      setFieldValue('permanent_email', values.temporary_email)
    }
  }, [
    sameAsCorrespondence,
    values.temporary_country,
    values.temporary_house_number,
    values.temporary_tole,
    values.temporary_province,
    values.temporary_district,
    values.temporary_municipality,
    values.temporary_municipality_other,
    values.temporary_phone_number_residence,
    values.temporary_phone_number_work,
    values.temporary_mobile_number,
    values.temporary_email,
    values.permanent_outside_nepal_address_line_1,
    values.permanent_outside_nepal_address_line_2,
    values.permanent_outside_nepal_address_line_3,
  ])

  return (
    <>
      <div className='col-12'>
        <h2 className='text-center'>PERMANENT ADDRESS</h2>
      </div>
      <FormCheckbox
        name='checkBox'
        label=' '
        checkBoxText='Same as Correspondence Address'
        containerClassName='col-12 my-6'
        onChange={(e) => {
          setSameAsCorrespondence(e.target.checked)
        }}
        errors={errors}
        touched={touched}
      />
      <div className='col-md-6 col-12'>
        <FormSelect
          name='permanent_country'
          label='Country'
          containerClassName='col-12'
          labelClassName='col-12'
          options={countries?.map((country) => ({label: country.title, value: country.id})) ?? []}
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
          disabled={sameAsCorrespondence}
        />
      </div>
      {values.permanent_country === countries.find((country) => country.title === 'Nepal')?.id ? (
        <>
          <div className='col-md-6 col-12'>
            <FormTextBox
              type='number'
              name='permanent_house_number'
              label='House Number'
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              disabled={sameAsCorrespondence}
            />
          </div>
          <div className='col-md-6 col-12'>
            <FormTextBox
              name='permanent_tole'
              label='Street Name/Tole'
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={handleUppercaseChange(handleChange)}
              errors={errors}
              touched={touched}
              required
              disabled={sameAsCorrespondence}
            />
          </div>
          <div className='col-md-6 col-12'>
            <FormTextBox
              type='number'
              name='permanent_ward_number'
              label='Ward Number'
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              required
              disabled={sameAsCorrespondence}
            />
          </div>
          <div className='col-md-6 col-12'>
            <FormSelect
              name='permanent_province'
              label='Province'
              containerClassName='col-12'
              labelClassName='col-12'
              options={
                province?.map((provinceData) => ({
                  label:
                    typeof provinceData.title === 'string'
                      ? provinceData.title
                      : provinceData.title.toString(),
                  value:
                    typeof provinceData.id === 'string'
                      ? provinceData.id
                      : provinceData.id.toString(),
                })) ?? []
              }
              setFieldValue={setFieldValue}
              onChange={handleChange}
              errors={errors}
              touched={touched}
              values={values}
              required
              disabled={sameAsCorrespondence}
            />
          </div>
          <div className='col-md-6 col-12'>
            <FormSelect
              name='permanent_district'
              label='District'
              containerClassName='col-12'
              labelClassName='col-12'
              options={
                districtList?.map((districtData) => ({
                  label:
                    typeof districtData.title === 'string'
                      ? districtData.title
                      : districtData.title.toString(),
                  value:
                    typeof districtData.id === 'string'
                      ? districtData.id
                      : districtData.id.toString(),
                })) ?? []
              }
              setFieldValue={setFieldValue}
              onChange={handleChange}
              errors={errors}
              values={values}
              touched={touched}
              required
              disabled={sameAsCorrespondence}
            />
          </div>
          <div className='col-md-6 col-12'>
            <FormSelect
              name='permanent_municipality'
              label='Municipality'
              containerClassName='col-12'
              labelClassName='col-12'
              options={
                municipalityList?.map((municipalityData) => ({
                  label:
                    typeof municipalityData.title === 'string'
                      ? municipalityData.title
                      : municipalityData.title.toString(),
                  value:
                    typeof municipalityData.id === 'string'
                      ? municipalityData.id
                      : municipalityData.id.toString(),
                })) ?? []
              }
              setFieldValue={setFieldValue}
              onChange={handleChange}
              errors={errors}
              touched={touched}
              values={values}
              required
              disabled={sameAsCorrespondence}
            />
          </div>
          {values.permanent_municipality ===
            municipality?.find((m) => m?.title === 'Others')?.id && (
            <div className='col-md-6 col-12'>
              <FormTextBox
                name='permanent_municipality_other'
                label='Other Municipality'
                containerClassName='col-12'
                labelClassName='col-12'
                onChange={handleUppercaseChange(handleChange)}
                errors={errors}
                touched={touched}
                disabled={sameAsCorrespondence}
                required
              />
            </div>
          )}
        </>
      ) : (
        <>
          <div className='col-12'>
            <FormTextBox
              name='permanent_outside_nepal_address_line_1'
              label='Permanent Addr Line 1'
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={handleUppercaseChange(handleChange)}
              errors={errors}
              touched={touched}
              disabled={sameAsCorrespondence}
              required
            />
          </div>
          <div className='col-12'>
            <FormTextBox
              name='permanent_outside_nepal_address_line_2'
              label='Permanent Addr Line 2'
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={handleUppercaseChange(handleChange)}
              errors={errors}
              touched={touched}
              disabled={sameAsCorrespondence}
              required
            />
          </div>
          <div className='col-12'>
            <FormTextBox
              name='permanent_outside_nepal_address_line_3'
              label='Permanent Addr Line 3'
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={handleUppercaseChange(handleChange)}
              errors={errors}
              touched={touched}
              disabled={sameAsCorrespondence}
            />
          </div>
        </>
      )}
      <div className='col-md-6 col-12'>
        <FormTextBox
          type='number'
          name='permanent_phone_number_residence'
          label='Phone Number(Res.)'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
          disabled={sameAsCorrespondence}
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          type='number'
          name='permanent_phone_number_work'
          label='Phone Number(Work.)'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
          disabled={sameAsCorrespondence}
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          type='number'
          name='permanent_mobile_number'
          label='Mobile Number'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
          disabled={sameAsCorrespondence}
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='permanent_email'
          label='Official Email'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleUppercaseChange(handleChange)}
          errors={errors}
          touched={touched}
          disabled={sameAsCorrespondence}
        />
      </div>
    </>
  )
}

export default PersonalAddressSection
