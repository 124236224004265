import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface IBranchCheckerAndAccountList {
  loading: boolean
  success: boolean
  data: {
    new_accounts: Record<string, string>[]
    meta: Record<string, number>
  }
}

const individualJointMinorInitialState: IBranchCheckerAndAccountList = {
  loading: false,
  success: false,
  data: {new_accounts: [], meta: {}},
}

export const individualJointMinorReducer = (
  state: IBranchCheckerAndAccountList = individualJointMinorInitialState,
  action: ActionWithPayload<IBranchCheckerAndAccountList>
) => {
  switch (action.type) {
    case actionTypes.GET_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_LIST_START:
      return {...state, loading: true}
    case actionTypes.GET_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_SUCCESS:
      return {...state, loading: false, data: action.payload}
    case actionTypes.GET_NEW_CIF_AND_ACCOUNT_INDIVIDUAL_JOINT_MINOR_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IPostAccountPreliminaryBranchReviewState {
  success: boolean
  loading: boolean
  data?: any
}

const accountPreliminaryBranchReviewInitialState: IPostAccountPreliminaryBranchReviewState = {
  loading: false,
  success: false,
  data: {},
}

export const postAccountPreliminaryBranchReviewReducer = (
  state: IPostAccountPreliminaryBranchReviewState = accountPreliminaryBranchReviewInitialState,
  action: ActionWithPayload<any>
) => {
  switch (action.type) {
    case actionTypes.POST_ACCOUNT_PRELIMINARY_BRANCH_REVIEW_START:
      return {...state, loading: true, success: false}
    case actionTypes.POST_ACCOUNT_PRELIMINARY_BRANCH_REVIEW_SUCCESS:
      return {...state, loading: false, success: true, data: action.payload}
    case actionTypes.POST_ACCOUNT_PRELIMINARY_BRANCH_REVIEW_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}

export interface IBranchCheckerAndAccountDetailsById {
  loading: boolean
  success: boolean
  data: any
}

const individualJointMinorDetailsInitialState: IBranchCheckerAndAccountDetailsById = {
  loading: false,
  success: false,
  data: {},
}

export const individualJointMinorDetailsReducer = (
  state: IBranchCheckerAndAccountDetailsById = individualJointMinorDetailsInitialState,
  action: ActionWithPayload<IBranchCheckerAndAccountDetailsById>
) => {
  switch (action.type) {
    case actionTypes.GET_NEW_CIF_AND_ACCOUNT_DETAILS_BY_ID_START:
      return {...state, loading: true}
    case actionTypes.GET_NEW_CIF_AND_ACCOUNT_DETAILS_BY_ID_SUCCESS:
      return {...state, loading: false, data: action.payload}
    case actionTypes.GET_NEW_CIF_AND_ACCOUNT_DETAILS_BY_ID_FINISH:
      return {...state, loading: false}
    default:
      return state
  }
}
