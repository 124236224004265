import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'
import useSubmitDocuments from 'src/app/queries/Account Preliminary/post/usePostDocumentsAccountPreliminary'

const requestUrl = 'http://localhost:55030'
interface DigitalSignatureSectionProps {
  SignatureId: string
  accountId: string
  setResetForm: (resetForm: boolean) => void
  resetForm: boolean
}

const DigitalSignatureSection: React.FC<DigitalSignatureSectionProps> = ({
  SignatureId,
  accountId,
  setResetForm,
  resetForm,
}) => {
  const [signatureImg, setSignatureImg] = useState<string>('')
  const [signatureConfirmImg, setSignatureConfirmImg] = useState<string>('')
  const [isSigning, setIsSigning] = useState<boolean>(false)
  const {mutate, isLoading} = useSubmitDocuments()

  const base64ToBlob = (base64: any, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(base64.split(',')[1])
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, {type: contentType})
  }

  const handleSign = async () => {
    setIsSigning(true)
    try {
      const response = await axios.get(`${requestUrl}/scan?mode=data`)
      if (response.data.status) {
        const base64 = `data:image/jpg;base64,${response.data.data}`
        setSignatureImg(base64)
      } else {
        toast.error(`Error: ${response.data.message}`)
      }
    } catch (error: any) {
      toast.error(`Error: ${error?.message}`)
    } finally {
      setIsSigning(false)
    }
  }

  const handleConfirm = () => {
    setSignatureConfirmImg(signatureImg)

    const blob = base64ToBlob(signatureImg, 'image/png')
    const formData = new FormData()
    formData.append('image', blob, 'signature.png')
    formData.append('documentTypeId', SignatureId)
    mutate({formData, accountId: accountId})
  }

  const handleCancel = () => {
    setSignatureImg('')
    setSignatureConfirmImg('')
  }

  const handleReset = () => {
    handleCancel()
  }

  useEffect(() => {
    if (resetForm) {
      handleReset()
      setResetForm(false)
    }
  }, [resetForm])
  return (
    <div className='border'>
      <div className='bg-warning text-white px-3 py-1 d-flex justify-content-between align-items-center'>
        <span>Signature Verify</span>
        <button
          className='btn-sm text-white'
          style={{backgroundColor: '#006400'}}
          onClick={handleReset}
        >
          Reset
        </button>
      </div>
      <div className='row m-5'>
        <div className='col-4'>
          <div className='border'>
            <div style={{ height: '20rem' }} className="d-flex justify-content-center align-items-center">
              {signatureImg && <img className="img-fluid"  src={signatureImg} alt='Signature' />}
            </div>
            <div className='bg-secondary p-2'>
              <button
                className='btn-sm btn-primary text-white'
                onClick={handleSign}
                disabled={isSigning}
              >
                {isSigning ? 'Signing...' : 'Sign'}
              </button>
            </div>
          </div>
        </div>
        <div className='col-4'>
          <div className='border'>
            <div className='bg-secondary p-2'>
              <strong>Confirm</strong>
            </div>
            <div>
              <div className='d-flex flex-column justify-content-center align-items-center p-4'>
                <span>Are you sure?</span>
                <div className='d-flex gap-2 mt-2'>
                  <button
                    className='btn-sm btn-primary text-white'
                    onClick={handleConfirm}
                    disabled={!signatureImg}
                  >
                    OK
                  </button>
                  <button
                    className='btn-sm btn-warning text-white'
                    onClick={handleCancel}
                    disabled={!signatureImg}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-4'>
          <div className='border'>
            <div style={{ height: '20rem' }} className="d-flex justify-content-center align-items-center">
              {signatureConfirmImg && <img className="img-fluid" src={signatureConfirmImg} alt='Confirmed Signature' />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DigitalSignatureSection
