import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL
const ACCOUNT_PRELIMINARY_API = `${API_URL}/corporate-newcif-newaccount`

type UpdatePayloadType = {
  id: string
  data: any
}

const updateCorporateAccount = async (payload: UpdatePayloadType) => {
  const {id, data} = payload
  try {
    const response = await axios.patch(`${ACCOUNT_PRELIMINARY_API}/${id}`, data)
    return response.data
  } catch (error: any) {
    throw new Error(`${error.response?.data?.message || 'error updating account'}`)
  }
}

const useUpdateCorporateAccount = () => {
  const queryClient = useQueryClient()
  return useMutation(updateCorporateAccount, {
    onSuccess: (data) => {
      if (data?.resCod == 200) {
        toast.success(data?.resDesc ?? 'Account updated successfully!')
      }
      queryClient.invalidateQueries('getCorporateBranchMakerAccountLists')
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error.message || 'An error occurred'}`)
    },
  })
}

export default useUpdateCorporateAccount
