import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {handleUppercaseChange} from 'src/app/modules/CreateNewCIFAndCustomerAccount/createNewAccount/components'
import {MasterDataModel} from 'src/app/modules/masterData/Model'
import FormCheckbox from 'src/cms/helpers/components/forms/FormCheckbox'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {RootState} from 'src/setup'

interface RegistrationAddressSectionProps {
  handleChange: any
  touched: any
  errors: any
  setFieldValue: any
  values: any
  initialValues: any
}

const RegistrationAddressSection = ({
  handleChange,
  touched,
  errors,
  setFieldValue,
  values,
  initialValues,
}: RegistrationAddressSectionProps) => {
  const [sameAsCorrespondence, setSameAsCorrespondence] = useState(false)

  const [districtList, setDistrictList] = useState<{[key: string]: string | number}[]>()
  const [municipalityList, setMunicipalityList] = useState<{[key: string]: string | number}[]>()

  const {district, province, municipality, loading} = useSelector((state: RootState) => state.place)
  const {data: countries}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.countriesList
  )

  useEffect(() => {
    if (values.province) {
      const districts = district?.filter((d) => {
        return d?.province_id === values?.province
      })
      setDistrictList(districts)
    } else {
      setDistrictList(district)
    }
  }, [values.province, district])

  useEffect(() => {
    if (values.district) {
      const municipalities = municipality?.filter((m) => {
        return m.district_id === values.district || m.title === 'Others'
      })
      setMunicipalityList(municipalities)
    } else {
      setMunicipalityList(municipality)
    }
  }, [values.district, municipality])

  // Resetting Municipality on District change and both on province change
  useEffect(() => {
    if (!sameAsCorrespondence) {
      if (values.district && values.district !== initialValues.district) {
        setFieldValue('district', '')
      }
    }
  }, [values.province, sameAsCorrespondence])
  useEffect(() => {
    if (!sameAsCorrespondence) {
      if (values.municipality && values.municipality !== initialValues.municipality) {
        setFieldValue('municipality', '')
      }
    }
  }, [values.district, sameAsCorrespondence])

  useEffect(() => {
    if (sameAsCorrespondence) {
      setFieldValue('country', values.correspondence_country)
      setFieldValue('phone_number', values.correspondence_phone_number)
      setFieldValue('mobile_number', values.correspondence_mobile_number)
      setFieldValue('email', values.correspondence_email)
      if (
        values.correspondence_country === countries.find((country) => country.title === 'Nepal')?.id
      ) {
        setFieldValue('house_number', values.correspondence_house_number)
        setFieldValue('tole', values.correspondence_tole)
        setFieldValue('ward_number', values.correspondence_ward_number)
        setFieldValue('province', values.correspondence_province)
        setFieldValue('district', values.correspondence_district)
        setFieldValue('municipality', values.correspondence_municipality)
        setFieldValue('municipality_other', values.correspondence_municipality_other)
      } else {
        setFieldValue(
          'permanent_outside_nepal_address_line_1',
          values.correspondence_outside_nepal_address_line_1
        )
        setFieldValue(
          'permanent_outside_nepal_address_line_2',
          values.correspondence_outside_nepal_address_line_2
        )
        setFieldValue(
          'permanent_outside_nepal_address_line_3',
          values.correspondence_outside_nepal_address_line_3
        )
      }
    }
  }, [
    sameAsCorrespondence,
    values.correspondence_country,
    values.correspondence_house_number,
    values.correspondence_tole,
    values.correspondence_province,
    values.correspondence_district,
    values.correspondence_ward_number,
    values.correspondence_municipality,
    values.correspondence_municipality_other,
    values.correspondence_phone_number_work,
    values.correspondence_mobile_number,
    values.correspondence_email,
    values.correspondence_outside_nepal_address_line_1,
    values.correspondence_outside_nepal_address_line_2,
    values.correspondence_outside_nepal_address_line_3,
  ])

  return (
    <>
      <div className='col-12'>
        <h2 className='text-center'>REGISTRATION ADDRESS</h2>
      </div>
      <FormCheckbox
        name='checkBox'
        label=' '
        checkBoxText='Same as Correspondence Address'
        containerClassName='col-12 my-6'
        onChange={(e) => {
          setSameAsCorrespondence(e.target.checked)
        }}
        errors={errors}
        touched={touched}
      />
      <div className='col-md-6 col-12'>
        <FormSelect
          name='country'
          label='Country'
          containerClassName='col-12'
          labelClassName='col-12'
          options={countries?.map((country) => ({label: country.title, value: country.id})) ?? []}
          setFieldValue={setFieldValue}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          values={values}
          required
          disabled={sameAsCorrespondence}
        />
      </div>
      {values.country === countries.find((country) => country.title === 'Nepal')?.id ? (
        <>
          <div className='col-md-6 col-12'>
            <FormTextBox
              name='house_number'
              label='House Number'
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              disabled={sameAsCorrespondence}
            />
          </div>
          <div className='col-md-6 col-12'>
            <FormTextBox
              name='tole'
              label='Street Name/Tole'
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              required
              disabled={sameAsCorrespondence}
            />
          </div>
          <div className='col-md-6 col-12'>
            <FormTextBox
              name='ward_number'
              label='Ward Number'
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              required
              disabled={sameAsCorrespondence}
            />
          </div>
          <div className='col-md-6 col-12'>
            <FormSelect
              name='province'
              label='Province'
              containerClassName='col-12'
              labelClassName='col-12'
              options={
                province?.map((provinceData) => ({
                  label:
                    typeof provinceData.title === 'string'
                      ? provinceData.title
                      : provinceData.title.toString(),
                  value:
                    typeof provinceData.id === 'string'
                      ? provinceData.id
                      : provinceData.id.toString(),
                })) ?? []
              }
              setFieldValue={setFieldValue}
              onChange={handleChange}
              errors={errors}
              touched={touched}
              values={values}
              required
              disabled={sameAsCorrespondence}
            />
          </div>
          <div className='col-md-6 col-12'>
            <FormSelect
              name='district'
              label='District'
              containerClassName='col-12'
              labelClassName='col-12'
              options={
                districtList?.map((districtData) => ({
                  label:
                    typeof districtData.title === 'string'
                      ? districtData.title
                      : districtData.title.toString(),
                  value:
                    typeof districtData.id === 'string'
                      ? districtData.id
                      : districtData.id.toString(),
                })) ?? []
              }
              setFieldValue={setFieldValue}
              onChange={handleChange}
              errors={errors}
              touched={touched}
              values={values}
              required
              disabled={sameAsCorrespondence}
            />
          </div>
          <div className='col-md-6 col-12'>
            <FormSelect
              name='municipality'
              label='Municipality'
              containerClassName='col-12'
              labelClassName='col-12'
              options={
                municipalityList?.map((municipalityData) => ({
                  label:
                    typeof municipalityData.title === 'string'
                      ? municipalityData.title
                      : municipalityData.title.toString(),
                  value:
                    typeof municipalityData.id === 'string'
                      ? municipalityData.id
                      : municipalityData.id.toString(),
                })) ?? []
              }
              setFieldValue={setFieldValue}
              onChange={handleChange}
              errors={errors}
              touched={touched}
              values={values}
              required
              disabled={sameAsCorrespondence}
            />
          </div>
          {values.municipality === municipality?.find((m) => m?.title === 'Others')?.id && (
            <div className='col-md-6 col-12'>
              <FormTextBox
                name='municipality_other'
                label='Other Municipality'
                containerClassName='col-12'
                labelClassName='col-12'
                onChange={handleUppercaseChange(handleChange)}
                errors={errors}
                touched={touched}
                required
                disabled={sameAsCorrespondence}
              />
            </div>
          )}
        </>
      ) : (
        <>
          <div className='col-12'>
            <FormTextBox
              name='permanent_outside_nepal_address_line_1'
              label='Permanent Address Line 1'
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={handleUppercaseChange(handleChange)}
              errors={errors}
              touched={touched}
              disabled={sameAsCorrespondence}
              required
            />
          </div>
          <div className='col-12'>
            <FormTextBox
              name='permanent_outside_nepal_address_line_2'
              label='Permanent Address Line 2'
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={handleUppercaseChange(handleChange)}
              errors={errors}
              touched={touched}
              disabled={sameAsCorrespondence}
              required
            />
          </div>
          <div className='col-12'>
            <FormTextBox
              name='permanent_outside_nepal_address_line_3'
              label='Permanent Address Line 3'
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={handleUppercaseChange(handleChange)}
              errors={errors}
              touched={touched}
              disabled={sameAsCorrespondence}
            />
          </div>
        </>
      )}
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='mobile_number'
          label='Mobile Number'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
          required
          disabled={sameAsCorrespondence}
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='phone_number'
          label='Office Phone No.'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleChange}
          errors={errors}
          touched={touched}
          disabled={sameAsCorrespondence}
        />
      </div>
      <div className='col-md-6 col-12'>
        <FormTextBox
          name='email'
          label='Official Email'
          containerClassName='col-12'
          labelClassName='col-12'
          onChange={handleUppercaseChange(handleChange)}
          errors={errors}
          touched={touched}
          required
          disabled={sameAsCorrespondence}
        />
      </div>
    </>
  )
}

export default RegistrationAddressSection
