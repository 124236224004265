import React, {useEffect} from 'react'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import usePostAccountPreliminary from 'src/app/queries/Account Preliminary/post/usePostAccountPreliminary'
import DocumentsPreview from 'src/app/modules/common/components/cpuMaker/IndividualJointMinor/DocumentsPreview'
import AccountStatusLog from 'src/app/modules/common/AccountStatusLog'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import InfoCell from 'src/app/modules/branchChecker/reviewCustomerAccountInformation/newCIFAndAccountList/components/details/component/InfoCell'
import FormCheckbox from 'src/cms/helpers/components/forms/FormCheckbox'
import {RootState} from 'src/setup'
import {MasterDataModel} from 'src/app/modules/masterData/Model'
import * as masterDataRedux from 'src/app/modules/masterData/redux'

interface RouteParams {
  id: string
}

const validationSchema = Yup.object().shape({
  status: Yup.string().required('Status Type is required'),
  remarks: Yup.string().required('Remarks is required'),
})

const allStatusType = [
  {label: 'APPROVE', value: 'approve'},
  {label: 'REJECT', value: 'reject'},
  {label: 'REVERT BACK TO BRANCH MAKER', value: 'revert'},
]

const BranchCheckerAncillaryReviewPage = () => {
  const {id} = useParams<RouteParams>()
  const dispatch = useDispatch()
  const history = useHistory()
  const {state: data}: {state: any} = useLocation()
  const {mutate: postResponse, isLoading: postRespLoading} = usePostAccountPreliminary()

  const {data: ancillaryRemarkList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state?.ancillaryRemarkList
  )

  useEffect(() => {
    dispatch(masterDataRedux.actions.getAllMasterData())
  }, [])

  const createFullName = (firstName: string, middleName: string, lastName: string) => {
    const fn = firstName || ''
    const mn = middleName || ''
    const ln = lastName || ''
    return `${fn} ${mn} ${ln}`.trim()
  }

  const getFieldValue = (field: any, key: string) => {
    return field ? field[key] : ''
  }

  return (
    <div className='modal-container '>
      <div className='heading__component__title fw-bolder mb-5' data-cy='dynamic-setting-heading'>
        {'Ancillary Service Review'}
      </div>
      <div className='row mb-6'>
        <div className='col-md-6 mb-2'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-9 '>
              <DocumentsPreview data={data} />
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='card'>
            <div className='card-body' style={{maxHeight: '640px', overflowY: 'auto'}}>
              <div className='row'>
                <InfoCell label='Account Type' value={data?.accountType} />
                <InfoCell
                  label='Full Name'
                  value={createFullName(data?.first_name, data?.middle_name, data?.last_name)}
                />
              </div>
              <div className='row'>
                <InfoCell label='Email' value={data?.email} />
                <InfoCell label='Mobile Number' value={data?.mobile_number} />
              </div>
              <div className='row'>
                <InfoCell label='House No' value={data?.house_number ?? ''} />
                <InfoCell label='Street Name/Tole' value={data?.tole} />
              </div>
              <div className='row'>
                <InfoCell label='ID Type' value={getFieldValue(data?.id_type_id, 'title')} />
                <InfoCell label='ID Number' value={data?.id_number} />
              </div>
              <div className='row'>
                <InfoCell
                  label='Customer Category'
                  value={getFieldValue(data?.customerCategoryId, 'title')}
                />
                <InfoCell
                  label='Customer Classification'
                  value={getFieldValue(data?.customerClassificationId, 'title')}
                />
              </div>
              <div className='row'>
                <InfoCell label='Country' value={getFieldValue(data?.country_id, 'title')} />
                <InfoCell label='Nationality' value={getFieldValue(data?.nationality, 'title')} />
              </div>
              <div className='row'>
                <InfoCell label='Language' value={data?.language} />
                <InfoCell
                  label='Location(Branch Location Code)'
                  value={data?.branch_location_code}
                />
              </div>
              <div className='row'>
                <InfoCell
                  label='Tax Category'
                  value={getFieldValue(data?.taxCategoryId, 'title')}
                />
                <InfoCell label='Tax Group' value={getFieldValue(data?.taxGroupId, 'cbs_code')} />
              </div>
              <div className='row'>
                <InfoCell label='AML Risk Rating' value={data?.aml_risk_rating} />
                <InfoCell label='Cheque Book Name' value={data?.cheque_book_name} />
              </div>

              <Formik
                initialValues={{
                  status: '',
                  remarks: '',
                  mobile_banking: data?.mobile_banking == 1 ? true : false,
                  debit_card: data?.debit_card == 1 ? true : false,
                  mobile_banking_no_reason: '',
                  other_mobile_banking_no_reason: '',
                  debit_card_no_reason: '',
                  other_debit_card_no_reason: '',
                }}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  postResponse(
                    {id, data: values},
                    {
                      onSuccess: () => {
                        history.goBack()
                      },
                    }
                  )
                }}
              >
                {({handleChange, errors, touched, values, setFieldValue}) => (
                  <Form>
                    <div className='row '>
                      <div className='mb-4 col-sm-12 col-xs-12'>
                        <FormCheckbox
                          labelClassName='col-sm-12'
                          containerClassName='col-sm-12'
                          name='mobile_banking'
                          touched={touched}
                          errors={errors}
                          onChange={handleChange}
                          checked={values.mobile_banking}
                          checkBoxText={'Mobile Banking.'}
                          data-cy='CIF-check-box-field'
                        />
                      </div>
                      <div className='col-md-12'>
                        <span className='form-review-field fw-bold fs-6 opacity-50'>
                          {'Reason For Mobile Banking Rejection(Branch Maker)'}
                        </span>
                        <p>
                          {data?.mobile_banking_no_reason?.remarks ??
                            data?.other_mobile_banking_no_reason}
                        </p>
                      </div>
                      <div className='col-md-12 '>
                        <FormSelect
                          labelClassName='col-md-12'
                          containerClassName='col-md-12'
                          label='Reason for Mobile Banking Rejection (Branch Checker)'
                          name='mobile_banking_no_reason'
                          isClearable={true}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          options={ancillaryRemarkList?.map((item) => ({
                            label: item.remarks?.toLocaleUpperCase() || '',
                            value: item.id,
                          }))}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                      <div className='col-md-12'>
                        {values?.mobile_banking_no_reason ===
                          ancillaryRemarkList.find((list) => list.remarks?.includes('Other'))
                            ?.id && (
                          <FormTextBox
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            type='text'
                            placeholder='Reason For Mobile Banking Rejection'
                            label='Other reason for mobile banking rejection'
                            name='other_mobile_banking_no_reason'
                            disabled={false}
                            onChange={handleChange}
                            uppercase={true}
                            errors={errors}
                            touched={touched}
                            as='textarea'
                          />
                        )}
                      </div>
                      <div className='mb-4 col-sm-12 col-xs-12'>
                        <FormCheckbox
                          labelClassName='col-sm-12'
                          containerClassName='col-sm-12'
                          name='debit_card'
                          touched={touched}
                          errors={errors}
                          onChange={handleChange}
                          checked={values.debit_card}
                          checkBoxText={'Debit Card.'}
                          data-cy='CIF-check-box-field'
                        />
                      </div>
                      <div className='col-md-12'>
                        <span className='form-review-field fw-bold fs-6 opacity-50'>
                          {'Reason For Debit Card Rejection(Branch Maker)'}
                        </span>
                        <p>
                          {data?.debit_card_no_reason?.remarks ?? data?.other_debit_card_no_reason}
                        </p>
                      </div>
                      <div className='col-md-12 '>
                        <FormSelect
                          labelClassName='col-md-12'
                          containerClassName='col-md-12'
                          label='Reason for Debit Card Rejection (Branch Checker)'
                          name='debit_card_no_reason'
                          isClearable={true}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          options={ancillaryRemarkList?.map((item) => ({
                            label: item.remarks?.toLocaleUpperCase() || '',
                            value: item.id,
                          }))}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                      <div className='col-md-12'>
                        {values?.debit_card_no_reason ===
                          ancillaryRemarkList.find((list) => list.remarks?.includes('Other'))
                            ?.id && (
                          <FormTextBox
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            type='text'
                            placeholder='Reason For Mobile Banking Rejection'
                            label='Other reason for mobile banking rejection'
                            name='other_debit_card_no_reason'
                            disabled={false}
                            onChange={handleChange}
                            uppercase={true}
                            errors={errors}
                            touched={touched}
                            as='textarea'
                          />
                        )}
                      </div>
                      <div className='col-md-6'>
                        <FormSelect
                          labelClassName='col-md-12'
                          containerClassName='col-md-12'
                          label='Status Type'
                          name='status'
                          isClearable={true}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          options={allStatusType.map((item) => ({
                            label: item.label,
                            value: item.value,
                          }))}
                          required={true}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                      <div className='border my-2 mb-6'></div>
                      <FormTextBox
                        containerClassName='col-md-12'
                        labelClassName='col-md-12'
                        type='text'
                        placeholder='Remarks'
                        label='Remarks'
                        name='remarks'
                        disabled={false}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required={true}
                        data-cy='add-id-number-text-field'
                        as='textarea'
                      />
                      <div className='d-flex justify-content-end mt-3'>
                        <button
                          type='button'
                          onClick={() => history.goBack()}
                          className='btn btn-secondary mx-4'
                        >
                          Back
                        </button>
                        <button
                          disabled={postRespLoading}
                          type='submit'
                          className='btn btn-primary'
                        >
                          {postRespLoading ? 'Sending...' : 'Send'}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <AccountStatusLog data={data} />
    </div>
  )
}

export default BranchCheckerAncillaryReviewPage
