import DetailCell from '../DetailCell'

interface RelativeInformationSectionProps {
  data?: any
}

const RelativeInformationSection = ({data}: RelativeInformationSectionProps) => {
  return (
    <>
      <div className='row'>
        <DetailCell label='Spouse Name' value={data?.kyc_information?.spouse_name} />
        <DetailCell label='Father Name' value={data?.kyc_information?.father_name} />
      </div>
      <div className='row'>
        <DetailCell label='Mother Name' value={data?.kyc_information?.mother_name} />
        <DetailCell label='Grandfather Name' value={data?.kyc_information?.grand_father_name} />
      </div>
      <div className='row'>
        <DetailCell label='Grandmother Name' value={data?.kyc_information?.grand_mother_name} />
        <DetailCell
          label='Daughter-in-law Name'
          value={data?.kyc_information?.daughter_in_law_name}
        />
      </div>
      <div className='row'>
        <DetailCell label='Father-in-law Name' value={data?.kyc_information?.father_in_law_name} />
        <DetailCell label='Guardian Name' value={data?.guardian_name} />
      </div>
    </>
  )
}

export default RelativeInformationSection
