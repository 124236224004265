import {adToBs, bsToAd} from '@sbmdkl/nepali-date-converter'
import moment from 'moment'
import {ChangeEvent, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {MasterDataModel} from 'src/app/modules/masterData/Model'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormNepaliCalendarDatePicker from 'src/cms/helpers/components/forms/form-fields/FormNepaliCalendarDatePicker'
import {RootState} from 'src/setup'

interface IdentificationDocumentSectionProps {
  handleChange: any
  touched: any
  errors: any
  setFieldValue: any
  values: any
}

const IdentificationDocumentSection = ({
  handleChange,
  errors,
  touched,
  setFieldValue,
  values,
}: IdentificationDocumentSectionProps) => {
  const {data: qualifications}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.qualificationsList
  )
  const {data: uniqueIdIssuedBy}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.individualUniqueIdList
  )
  const {data: identityTypeList}: {data: MasterDataModel[]} = useSelector(
    (state: RootState) => state.idTypeList
  )
  const {district, loading} = useSelector((state: RootState) => state.place)

  return (
    <>
      <div className=' d-flex my-7 col-12'>
        <label className='form-label fw-bolder text-dark fs-6 me-8 required '>
          Basic Identification
        </label>
        <div className='d-flex'>
          {[
            {label: 'Citizenship', value: 'citizenship'},
            {label: 'Passport', value: 'passport'},
            {label: 'Others', value: 'other'},
          ].map((identityDocumentType) => (
            <FormRadio
              key={identityDocumentType.value}
              checkBoxText={identityDocumentType.label}
              errors={errors}
              containerClassName='col-md-5'
              name='basic_identification'
              defaultValue='citizenship'
              onChange={handleChange}
              touched={touched}
              value={identityDocumentType.value}
            />
          ))}
        </div>
      </div>
      {values.basic_identification === 'citizenship' && (
        <>
          <div className='col-md-6 col-12'>
            <FormTextBox
              name='citizenship_number'
              label='Citizenship No.'
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              required
            />
          </div>
          <div className='col-md-6 col-12'>
            <FormSelect
              name='citizenship_issue_district'
              label='Issuance by District'
              containerClassName='col-12'
              labelClassName='col-12'
              options={
                district?.map((districtData) => ({
                  label:
                    typeof districtData.title === 'string'
                      ? districtData.title
                      : districtData.title.toString(),
                  value:
                    typeof districtData.id === 'string'
                      ? districtData.id
                      : districtData.id.toString(),
                })) ?? []
              }
              setFieldValue={setFieldValue}
              onChange={handleChange}
              errors={errors}
              touched={touched}
              values={values}
              required
            />
          </div>
          <div className='col-md-6 col-12'>
            <FormNepaliCalendarDatePicker
              nepaliFormName='citizenship_issue_date_bs'
              englishFormName='citizenship_issue_date_ad'
              label='Date of Issuance(B.S.)'
              setFieldValue={setFieldValue}
              touched={touched}
              values={values}
              required
            />
          </div>
          <div className='col-md-6 col-12'>
            <FormTextBox
              type='date'
              name='citizenship_issue_date_ad'
              label='Date of Issuance(A.D.)'
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={(e: any) => {
                setFieldValue('citizenship_issue_date_bs', e.target.value && adToBs(e.target.value))
                handleChange(e)
              }}
              errors={errors}
              touched={touched}
              required
            />
          </div>
        </>
      )}
      {values.basic_identification === 'passport' && (
        <>
          <div className='col-md-6 col-12'>
            <FormTextBox
              name='passport_number'
              label='Passport No.'
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              required
            />
          </div>
          <div className='col-md-6 col-12'>
            <FormSelect
              name='passport_issue_district'
              label='Place of Issuance'
              containerClassName='col-12'
              labelClassName='col-12'
              options={
                district?.map((districtData) => ({
                  label:
                    typeof districtData.title === 'string'
                      ? districtData.title
                      : districtData.title.toString(),
                  value:
                    typeof districtData.id === 'string'
                      ? districtData.id
                      : districtData.id.toString(),
                })) ?? []
              }
              setFieldValue={setFieldValue}
              onChange={handleChange}
              errors={errors}
              touched={touched}
              values={values}
              required
            />
          </div>
          <div className='col-md-6 col-12'>
            <FormTextBox
              type='date'
              name='passport_issue_date'
              label='Date of Issuance'
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              required
            />
          </div>
          <div className='col-md-6 col-12'>
            <FormTextBox
              type='date'
              name='passport_expiry_date'
              label='Date of Expiry'
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              required
            />
          </div>
        </>
      )}
      {values.basic_identification === 'other' && (
        <>
          <div className='col-md-6 col-12'>
            <FormSelect
              name='type_of_identity_card'
              label='Type of Identity Card'
              containerClassName='col-12'
              labelClassName='col-12'
              options={
                identityTypeList.map((idType) => ({
                  label: idType.title,
                  value: idType.id,
                })) ?? []
              }
              setFieldValue={setFieldValue}
              onChange={handleChange}
              errors={errors}
              touched={touched}
              values={values}
              required
            />
          </div>
          <div className='col-md-6 col-12'>
            <FormTextBox
              name='id_card_number'
              label='ID Card No.'
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              required
            />
          </div>
          <div className='col-md-6 col-12'>
            <FormTextBox
              type='date'
              name='id_card_issue_date_ad'
              label='Date of Issuance(A.D.)'
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={(e: any) => {
                setFieldValue('id_card_issue_date', e.target.value && adToBs(e.target.value))
                handleChange(e)
              }}
              errors={errors}
              touched={touched}
              required
            />
          </div>
          <div className='col-md-6 col-12'>
            <FormNepaliCalendarDatePicker
              nepaliFormName='id_card_issue_date'
              englishFormName='id_card_issue_date_ad'
              label='Date of Issuance(B.S.)'
              setFieldValue={setFieldValue}
              touched={touched}
              values={values}
              required
            />
          </div>
          <div className='col-md-6 col-12'>
            <FormTextBox
              type='date'
              name='id_card_expiry_date_ad'
              label='Date of Expriy(A.D.)'
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={(e: any) => {
                setFieldValue('id_card_expiry_date', e.target.value && adToBs(e.target.value))
                handleChange(e)
              }}
              errors={errors}
              touched={touched}
              required
            />
          </div>
          <div className='col-md-6 col-12'>
            <FormNepaliCalendarDatePicker
              nepaliFormName='id_card_expiry_date'
              englishFormName='id_card_expiry_date_ad'
              label='Date of Expiry(B.S.)'
              setFieldValue={setFieldValue}
              touched={touched}
              values={values}
              required
            />
          </div>
          <div className='col-md-6 col-12'>
            <FormSelect
              name='id_card_issuing_office'
              label='Place of Issuance'
              containerClassName='col-12'
              labelClassName='col-12'
              options={
                district?.map((districtData) => ({
                  label:
                    typeof districtData.title === 'string'
                      ? districtData.title
                      : districtData.title.toString(),
                  value:
                    typeof districtData.id === 'string'
                      ? districtData.id
                      : districtData.id.toString(),
                })) ?? []
              }
              setFieldValue={setFieldValue}
              onChange={handleChange}
              errors={errors}
              touched={touched}
              values={values}
              required
            />
          </div>
        </>
      )}
      <div className='row'>
        <div className='col-md-6 col-xs-12'>
          <FormSelect
            name='unique_id_issued_by'
            label='Unique ID Issued By'
            containerClassName='col-12'
            labelClassName='col-12'
            options={
              uniqueIdIssuedBy.map((uid) => ({
                label: uid.title,
                value: uid.id,
              })) ?? []
            }
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
            required
          />
        </div>
        <div className='col-md-6 col-xs-12'>
          {values.unique_id_issued_by ===
            uniqueIdIssuedBy.find((uid) => uid.title === 'OTHERS')?.id && (
            <FormTextBox
              name='unique_id_issued_by_other'
              label='Other Unique ID Issued By'
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              required
            />
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6 col-xs-12'>
          <FormSelect
            name='education_qualification'
            label='Education Qualification'
            containerClassName='col-12'
            labelClassName='col-12'
            options={
              qualifications.map((qualification) => ({
                label: qualification.title,
                value: qualification.id,
              })) ?? []
            }
            setFieldValue={setFieldValue}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            values={values}
            required
          />
        </div>
        {values.education_qualification ===
          qualifications.find((qualication) => qualication.title === 'OTHERS')?.id && (
          <div className='col-md-6 col-xs-12'>
            <FormTextBox
              name='education_qualification_others'
              label='Other Education Qualification'
              containerClassName='col-12'
              labelClassName='col-12'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              required
            />
          </div>
        )}
      </div>
    </>
  )
}

export default IdentificationDocumentSection
