import React, {useState} from 'react'
import {Tab, Tabs} from 'react-bootstrap-v5'
import IndividualJointMinorTable from './tables/IndividualJointMinorTable'
import CorporateBankTable from './tables/CorporateBankTable'
import {useHistory} from 'react-router-dom'
import {FaPlus} from 'react-icons/fa'

const BranchMakerNewCIFAccountListPage = () => {
  const history = useHistory()
  const [activeKey, setActiveKey] = useState('individualJointMinor')

  return (
    <>
      <div className='modal-container shadow rounded' data-cy='new-cif-account-list-modal'>
        <div className='bg-white p-5'>
          <div className='row g-0 border-bottom align-items-center mb-3 py-3'>
            <div className='col-lg-6'>
              <div className='heading__component__title fw-bolder'>New Account Lists</div>
            </div>
            <div className='col-lg-6'>
              <div className='d-flex justify-content-end'>
                <div
                  className='sub_button'
                  onClick={() => history.push('/create-new-cif-and-account/create-new-account')}
                >
                  <button className='dt-btn dt-btn-primary' data-cy='add-button'>
                    <FaPlus className='me-2' />
                    Create New Account
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Tabs
            activeKey={activeKey}
            onSelect={(k) => setActiveKey(k ?? 'individualJointMinor')}
            id='branchMaker-new-cif-account-tabs'
            className='mb-5'
            data-cy='new-cif-account-list-tabs'
          >
            <Tab
              eventKey='individualJointMinor'
              title='Individual/Joint/Minor'
              data-cy='tab-individual-joint-minor'
            >
              <div className='card-body bg-white' data-cy='tab-content-individual-joint-minor'>
                <IndividualJointMinorTable />
              </div>
            </Tab>
            <Tab eventKey='corporateBank' title='Corporate/Banks' data-cy='tab-corporate-banks'>
              <div className='card-body bg-white' data-cy='tab-content-corporate-banks'>
                <CorporateBankTable />
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  )
}

export default BranchMakerNewCIFAccountListPage
